import { default as api_45legacyYpEQPlZRy5Meta } from "/codebuild/output/src298072591/src/horizon/pages/api-legacy.vue?macro=true";
import { default as awardsxdyRtwGudcMeta } from "/codebuild/output/src298072591/src/horizon/pages/awards.vue?macro=true";
import { default as programsAQhRkx8TfKMeta } from "/codebuild/output/src298072591/src/horizon/pages/benefits/programs.vue?macro=true";
import { default as _91slug_93xJPv69u6BbMeta } from "/codebuild/output/src298072591/src/horizon/pages/blog/[slug].vue?macro=true";
import { default as _91slug_93LpsP7YZ1tAMeta } from "/codebuild/output/src298072591/src/horizon/pages/blog/category/[slug].vue?macro=true";
import { default as indexgVyS9NOkmcMeta } from "/codebuild/output/src298072591/src/horizon/pages/blog/index.vue?macro=true";
import { default as call_45for_45speakers_45at_45samsara_45beyondW4fU18e24iMeta } from "/codebuild/output/src298072591/src/horizon/pages/call-for-speakers-at-samsara-beyond.vue?macro=true";
import { default as _91slug_93XWSPowd4t3Meta } from "/codebuild/output/src298072591/src/horizon/pages/company/[prefix]/[slug].vue?macro=true";
import { default as _91slug_93jabRZEyqgYMeta } from "/codebuild/output/src298072591/src/horizon/pages/company/[slug].vue?macro=true";
import { default as aboutp86tM1aETmMeta } from "/codebuild/output/src298072591/src/horizon/pages/company/about.vue?macro=true";
import { default as indexACIN9AgaIYMeta } from "/codebuild/output/src298072591/src/horizon/pages/company/careers/benefits/index.vue?macro=true";
import { default as engineering_45and_45productBroXPL4739Meta } from "/codebuild/output/src298072591/src/horizon/pages/company/careers/departments/engineering-and-product.vue?macro=true";
import { default as index6gcFF2mVJTMeta } from "/codebuild/output/src298072591/src/horizon/pages/company/careers/departments/index.vue?macro=true";
import { default as salesT0NQDYBybcMeta } from "/codebuild/output/src298072591/src/horizon/pages/company/careers/departments/sales.vue?macro=true";
import { default as emerging_45talentOAtweN9GuIMeta } from "/codebuild/output/src298072591/src/horizon/pages/company/careers/emerging-talent.vue?macro=true";
import { default as index05jwcEp0jyMeta } from "/codebuild/output/src298072591/src/horizon/pages/company/careers/index.vue?macro=true";
import { default as indexQdSvJ17HavMeta } from "/codebuild/output/src298072591/src/horizon/pages/company/careers/locations/index.vue?macro=true";
import { default as _91slug_93B1jXWy4EMWMeta } from "/codebuild/output/src298072591/src/horizon/pages/company/careers/roles/[slug].vue?macro=true";
import { default as indexJCoLBqngX4Meta } from "/codebuild/output/src298072591/src/horizon/pages/company/careers/roles/index.vue?macro=true";
import { default as contactshuSLDYFykMeta } from "/codebuild/output/src298072591/src/horizon/pages/company/contact.vue?macro=true";
import { default as diversityXs1C0esTFCMeta } from "/codebuild/output/src298072591/src/horizon/pages/company/diversity.vue?macro=true";
import { default as indexTKfajufZHzMeta } from "/codebuild/output/src298072591/src/horizon/pages/company/index.vue?macro=true";
import { default as efficiencyETqZzvetSgMeta } from "/codebuild/output/src298072591/src/horizon/pages/company/mission/efficiency.vue?macro=true";
import { default as safetysE9VAaXvZ3Meta } from "/codebuild/output/src298072591/src/horizon/pages/company/mission/safety.vue?macro=true";
import { default as sustainabilityj0oYNKMxapMeta } from "/codebuild/output/src298072591/src/horizon/pages/company/mission/sustainability.vue?macro=true";
import { default as in_45the_45newsNEgI7fmv7zMeta } from "/codebuild/output/src298072591/src/horizon/pages/company/news/in-the-news.vue?macro=true";
import { default as index0KEpppWO3HMeta } from "/codebuild/output/src298072591/src/horizon/pages/company/news/index.vue?macro=true";
import { default as press_45releasesWmTKWMADtgMeta } from "/codebuild/output/src298072591/src/horizon/pages/company/news/press-releases.vue?macro=true";
import { default as privacymoZrZgUmQ8Meta } from "/codebuild/output/src298072591/src/horizon/pages/company/privacy.vue?macro=true";
import { default as cost_45assessment_45toolC4DFGimdGdMeta } from "/codebuild/output/src298072591/src/horizon/pages/cost-assessment-tool.vue?macro=true";
import { default as _91slug_93tHGdEi5QklMeta } from "/codebuild/output/src298072591/src/horizon/pages/customers/[slug].vue?macro=true";
import { default as _91slug_93sxJeHbOXHLMeta } from "/codebuild/output/src298072591/src/horizon/pages/customers/category/[slug].vue?macro=true";
import { default as indexF14nP73BecMeta } from "/codebuild/output/src298072591/src/horizon/pages/customers/index.vue?macro=true";
import { default as _91slug_93bF5Aie7WhSMeta } from "/codebuild/output/src298072591/src/horizon/pages/customers/tips/[slug].vue?macro=true";
import { default as _91slug_93hJhl3iYi08Meta } from "/codebuild/output/src298072591/src/horizon/pages/customers/tips/category/[slug].vue?macro=true";
import { default as index6JoPwZbuKDMeta } from "/codebuild/output/src298072591/src/horizon/pages/customers/tips/index.vue?macro=true";
import { default as _91_46_46_46slug_93DwWpugBFi8Meta } from "/codebuild/output/src298072591/src/horizon/pages/customers/topfleets/[prefix]/[...slug].vue?macro=true";
import { default as above_45beyondlSVP4I3HolMeta } from "/codebuild/output/src298072591/src/horizon/pages/customers/topfleets/2019/above-beyond.vue?macro=true";
import { default as excellence_45performanceEXX8aiq4GqMeta } from "/codebuild/output/src298072591/src/horizon/pages/customers/topfleets/2019/excellence-performance.vue?macro=true";
import { default as excellence_45serviceI9LpwZxXagMeta } from "/codebuild/output/src298072591/src/horizon/pages/customers/topfleets/2019/excellence-service.vue?macro=true";
import { default as indexlxrExgPnN5Meta } from "/codebuild/output/src298072591/src/horizon/pages/customers/topfleets/2019/index.vue?macro=true";
import { default as safest_45fleetMQ4aWZSQtSMeta } from "/codebuild/output/src298072591/src/horizon/pages/customers/topfleets/2019/safest-fleet.vue?macro=true";
import { default as driver_45performanceW3sS3LxK6cMeta } from "/codebuild/output/src298072591/src/horizon/pages/customers/topfleets/2020/driver-performance.vue?macro=true";
import { default as excellence_45performanceOMcSwSwnyEMeta } from "/codebuild/output/src298072591/src/horizon/pages/customers/topfleets/2020/excellence-performance.vue?macro=true";
import { default as fleet_45innovator3e9q5jgDGNMeta } from "/codebuild/output/src298072591/src/horizon/pages/customers/topfleets/2020/fleet-innovator.vue?macro=true";
import { default as index4QfC03ENSEMeta } from "/codebuild/output/src298072591/src/horizon/pages/customers/topfleets/2020/index.vue?macro=true";
import { default as public_45fleetGDNsNq40l3Meta } from "/codebuild/output/src298072591/src/horizon/pages/customers/topfleets/2020/public-fleet.vue?macro=true";
import { default as safest_45fleetjlaoZpGWDuMeta } from "/codebuild/output/src298072591/src/horizon/pages/customers/topfleets/2020/safest-fleet.vue?macro=true";
import { default as indexp8CtNVJglLMeta } from "/codebuild/output/src298072591/src/horizon/pages/customers/topfleets/index.vue?macro=true";
import { default as _91slug_93EiaoziqcoOMeta } from "/codebuild/output/src298072591/src/horizon/pages/events/[slug].vue?macro=true";
import { default as indexkQyzzsFxOgMeta } from "/codebuild/output/src298072591/src/horizon/pages/events/index.vue?macro=true";
import { default as fleet_45app0u6FGkvDJyMeta } from "/codebuild/output/src298072591/src/horizon/pages/fleet-app.vue?macro=true";
import { default as _91slug_93ZWYfcoXahPMeta } from "/codebuild/output/src298072591/src/horizon/pages/fleet/[prefix]/[slug].vue?macro=true";
import { default as _91slug_93dqbCaXDP42Meta } from "/codebuild/output/src298072591/src/horizon/pages/fleet/[slug].vue?macro=true";
import { default as _91slug_93Xjs7EdmLkMMeta } from "/codebuild/output/src298072591/src/horizon/pages/guides/[slug].vue?macro=true";
import { default as attract_45retain_45talent7UXYvqfaK8Meta } from "/codebuild/output/src298072591/src/horizon/pages/guides/build-business-resilience/attract-retain-talent.vue?macro=true";
import { default as increase_45efficiency_45maximize_45profitsZDsMvJvWF3Meta } from "/codebuild/output/src298072591/src/horizon/pages/guides/build-business-resilience/increase-efficiency-maximize-profits.vue?macro=true";
import { default as indext2UH8RZdvzMeta } from "/codebuild/output/src298072591/src/horizon/pages/guides/build-business-resilience/index.vue?macro=true";
import { default as protect_45optimize_45assets0G1Hdz0J4yMeta } from "/codebuild/output/src298072591/src/horizon/pages/guides/build-business-resilience/protect-optimize-assets.vue?macro=true";
import { default as best_45dash_45camsUsXT017jROMeta } from "/codebuild/output/src298072591/src/horizon/pages/guides/fleet-safety/best-dash-cams.vue?macro=true";
import { default as indexv6lGvDb5naMeta } from "/codebuild/output/src298072591/src/horizon/pages/guides/fleet-safety/index.vue?macro=true";
import { default as safety_45program0ZL7te0bIYMeta } from "/codebuild/output/src298072591/src/horizon/pages/guides/fleet-safety/safety-program.vue?macro=true";
import { default as safety_45roiJguvdnPtUUMeta } from "/codebuild/output/src298072591/src/horizon/pages/guides/fleet-safety/safety-roi.vue?macro=true";
import { default as indexmx8gmFw4rpMeta } from "/codebuild/output/src298072591/src/horizon/pages/guides/index.vue?macro=true";
import { default as build_45for_45the_45futurevWvzK4ANyIMeta } from "/codebuild/output/src298072591/src/horizon/pages/guides/make-more-possible/build-for-the-future.vue?macro=true";
import { default as empower_45your_45teamsC5V8VNaSk6Meta } from "/codebuild/output/src298072591/src/horizon/pages/guides/make-more-possible/empower-your-teams.vue?macro=true";
import { default as indexJmwB27lHrhMeta } from "/codebuild/output/src298072591/src/horizon/pages/guides/make-more-possible/index.vue?macro=true";
import { default as take_45action_45with_45dataHpUu3sJPgUMeta } from "/codebuild/output/src298072591/src/horizon/pages/guides/make-more-possible/take-action-with-data.vue?macro=true";
import { default as _91slug_93ordRFYWO5kMeta } from "/codebuild/output/src298072591/src/horizon/pages/guides/your-partner-for-the-road-ahead/[slug].vue?macro=true";
import { default as index11TsaPYgOkMeta } from "/codebuild/output/src298072591/src/horizon/pages/guides/your-partner-for-the-road-ahead/index.vue?macro=true";
import { default as indexHx8hrwmgpZMeta } from "/codebuild/output/src298072591/src/horizon/pages/index.vue?macro=true";
import { default as _91slug_93UviZ3mhhUIMeta } from "/codebuild/output/src298072591/src/horizon/pages/industrial/[slug].vue?macro=true";
import { default as _91slug_93UeZsyUbHjMMeta } from "/codebuild/output/src298072591/src/horizon/pages/industries/[slug].vue?macro=true";
import { default as indexqB4dDSXBZmMeta } from "/codebuild/output/src298072591/src/horizon/pages/industries/index.vue?macro=true";
import { default as cities4voDpIQAMrMeta } from "/codebuild/output/src298072591/src/horizon/pages/insights/cities.vue?macro=true";
import { default as indexU7bnLEL2ohMeta } from "/codebuild/output/src298072591/src/horizon/pages/integrations/index.vue?macro=true";
import { default as indexAh8TdxxglxMeta } from "/codebuild/output/src298072591/src/horizon/pages/invite/index.vue?macro=true";
import { default as learn_45morexRmhwblBOXMeta } from "/codebuild/output/src298072591/src/horizon/pages/learn-more.vue?macro=true";
import { default as candidate_45privacy_45noticeH9iblHOkyBMeta } from "/codebuild/output/src298072591/src/horizon/pages/legal/candidate-privacy-notice.vue?macro=true";
import { default as cookie_45consenttwPJgFWVPjMeta } from "/codebuild/output/src298072591/src/horizon/pages/legal/cookie-consent.vue?macro=true";
import { default as cookie_45policy7OU3xr3js7Meta } from "/codebuild/output/src298072591/src/horizon/pages/legal/cookie-policy.vue?macro=true";
import { default as data_45protection_45addendumlqXAW4ZuGUMeta } from "/codebuild/output/src298072591/src/horizon/pages/legal/data-protection-addendum.vue?macro=true";
import { default as express_45order_45termsTBLzYSLi61Meta } from "/codebuild/output/src298072591/src/horizon/pages/legal/express-order-terms.vue?macro=true";
import { default as hardware_45warrantyruWm9bpXgTMeta } from "/codebuild/output/src298072591/src/horizon/pages/legal/hardware-warranty.vue?macro=true";
import { default as hosted_45software_45slauSTQRbT8LsMeta } from "/codebuild/output/src298072591/src/horizon/pages/legal/hosted-software-sla.vue?macro=true";
import { default as partner_45code_45of_45conductcjdmU1oEUCMeta } from "/codebuild/output/src298072591/src/horizon/pages/legal/partner-code-of-conduct.vue?macro=true";
import { default as platform_45terms_45of_45serviceEfj4xYO50rMeta } from "/codebuild/output/src298072591/src/horizon/pages/legal/platform-terms-of-service.vue?macro=true";
import { default as privacy8wShXwBjmqMeta } from "/codebuild/output/src298072591/src/horizon/pages/legal/privacy.vue?macro=true";
import { default as public_45sector_45customers_45platform_45terms_45of_45servicegHO0LTGgzHMeta } from "/codebuild/output/src298072591/src/horizon/pages/legal/public-sector-customers-platform-terms-of-service.vue?macro=true";
import { default as requestingcustomerdata3jPS3TV1fsMeta } from "/codebuild/output/src298072591/src/horizon/pages/legal/requestingcustomerdata.vue?macro=true";
import { default as securityivOpVI0QPpMeta } from "/codebuild/output/src298072591/src/horizon/pages/legal/security.vue?macro=true";
import { default as transparencyreportizaaIFByKVMeta } from "/codebuild/output/src298072591/src/horizon/pages/legal/transparencyreport.vue?macro=true";
import { default as website_45terms_45of_45servicem4FVSkEWtKMeta } from "/codebuild/output/src298072591/src/horizon/pages/legal/website-terms-of-service.vue?macro=true";
import { default as your_45privacy_45choices4FGw6PXpR1Meta } from "/codebuild/output/src298072591/src/horizon/pages/legal/your-privacy-choices.vue?macro=true";
import { default as pricingh6ERH8b6n1Meta } from "/codebuild/output/src298072591/src/horizon/pages/page/pricing.vue?macro=true";
import { default as _91slug_93qcfkMijG8xMeta } from "/codebuild/output/src298072591/src/horizon/pages/pages/[prefix]/[slug].vue?macro=true";
import { default as _91slug_937Dq3Hwl6WaMeta } from "/codebuild/output/src298072591/src/horizon/pages/pages/[slug].vue?macro=true";
import { default as asset_45tracking1Unsb8mTH4Meta } from "/codebuild/output/src298072591/src/horizon/pages/pages/asset-tracking.vue?macro=true";
import { default as best_45dash_45camsZsVCsbkXStMeta } from "/codebuild/output/src298072591/src/horizon/pages/pages/best-dash-cams.vue?macro=true";
import { default as constructionP3xhlm7RYFMeta } from "/codebuild/output/src298072591/src/horizon/pages/pages/construction.vue?macro=true";
import { default as fleet_45managementEjnwZ8juF2Meta } from "/codebuild/output/src298072591/src/horizon/pages/pages/fleet-management.vue?macro=true";
import { default as nationwideqqFg3SLTfIMeta } from "/codebuild/output/src298072591/src/horizon/pages/pages/nationwide.vue?macro=true";
import { default as safety_45coachingeLNBiTU7nWMeta } from "/codebuild/output/src298072591/src/horizon/pages/pages/safety-coaching.vue?macro=true";
import { default as pricing7zBqv6aTH6Meta } from "/codebuild/output/src298072591/src/horizon/pages/pricing.vue?macro=true";
import { default as _91slug_93INM8dMTc2UMeta } from "/codebuild/output/src298072591/src/horizon/pages/products/[prefix]/[slug].vue?macro=true";
import { default as _91slug_93Rb6XzrMqGvMeta } from "/codebuild/output/src298072591/src/horizon/pages/products/[slug].vue?macro=true";
import { default as asset_45trackingm1VUYdEWrHMeta } from "/codebuild/output/src298072591/src/horizon/pages/products/equipment-monitoring/asset-tracking.vue?macro=true";
import { default as equipment_45tracking2Lv2LsOnuUMeta } from "/codebuild/output/src298072591/src/horizon/pages/products/equipment-monitoring/equipment-tracking.vue?macro=true";
import { default as reefer_45monitoringAPivHuBNAIMeta } from "/codebuild/output/src298072591/src/horizon/pages/products/equipment-monitoring/reefer-monitoring.vue?macro=true";
import { default as smart_45trailersBYXQNAAF4VMeta } from "/codebuild/output/src298072591/src/horizon/pages/products/equipment-monitoring/smart-trailers.vue?macro=true";
import { default as trailer_45trackingdQJXRwFiJNMeta } from "/codebuild/output/src298072591/src/horizon/pages/products/equipment-monitoring/trailer-tracking.vue?macro=true";
import { default as indexgkFzoV68xNMeta } from "/codebuild/output/src298072591/src/horizon/pages/products/index.vue?macro=true";
import { default as _91slug_93WlVdMlHbLDMeta } from "/codebuild/output/src298072591/src/horizon/pages/products/models/[slug].vue?macro=true";
import { default as indexF6zXiwXgnwMeta } from "/codebuild/output/src298072591/src/horizon/pages/products/models/index.vue?macro=true";
import { default as gps_45fleet_45tracking3JICIlie1kMeta } from "/codebuild/output/src298072591/src/horizon/pages/products/telematics/gps-fleet-tracking.vue?macro=true";
import { default as indexknv7u3gwodMeta } from "/codebuild/output/src298072591/src/horizon/pages/products/telematics/index.vue?macro=true";
import { default as referralsKjh4lGdYi6Meta } from "/codebuild/output/src298072591/src/horizon/pages/referrals.vue?macro=true";
import { default as _91slug_93NHhHq3wujiMeta } from "/codebuild/output/src298072591/src/horizon/pages/resources/[slug].vue?macro=true";
import { default as brand_45assets8DzPCr39K6Meta } from "/codebuild/output/src298072591/src/horizon/pages/resources/brand-assets.vue?macro=true";
import { default as _91slug_9387gtdn15qFMeta } from "/codebuild/output/src298072591/src/horizon/pages/resources/content/[slug].vue?macro=true";
import { default as email_45preferences_45center97T2B5g3Z8Meta } from "/codebuild/output/src298072591/src/horizon/pages/resources/content/email-preferences-center.vue?macro=true";
import { default as _91slug_93zWXmXcQC6nMeta } from "/codebuild/output/src298072591/src/horizon/pages/resources/experts/[slug].vue?macro=true";
import { default as _91slug_93yEOe1IsQw2Meta } from "/codebuild/output/src298072591/src/horizon/pages/resources/experts/category/[slug].vue?macro=true";
import { default as indexps3sDvveepMeta } from "/codebuild/output/src298072591/src/horizon/pages/resources/experts/index.vue?macro=true";
import { default as indexGyPpifrVFwMeta } from "/codebuild/output/src298072591/src/horizon/pages/resources/index.vue?macro=true";
import { default as _91slug_93y6WGvY67A9Meta } from "/codebuild/output/src298072591/src/horizon/pages/resources/marketplace/[slug].vue?macro=true";
import { default as _91slug_93yUTC1omFMAMeta } from "/codebuild/output/src298072591/src/horizon/pages/resources/marketplace/category/[slug].vue?macro=true";
import { default as indexT1dsTkqZB8Meta } from "/codebuild/output/src298072591/src/horizon/pages/resources/marketplace/index.vue?macro=true";
import { default as build_45with_45samsarayqzEzKmprRMeta } from "/codebuild/output/src298072591/src/horizon/pages/resources/partner-programs/build-with-samsara.vue?macro=true";
import { default as evolve_45insurance_45programskUOg3lTuDXMeta } from "/codebuild/output/src298072591/src/horizon/pages/resources/partner-programs/evolve-insurance-programs.vue?macro=true";
import { default as indexM4UqZ2xKlUMeta } from "/codebuild/output/src298072591/src/horizon/pages/resources/partner-programs/index.vue?macro=true";
import { default as provide_45servicesXuQHIaDk0MMeta } from "/codebuild/output/src298072591/src/horizon/pages/resources/partner-programs/provide-services.vue?macro=true";
import { default as sell_45samsaraoGpoWOgZUeMeta } from "/codebuild/output/src298072591/src/horizon/pages/resources/partner-programs/sell-samsara.vue?macro=true";
import { default as tour5D7FRGO5PoMeta } from "/codebuild/output/src298072591/src/horizon/pages/resources/tour.vue?macro=true";
import { default as videosg60JwpxGjcMeta } from "/codebuild/output/src298072591/src/horizon/pages/resources/videos.vue?macro=true";
import { default as index0z1gkwV7WBMeta } from "/codebuild/output/src298072591/src/horizon/pages/roi-calculator/index.vue?macro=true";
import { default as samsara_45venturesN2a3saOJIJMeta } from "/codebuild/output/src298072591/src/horizon/pages/samsara-ventures.vue?macro=true";
import { default as security_45summit1SxdN6mo1RMeta } from "/codebuild/output/src298072591/src/horizon/pages/security-summit.vue?macro=true";
import { default as _91slug_932Dl9kvOCW3Meta } from "/codebuild/output/src298072591/src/horizon/pages/solutions/[slug].vue?macro=true";
import { default as indexsQQZY9b02hMeta } from "/codebuild/output/src298072591/src/horizon/pages/solutions/index.vue?macro=true";
import { default as securityBMyEMOKrBVMeta } from "/codebuild/output/src298072591/src/horizon/pages/solutions/security.vue?macro=true";
import { default as spacei69V9NjYTgMeta } from "/codebuild/output/src298072591/src/horizon/pages/space.vue?macro=true";
import { default as sparkwyaToaiM00Meta } from "/codebuild/output/src298072591/src/horizon/pages/spark.vue?macro=true";
import { default as _91slug_93nrjb4f4cDpMeta } from "/codebuild/output/src298072591/src/horizon/pages/styles/[slug].vue?macro=true";
import { default as _91slug_93O16HARYgGmMeta } from "/codebuild/output/src298072591/src/horizon/pages/support/[prefix]/[slug].vue?macro=true";
import { default as _91slug_93MPARUJTBUCMeta } from "/codebuild/output/src298072591/src/horizon/pages/support/[slug].vue?macro=true";
import { default as apps_45privacy_45policyYAif8v1gB7Meta } from "/codebuild/output/src298072591/src/horizon/pages/support/apps-privacy-policy.vue?macro=true";
import { default as customer_45referrals_45faqnoebkzI9DOMeta } from "/codebuild/output/src298072591/src/horizon/pages/support/customer-referrals-faq.vue?macro=true";
import { default as driver_45training_45spanishg5rJfmjxeEMeta } from "/codebuild/output/src298072591/src/horizon/pages/support/driver-training-spanish.vue?macro=true";
import { default as driver_45trainingGxB91EKJTNMeta } from "/codebuild/output/src298072591/src/horizon/pages/support/driver-training.vue?macro=true";
import { default as indexK9kOIR49UEMeta } from "/codebuild/output/src298072591/src/horizon/pages/support/index.vue?macro=true";
import { default as industrialPW3yEqRNgTMeta } from "/codebuild/output/src298072591/src/horizon/pages/support/industrial.vue?macro=true";
import { default as modern_45slavery_45statementppUEXI7OEVMeta } from "/codebuild/output/src298072591/src/horizon/pages/support/modern-slavery-statement.vue?macro=true";
import { default as apps_45privacy_45policyYqPXt1fSdJMeta } from "/codebuild/output/src298072591/src/horizon/pages/support/privacy/apps-privacy-policy.vue?macro=true";
import { default as index5Sl0SQyz0DMeta } from "/codebuild/output/src298072591/src/horizon/pages/support/privacy/index.vue?macro=true";
import { default as special_45featuresvN4iXq6O36Meta } from "/codebuild/output/src298072591/src/horizon/pages/support/privacy/special-features.vue?macro=true";
import { default as subprocessor_45listoHE1svX0plMeta } from "/codebuild/output/src298072591/src/horizon/pages/support/privacy/subprocessor-list.vue?macro=true";
import { default as support_45kb_45articlesMSmViJcgLhMeta } from "/codebuild/output/src298072591/src/horizon/pages/support/support-kb-articles.vue?macro=true";
import { default as thank_45you_45pagejkJKLwryrWMeta } from "/codebuild/output/src298072591/src/horizon/pages/thank-you-page.vue?macro=true";
import { default as thankyou_45recruitingYjmsuYLZyjMeta } from "/codebuild/output/src298072591/src/horizon/pages/thankyou-recruiting.vue?macro=true";
import { default as thankyou_45referralWCvNYbLJfZMeta } from "/codebuild/output/src298072591/src/horizon/pages/thankyou-referral.vue?macro=true";
import { default as thankyouPZHhtu3f8vMeta } from "/codebuild/output/src298072591/src/horizon/pages/thankyou.vue?macro=true";
import { default as eliteZ9FLwkC7z0Meta } from "/codebuild/output/src298072591/src/horizon/pages/tiers/elite.vue?macro=true";
import { default as plusAt9faH3Yv0Meta } from "/codebuild/output/src298072591/src/horizon/pages/tiers/plus.vue?macro=true";
import { default as premierdgidJHP9HCMeta } from "/codebuild/output/src298072591/src/horizon/pages/tiers/premier.vue?macro=true";
import { default as starterNhW4x0rBfRMeta } from "/codebuild/output/src298072591/src/horizon/pages/tiers/starter.vue?macro=true";
import { default as processZJO7ndr1jVMeta } from "/codebuild/output/src298072591/src/horizon/pages/trial/process.vue?macro=true";
import { default as supportkanVjPWHOzMeta } from "/codebuild/output/src298072591/src/horizon/pages/warranty/support.vue?macro=true";
import { default as _91slug_93vnmUVMG7c1Meta } from "/codebuild/output/src298072591/src/horizon/pages/webinars/[slug].vue?macro=true";
import { default as indextjKbUF5IdyMeta } from "/codebuild/output/src298072591/src/horizon/pages/webinars/index.vue?macro=true";
import { default as _91slug_93FMr0SHE25sMeta } from "/codebuild/output/src298072591/src/horizon/pages/webinars/thank-you/[slug].vue?macro=true";
export default [
  {
    name: api_45legacyYpEQPlZRy5Meta?.name ?? "api-legacy___en-US",
    path: api_45legacyYpEQPlZRy5Meta?.path ?? "/api-legacy",
    meta: api_45legacyYpEQPlZRy5Meta || {},
    alias: api_45legacyYpEQPlZRy5Meta?.alias || [],
    redirect: api_45legacyYpEQPlZRy5Meta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/api-legacy.vue").then(m => m.default || m)
  },
  {
    name: api_45legacyYpEQPlZRy5Meta?.name ?? "api-legacy___ca",
    path: api_45legacyYpEQPlZRy5Meta?.path ?? "/ca/api-legacy",
    meta: api_45legacyYpEQPlZRy5Meta || {},
    alias: api_45legacyYpEQPlZRy5Meta?.alias || [],
    redirect: api_45legacyYpEQPlZRy5Meta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/api-legacy.vue").then(m => m.default || m)
  },
  {
    name: api_45legacyYpEQPlZRy5Meta?.name ?? "api-legacy___nl",
    path: api_45legacyYpEQPlZRy5Meta?.path ?? "/nl/api-legacy",
    meta: api_45legacyYpEQPlZRy5Meta || {},
    alias: api_45legacyYpEQPlZRy5Meta?.alias || [],
    redirect: api_45legacyYpEQPlZRy5Meta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/api-legacy.vue").then(m => m.default || m)
  },
  {
    name: api_45legacyYpEQPlZRy5Meta?.name ?? "api-legacy___uk",
    path: api_45legacyYpEQPlZRy5Meta?.path ?? "/uk/api-legacy",
    meta: api_45legacyYpEQPlZRy5Meta || {},
    alias: api_45legacyYpEQPlZRy5Meta?.alias || [],
    redirect: api_45legacyYpEQPlZRy5Meta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/api-legacy.vue").then(m => m.default || m)
  },
  {
    name: api_45legacyYpEQPlZRy5Meta?.name ?? "api-legacy___de",
    path: api_45legacyYpEQPlZRy5Meta?.path ?? "/de/api-legacy",
    meta: api_45legacyYpEQPlZRy5Meta || {},
    alias: api_45legacyYpEQPlZRy5Meta?.alias || [],
    redirect: api_45legacyYpEQPlZRy5Meta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/api-legacy.vue").then(m => m.default || m)
  },
  {
    name: api_45legacyYpEQPlZRy5Meta?.name ?? "api-legacy___mx",
    path: api_45legacyYpEQPlZRy5Meta?.path ?? "/mx/api-legacy",
    meta: api_45legacyYpEQPlZRy5Meta || {},
    alias: api_45legacyYpEQPlZRy5Meta?.alias || [],
    redirect: api_45legacyYpEQPlZRy5Meta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/api-legacy.vue").then(m => m.default || m)
  },
  {
    name: api_45legacyYpEQPlZRy5Meta?.name ?? "api-legacy___fr",
    path: api_45legacyYpEQPlZRy5Meta?.path ?? "/fr/api-legacy",
    meta: api_45legacyYpEQPlZRy5Meta || {},
    alias: api_45legacyYpEQPlZRy5Meta?.alias || [],
    redirect: api_45legacyYpEQPlZRy5Meta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/api-legacy.vue").then(m => m.default || m)
  },
  {
    name: api_45legacyYpEQPlZRy5Meta?.name ?? "api-legacy___fr-ca",
    path: api_45legacyYpEQPlZRy5Meta?.path ?? "/fr-ca/api-legacy",
    meta: api_45legacyYpEQPlZRy5Meta || {},
    alias: api_45legacyYpEQPlZRy5Meta?.alias || [],
    redirect: api_45legacyYpEQPlZRy5Meta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/api-legacy.vue").then(m => m.default || m)
  },
  {
    name: awardsxdyRtwGudcMeta?.name ?? "awards___en-US",
    path: awardsxdyRtwGudcMeta?.path ?? "/awards",
    meta: awardsxdyRtwGudcMeta || {},
    alias: awardsxdyRtwGudcMeta?.alias || [],
    redirect: awardsxdyRtwGudcMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/awards.vue").then(m => m.default || m)
  },
  {
    name: awardsxdyRtwGudcMeta?.name ?? "awards___ca",
    path: awardsxdyRtwGudcMeta?.path ?? "/ca/awards",
    meta: awardsxdyRtwGudcMeta || {},
    alias: awardsxdyRtwGudcMeta?.alias || [],
    redirect: awardsxdyRtwGudcMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/awards.vue").then(m => m.default || m)
  },
  {
    name: awardsxdyRtwGudcMeta?.name ?? "awards___nl",
    path: awardsxdyRtwGudcMeta?.path ?? "/nl/awards",
    meta: awardsxdyRtwGudcMeta || {},
    alias: awardsxdyRtwGudcMeta?.alias || [],
    redirect: awardsxdyRtwGudcMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/awards.vue").then(m => m.default || m)
  },
  {
    name: awardsxdyRtwGudcMeta?.name ?? "awards___uk",
    path: awardsxdyRtwGudcMeta?.path ?? "/uk/awards",
    meta: awardsxdyRtwGudcMeta || {},
    alias: awardsxdyRtwGudcMeta?.alias || [],
    redirect: awardsxdyRtwGudcMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/awards.vue").then(m => m.default || m)
  },
  {
    name: awardsxdyRtwGudcMeta?.name ?? "awards___de",
    path: awardsxdyRtwGudcMeta?.path ?? "/de/awards",
    meta: awardsxdyRtwGudcMeta || {},
    alias: awardsxdyRtwGudcMeta?.alias || [],
    redirect: awardsxdyRtwGudcMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/awards.vue").then(m => m.default || m)
  },
  {
    name: awardsxdyRtwGudcMeta?.name ?? "awards___mx",
    path: awardsxdyRtwGudcMeta?.path ?? "/mx/awards",
    meta: awardsxdyRtwGudcMeta || {},
    alias: awardsxdyRtwGudcMeta?.alias || [],
    redirect: awardsxdyRtwGudcMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/awards.vue").then(m => m.default || m)
  },
  {
    name: awardsxdyRtwGudcMeta?.name ?? "awards___fr",
    path: awardsxdyRtwGudcMeta?.path ?? "/fr/awards",
    meta: awardsxdyRtwGudcMeta || {},
    alias: awardsxdyRtwGudcMeta?.alias || [],
    redirect: awardsxdyRtwGudcMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/awards.vue").then(m => m.default || m)
  },
  {
    name: awardsxdyRtwGudcMeta?.name ?? "awards___fr-ca",
    path: awardsxdyRtwGudcMeta?.path ?? "/fr-ca/awards",
    meta: awardsxdyRtwGudcMeta || {},
    alias: awardsxdyRtwGudcMeta?.alias || [],
    redirect: awardsxdyRtwGudcMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/awards.vue").then(m => m.default || m)
  },
  {
    name: programsAQhRkx8TfKMeta?.name ?? "benefits-programs___en-US",
    path: programsAQhRkx8TfKMeta?.path ?? "/benefits/programs",
    meta: programsAQhRkx8TfKMeta || {},
    alias: programsAQhRkx8TfKMeta?.alias || [],
    redirect: programsAQhRkx8TfKMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/benefits/programs.vue").then(m => m.default || m)
  },
  {
    name: programsAQhRkx8TfKMeta?.name ?? "benefits-programs___ca",
    path: programsAQhRkx8TfKMeta?.path ?? "/ca/benefits/programs",
    meta: programsAQhRkx8TfKMeta || {},
    alias: programsAQhRkx8TfKMeta?.alias || [],
    redirect: programsAQhRkx8TfKMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/benefits/programs.vue").then(m => m.default || m)
  },
  {
    name: programsAQhRkx8TfKMeta?.name ?? "benefits-programs___nl",
    path: programsAQhRkx8TfKMeta?.path ?? "/nl/benefits/programs",
    meta: programsAQhRkx8TfKMeta || {},
    alias: programsAQhRkx8TfKMeta?.alias || [],
    redirect: programsAQhRkx8TfKMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/benefits/programs.vue").then(m => m.default || m)
  },
  {
    name: programsAQhRkx8TfKMeta?.name ?? "benefits-programs___uk",
    path: programsAQhRkx8TfKMeta?.path ?? "/uk/benefits/programs",
    meta: programsAQhRkx8TfKMeta || {},
    alias: programsAQhRkx8TfKMeta?.alias || [],
    redirect: programsAQhRkx8TfKMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/benefits/programs.vue").then(m => m.default || m)
  },
  {
    name: programsAQhRkx8TfKMeta?.name ?? "benefits-programs___de",
    path: programsAQhRkx8TfKMeta?.path ?? "/de/benefits/programs",
    meta: programsAQhRkx8TfKMeta || {},
    alias: programsAQhRkx8TfKMeta?.alias || [],
    redirect: programsAQhRkx8TfKMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/benefits/programs.vue").then(m => m.default || m)
  },
  {
    name: programsAQhRkx8TfKMeta?.name ?? "benefits-programs___mx",
    path: programsAQhRkx8TfKMeta?.path ?? "/mx/benefits/programs",
    meta: programsAQhRkx8TfKMeta || {},
    alias: programsAQhRkx8TfKMeta?.alias || [],
    redirect: programsAQhRkx8TfKMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/benefits/programs.vue").then(m => m.default || m)
  },
  {
    name: programsAQhRkx8TfKMeta?.name ?? "benefits-programs___fr",
    path: programsAQhRkx8TfKMeta?.path ?? "/fr/benefits/programs",
    meta: programsAQhRkx8TfKMeta || {},
    alias: programsAQhRkx8TfKMeta?.alias || [],
    redirect: programsAQhRkx8TfKMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/benefits/programs.vue").then(m => m.default || m)
  },
  {
    name: programsAQhRkx8TfKMeta?.name ?? "benefits-programs___fr-ca",
    path: programsAQhRkx8TfKMeta?.path ?? "/fr-ca/benefits/programs",
    meta: programsAQhRkx8TfKMeta || {},
    alias: programsAQhRkx8TfKMeta?.alias || [],
    redirect: programsAQhRkx8TfKMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/benefits/programs.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93xJPv69u6BbMeta?.name ?? "blog-slug___en-US",
    path: _91slug_93xJPv69u6BbMeta?.path ?? "/blog/:slug()",
    meta: _91slug_93xJPv69u6BbMeta || {},
    alias: _91slug_93xJPv69u6BbMeta?.alias || [],
    redirect: _91slug_93xJPv69u6BbMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/blog/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93xJPv69u6BbMeta?.name ?? "blog-slug___ca",
    path: _91slug_93xJPv69u6BbMeta?.path ?? "/ca/blog/:slug()",
    meta: _91slug_93xJPv69u6BbMeta || {},
    alias: _91slug_93xJPv69u6BbMeta?.alias || [],
    redirect: _91slug_93xJPv69u6BbMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/blog/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93xJPv69u6BbMeta?.name ?? "blog-slug___mx",
    path: _91slug_93xJPv69u6BbMeta?.path ?? "/mx/blog/:slug()",
    meta: _91slug_93xJPv69u6BbMeta || {},
    alias: _91slug_93xJPv69u6BbMeta?.alias || [],
    redirect: _91slug_93xJPv69u6BbMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/blog/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93xJPv69u6BbMeta?.name ?? "blog-slug___uk",
    path: _91slug_93xJPv69u6BbMeta?.path ?? "/uk/blog/:slug()",
    meta: _91slug_93xJPv69u6BbMeta || {},
    alias: _91slug_93xJPv69u6BbMeta?.alias || [],
    redirect: _91slug_93xJPv69u6BbMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/blog/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93xJPv69u6BbMeta?.name ?? "blog-slug___fr",
    path: _91slug_93xJPv69u6BbMeta?.path ?? "/fr/blog/:slug()",
    meta: _91slug_93xJPv69u6BbMeta || {},
    alias: _91slug_93xJPv69u6BbMeta?.alias || [],
    redirect: _91slug_93xJPv69u6BbMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/blog/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93xJPv69u6BbMeta?.name ?? "blog-slug___de",
    path: _91slug_93xJPv69u6BbMeta?.path ?? "/de/blog/:slug()",
    meta: _91slug_93xJPv69u6BbMeta || {},
    alias: _91slug_93xJPv69u6BbMeta?.alias || [],
    redirect: _91slug_93xJPv69u6BbMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/blog/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93LpsP7YZ1tAMeta?.name ?? "blog-category-slug___en-US",
    path: _91slug_93LpsP7YZ1tAMeta?.path ?? "/blog/category/:slug()",
    meta: _91slug_93LpsP7YZ1tAMeta || {},
    alias: _91slug_93LpsP7YZ1tAMeta?.alias || [],
    redirect: _91slug_93LpsP7YZ1tAMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/blog/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93LpsP7YZ1tAMeta?.name ?? "blog-category-slug___ca",
    path: _91slug_93LpsP7YZ1tAMeta?.path ?? "/ca/blog/category/:slug()",
    meta: _91slug_93LpsP7YZ1tAMeta || {},
    alias: _91slug_93LpsP7YZ1tAMeta?.alias || [],
    redirect: _91slug_93LpsP7YZ1tAMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/blog/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93LpsP7YZ1tAMeta?.name ?? "blog-category-slug___mx",
    path: _91slug_93LpsP7YZ1tAMeta?.path ?? "/mx/blog/category/:slug()",
    meta: _91slug_93LpsP7YZ1tAMeta || {},
    alias: _91slug_93LpsP7YZ1tAMeta?.alias || [],
    redirect: _91slug_93LpsP7YZ1tAMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/blog/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93LpsP7YZ1tAMeta?.name ?? "blog-category-slug___uk",
    path: _91slug_93LpsP7YZ1tAMeta?.path ?? "/uk/blog/category/:slug()",
    meta: _91slug_93LpsP7YZ1tAMeta || {},
    alias: _91slug_93LpsP7YZ1tAMeta?.alias || [],
    redirect: _91slug_93LpsP7YZ1tAMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/blog/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93LpsP7YZ1tAMeta?.name ?? "blog-category-slug___fr",
    path: _91slug_93LpsP7YZ1tAMeta?.path ?? "/fr/blog/category/:slug()",
    meta: _91slug_93LpsP7YZ1tAMeta || {},
    alias: _91slug_93LpsP7YZ1tAMeta?.alias || [],
    redirect: _91slug_93LpsP7YZ1tAMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/blog/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93LpsP7YZ1tAMeta?.name ?? "blog-category-slug___de",
    path: _91slug_93LpsP7YZ1tAMeta?.path ?? "/de/blog/category/:slug()",
    meta: _91slug_93LpsP7YZ1tAMeta || {},
    alias: _91slug_93LpsP7YZ1tAMeta?.alias || [],
    redirect: _91slug_93LpsP7YZ1tAMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/blog/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexgVyS9NOkmcMeta?.name ?? "blog___en-US",
    path: indexgVyS9NOkmcMeta?.path ?? "/blog",
    meta: indexgVyS9NOkmcMeta || {},
    alias: indexgVyS9NOkmcMeta?.alias || [],
    redirect: indexgVyS9NOkmcMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: indexgVyS9NOkmcMeta?.name ?? "blog___ca",
    path: indexgVyS9NOkmcMeta?.path ?? "/ca/blog",
    meta: indexgVyS9NOkmcMeta || {},
    alias: indexgVyS9NOkmcMeta?.alias || [],
    redirect: indexgVyS9NOkmcMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: indexgVyS9NOkmcMeta?.name ?? "blog___mx",
    path: indexgVyS9NOkmcMeta?.path ?? "/mx/blog",
    meta: indexgVyS9NOkmcMeta || {},
    alias: indexgVyS9NOkmcMeta?.alias || [],
    redirect: indexgVyS9NOkmcMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: indexgVyS9NOkmcMeta?.name ?? "blog___uk",
    path: indexgVyS9NOkmcMeta?.path ?? "/uk/blog",
    meta: indexgVyS9NOkmcMeta || {},
    alias: indexgVyS9NOkmcMeta?.alias || [],
    redirect: indexgVyS9NOkmcMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: indexgVyS9NOkmcMeta?.name ?? "blog___fr",
    path: indexgVyS9NOkmcMeta?.path ?? "/fr/blog",
    meta: indexgVyS9NOkmcMeta || {},
    alias: indexgVyS9NOkmcMeta?.alias || [],
    redirect: indexgVyS9NOkmcMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: indexgVyS9NOkmcMeta?.name ?? "blog___de",
    path: indexgVyS9NOkmcMeta?.path ?? "/de/blog",
    meta: indexgVyS9NOkmcMeta || {},
    alias: indexgVyS9NOkmcMeta?.alias || [],
    redirect: indexgVyS9NOkmcMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: call_45for_45speakers_45at_45samsara_45beyondW4fU18e24iMeta?.name ?? "call-for-speakers-at-samsara-beyond___en-US",
    path: call_45for_45speakers_45at_45samsara_45beyondW4fU18e24iMeta?.path ?? "/call-for-speakers-at-samsara-beyond",
    meta: call_45for_45speakers_45at_45samsara_45beyondW4fU18e24iMeta || {},
    alias: call_45for_45speakers_45at_45samsara_45beyondW4fU18e24iMeta?.alias || [],
    redirect: call_45for_45speakers_45at_45samsara_45beyondW4fU18e24iMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/call-for-speakers-at-samsara-beyond.vue").then(m => m.default || m)
  },
  {
    name: call_45for_45speakers_45at_45samsara_45beyondW4fU18e24iMeta?.name ?? "call-for-speakers-at-samsara-beyond___ca",
    path: call_45for_45speakers_45at_45samsara_45beyondW4fU18e24iMeta?.path ?? "/ca/call-for-speakers-at-samsara-beyond",
    meta: call_45for_45speakers_45at_45samsara_45beyondW4fU18e24iMeta || {},
    alias: call_45for_45speakers_45at_45samsara_45beyondW4fU18e24iMeta?.alias || [],
    redirect: call_45for_45speakers_45at_45samsara_45beyondW4fU18e24iMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/call-for-speakers-at-samsara-beyond.vue").then(m => m.default || m)
  },
  {
    name: call_45for_45speakers_45at_45samsara_45beyondW4fU18e24iMeta?.name ?? "call-for-speakers-at-samsara-beyond___nl",
    path: call_45for_45speakers_45at_45samsara_45beyondW4fU18e24iMeta?.path ?? "/nl/call-for-speakers-at-samsara-beyond",
    meta: call_45for_45speakers_45at_45samsara_45beyondW4fU18e24iMeta || {},
    alias: call_45for_45speakers_45at_45samsara_45beyondW4fU18e24iMeta?.alias || [],
    redirect: call_45for_45speakers_45at_45samsara_45beyondW4fU18e24iMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/call-for-speakers-at-samsara-beyond.vue").then(m => m.default || m)
  },
  {
    name: call_45for_45speakers_45at_45samsara_45beyondW4fU18e24iMeta?.name ?? "call-for-speakers-at-samsara-beyond___uk",
    path: call_45for_45speakers_45at_45samsara_45beyondW4fU18e24iMeta?.path ?? "/uk/call-for-speakers-at-samsara-beyond",
    meta: call_45for_45speakers_45at_45samsara_45beyondW4fU18e24iMeta || {},
    alias: call_45for_45speakers_45at_45samsara_45beyondW4fU18e24iMeta?.alias || [],
    redirect: call_45for_45speakers_45at_45samsara_45beyondW4fU18e24iMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/call-for-speakers-at-samsara-beyond.vue").then(m => m.default || m)
  },
  {
    name: call_45for_45speakers_45at_45samsara_45beyondW4fU18e24iMeta?.name ?? "call-for-speakers-at-samsara-beyond___de",
    path: call_45for_45speakers_45at_45samsara_45beyondW4fU18e24iMeta?.path ?? "/de/call-for-speakers-at-samsara-beyond",
    meta: call_45for_45speakers_45at_45samsara_45beyondW4fU18e24iMeta || {},
    alias: call_45for_45speakers_45at_45samsara_45beyondW4fU18e24iMeta?.alias || [],
    redirect: call_45for_45speakers_45at_45samsara_45beyondW4fU18e24iMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/call-for-speakers-at-samsara-beyond.vue").then(m => m.default || m)
  },
  {
    name: call_45for_45speakers_45at_45samsara_45beyondW4fU18e24iMeta?.name ?? "call-for-speakers-at-samsara-beyond___mx",
    path: call_45for_45speakers_45at_45samsara_45beyondW4fU18e24iMeta?.path ?? "/mx/call-for-speakers-at-samsara-beyond",
    meta: call_45for_45speakers_45at_45samsara_45beyondW4fU18e24iMeta || {},
    alias: call_45for_45speakers_45at_45samsara_45beyondW4fU18e24iMeta?.alias || [],
    redirect: call_45for_45speakers_45at_45samsara_45beyondW4fU18e24iMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/call-for-speakers-at-samsara-beyond.vue").then(m => m.default || m)
  },
  {
    name: call_45for_45speakers_45at_45samsara_45beyondW4fU18e24iMeta?.name ?? "call-for-speakers-at-samsara-beyond___fr",
    path: call_45for_45speakers_45at_45samsara_45beyondW4fU18e24iMeta?.path ?? "/fr/call-for-speakers-at-samsara-beyond",
    meta: call_45for_45speakers_45at_45samsara_45beyondW4fU18e24iMeta || {},
    alias: call_45for_45speakers_45at_45samsara_45beyondW4fU18e24iMeta?.alias || [],
    redirect: call_45for_45speakers_45at_45samsara_45beyondW4fU18e24iMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/call-for-speakers-at-samsara-beyond.vue").then(m => m.default || m)
  },
  {
    name: call_45for_45speakers_45at_45samsara_45beyondW4fU18e24iMeta?.name ?? "call-for-speakers-at-samsara-beyond___fr-ca",
    path: call_45for_45speakers_45at_45samsara_45beyondW4fU18e24iMeta?.path ?? "/fr-ca/call-for-speakers-at-samsara-beyond",
    meta: call_45for_45speakers_45at_45samsara_45beyondW4fU18e24iMeta || {},
    alias: call_45for_45speakers_45at_45samsara_45beyondW4fU18e24iMeta?.alias || [],
    redirect: call_45for_45speakers_45at_45samsara_45beyondW4fU18e24iMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/call-for-speakers-at-samsara-beyond.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93XWSPowd4t3Meta?.name ?? "company-prefix-slug___en-US",
    path: _91slug_93XWSPowd4t3Meta?.path ?? "/company/:prefix()/:slug()",
    meta: _91slug_93XWSPowd4t3Meta || {},
    alias: _91slug_93XWSPowd4t3Meta?.alias || [],
    redirect: _91slug_93XWSPowd4t3Meta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/company/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93XWSPowd4t3Meta?.name ?? "company-prefix-slug___ca",
    path: _91slug_93XWSPowd4t3Meta?.path ?? "/ca/company/:prefix()/:slug()",
    meta: _91slug_93XWSPowd4t3Meta || {},
    alias: _91slug_93XWSPowd4t3Meta?.alias || [],
    redirect: _91slug_93XWSPowd4t3Meta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/company/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93XWSPowd4t3Meta?.name ?? "company-prefix-slug___nl",
    path: _91slug_93XWSPowd4t3Meta?.path ?? "/nl/company/:prefix()/:slug()",
    meta: _91slug_93XWSPowd4t3Meta || {},
    alias: _91slug_93XWSPowd4t3Meta?.alias || [],
    redirect: _91slug_93XWSPowd4t3Meta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/company/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93XWSPowd4t3Meta?.name ?? "company-prefix-slug___uk",
    path: _91slug_93XWSPowd4t3Meta?.path ?? "/uk/company/:prefix()/:slug()",
    meta: _91slug_93XWSPowd4t3Meta || {},
    alias: _91slug_93XWSPowd4t3Meta?.alias || [],
    redirect: _91slug_93XWSPowd4t3Meta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/company/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93XWSPowd4t3Meta?.name ?? "company-prefix-slug___de",
    path: _91slug_93XWSPowd4t3Meta?.path ?? "/de/company/:prefix()/:slug()",
    meta: _91slug_93XWSPowd4t3Meta || {},
    alias: _91slug_93XWSPowd4t3Meta?.alias || [],
    redirect: _91slug_93XWSPowd4t3Meta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/company/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93XWSPowd4t3Meta?.name ?? "company-prefix-slug___mx",
    path: _91slug_93XWSPowd4t3Meta?.path ?? "/mx/company/:prefix()/:slug()",
    meta: _91slug_93XWSPowd4t3Meta || {},
    alias: _91slug_93XWSPowd4t3Meta?.alias || [],
    redirect: _91slug_93XWSPowd4t3Meta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/company/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93XWSPowd4t3Meta?.name ?? "company-prefix-slug___fr",
    path: _91slug_93XWSPowd4t3Meta?.path ?? "/fr/company/:prefix()/:slug()",
    meta: _91slug_93XWSPowd4t3Meta || {},
    alias: _91slug_93XWSPowd4t3Meta?.alias || [],
    redirect: _91slug_93XWSPowd4t3Meta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/company/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93XWSPowd4t3Meta?.name ?? "company-prefix-slug___fr-ca",
    path: _91slug_93XWSPowd4t3Meta?.path ?? "/fr-ca/company/:prefix()/:slug()",
    meta: _91slug_93XWSPowd4t3Meta || {},
    alias: _91slug_93XWSPowd4t3Meta?.alias || [],
    redirect: _91slug_93XWSPowd4t3Meta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/company/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93jabRZEyqgYMeta?.name ?? "company-slug___en-US",
    path: _91slug_93jabRZEyqgYMeta?.path ?? "/company/:slug()",
    meta: _91slug_93jabRZEyqgYMeta || {},
    alias: _91slug_93jabRZEyqgYMeta?.alias || [],
    redirect: _91slug_93jabRZEyqgYMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/company/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93jabRZEyqgYMeta?.name ?? "company-slug___ca",
    path: _91slug_93jabRZEyqgYMeta?.path ?? "/ca/company/:slug()",
    meta: _91slug_93jabRZEyqgYMeta || {},
    alias: _91slug_93jabRZEyqgYMeta?.alias || [],
    redirect: _91slug_93jabRZEyqgYMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/company/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93jabRZEyqgYMeta?.name ?? "company-slug___nl",
    path: _91slug_93jabRZEyqgYMeta?.path ?? "/nl/company/:slug()",
    meta: _91slug_93jabRZEyqgYMeta || {},
    alias: _91slug_93jabRZEyqgYMeta?.alias || [],
    redirect: _91slug_93jabRZEyqgYMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/company/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93jabRZEyqgYMeta?.name ?? "company-slug___uk",
    path: _91slug_93jabRZEyqgYMeta?.path ?? "/uk/company/:slug()",
    meta: _91slug_93jabRZEyqgYMeta || {},
    alias: _91slug_93jabRZEyqgYMeta?.alias || [],
    redirect: _91slug_93jabRZEyqgYMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/company/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93jabRZEyqgYMeta?.name ?? "company-slug___de",
    path: _91slug_93jabRZEyqgYMeta?.path ?? "/de/company/:slug()",
    meta: _91slug_93jabRZEyqgYMeta || {},
    alias: _91slug_93jabRZEyqgYMeta?.alias || [],
    redirect: _91slug_93jabRZEyqgYMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/company/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93jabRZEyqgYMeta?.name ?? "company-slug___mx",
    path: _91slug_93jabRZEyqgYMeta?.path ?? "/mx/company/:slug()",
    meta: _91slug_93jabRZEyqgYMeta || {},
    alias: _91slug_93jabRZEyqgYMeta?.alias || [],
    redirect: _91slug_93jabRZEyqgYMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/company/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93jabRZEyqgYMeta?.name ?? "company-slug___fr",
    path: _91slug_93jabRZEyqgYMeta?.path ?? "/fr/company/:slug()",
    meta: _91slug_93jabRZEyqgYMeta || {},
    alias: _91slug_93jabRZEyqgYMeta?.alias || [],
    redirect: _91slug_93jabRZEyqgYMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/company/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93jabRZEyqgYMeta?.name ?? "company-slug___fr-ca",
    path: _91slug_93jabRZEyqgYMeta?.path ?? "/fr-ca/company/:slug()",
    meta: _91slug_93jabRZEyqgYMeta || {},
    alias: _91slug_93jabRZEyqgYMeta?.alias || [],
    redirect: _91slug_93jabRZEyqgYMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/company/[slug].vue").then(m => m.default || m)
  },
  {
    name: aboutp86tM1aETmMeta?.name ?? "company-about___en-US",
    path: aboutp86tM1aETmMeta?.path ?? "/company/about",
    meta: aboutp86tM1aETmMeta || {},
    alias: aboutp86tM1aETmMeta?.alias || [],
    redirect: aboutp86tM1aETmMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/company/about.vue").then(m => m.default || m)
  },
  {
    name: aboutp86tM1aETmMeta?.name ?? "company-about___ca",
    path: aboutp86tM1aETmMeta?.path ?? "/ca/company/about",
    meta: aboutp86tM1aETmMeta || {},
    alias: aboutp86tM1aETmMeta?.alias || [],
    redirect: aboutp86tM1aETmMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/company/about.vue").then(m => m.default || m)
  },
  {
    name: aboutp86tM1aETmMeta?.name ?? "company-about___nl",
    path: aboutp86tM1aETmMeta?.path ?? "/nl/company/about",
    meta: aboutp86tM1aETmMeta || {},
    alias: aboutp86tM1aETmMeta?.alias || [],
    redirect: aboutp86tM1aETmMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/company/about.vue").then(m => m.default || m)
  },
  {
    name: aboutp86tM1aETmMeta?.name ?? "company-about___uk",
    path: aboutp86tM1aETmMeta?.path ?? "/uk/company/about",
    meta: aboutp86tM1aETmMeta || {},
    alias: aboutp86tM1aETmMeta?.alias || [],
    redirect: aboutp86tM1aETmMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/company/about.vue").then(m => m.default || m)
  },
  {
    name: aboutp86tM1aETmMeta?.name ?? "company-about___de",
    path: aboutp86tM1aETmMeta?.path ?? "/de/company/about",
    meta: aboutp86tM1aETmMeta || {},
    alias: aboutp86tM1aETmMeta?.alias || [],
    redirect: aboutp86tM1aETmMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/company/about.vue").then(m => m.default || m)
  },
  {
    name: aboutp86tM1aETmMeta?.name ?? "company-about___mx",
    path: aboutp86tM1aETmMeta?.path ?? "/mx/company/about",
    meta: aboutp86tM1aETmMeta || {},
    alias: aboutp86tM1aETmMeta?.alias || [],
    redirect: aboutp86tM1aETmMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/company/about.vue").then(m => m.default || m)
  },
  {
    name: aboutp86tM1aETmMeta?.name ?? "company-about___fr",
    path: aboutp86tM1aETmMeta?.path ?? "/fr/company/about",
    meta: aboutp86tM1aETmMeta || {},
    alias: aboutp86tM1aETmMeta?.alias || [],
    redirect: aboutp86tM1aETmMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/company/about.vue").then(m => m.default || m)
  },
  {
    name: aboutp86tM1aETmMeta?.name ?? "company-about___fr-ca",
    path: aboutp86tM1aETmMeta?.path ?? "/fr-ca/company/about",
    meta: aboutp86tM1aETmMeta || {},
    alias: aboutp86tM1aETmMeta?.alias || [],
    redirect: aboutp86tM1aETmMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/company/about.vue").then(m => m.default || m)
  },
  {
    name: indexACIN9AgaIYMeta?.name ?? "company-careers-benefits___en-US",
    path: indexACIN9AgaIYMeta?.path ?? "/company/careers/benefits",
    meta: indexACIN9AgaIYMeta || {},
    alias: indexACIN9AgaIYMeta?.alias || [],
    redirect: indexACIN9AgaIYMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/company/careers/benefits/index.vue").then(m => m.default || m)
  },
  {
    name: indexACIN9AgaIYMeta?.name ?? "company-careers-benefits___ca",
    path: indexACIN9AgaIYMeta?.path ?? "/ca/company/careers/benefits",
    meta: indexACIN9AgaIYMeta || {},
    alias: indexACIN9AgaIYMeta?.alias || [],
    redirect: indexACIN9AgaIYMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/company/careers/benefits/index.vue").then(m => m.default || m)
  },
  {
    name: indexACIN9AgaIYMeta?.name ?? "company-careers-benefits___nl",
    path: indexACIN9AgaIYMeta?.path ?? "/nl/company/careers/benefits",
    meta: indexACIN9AgaIYMeta || {},
    alias: indexACIN9AgaIYMeta?.alias || [],
    redirect: indexACIN9AgaIYMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/company/careers/benefits/index.vue").then(m => m.default || m)
  },
  {
    name: indexACIN9AgaIYMeta?.name ?? "company-careers-benefits___uk",
    path: indexACIN9AgaIYMeta?.path ?? "/uk/company/careers/benefits",
    meta: indexACIN9AgaIYMeta || {},
    alias: indexACIN9AgaIYMeta?.alias || [],
    redirect: indexACIN9AgaIYMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/company/careers/benefits/index.vue").then(m => m.default || m)
  },
  {
    name: indexACIN9AgaIYMeta?.name ?? "company-careers-benefits___de",
    path: indexACIN9AgaIYMeta?.path ?? "/de/company/careers/benefits",
    meta: indexACIN9AgaIYMeta || {},
    alias: indexACIN9AgaIYMeta?.alias || [],
    redirect: indexACIN9AgaIYMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/company/careers/benefits/index.vue").then(m => m.default || m)
  },
  {
    name: indexACIN9AgaIYMeta?.name ?? "company-careers-benefits___mx",
    path: indexACIN9AgaIYMeta?.path ?? "/mx/company/careers/benefits",
    meta: indexACIN9AgaIYMeta || {},
    alias: indexACIN9AgaIYMeta?.alias || [],
    redirect: indexACIN9AgaIYMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/company/careers/benefits/index.vue").then(m => m.default || m)
  },
  {
    name: indexACIN9AgaIYMeta?.name ?? "company-careers-benefits___fr",
    path: indexACIN9AgaIYMeta?.path ?? "/fr/company/careers/benefits",
    meta: indexACIN9AgaIYMeta || {},
    alias: indexACIN9AgaIYMeta?.alias || [],
    redirect: indexACIN9AgaIYMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/company/careers/benefits/index.vue").then(m => m.default || m)
  },
  {
    name: indexACIN9AgaIYMeta?.name ?? "company-careers-benefits___fr-ca",
    path: indexACIN9AgaIYMeta?.path ?? "/fr-ca/company/careers/benefits",
    meta: indexACIN9AgaIYMeta || {},
    alias: indexACIN9AgaIYMeta?.alias || [],
    redirect: indexACIN9AgaIYMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/company/careers/benefits/index.vue").then(m => m.default || m)
  },
  {
    name: engineering_45and_45productBroXPL4739Meta?.name ?? "company-careers-departments-engineering-and-product___en-US",
    path: engineering_45and_45productBroXPL4739Meta?.path ?? "/company/careers/departments/engineering-and-product",
    meta: engineering_45and_45productBroXPL4739Meta || {},
    alias: engineering_45and_45productBroXPL4739Meta?.alias || [],
    redirect: engineering_45and_45productBroXPL4739Meta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/company/careers/departments/engineering-and-product.vue").then(m => m.default || m)
  },
  {
    name: engineering_45and_45productBroXPL4739Meta?.name ?? "company-careers-departments-engineering-and-product___ca",
    path: engineering_45and_45productBroXPL4739Meta?.path ?? "/ca/company/careers/departments/engineering-and-product",
    meta: engineering_45and_45productBroXPL4739Meta || {},
    alias: engineering_45and_45productBroXPL4739Meta?.alias || [],
    redirect: engineering_45and_45productBroXPL4739Meta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/company/careers/departments/engineering-and-product.vue").then(m => m.default || m)
  },
  {
    name: engineering_45and_45productBroXPL4739Meta?.name ?? "company-careers-departments-engineering-and-product___nl",
    path: engineering_45and_45productBroXPL4739Meta?.path ?? "/nl/company/careers/departments/engineering-and-product",
    meta: engineering_45and_45productBroXPL4739Meta || {},
    alias: engineering_45and_45productBroXPL4739Meta?.alias || [],
    redirect: engineering_45and_45productBroXPL4739Meta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/company/careers/departments/engineering-and-product.vue").then(m => m.default || m)
  },
  {
    name: engineering_45and_45productBroXPL4739Meta?.name ?? "company-careers-departments-engineering-and-product___uk",
    path: engineering_45and_45productBroXPL4739Meta?.path ?? "/uk/company/careers/departments/engineering-and-product",
    meta: engineering_45and_45productBroXPL4739Meta || {},
    alias: engineering_45and_45productBroXPL4739Meta?.alias || [],
    redirect: engineering_45and_45productBroXPL4739Meta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/company/careers/departments/engineering-and-product.vue").then(m => m.default || m)
  },
  {
    name: engineering_45and_45productBroXPL4739Meta?.name ?? "company-careers-departments-engineering-and-product___de",
    path: engineering_45and_45productBroXPL4739Meta?.path ?? "/de/company/careers/departments/engineering-and-product",
    meta: engineering_45and_45productBroXPL4739Meta || {},
    alias: engineering_45and_45productBroXPL4739Meta?.alias || [],
    redirect: engineering_45and_45productBroXPL4739Meta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/company/careers/departments/engineering-and-product.vue").then(m => m.default || m)
  },
  {
    name: engineering_45and_45productBroXPL4739Meta?.name ?? "company-careers-departments-engineering-and-product___mx",
    path: engineering_45and_45productBroXPL4739Meta?.path ?? "/mx/company/careers/departments/engineering-and-product",
    meta: engineering_45and_45productBroXPL4739Meta || {},
    alias: engineering_45and_45productBroXPL4739Meta?.alias || [],
    redirect: engineering_45and_45productBroXPL4739Meta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/company/careers/departments/engineering-and-product.vue").then(m => m.default || m)
  },
  {
    name: engineering_45and_45productBroXPL4739Meta?.name ?? "company-careers-departments-engineering-and-product___fr",
    path: engineering_45and_45productBroXPL4739Meta?.path ?? "/fr/company/careers/departments/engineering-and-product",
    meta: engineering_45and_45productBroXPL4739Meta || {},
    alias: engineering_45and_45productBroXPL4739Meta?.alias || [],
    redirect: engineering_45and_45productBroXPL4739Meta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/company/careers/departments/engineering-and-product.vue").then(m => m.default || m)
  },
  {
    name: engineering_45and_45productBroXPL4739Meta?.name ?? "company-careers-departments-engineering-and-product___fr-ca",
    path: engineering_45and_45productBroXPL4739Meta?.path ?? "/fr-ca/company/careers/departments/engineering-and-product",
    meta: engineering_45and_45productBroXPL4739Meta || {},
    alias: engineering_45and_45productBroXPL4739Meta?.alias || [],
    redirect: engineering_45and_45productBroXPL4739Meta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/company/careers/departments/engineering-and-product.vue").then(m => m.default || m)
  },
  {
    name: index6gcFF2mVJTMeta?.name ?? "company-careers-departments___en-US",
    path: index6gcFF2mVJTMeta?.path ?? "/company/careers/departments",
    meta: index6gcFF2mVJTMeta || {},
    alias: index6gcFF2mVJTMeta?.alias || [],
    redirect: index6gcFF2mVJTMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/company/careers/departments/index.vue").then(m => m.default || m)
  },
  {
    name: index6gcFF2mVJTMeta?.name ?? "company-careers-departments___ca",
    path: index6gcFF2mVJTMeta?.path ?? "/ca/company/careers/departments",
    meta: index6gcFF2mVJTMeta || {},
    alias: index6gcFF2mVJTMeta?.alias || [],
    redirect: index6gcFF2mVJTMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/company/careers/departments/index.vue").then(m => m.default || m)
  },
  {
    name: index6gcFF2mVJTMeta?.name ?? "company-careers-departments___nl",
    path: index6gcFF2mVJTMeta?.path ?? "/nl/company/careers/departments",
    meta: index6gcFF2mVJTMeta || {},
    alias: index6gcFF2mVJTMeta?.alias || [],
    redirect: index6gcFF2mVJTMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/company/careers/departments/index.vue").then(m => m.default || m)
  },
  {
    name: index6gcFF2mVJTMeta?.name ?? "company-careers-departments___uk",
    path: index6gcFF2mVJTMeta?.path ?? "/uk/company/careers/departments",
    meta: index6gcFF2mVJTMeta || {},
    alias: index6gcFF2mVJTMeta?.alias || [],
    redirect: index6gcFF2mVJTMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/company/careers/departments/index.vue").then(m => m.default || m)
  },
  {
    name: index6gcFF2mVJTMeta?.name ?? "company-careers-departments___de",
    path: index6gcFF2mVJTMeta?.path ?? "/de/company/careers/departments",
    meta: index6gcFF2mVJTMeta || {},
    alias: index6gcFF2mVJTMeta?.alias || [],
    redirect: index6gcFF2mVJTMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/company/careers/departments/index.vue").then(m => m.default || m)
  },
  {
    name: index6gcFF2mVJTMeta?.name ?? "company-careers-departments___mx",
    path: index6gcFF2mVJTMeta?.path ?? "/mx/company/careers/departments",
    meta: index6gcFF2mVJTMeta || {},
    alias: index6gcFF2mVJTMeta?.alias || [],
    redirect: index6gcFF2mVJTMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/company/careers/departments/index.vue").then(m => m.default || m)
  },
  {
    name: index6gcFF2mVJTMeta?.name ?? "company-careers-departments___fr",
    path: index6gcFF2mVJTMeta?.path ?? "/fr/company/careers/departments",
    meta: index6gcFF2mVJTMeta || {},
    alias: index6gcFF2mVJTMeta?.alias || [],
    redirect: index6gcFF2mVJTMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/company/careers/departments/index.vue").then(m => m.default || m)
  },
  {
    name: index6gcFF2mVJTMeta?.name ?? "company-careers-departments___fr-ca",
    path: index6gcFF2mVJTMeta?.path ?? "/fr-ca/company/careers/departments",
    meta: index6gcFF2mVJTMeta || {},
    alias: index6gcFF2mVJTMeta?.alias || [],
    redirect: index6gcFF2mVJTMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/company/careers/departments/index.vue").then(m => m.default || m)
  },
  {
    name: salesT0NQDYBybcMeta?.name ?? "company-careers-departments-sales___en-US",
    path: salesT0NQDYBybcMeta?.path ?? "/company/careers/departments/sales",
    meta: salesT0NQDYBybcMeta || {},
    alias: salesT0NQDYBybcMeta?.alias || [],
    redirect: salesT0NQDYBybcMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/company/careers/departments/sales.vue").then(m => m.default || m)
  },
  {
    name: salesT0NQDYBybcMeta?.name ?? "company-careers-departments-sales___ca",
    path: salesT0NQDYBybcMeta?.path ?? "/ca/company/careers/departments/sales",
    meta: salesT0NQDYBybcMeta || {},
    alias: salesT0NQDYBybcMeta?.alias || [],
    redirect: salesT0NQDYBybcMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/company/careers/departments/sales.vue").then(m => m.default || m)
  },
  {
    name: salesT0NQDYBybcMeta?.name ?? "company-careers-departments-sales___nl",
    path: salesT0NQDYBybcMeta?.path ?? "/nl/company/careers/departments/sales",
    meta: salesT0NQDYBybcMeta || {},
    alias: salesT0NQDYBybcMeta?.alias || [],
    redirect: salesT0NQDYBybcMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/company/careers/departments/sales.vue").then(m => m.default || m)
  },
  {
    name: salesT0NQDYBybcMeta?.name ?? "company-careers-departments-sales___uk",
    path: salesT0NQDYBybcMeta?.path ?? "/uk/company/careers/departments/sales",
    meta: salesT0NQDYBybcMeta || {},
    alias: salesT0NQDYBybcMeta?.alias || [],
    redirect: salesT0NQDYBybcMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/company/careers/departments/sales.vue").then(m => m.default || m)
  },
  {
    name: salesT0NQDYBybcMeta?.name ?? "company-careers-departments-sales___de",
    path: salesT0NQDYBybcMeta?.path ?? "/de/company/careers/departments/sales",
    meta: salesT0NQDYBybcMeta || {},
    alias: salesT0NQDYBybcMeta?.alias || [],
    redirect: salesT0NQDYBybcMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/company/careers/departments/sales.vue").then(m => m.default || m)
  },
  {
    name: salesT0NQDYBybcMeta?.name ?? "company-careers-departments-sales___mx",
    path: salesT0NQDYBybcMeta?.path ?? "/mx/company/careers/departments/sales",
    meta: salesT0NQDYBybcMeta || {},
    alias: salesT0NQDYBybcMeta?.alias || [],
    redirect: salesT0NQDYBybcMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/company/careers/departments/sales.vue").then(m => m.default || m)
  },
  {
    name: salesT0NQDYBybcMeta?.name ?? "company-careers-departments-sales___fr",
    path: salesT0NQDYBybcMeta?.path ?? "/fr/company/careers/departments/sales",
    meta: salesT0NQDYBybcMeta || {},
    alias: salesT0NQDYBybcMeta?.alias || [],
    redirect: salesT0NQDYBybcMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/company/careers/departments/sales.vue").then(m => m.default || m)
  },
  {
    name: salesT0NQDYBybcMeta?.name ?? "company-careers-departments-sales___fr-ca",
    path: salesT0NQDYBybcMeta?.path ?? "/fr-ca/company/careers/departments/sales",
    meta: salesT0NQDYBybcMeta || {},
    alias: salesT0NQDYBybcMeta?.alias || [],
    redirect: salesT0NQDYBybcMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/company/careers/departments/sales.vue").then(m => m.default || m)
  },
  {
    name: emerging_45talentOAtweN9GuIMeta?.name ?? "company-careers-emerging-talent___en-US",
    path: emerging_45talentOAtweN9GuIMeta?.path ?? "/company/careers/emerging-talent",
    meta: emerging_45talentOAtweN9GuIMeta || {},
    alias: emerging_45talentOAtweN9GuIMeta?.alias || [],
    redirect: emerging_45talentOAtweN9GuIMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/company/careers/emerging-talent.vue").then(m => m.default || m)
  },
  {
    name: emerging_45talentOAtweN9GuIMeta?.name ?? "company-careers-emerging-talent___ca",
    path: emerging_45talentOAtweN9GuIMeta?.path ?? "/ca/company/careers/emerging-talent",
    meta: emerging_45talentOAtweN9GuIMeta || {},
    alias: emerging_45talentOAtweN9GuIMeta?.alias || [],
    redirect: emerging_45talentOAtweN9GuIMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/company/careers/emerging-talent.vue").then(m => m.default || m)
  },
  {
    name: emerging_45talentOAtweN9GuIMeta?.name ?? "company-careers-emerging-talent___nl",
    path: emerging_45talentOAtweN9GuIMeta?.path ?? "/nl/company/careers/emerging-talent",
    meta: emerging_45talentOAtweN9GuIMeta || {},
    alias: emerging_45talentOAtweN9GuIMeta?.alias || [],
    redirect: emerging_45talentOAtweN9GuIMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/company/careers/emerging-talent.vue").then(m => m.default || m)
  },
  {
    name: emerging_45talentOAtweN9GuIMeta?.name ?? "company-careers-emerging-talent___uk",
    path: emerging_45talentOAtweN9GuIMeta?.path ?? "/uk/company/careers/emerging-talent",
    meta: emerging_45talentOAtweN9GuIMeta || {},
    alias: emerging_45talentOAtweN9GuIMeta?.alias || [],
    redirect: emerging_45talentOAtweN9GuIMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/company/careers/emerging-talent.vue").then(m => m.default || m)
  },
  {
    name: emerging_45talentOAtweN9GuIMeta?.name ?? "company-careers-emerging-talent___de",
    path: emerging_45talentOAtweN9GuIMeta?.path ?? "/de/company/careers/emerging-talent",
    meta: emerging_45talentOAtweN9GuIMeta || {},
    alias: emerging_45talentOAtweN9GuIMeta?.alias || [],
    redirect: emerging_45talentOAtweN9GuIMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/company/careers/emerging-talent.vue").then(m => m.default || m)
  },
  {
    name: emerging_45talentOAtweN9GuIMeta?.name ?? "company-careers-emerging-talent___mx",
    path: emerging_45talentOAtweN9GuIMeta?.path ?? "/mx/company/careers/emerging-talent",
    meta: emerging_45talentOAtweN9GuIMeta || {},
    alias: emerging_45talentOAtweN9GuIMeta?.alias || [],
    redirect: emerging_45talentOAtweN9GuIMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/company/careers/emerging-talent.vue").then(m => m.default || m)
  },
  {
    name: emerging_45talentOAtweN9GuIMeta?.name ?? "company-careers-emerging-talent___fr",
    path: emerging_45talentOAtweN9GuIMeta?.path ?? "/fr/company/careers/emerging-talent",
    meta: emerging_45talentOAtweN9GuIMeta || {},
    alias: emerging_45talentOAtweN9GuIMeta?.alias || [],
    redirect: emerging_45talentOAtweN9GuIMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/company/careers/emerging-talent.vue").then(m => m.default || m)
  },
  {
    name: emerging_45talentOAtweN9GuIMeta?.name ?? "company-careers-emerging-talent___fr-ca",
    path: emerging_45talentOAtweN9GuIMeta?.path ?? "/fr-ca/company/careers/emerging-talent",
    meta: emerging_45talentOAtweN9GuIMeta || {},
    alias: emerging_45talentOAtweN9GuIMeta?.alias || [],
    redirect: emerging_45talentOAtweN9GuIMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/company/careers/emerging-talent.vue").then(m => m.default || m)
  },
  {
    name: index05jwcEp0jyMeta?.name ?? "company-careers___en-US",
    path: index05jwcEp0jyMeta?.path ?? "/company/careers",
    meta: index05jwcEp0jyMeta || {},
    alias: index05jwcEp0jyMeta?.alias || [],
    redirect: index05jwcEp0jyMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/company/careers/index.vue").then(m => m.default || m)
  },
  {
    name: index05jwcEp0jyMeta?.name ?? "company-careers___ca",
    path: index05jwcEp0jyMeta?.path ?? "/ca/company/careers",
    meta: index05jwcEp0jyMeta || {},
    alias: index05jwcEp0jyMeta?.alias || [],
    redirect: index05jwcEp0jyMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/company/careers/index.vue").then(m => m.default || m)
  },
  {
    name: index05jwcEp0jyMeta?.name ?? "company-careers___nl",
    path: index05jwcEp0jyMeta?.path ?? "/nl/company/careers",
    meta: index05jwcEp0jyMeta || {},
    alias: index05jwcEp0jyMeta?.alias || [],
    redirect: index05jwcEp0jyMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/company/careers/index.vue").then(m => m.default || m)
  },
  {
    name: index05jwcEp0jyMeta?.name ?? "company-careers___uk",
    path: index05jwcEp0jyMeta?.path ?? "/uk/company/careers",
    meta: index05jwcEp0jyMeta || {},
    alias: index05jwcEp0jyMeta?.alias || [],
    redirect: index05jwcEp0jyMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/company/careers/index.vue").then(m => m.default || m)
  },
  {
    name: index05jwcEp0jyMeta?.name ?? "company-careers___de",
    path: index05jwcEp0jyMeta?.path ?? "/de/company/careers",
    meta: index05jwcEp0jyMeta || {},
    alias: index05jwcEp0jyMeta?.alias || [],
    redirect: index05jwcEp0jyMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/company/careers/index.vue").then(m => m.default || m)
  },
  {
    name: index05jwcEp0jyMeta?.name ?? "company-careers___mx",
    path: index05jwcEp0jyMeta?.path ?? "/mx/company/careers",
    meta: index05jwcEp0jyMeta || {},
    alias: index05jwcEp0jyMeta?.alias || [],
    redirect: index05jwcEp0jyMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/company/careers/index.vue").then(m => m.default || m)
  },
  {
    name: index05jwcEp0jyMeta?.name ?? "company-careers___fr",
    path: index05jwcEp0jyMeta?.path ?? "/fr/company/careers",
    meta: index05jwcEp0jyMeta || {},
    alias: index05jwcEp0jyMeta?.alias || [],
    redirect: index05jwcEp0jyMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/company/careers/index.vue").then(m => m.default || m)
  },
  {
    name: index05jwcEp0jyMeta?.name ?? "company-careers___fr-ca",
    path: index05jwcEp0jyMeta?.path ?? "/fr-ca/company/careers",
    meta: index05jwcEp0jyMeta || {},
    alias: index05jwcEp0jyMeta?.alias || [],
    redirect: index05jwcEp0jyMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/company/careers/index.vue").then(m => m.default || m)
  },
  {
    name: indexQdSvJ17HavMeta?.name ?? "company-careers-locations___en-US",
    path: indexQdSvJ17HavMeta?.path ?? "/company/careers/locations",
    meta: indexQdSvJ17HavMeta || {},
    alias: indexQdSvJ17HavMeta?.alias || [],
    redirect: indexQdSvJ17HavMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/company/careers/locations/index.vue").then(m => m.default || m)
  },
  {
    name: indexQdSvJ17HavMeta?.name ?? "company-careers-locations___ca",
    path: indexQdSvJ17HavMeta?.path ?? "/ca/company/careers/locations",
    meta: indexQdSvJ17HavMeta || {},
    alias: indexQdSvJ17HavMeta?.alias || [],
    redirect: indexQdSvJ17HavMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/company/careers/locations/index.vue").then(m => m.default || m)
  },
  {
    name: indexQdSvJ17HavMeta?.name ?? "company-careers-locations___nl",
    path: indexQdSvJ17HavMeta?.path ?? "/nl/company/careers/locations",
    meta: indexQdSvJ17HavMeta || {},
    alias: indexQdSvJ17HavMeta?.alias || [],
    redirect: indexQdSvJ17HavMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/company/careers/locations/index.vue").then(m => m.default || m)
  },
  {
    name: indexQdSvJ17HavMeta?.name ?? "company-careers-locations___uk",
    path: indexQdSvJ17HavMeta?.path ?? "/uk/company/careers/locations",
    meta: indexQdSvJ17HavMeta || {},
    alias: indexQdSvJ17HavMeta?.alias || [],
    redirect: indexQdSvJ17HavMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/company/careers/locations/index.vue").then(m => m.default || m)
  },
  {
    name: indexQdSvJ17HavMeta?.name ?? "company-careers-locations___de",
    path: indexQdSvJ17HavMeta?.path ?? "/de/company/careers/locations",
    meta: indexQdSvJ17HavMeta || {},
    alias: indexQdSvJ17HavMeta?.alias || [],
    redirect: indexQdSvJ17HavMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/company/careers/locations/index.vue").then(m => m.default || m)
  },
  {
    name: indexQdSvJ17HavMeta?.name ?? "company-careers-locations___mx",
    path: indexQdSvJ17HavMeta?.path ?? "/mx/company/careers/locations",
    meta: indexQdSvJ17HavMeta || {},
    alias: indexQdSvJ17HavMeta?.alias || [],
    redirect: indexQdSvJ17HavMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/company/careers/locations/index.vue").then(m => m.default || m)
  },
  {
    name: indexQdSvJ17HavMeta?.name ?? "company-careers-locations___fr",
    path: indexQdSvJ17HavMeta?.path ?? "/fr/company/careers/locations",
    meta: indexQdSvJ17HavMeta || {},
    alias: indexQdSvJ17HavMeta?.alias || [],
    redirect: indexQdSvJ17HavMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/company/careers/locations/index.vue").then(m => m.default || m)
  },
  {
    name: indexQdSvJ17HavMeta?.name ?? "company-careers-locations___fr-ca",
    path: indexQdSvJ17HavMeta?.path ?? "/fr-ca/company/careers/locations",
    meta: indexQdSvJ17HavMeta || {},
    alias: indexQdSvJ17HavMeta?.alias || [],
    redirect: indexQdSvJ17HavMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/company/careers/locations/index.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93B1jXWy4EMWMeta?.name ?? "company-careers-roles-slug___en-US",
    path: _91slug_93B1jXWy4EMWMeta?.path ?? "/company/careers/roles/:slug()",
    meta: _91slug_93B1jXWy4EMWMeta || {},
    alias: _91slug_93B1jXWy4EMWMeta?.alias || [],
    redirect: _91slug_93B1jXWy4EMWMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/company/careers/roles/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93B1jXWy4EMWMeta?.name ?? "company-careers-roles-slug___ca",
    path: _91slug_93B1jXWy4EMWMeta?.path ?? "/ca/company/careers/roles/:slug()",
    meta: _91slug_93B1jXWy4EMWMeta || {},
    alias: _91slug_93B1jXWy4EMWMeta?.alias || [],
    redirect: _91slug_93B1jXWy4EMWMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/company/careers/roles/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93B1jXWy4EMWMeta?.name ?? "company-careers-roles-slug___nl",
    path: _91slug_93B1jXWy4EMWMeta?.path ?? "/nl/company/careers/roles/:slug()",
    meta: _91slug_93B1jXWy4EMWMeta || {},
    alias: _91slug_93B1jXWy4EMWMeta?.alias || [],
    redirect: _91slug_93B1jXWy4EMWMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/company/careers/roles/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93B1jXWy4EMWMeta?.name ?? "company-careers-roles-slug___uk",
    path: _91slug_93B1jXWy4EMWMeta?.path ?? "/uk/company/careers/roles/:slug()",
    meta: _91slug_93B1jXWy4EMWMeta || {},
    alias: _91slug_93B1jXWy4EMWMeta?.alias || [],
    redirect: _91slug_93B1jXWy4EMWMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/company/careers/roles/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93B1jXWy4EMWMeta?.name ?? "company-careers-roles-slug___de",
    path: _91slug_93B1jXWy4EMWMeta?.path ?? "/de/company/careers/roles/:slug()",
    meta: _91slug_93B1jXWy4EMWMeta || {},
    alias: _91slug_93B1jXWy4EMWMeta?.alias || [],
    redirect: _91slug_93B1jXWy4EMWMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/company/careers/roles/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93B1jXWy4EMWMeta?.name ?? "company-careers-roles-slug___mx",
    path: _91slug_93B1jXWy4EMWMeta?.path ?? "/mx/company/careers/roles/:slug()",
    meta: _91slug_93B1jXWy4EMWMeta || {},
    alias: _91slug_93B1jXWy4EMWMeta?.alias || [],
    redirect: _91slug_93B1jXWy4EMWMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/company/careers/roles/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93B1jXWy4EMWMeta?.name ?? "company-careers-roles-slug___fr",
    path: _91slug_93B1jXWy4EMWMeta?.path ?? "/fr/company/careers/roles/:slug()",
    meta: _91slug_93B1jXWy4EMWMeta || {},
    alias: _91slug_93B1jXWy4EMWMeta?.alias || [],
    redirect: _91slug_93B1jXWy4EMWMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/company/careers/roles/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93B1jXWy4EMWMeta?.name ?? "company-careers-roles-slug___fr-ca",
    path: _91slug_93B1jXWy4EMWMeta?.path ?? "/fr-ca/company/careers/roles/:slug()",
    meta: _91slug_93B1jXWy4EMWMeta || {},
    alias: _91slug_93B1jXWy4EMWMeta?.alias || [],
    redirect: _91slug_93B1jXWy4EMWMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/company/careers/roles/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexJCoLBqngX4Meta?.name ?? "company-careers-roles___en-US",
    path: indexJCoLBqngX4Meta?.path ?? "/company/careers/roles",
    meta: indexJCoLBqngX4Meta || {},
    alias: indexJCoLBqngX4Meta?.alias || [],
    redirect: indexJCoLBqngX4Meta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/company/careers/roles/index.vue").then(m => m.default || m)
  },
  {
    name: indexJCoLBqngX4Meta?.name ?? "company-careers-roles___ca",
    path: indexJCoLBqngX4Meta?.path ?? "/ca/company/careers/roles",
    meta: indexJCoLBqngX4Meta || {},
    alias: indexJCoLBqngX4Meta?.alias || [],
    redirect: indexJCoLBqngX4Meta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/company/careers/roles/index.vue").then(m => m.default || m)
  },
  {
    name: indexJCoLBqngX4Meta?.name ?? "company-careers-roles___nl",
    path: indexJCoLBqngX4Meta?.path ?? "/nl/company/careers/roles",
    meta: indexJCoLBqngX4Meta || {},
    alias: indexJCoLBqngX4Meta?.alias || [],
    redirect: indexJCoLBqngX4Meta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/company/careers/roles/index.vue").then(m => m.default || m)
  },
  {
    name: indexJCoLBqngX4Meta?.name ?? "company-careers-roles___uk",
    path: indexJCoLBqngX4Meta?.path ?? "/uk/company/careers/roles",
    meta: indexJCoLBqngX4Meta || {},
    alias: indexJCoLBqngX4Meta?.alias || [],
    redirect: indexJCoLBqngX4Meta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/company/careers/roles/index.vue").then(m => m.default || m)
  },
  {
    name: indexJCoLBqngX4Meta?.name ?? "company-careers-roles___de",
    path: indexJCoLBqngX4Meta?.path ?? "/de/company/careers/roles",
    meta: indexJCoLBqngX4Meta || {},
    alias: indexJCoLBqngX4Meta?.alias || [],
    redirect: indexJCoLBqngX4Meta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/company/careers/roles/index.vue").then(m => m.default || m)
  },
  {
    name: indexJCoLBqngX4Meta?.name ?? "company-careers-roles___mx",
    path: indexJCoLBqngX4Meta?.path ?? "/mx/company/careers/roles",
    meta: indexJCoLBqngX4Meta || {},
    alias: indexJCoLBqngX4Meta?.alias || [],
    redirect: indexJCoLBqngX4Meta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/company/careers/roles/index.vue").then(m => m.default || m)
  },
  {
    name: indexJCoLBqngX4Meta?.name ?? "company-careers-roles___fr",
    path: indexJCoLBqngX4Meta?.path ?? "/fr/company/careers/roles",
    meta: indexJCoLBqngX4Meta || {},
    alias: indexJCoLBqngX4Meta?.alias || [],
    redirect: indexJCoLBqngX4Meta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/company/careers/roles/index.vue").then(m => m.default || m)
  },
  {
    name: indexJCoLBqngX4Meta?.name ?? "company-careers-roles___fr-ca",
    path: indexJCoLBqngX4Meta?.path ?? "/fr-ca/company/careers/roles",
    meta: indexJCoLBqngX4Meta || {},
    alias: indexJCoLBqngX4Meta?.alias || [],
    redirect: indexJCoLBqngX4Meta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/company/careers/roles/index.vue").then(m => m.default || m)
  },
  {
    name: contactshuSLDYFykMeta?.name ?? "company-contact___en-US",
    path: contactshuSLDYFykMeta?.path ?? "/company/contact",
    meta: contactshuSLDYFykMeta || {},
    alias: contactshuSLDYFykMeta?.alias || [],
    redirect: contactshuSLDYFykMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/company/contact.vue").then(m => m.default || m)
  },
  {
    name: contactshuSLDYFykMeta?.name ?? "company-contact___ca",
    path: contactshuSLDYFykMeta?.path ?? "/ca/company/contact",
    meta: contactshuSLDYFykMeta || {},
    alias: contactshuSLDYFykMeta?.alias || [],
    redirect: contactshuSLDYFykMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/company/contact.vue").then(m => m.default || m)
  },
  {
    name: contactshuSLDYFykMeta?.name ?? "company-contact___nl",
    path: contactshuSLDYFykMeta?.path ?? "/nl/company/contact",
    meta: contactshuSLDYFykMeta || {},
    alias: contactshuSLDYFykMeta?.alias || [],
    redirect: contactshuSLDYFykMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/company/contact.vue").then(m => m.default || m)
  },
  {
    name: contactshuSLDYFykMeta?.name ?? "company-contact___uk",
    path: contactshuSLDYFykMeta?.path ?? "/uk/company/contact",
    meta: contactshuSLDYFykMeta || {},
    alias: contactshuSLDYFykMeta?.alias || [],
    redirect: contactshuSLDYFykMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/company/contact.vue").then(m => m.default || m)
  },
  {
    name: contactshuSLDYFykMeta?.name ?? "company-contact___de",
    path: contactshuSLDYFykMeta?.path ?? "/de/company/contact",
    meta: contactshuSLDYFykMeta || {},
    alias: contactshuSLDYFykMeta?.alias || [],
    redirect: contactshuSLDYFykMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/company/contact.vue").then(m => m.default || m)
  },
  {
    name: contactshuSLDYFykMeta?.name ?? "company-contact___mx",
    path: contactshuSLDYFykMeta?.path ?? "/mx/company/contact",
    meta: contactshuSLDYFykMeta || {},
    alias: contactshuSLDYFykMeta?.alias || [],
    redirect: contactshuSLDYFykMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/company/contact.vue").then(m => m.default || m)
  },
  {
    name: contactshuSLDYFykMeta?.name ?? "company-contact___fr",
    path: contactshuSLDYFykMeta?.path ?? "/fr/company/contact",
    meta: contactshuSLDYFykMeta || {},
    alias: contactshuSLDYFykMeta?.alias || [],
    redirect: contactshuSLDYFykMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/company/contact.vue").then(m => m.default || m)
  },
  {
    name: contactshuSLDYFykMeta?.name ?? "company-contact___fr-ca",
    path: contactshuSLDYFykMeta?.path ?? "/fr-ca/company/contact",
    meta: contactshuSLDYFykMeta || {},
    alias: contactshuSLDYFykMeta?.alias || [],
    redirect: contactshuSLDYFykMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/company/contact.vue").then(m => m.default || m)
  },
  {
    name: diversityXs1C0esTFCMeta?.name ?? "company-diversity___en-US",
    path: diversityXs1C0esTFCMeta?.path ?? "/company/diversity",
    meta: diversityXs1C0esTFCMeta || {},
    alias: diversityXs1C0esTFCMeta?.alias || [],
    redirect: diversityXs1C0esTFCMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/company/diversity.vue").then(m => m.default || m)
  },
  {
    name: diversityXs1C0esTFCMeta?.name ?? "company-diversity___ca",
    path: diversityXs1C0esTFCMeta?.path ?? "/ca/company/diversity",
    meta: diversityXs1C0esTFCMeta || {},
    alias: diversityXs1C0esTFCMeta?.alias || [],
    redirect: diversityXs1C0esTFCMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/company/diversity.vue").then(m => m.default || m)
  },
  {
    name: diversityXs1C0esTFCMeta?.name ?? "company-diversity___nl",
    path: diversityXs1C0esTFCMeta?.path ?? "/nl/company/diversity",
    meta: diversityXs1C0esTFCMeta || {},
    alias: diversityXs1C0esTFCMeta?.alias || [],
    redirect: diversityXs1C0esTFCMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/company/diversity.vue").then(m => m.default || m)
  },
  {
    name: diversityXs1C0esTFCMeta?.name ?? "company-diversity___uk",
    path: diversityXs1C0esTFCMeta?.path ?? "/uk/company/diversity",
    meta: diversityXs1C0esTFCMeta || {},
    alias: diversityXs1C0esTFCMeta?.alias || [],
    redirect: diversityXs1C0esTFCMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/company/diversity.vue").then(m => m.default || m)
  },
  {
    name: diversityXs1C0esTFCMeta?.name ?? "company-diversity___de",
    path: diversityXs1C0esTFCMeta?.path ?? "/de/company/diversity",
    meta: diversityXs1C0esTFCMeta || {},
    alias: diversityXs1C0esTFCMeta?.alias || [],
    redirect: diversityXs1C0esTFCMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/company/diversity.vue").then(m => m.default || m)
  },
  {
    name: diversityXs1C0esTFCMeta?.name ?? "company-diversity___mx",
    path: diversityXs1C0esTFCMeta?.path ?? "/mx/company/diversity",
    meta: diversityXs1C0esTFCMeta || {},
    alias: diversityXs1C0esTFCMeta?.alias || [],
    redirect: diversityXs1C0esTFCMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/company/diversity.vue").then(m => m.default || m)
  },
  {
    name: diversityXs1C0esTFCMeta?.name ?? "company-diversity___fr",
    path: diversityXs1C0esTFCMeta?.path ?? "/fr/company/diversity",
    meta: diversityXs1C0esTFCMeta || {},
    alias: diversityXs1C0esTFCMeta?.alias || [],
    redirect: diversityXs1C0esTFCMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/company/diversity.vue").then(m => m.default || m)
  },
  {
    name: diversityXs1C0esTFCMeta?.name ?? "company-diversity___fr-ca",
    path: diversityXs1C0esTFCMeta?.path ?? "/fr-ca/company/diversity",
    meta: diversityXs1C0esTFCMeta || {},
    alias: diversityXs1C0esTFCMeta?.alias || [],
    redirect: diversityXs1C0esTFCMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/company/diversity.vue").then(m => m.default || m)
  },
  {
    name: indexTKfajufZHzMeta?.name ?? "company___en-US",
    path: indexTKfajufZHzMeta?.path ?? "/company",
    meta: indexTKfajufZHzMeta || {},
    alias: indexTKfajufZHzMeta?.alias || [],
    redirect: indexTKfajufZHzMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/company/index.vue").then(m => m.default || m)
  },
  {
    name: indexTKfajufZHzMeta?.name ?? "company___ca",
    path: indexTKfajufZHzMeta?.path ?? "/ca/company",
    meta: indexTKfajufZHzMeta || {},
    alias: indexTKfajufZHzMeta?.alias || [],
    redirect: indexTKfajufZHzMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/company/index.vue").then(m => m.default || m)
  },
  {
    name: indexTKfajufZHzMeta?.name ?? "company___nl",
    path: indexTKfajufZHzMeta?.path ?? "/nl/company",
    meta: indexTKfajufZHzMeta || {},
    alias: indexTKfajufZHzMeta?.alias || [],
    redirect: indexTKfajufZHzMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/company/index.vue").then(m => m.default || m)
  },
  {
    name: indexTKfajufZHzMeta?.name ?? "company___uk",
    path: indexTKfajufZHzMeta?.path ?? "/uk/company",
    meta: indexTKfajufZHzMeta || {},
    alias: indexTKfajufZHzMeta?.alias || [],
    redirect: indexTKfajufZHzMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/company/index.vue").then(m => m.default || m)
  },
  {
    name: indexTKfajufZHzMeta?.name ?? "company___de",
    path: indexTKfajufZHzMeta?.path ?? "/de/company",
    meta: indexTKfajufZHzMeta || {},
    alias: indexTKfajufZHzMeta?.alias || [],
    redirect: indexTKfajufZHzMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/company/index.vue").then(m => m.default || m)
  },
  {
    name: indexTKfajufZHzMeta?.name ?? "company___mx",
    path: indexTKfajufZHzMeta?.path ?? "/mx/company",
    meta: indexTKfajufZHzMeta || {},
    alias: indexTKfajufZHzMeta?.alias || [],
    redirect: indexTKfajufZHzMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/company/index.vue").then(m => m.default || m)
  },
  {
    name: indexTKfajufZHzMeta?.name ?? "company___fr",
    path: indexTKfajufZHzMeta?.path ?? "/fr/company",
    meta: indexTKfajufZHzMeta || {},
    alias: indexTKfajufZHzMeta?.alias || [],
    redirect: indexTKfajufZHzMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/company/index.vue").then(m => m.default || m)
  },
  {
    name: indexTKfajufZHzMeta?.name ?? "company___fr-ca",
    path: indexTKfajufZHzMeta?.path ?? "/fr-ca/company",
    meta: indexTKfajufZHzMeta || {},
    alias: indexTKfajufZHzMeta?.alias || [],
    redirect: indexTKfajufZHzMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/company/index.vue").then(m => m.default || m)
  },
  {
    name: efficiencyETqZzvetSgMeta?.name ?? "company-mission-efficiency___en-US",
    path: efficiencyETqZzvetSgMeta?.path ?? "/company/mission/efficiency",
    meta: efficiencyETqZzvetSgMeta || {},
    alias: efficiencyETqZzvetSgMeta?.alias || [],
    redirect: efficiencyETqZzvetSgMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/company/mission/efficiency.vue").then(m => m.default || m)
  },
  {
    name: efficiencyETqZzvetSgMeta?.name ?? "company-mission-efficiency___ca",
    path: efficiencyETqZzvetSgMeta?.path ?? "/ca/company/mission/efficiency",
    meta: efficiencyETqZzvetSgMeta || {},
    alias: efficiencyETqZzvetSgMeta?.alias || [],
    redirect: efficiencyETqZzvetSgMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/company/mission/efficiency.vue").then(m => m.default || m)
  },
  {
    name: efficiencyETqZzvetSgMeta?.name ?? "company-mission-efficiency___nl",
    path: efficiencyETqZzvetSgMeta?.path ?? "/nl/company/mission/efficiency",
    meta: efficiencyETqZzvetSgMeta || {},
    alias: efficiencyETqZzvetSgMeta?.alias || [],
    redirect: efficiencyETqZzvetSgMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/company/mission/efficiency.vue").then(m => m.default || m)
  },
  {
    name: efficiencyETqZzvetSgMeta?.name ?? "company-mission-efficiency___uk",
    path: efficiencyETqZzvetSgMeta?.path ?? "/uk/company/mission/efficiency",
    meta: efficiencyETqZzvetSgMeta || {},
    alias: efficiencyETqZzvetSgMeta?.alias || [],
    redirect: efficiencyETqZzvetSgMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/company/mission/efficiency.vue").then(m => m.default || m)
  },
  {
    name: efficiencyETqZzvetSgMeta?.name ?? "company-mission-efficiency___de",
    path: efficiencyETqZzvetSgMeta?.path ?? "/de/company/mission/efficiency",
    meta: efficiencyETqZzvetSgMeta || {},
    alias: efficiencyETqZzvetSgMeta?.alias || [],
    redirect: efficiencyETqZzvetSgMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/company/mission/efficiency.vue").then(m => m.default || m)
  },
  {
    name: efficiencyETqZzvetSgMeta?.name ?? "company-mission-efficiency___mx",
    path: efficiencyETqZzvetSgMeta?.path ?? "/mx/company/mission/efficiency",
    meta: efficiencyETqZzvetSgMeta || {},
    alias: efficiencyETqZzvetSgMeta?.alias || [],
    redirect: efficiencyETqZzvetSgMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/company/mission/efficiency.vue").then(m => m.default || m)
  },
  {
    name: efficiencyETqZzvetSgMeta?.name ?? "company-mission-efficiency___fr",
    path: efficiencyETqZzvetSgMeta?.path ?? "/fr/company/mission/efficiency",
    meta: efficiencyETqZzvetSgMeta || {},
    alias: efficiencyETqZzvetSgMeta?.alias || [],
    redirect: efficiencyETqZzvetSgMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/company/mission/efficiency.vue").then(m => m.default || m)
  },
  {
    name: efficiencyETqZzvetSgMeta?.name ?? "company-mission-efficiency___fr-ca",
    path: efficiencyETqZzvetSgMeta?.path ?? "/fr-ca/company/mission/efficiency",
    meta: efficiencyETqZzvetSgMeta || {},
    alias: efficiencyETqZzvetSgMeta?.alias || [],
    redirect: efficiencyETqZzvetSgMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/company/mission/efficiency.vue").then(m => m.default || m)
  },
  {
    name: safetysE9VAaXvZ3Meta?.name ?? "company-mission-safety___en-US",
    path: safetysE9VAaXvZ3Meta?.path ?? "/company/mission/safety",
    meta: safetysE9VAaXvZ3Meta || {},
    alias: safetysE9VAaXvZ3Meta?.alias || [],
    redirect: safetysE9VAaXvZ3Meta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/company/mission/safety.vue").then(m => m.default || m)
  },
  {
    name: safetysE9VAaXvZ3Meta?.name ?? "company-mission-safety___ca",
    path: safetysE9VAaXvZ3Meta?.path ?? "/ca/company/mission/safety",
    meta: safetysE9VAaXvZ3Meta || {},
    alias: safetysE9VAaXvZ3Meta?.alias || [],
    redirect: safetysE9VAaXvZ3Meta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/company/mission/safety.vue").then(m => m.default || m)
  },
  {
    name: safetysE9VAaXvZ3Meta?.name ?? "company-mission-safety___nl",
    path: safetysE9VAaXvZ3Meta?.path ?? "/nl/company/mission/safety",
    meta: safetysE9VAaXvZ3Meta || {},
    alias: safetysE9VAaXvZ3Meta?.alias || [],
    redirect: safetysE9VAaXvZ3Meta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/company/mission/safety.vue").then(m => m.default || m)
  },
  {
    name: safetysE9VAaXvZ3Meta?.name ?? "company-mission-safety___uk",
    path: safetysE9VAaXvZ3Meta?.path ?? "/uk/company/mission/safety",
    meta: safetysE9VAaXvZ3Meta || {},
    alias: safetysE9VAaXvZ3Meta?.alias || [],
    redirect: safetysE9VAaXvZ3Meta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/company/mission/safety.vue").then(m => m.default || m)
  },
  {
    name: safetysE9VAaXvZ3Meta?.name ?? "company-mission-safety___de",
    path: safetysE9VAaXvZ3Meta?.path ?? "/de/company/mission/safety",
    meta: safetysE9VAaXvZ3Meta || {},
    alias: safetysE9VAaXvZ3Meta?.alias || [],
    redirect: safetysE9VAaXvZ3Meta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/company/mission/safety.vue").then(m => m.default || m)
  },
  {
    name: safetysE9VAaXvZ3Meta?.name ?? "company-mission-safety___mx",
    path: safetysE9VAaXvZ3Meta?.path ?? "/mx/company/mission/safety",
    meta: safetysE9VAaXvZ3Meta || {},
    alias: safetysE9VAaXvZ3Meta?.alias || [],
    redirect: safetysE9VAaXvZ3Meta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/company/mission/safety.vue").then(m => m.default || m)
  },
  {
    name: safetysE9VAaXvZ3Meta?.name ?? "company-mission-safety___fr",
    path: safetysE9VAaXvZ3Meta?.path ?? "/fr/company/mission/safety",
    meta: safetysE9VAaXvZ3Meta || {},
    alias: safetysE9VAaXvZ3Meta?.alias || [],
    redirect: safetysE9VAaXvZ3Meta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/company/mission/safety.vue").then(m => m.default || m)
  },
  {
    name: safetysE9VAaXvZ3Meta?.name ?? "company-mission-safety___fr-ca",
    path: safetysE9VAaXvZ3Meta?.path ?? "/fr-ca/company/mission/safety",
    meta: safetysE9VAaXvZ3Meta || {},
    alias: safetysE9VAaXvZ3Meta?.alias || [],
    redirect: safetysE9VAaXvZ3Meta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/company/mission/safety.vue").then(m => m.default || m)
  },
  {
    name: sustainabilityj0oYNKMxapMeta?.name ?? "company-mission-sustainability___en-US",
    path: sustainabilityj0oYNKMxapMeta?.path ?? "/company/mission/sustainability",
    meta: sustainabilityj0oYNKMxapMeta || {},
    alias: sustainabilityj0oYNKMxapMeta?.alias || [],
    redirect: sustainabilityj0oYNKMxapMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/company/mission/sustainability.vue").then(m => m.default || m)
  },
  {
    name: sustainabilityj0oYNKMxapMeta?.name ?? "company-mission-sustainability___ca",
    path: sustainabilityj0oYNKMxapMeta?.path ?? "/ca/company/mission/sustainability",
    meta: sustainabilityj0oYNKMxapMeta || {},
    alias: sustainabilityj0oYNKMxapMeta?.alias || [],
    redirect: sustainabilityj0oYNKMxapMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/company/mission/sustainability.vue").then(m => m.default || m)
  },
  {
    name: sustainabilityj0oYNKMxapMeta?.name ?? "company-mission-sustainability___nl",
    path: sustainabilityj0oYNKMxapMeta?.path ?? "/nl/company/mission/sustainability",
    meta: sustainabilityj0oYNKMxapMeta || {},
    alias: sustainabilityj0oYNKMxapMeta?.alias || [],
    redirect: sustainabilityj0oYNKMxapMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/company/mission/sustainability.vue").then(m => m.default || m)
  },
  {
    name: sustainabilityj0oYNKMxapMeta?.name ?? "company-mission-sustainability___uk",
    path: sustainabilityj0oYNKMxapMeta?.path ?? "/uk/company/mission/sustainability",
    meta: sustainabilityj0oYNKMxapMeta || {},
    alias: sustainabilityj0oYNKMxapMeta?.alias || [],
    redirect: sustainabilityj0oYNKMxapMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/company/mission/sustainability.vue").then(m => m.default || m)
  },
  {
    name: sustainabilityj0oYNKMxapMeta?.name ?? "company-mission-sustainability___de",
    path: sustainabilityj0oYNKMxapMeta?.path ?? "/de/company/mission/sustainability",
    meta: sustainabilityj0oYNKMxapMeta || {},
    alias: sustainabilityj0oYNKMxapMeta?.alias || [],
    redirect: sustainabilityj0oYNKMxapMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/company/mission/sustainability.vue").then(m => m.default || m)
  },
  {
    name: sustainabilityj0oYNKMxapMeta?.name ?? "company-mission-sustainability___mx",
    path: sustainabilityj0oYNKMxapMeta?.path ?? "/mx/company/mission/sustainability",
    meta: sustainabilityj0oYNKMxapMeta || {},
    alias: sustainabilityj0oYNKMxapMeta?.alias || [],
    redirect: sustainabilityj0oYNKMxapMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/company/mission/sustainability.vue").then(m => m.default || m)
  },
  {
    name: sustainabilityj0oYNKMxapMeta?.name ?? "company-mission-sustainability___fr",
    path: sustainabilityj0oYNKMxapMeta?.path ?? "/fr/company/mission/sustainability",
    meta: sustainabilityj0oYNKMxapMeta || {},
    alias: sustainabilityj0oYNKMxapMeta?.alias || [],
    redirect: sustainabilityj0oYNKMxapMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/company/mission/sustainability.vue").then(m => m.default || m)
  },
  {
    name: sustainabilityj0oYNKMxapMeta?.name ?? "company-mission-sustainability___fr-ca",
    path: sustainabilityj0oYNKMxapMeta?.path ?? "/fr-ca/company/mission/sustainability",
    meta: sustainabilityj0oYNKMxapMeta || {},
    alias: sustainabilityj0oYNKMxapMeta?.alias || [],
    redirect: sustainabilityj0oYNKMxapMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/company/mission/sustainability.vue").then(m => m.default || m)
  },
  {
    name: in_45the_45newsNEgI7fmv7zMeta?.name ?? "company-news-in-the-news___en-US",
    path: in_45the_45newsNEgI7fmv7zMeta?.path ?? "/company/news/in-the-news",
    meta: in_45the_45newsNEgI7fmv7zMeta || {},
    alias: in_45the_45newsNEgI7fmv7zMeta?.alias || [],
    redirect: in_45the_45newsNEgI7fmv7zMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/company/news/in-the-news.vue").then(m => m.default || m)
  },
  {
    name: in_45the_45newsNEgI7fmv7zMeta?.name ?? "company-news-in-the-news___ca",
    path: in_45the_45newsNEgI7fmv7zMeta?.path ?? "/ca/company/news/in-the-news",
    meta: in_45the_45newsNEgI7fmv7zMeta || {},
    alias: in_45the_45newsNEgI7fmv7zMeta?.alias || [],
    redirect: in_45the_45newsNEgI7fmv7zMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/company/news/in-the-news.vue").then(m => m.default || m)
  },
  {
    name: in_45the_45newsNEgI7fmv7zMeta?.name ?? "company-news-in-the-news___nl",
    path: in_45the_45newsNEgI7fmv7zMeta?.path ?? "/nl/company/news/in-the-news",
    meta: in_45the_45newsNEgI7fmv7zMeta || {},
    alias: in_45the_45newsNEgI7fmv7zMeta?.alias || [],
    redirect: in_45the_45newsNEgI7fmv7zMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/company/news/in-the-news.vue").then(m => m.default || m)
  },
  {
    name: in_45the_45newsNEgI7fmv7zMeta?.name ?? "company-news-in-the-news___uk",
    path: in_45the_45newsNEgI7fmv7zMeta?.path ?? "/uk/company/news/in-the-news",
    meta: in_45the_45newsNEgI7fmv7zMeta || {},
    alias: in_45the_45newsNEgI7fmv7zMeta?.alias || [],
    redirect: in_45the_45newsNEgI7fmv7zMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/company/news/in-the-news.vue").then(m => m.default || m)
  },
  {
    name: in_45the_45newsNEgI7fmv7zMeta?.name ?? "company-news-in-the-news___de",
    path: in_45the_45newsNEgI7fmv7zMeta?.path ?? "/de/company/news/in-the-news",
    meta: in_45the_45newsNEgI7fmv7zMeta || {},
    alias: in_45the_45newsNEgI7fmv7zMeta?.alias || [],
    redirect: in_45the_45newsNEgI7fmv7zMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/company/news/in-the-news.vue").then(m => m.default || m)
  },
  {
    name: in_45the_45newsNEgI7fmv7zMeta?.name ?? "company-news-in-the-news___mx",
    path: in_45the_45newsNEgI7fmv7zMeta?.path ?? "/mx/company/news/in-the-news",
    meta: in_45the_45newsNEgI7fmv7zMeta || {},
    alias: in_45the_45newsNEgI7fmv7zMeta?.alias || [],
    redirect: in_45the_45newsNEgI7fmv7zMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/company/news/in-the-news.vue").then(m => m.default || m)
  },
  {
    name: in_45the_45newsNEgI7fmv7zMeta?.name ?? "company-news-in-the-news___fr",
    path: in_45the_45newsNEgI7fmv7zMeta?.path ?? "/fr/company/news/in-the-news",
    meta: in_45the_45newsNEgI7fmv7zMeta || {},
    alias: in_45the_45newsNEgI7fmv7zMeta?.alias || [],
    redirect: in_45the_45newsNEgI7fmv7zMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/company/news/in-the-news.vue").then(m => m.default || m)
  },
  {
    name: in_45the_45newsNEgI7fmv7zMeta?.name ?? "company-news-in-the-news___fr-ca",
    path: in_45the_45newsNEgI7fmv7zMeta?.path ?? "/fr-ca/company/news/in-the-news",
    meta: in_45the_45newsNEgI7fmv7zMeta || {},
    alias: in_45the_45newsNEgI7fmv7zMeta?.alias || [],
    redirect: in_45the_45newsNEgI7fmv7zMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/company/news/in-the-news.vue").then(m => m.default || m)
  },
  {
    name: index0KEpppWO3HMeta?.name ?? "company-news___en-US",
    path: index0KEpppWO3HMeta?.path ?? "/company/news",
    meta: index0KEpppWO3HMeta || {},
    alias: index0KEpppWO3HMeta?.alias || [],
    redirect: index0KEpppWO3HMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/company/news/index.vue").then(m => m.default || m)
  },
  {
    name: index0KEpppWO3HMeta?.name ?? "company-news___ca",
    path: index0KEpppWO3HMeta?.path ?? "/ca/company/news",
    meta: index0KEpppWO3HMeta || {},
    alias: index0KEpppWO3HMeta?.alias || [],
    redirect: index0KEpppWO3HMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/company/news/index.vue").then(m => m.default || m)
  },
  {
    name: index0KEpppWO3HMeta?.name ?? "company-news___mx",
    path: index0KEpppWO3HMeta?.path ?? "/mx/company/news",
    meta: index0KEpppWO3HMeta || {},
    alias: index0KEpppWO3HMeta?.alias || [],
    redirect: index0KEpppWO3HMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/company/news/index.vue").then(m => m.default || m)
  },
  {
    name: index0KEpppWO3HMeta?.name ?? "company-news___uk",
    path: index0KEpppWO3HMeta?.path ?? "/uk/company/news",
    meta: index0KEpppWO3HMeta || {},
    alias: index0KEpppWO3HMeta?.alias || [],
    redirect: index0KEpppWO3HMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/company/news/index.vue").then(m => m.default || m)
  },
  {
    name: press_45releasesWmTKWMADtgMeta?.name ?? "company-news-press-releases___en-US",
    path: press_45releasesWmTKWMADtgMeta?.path ?? "/company/news/press-releases",
    meta: press_45releasesWmTKWMADtgMeta || {},
    alias: press_45releasesWmTKWMADtgMeta?.alias || [],
    redirect: press_45releasesWmTKWMADtgMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/company/news/press-releases.vue").then(m => m.default || m)
  },
  {
    name: press_45releasesWmTKWMADtgMeta?.name ?? "company-news-press-releases___ca",
    path: press_45releasesWmTKWMADtgMeta?.path ?? "/ca/company/news/press-releases",
    meta: press_45releasesWmTKWMADtgMeta || {},
    alias: press_45releasesWmTKWMADtgMeta?.alias || [],
    redirect: press_45releasesWmTKWMADtgMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/company/news/press-releases.vue").then(m => m.default || m)
  },
  {
    name: press_45releasesWmTKWMADtgMeta?.name ?? "company-news-press-releases___nl",
    path: press_45releasesWmTKWMADtgMeta?.path ?? "/nl/company/news/press-releases",
    meta: press_45releasesWmTKWMADtgMeta || {},
    alias: press_45releasesWmTKWMADtgMeta?.alias || [],
    redirect: press_45releasesWmTKWMADtgMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/company/news/press-releases.vue").then(m => m.default || m)
  },
  {
    name: press_45releasesWmTKWMADtgMeta?.name ?? "company-news-press-releases___uk",
    path: press_45releasesWmTKWMADtgMeta?.path ?? "/uk/company/news/press-releases",
    meta: press_45releasesWmTKWMADtgMeta || {},
    alias: press_45releasesWmTKWMADtgMeta?.alias || [],
    redirect: press_45releasesWmTKWMADtgMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/company/news/press-releases.vue").then(m => m.default || m)
  },
  {
    name: press_45releasesWmTKWMADtgMeta?.name ?? "company-news-press-releases___de",
    path: press_45releasesWmTKWMADtgMeta?.path ?? "/de/company/news/press-releases",
    meta: press_45releasesWmTKWMADtgMeta || {},
    alias: press_45releasesWmTKWMADtgMeta?.alias || [],
    redirect: press_45releasesWmTKWMADtgMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/company/news/press-releases.vue").then(m => m.default || m)
  },
  {
    name: press_45releasesWmTKWMADtgMeta?.name ?? "company-news-press-releases___mx",
    path: press_45releasesWmTKWMADtgMeta?.path ?? "/mx/company/news/press-releases",
    meta: press_45releasesWmTKWMADtgMeta || {},
    alias: press_45releasesWmTKWMADtgMeta?.alias || [],
    redirect: press_45releasesWmTKWMADtgMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/company/news/press-releases.vue").then(m => m.default || m)
  },
  {
    name: press_45releasesWmTKWMADtgMeta?.name ?? "company-news-press-releases___fr",
    path: press_45releasesWmTKWMADtgMeta?.path ?? "/fr/company/news/press-releases",
    meta: press_45releasesWmTKWMADtgMeta || {},
    alias: press_45releasesWmTKWMADtgMeta?.alias || [],
    redirect: press_45releasesWmTKWMADtgMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/company/news/press-releases.vue").then(m => m.default || m)
  },
  {
    name: press_45releasesWmTKWMADtgMeta?.name ?? "company-news-press-releases___fr-ca",
    path: press_45releasesWmTKWMADtgMeta?.path ?? "/fr-ca/company/news/press-releases",
    meta: press_45releasesWmTKWMADtgMeta || {},
    alias: press_45releasesWmTKWMADtgMeta?.alias || [],
    redirect: press_45releasesWmTKWMADtgMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/company/news/press-releases.vue").then(m => m.default || m)
  },
  {
    name: privacymoZrZgUmQ8Meta?.name ?? "company-privacy___en-US",
    path: privacymoZrZgUmQ8Meta?.path ?? "/company/privacy",
    meta: privacymoZrZgUmQ8Meta || {},
    alias: privacymoZrZgUmQ8Meta?.alias || [],
    redirect: privacymoZrZgUmQ8Meta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/company/privacy.vue").then(m => m.default || m)
  },
  {
    name: privacymoZrZgUmQ8Meta?.name ?? "company-privacy___ca",
    path: privacymoZrZgUmQ8Meta?.path ?? "/ca/company/privacy",
    meta: privacymoZrZgUmQ8Meta || {},
    alias: privacymoZrZgUmQ8Meta?.alias || [],
    redirect: privacymoZrZgUmQ8Meta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/company/privacy.vue").then(m => m.default || m)
  },
  {
    name: privacymoZrZgUmQ8Meta?.name ?? "company-privacy___nl",
    path: privacymoZrZgUmQ8Meta?.path ?? "/nl/company/privacy",
    meta: privacymoZrZgUmQ8Meta || {},
    alias: privacymoZrZgUmQ8Meta?.alias || [],
    redirect: privacymoZrZgUmQ8Meta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/company/privacy.vue").then(m => m.default || m)
  },
  {
    name: privacymoZrZgUmQ8Meta?.name ?? "company-privacy___uk",
    path: privacymoZrZgUmQ8Meta?.path ?? "/uk/company/privacy",
    meta: privacymoZrZgUmQ8Meta || {},
    alias: privacymoZrZgUmQ8Meta?.alias || [],
    redirect: privacymoZrZgUmQ8Meta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/company/privacy.vue").then(m => m.default || m)
  },
  {
    name: privacymoZrZgUmQ8Meta?.name ?? "company-privacy___de",
    path: privacymoZrZgUmQ8Meta?.path ?? "/de/company/privacy",
    meta: privacymoZrZgUmQ8Meta || {},
    alias: privacymoZrZgUmQ8Meta?.alias || [],
    redirect: privacymoZrZgUmQ8Meta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/company/privacy.vue").then(m => m.default || m)
  },
  {
    name: privacymoZrZgUmQ8Meta?.name ?? "company-privacy___mx",
    path: privacymoZrZgUmQ8Meta?.path ?? "/mx/company/privacy",
    meta: privacymoZrZgUmQ8Meta || {},
    alias: privacymoZrZgUmQ8Meta?.alias || [],
    redirect: privacymoZrZgUmQ8Meta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/company/privacy.vue").then(m => m.default || m)
  },
  {
    name: privacymoZrZgUmQ8Meta?.name ?? "company-privacy___fr",
    path: privacymoZrZgUmQ8Meta?.path ?? "/fr/company/privacy",
    meta: privacymoZrZgUmQ8Meta || {},
    alias: privacymoZrZgUmQ8Meta?.alias || [],
    redirect: privacymoZrZgUmQ8Meta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/company/privacy.vue").then(m => m.default || m)
  },
  {
    name: privacymoZrZgUmQ8Meta?.name ?? "company-privacy___fr-ca",
    path: privacymoZrZgUmQ8Meta?.path ?? "/fr-ca/company/privacy",
    meta: privacymoZrZgUmQ8Meta || {},
    alias: privacymoZrZgUmQ8Meta?.alias || [],
    redirect: privacymoZrZgUmQ8Meta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/company/privacy.vue").then(m => m.default || m)
  },
  {
    name: cost_45assessment_45toolC4DFGimdGdMeta?.name ?? "cost-assessment-tool___en-US",
    path: cost_45assessment_45toolC4DFGimdGdMeta?.path ?? "/cost-assessment-tool",
    meta: cost_45assessment_45toolC4DFGimdGdMeta || {},
    alias: cost_45assessment_45toolC4DFGimdGdMeta?.alias || [],
    redirect: cost_45assessment_45toolC4DFGimdGdMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/cost-assessment-tool.vue").then(m => m.default || m)
  },
  {
    name: cost_45assessment_45toolC4DFGimdGdMeta?.name ?? "cost-assessment-tool___ca",
    path: cost_45assessment_45toolC4DFGimdGdMeta?.path ?? "/ca/cost-assessment-tool",
    meta: cost_45assessment_45toolC4DFGimdGdMeta || {},
    alias: cost_45assessment_45toolC4DFGimdGdMeta?.alias || [],
    redirect: cost_45assessment_45toolC4DFGimdGdMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/cost-assessment-tool.vue").then(m => m.default || m)
  },
  {
    name: cost_45assessment_45toolC4DFGimdGdMeta?.name ?? "cost-assessment-tool___nl",
    path: cost_45assessment_45toolC4DFGimdGdMeta?.path ?? "/nl/cost-assessment-tool",
    meta: cost_45assessment_45toolC4DFGimdGdMeta || {},
    alias: cost_45assessment_45toolC4DFGimdGdMeta?.alias || [],
    redirect: cost_45assessment_45toolC4DFGimdGdMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/cost-assessment-tool.vue").then(m => m.default || m)
  },
  {
    name: cost_45assessment_45toolC4DFGimdGdMeta?.name ?? "cost-assessment-tool___uk",
    path: cost_45assessment_45toolC4DFGimdGdMeta?.path ?? "/uk/cost-assessment-tool",
    meta: cost_45assessment_45toolC4DFGimdGdMeta || {},
    alias: cost_45assessment_45toolC4DFGimdGdMeta?.alias || [],
    redirect: cost_45assessment_45toolC4DFGimdGdMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/cost-assessment-tool.vue").then(m => m.default || m)
  },
  {
    name: cost_45assessment_45toolC4DFGimdGdMeta?.name ?? "cost-assessment-tool___de",
    path: cost_45assessment_45toolC4DFGimdGdMeta?.path ?? "/de/cost-assessment-tool",
    meta: cost_45assessment_45toolC4DFGimdGdMeta || {},
    alias: cost_45assessment_45toolC4DFGimdGdMeta?.alias || [],
    redirect: cost_45assessment_45toolC4DFGimdGdMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/cost-assessment-tool.vue").then(m => m.default || m)
  },
  {
    name: cost_45assessment_45toolC4DFGimdGdMeta?.name ?? "cost-assessment-tool___mx",
    path: cost_45assessment_45toolC4DFGimdGdMeta?.path ?? "/mx/cost-assessment-tool",
    meta: cost_45assessment_45toolC4DFGimdGdMeta || {},
    alias: cost_45assessment_45toolC4DFGimdGdMeta?.alias || [],
    redirect: cost_45assessment_45toolC4DFGimdGdMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/cost-assessment-tool.vue").then(m => m.default || m)
  },
  {
    name: cost_45assessment_45toolC4DFGimdGdMeta?.name ?? "cost-assessment-tool___fr",
    path: cost_45assessment_45toolC4DFGimdGdMeta?.path ?? "/fr/cost-assessment-tool",
    meta: cost_45assessment_45toolC4DFGimdGdMeta || {},
    alias: cost_45assessment_45toolC4DFGimdGdMeta?.alias || [],
    redirect: cost_45assessment_45toolC4DFGimdGdMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/cost-assessment-tool.vue").then(m => m.default || m)
  },
  {
    name: cost_45assessment_45toolC4DFGimdGdMeta?.name ?? "cost-assessment-tool___fr-ca",
    path: cost_45assessment_45toolC4DFGimdGdMeta?.path ?? "/fr-ca/cost-assessment-tool",
    meta: cost_45assessment_45toolC4DFGimdGdMeta || {},
    alias: cost_45assessment_45toolC4DFGimdGdMeta?.alias || [],
    redirect: cost_45assessment_45toolC4DFGimdGdMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/cost-assessment-tool.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93tHGdEi5QklMeta?.name ?? "customers-slug___en-US",
    path: _91slug_93tHGdEi5QklMeta?.path ?? "/customers/:slug()",
    meta: _91slug_93tHGdEi5QklMeta || {},
    alias: _91slug_93tHGdEi5QklMeta?.alias || [],
    redirect: _91slug_93tHGdEi5QklMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/customers/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93tHGdEi5QklMeta?.name ?? "customers-slug___ca",
    path: _91slug_93tHGdEi5QklMeta?.path ?? "/ca/customers/:slug()",
    meta: _91slug_93tHGdEi5QklMeta || {},
    alias: _91slug_93tHGdEi5QklMeta?.alias || [],
    redirect: _91slug_93tHGdEi5QklMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/customers/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93tHGdEi5QklMeta?.name ?? "customers-slug___nl",
    path: _91slug_93tHGdEi5QklMeta?.path ?? "/nl/customers/:slug()",
    meta: _91slug_93tHGdEi5QklMeta || {},
    alias: _91slug_93tHGdEi5QklMeta?.alias || [],
    redirect: _91slug_93tHGdEi5QklMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/customers/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93tHGdEi5QklMeta?.name ?? "customers-slug___uk",
    path: _91slug_93tHGdEi5QklMeta?.path ?? "/uk/customers/:slug()",
    meta: _91slug_93tHGdEi5QklMeta || {},
    alias: _91slug_93tHGdEi5QklMeta?.alias || [],
    redirect: _91slug_93tHGdEi5QklMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/customers/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93tHGdEi5QklMeta?.name ?? "customers-slug___de",
    path: _91slug_93tHGdEi5QklMeta?.path ?? "/de/customers/:slug()",
    meta: _91slug_93tHGdEi5QklMeta || {},
    alias: _91slug_93tHGdEi5QklMeta?.alias || [],
    redirect: _91slug_93tHGdEi5QklMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/customers/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93tHGdEi5QklMeta?.name ?? "customers-slug___mx",
    path: _91slug_93tHGdEi5QklMeta?.path ?? "/mx/customers/:slug()",
    meta: _91slug_93tHGdEi5QklMeta || {},
    alias: _91slug_93tHGdEi5QklMeta?.alias || [],
    redirect: _91slug_93tHGdEi5QklMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/customers/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93tHGdEi5QklMeta?.name ?? "customers-slug___fr",
    path: _91slug_93tHGdEi5QklMeta?.path ?? "/fr/customers/:slug()",
    meta: _91slug_93tHGdEi5QklMeta || {},
    alias: _91slug_93tHGdEi5QklMeta?.alias || [],
    redirect: _91slug_93tHGdEi5QklMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/customers/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93tHGdEi5QklMeta?.name ?? "customers-slug___fr-ca",
    path: _91slug_93tHGdEi5QklMeta?.path ?? "/fr-ca/customers/:slug()",
    meta: _91slug_93tHGdEi5QklMeta || {},
    alias: _91slug_93tHGdEi5QklMeta?.alias || [],
    redirect: _91slug_93tHGdEi5QklMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/customers/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93sxJeHbOXHLMeta?.name ?? "customers-category-slug___en-US",
    path: _91slug_93sxJeHbOXHLMeta?.path ?? "/customers/category/:slug()",
    meta: _91slug_93sxJeHbOXHLMeta || {},
    alias: _91slug_93sxJeHbOXHLMeta?.alias || [],
    redirect: _91slug_93sxJeHbOXHLMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/customers/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93sxJeHbOXHLMeta?.name ?? "customers-category-slug___ca",
    path: _91slug_93sxJeHbOXHLMeta?.path ?? "/ca/customers/category/:slug()",
    meta: _91slug_93sxJeHbOXHLMeta || {},
    alias: _91slug_93sxJeHbOXHLMeta?.alias || [],
    redirect: _91slug_93sxJeHbOXHLMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/customers/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93sxJeHbOXHLMeta?.name ?? "customers-category-slug___nl",
    path: _91slug_93sxJeHbOXHLMeta?.path ?? "/nl/customers/category/:slug()",
    meta: _91slug_93sxJeHbOXHLMeta || {},
    alias: _91slug_93sxJeHbOXHLMeta?.alias || [],
    redirect: _91slug_93sxJeHbOXHLMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/customers/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93sxJeHbOXHLMeta?.name ?? "customers-category-slug___uk",
    path: _91slug_93sxJeHbOXHLMeta?.path ?? "/uk/customers/category/:slug()",
    meta: _91slug_93sxJeHbOXHLMeta || {},
    alias: _91slug_93sxJeHbOXHLMeta?.alias || [],
    redirect: _91slug_93sxJeHbOXHLMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/customers/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93sxJeHbOXHLMeta?.name ?? "customers-category-slug___de",
    path: _91slug_93sxJeHbOXHLMeta?.path ?? "/de/customers/category/:slug()",
    meta: _91slug_93sxJeHbOXHLMeta || {},
    alias: _91slug_93sxJeHbOXHLMeta?.alias || [],
    redirect: _91slug_93sxJeHbOXHLMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/customers/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93sxJeHbOXHLMeta?.name ?? "customers-category-slug___mx",
    path: _91slug_93sxJeHbOXHLMeta?.path ?? "/mx/customers/category/:slug()",
    meta: _91slug_93sxJeHbOXHLMeta || {},
    alias: _91slug_93sxJeHbOXHLMeta?.alias || [],
    redirect: _91slug_93sxJeHbOXHLMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/customers/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93sxJeHbOXHLMeta?.name ?? "customers-category-slug___fr",
    path: _91slug_93sxJeHbOXHLMeta?.path ?? "/fr/customers/category/:slug()",
    meta: _91slug_93sxJeHbOXHLMeta || {},
    alias: _91slug_93sxJeHbOXHLMeta?.alias || [],
    redirect: _91slug_93sxJeHbOXHLMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/customers/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93sxJeHbOXHLMeta?.name ?? "customers-category-slug___fr-ca",
    path: _91slug_93sxJeHbOXHLMeta?.path ?? "/fr-ca/customers/category/:slug()",
    meta: _91slug_93sxJeHbOXHLMeta || {},
    alias: _91slug_93sxJeHbOXHLMeta?.alias || [],
    redirect: _91slug_93sxJeHbOXHLMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/customers/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexF14nP73BecMeta?.name ?? "customers___en-US",
    path: indexF14nP73BecMeta?.path ?? "/customers",
    meta: indexF14nP73BecMeta || {},
    alias: indexF14nP73BecMeta?.alias || [],
    redirect: indexF14nP73BecMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/customers/index.vue").then(m => m.default || m)
  },
  {
    name: indexF14nP73BecMeta?.name ?? "customers___ca",
    path: indexF14nP73BecMeta?.path ?? "/ca/customers",
    meta: indexF14nP73BecMeta || {},
    alias: indexF14nP73BecMeta?.alias || [],
    redirect: indexF14nP73BecMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/customers/index.vue").then(m => m.default || m)
  },
  {
    name: indexF14nP73BecMeta?.name ?? "customers___nl",
    path: indexF14nP73BecMeta?.path ?? "/nl/customers",
    meta: indexF14nP73BecMeta || {},
    alias: indexF14nP73BecMeta?.alias || [],
    redirect: indexF14nP73BecMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/customers/index.vue").then(m => m.default || m)
  },
  {
    name: indexF14nP73BecMeta?.name ?? "customers___uk",
    path: indexF14nP73BecMeta?.path ?? "/uk/customers",
    meta: indexF14nP73BecMeta || {},
    alias: indexF14nP73BecMeta?.alias || [],
    redirect: indexF14nP73BecMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/customers/index.vue").then(m => m.default || m)
  },
  {
    name: indexF14nP73BecMeta?.name ?? "customers___de",
    path: indexF14nP73BecMeta?.path ?? "/de/customers",
    meta: indexF14nP73BecMeta || {},
    alias: indexF14nP73BecMeta?.alias || [],
    redirect: indexF14nP73BecMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/customers/index.vue").then(m => m.default || m)
  },
  {
    name: indexF14nP73BecMeta?.name ?? "customers___mx",
    path: indexF14nP73BecMeta?.path ?? "/mx/customers",
    meta: indexF14nP73BecMeta || {},
    alias: indexF14nP73BecMeta?.alias || [],
    redirect: indexF14nP73BecMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/customers/index.vue").then(m => m.default || m)
  },
  {
    name: indexF14nP73BecMeta?.name ?? "customers___fr",
    path: indexF14nP73BecMeta?.path ?? "/fr/customers",
    meta: indexF14nP73BecMeta || {},
    alias: indexF14nP73BecMeta?.alias || [],
    redirect: indexF14nP73BecMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/customers/index.vue").then(m => m.default || m)
  },
  {
    name: indexF14nP73BecMeta?.name ?? "customers___fr-ca",
    path: indexF14nP73BecMeta?.path ?? "/fr-ca/customers",
    meta: indexF14nP73BecMeta || {},
    alias: indexF14nP73BecMeta?.alias || [],
    redirect: indexF14nP73BecMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/customers/index.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93bF5Aie7WhSMeta?.name ?? "customers-tips-slug___en-US",
    path: _91slug_93bF5Aie7WhSMeta?.path ?? "/customers/tips/:slug()",
    meta: _91slug_93bF5Aie7WhSMeta || {},
    alias: _91slug_93bF5Aie7WhSMeta?.alias || [],
    redirect: _91slug_93bF5Aie7WhSMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/customers/tips/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93bF5Aie7WhSMeta?.name ?? "customers-tips-slug___ca",
    path: _91slug_93bF5Aie7WhSMeta?.path ?? "/ca/customers/tips/:slug()",
    meta: _91slug_93bF5Aie7WhSMeta || {},
    alias: _91slug_93bF5Aie7WhSMeta?.alias || [],
    redirect: _91slug_93bF5Aie7WhSMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/customers/tips/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93bF5Aie7WhSMeta?.name ?? "customers-tips-slug___nl",
    path: _91slug_93bF5Aie7WhSMeta?.path ?? "/nl/customers/tips/:slug()",
    meta: _91slug_93bF5Aie7WhSMeta || {},
    alias: _91slug_93bF5Aie7WhSMeta?.alias || [],
    redirect: _91slug_93bF5Aie7WhSMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/customers/tips/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93bF5Aie7WhSMeta?.name ?? "customers-tips-slug___uk",
    path: _91slug_93bF5Aie7WhSMeta?.path ?? "/uk/customers/tips/:slug()",
    meta: _91slug_93bF5Aie7WhSMeta || {},
    alias: _91slug_93bF5Aie7WhSMeta?.alias || [],
    redirect: _91slug_93bF5Aie7WhSMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/customers/tips/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93bF5Aie7WhSMeta?.name ?? "customers-tips-slug___de",
    path: _91slug_93bF5Aie7WhSMeta?.path ?? "/de/customers/tips/:slug()",
    meta: _91slug_93bF5Aie7WhSMeta || {},
    alias: _91slug_93bF5Aie7WhSMeta?.alias || [],
    redirect: _91slug_93bF5Aie7WhSMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/customers/tips/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93bF5Aie7WhSMeta?.name ?? "customers-tips-slug___mx",
    path: _91slug_93bF5Aie7WhSMeta?.path ?? "/mx/customers/tips/:slug()",
    meta: _91slug_93bF5Aie7WhSMeta || {},
    alias: _91slug_93bF5Aie7WhSMeta?.alias || [],
    redirect: _91slug_93bF5Aie7WhSMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/customers/tips/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93bF5Aie7WhSMeta?.name ?? "customers-tips-slug___fr",
    path: _91slug_93bF5Aie7WhSMeta?.path ?? "/fr/customers/tips/:slug()",
    meta: _91slug_93bF5Aie7WhSMeta || {},
    alias: _91slug_93bF5Aie7WhSMeta?.alias || [],
    redirect: _91slug_93bF5Aie7WhSMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/customers/tips/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93bF5Aie7WhSMeta?.name ?? "customers-tips-slug___fr-ca",
    path: _91slug_93bF5Aie7WhSMeta?.path ?? "/fr-ca/customers/tips/:slug()",
    meta: _91slug_93bF5Aie7WhSMeta || {},
    alias: _91slug_93bF5Aie7WhSMeta?.alias || [],
    redirect: _91slug_93bF5Aie7WhSMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/customers/tips/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93hJhl3iYi08Meta?.name ?? "customers-tips-category-slug___en-US",
    path: _91slug_93hJhl3iYi08Meta?.path ?? "/customers/tips/category/:slug()",
    meta: _91slug_93hJhl3iYi08Meta || {},
    alias: _91slug_93hJhl3iYi08Meta?.alias || [],
    redirect: _91slug_93hJhl3iYi08Meta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/customers/tips/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93hJhl3iYi08Meta?.name ?? "customers-tips-category-slug___ca",
    path: _91slug_93hJhl3iYi08Meta?.path ?? "/ca/customers/tips/category/:slug()",
    meta: _91slug_93hJhl3iYi08Meta || {},
    alias: _91slug_93hJhl3iYi08Meta?.alias || [],
    redirect: _91slug_93hJhl3iYi08Meta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/customers/tips/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93hJhl3iYi08Meta?.name ?? "customers-tips-category-slug___nl",
    path: _91slug_93hJhl3iYi08Meta?.path ?? "/nl/customers/tips/category/:slug()",
    meta: _91slug_93hJhl3iYi08Meta || {},
    alias: _91slug_93hJhl3iYi08Meta?.alias || [],
    redirect: _91slug_93hJhl3iYi08Meta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/customers/tips/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93hJhl3iYi08Meta?.name ?? "customers-tips-category-slug___uk",
    path: _91slug_93hJhl3iYi08Meta?.path ?? "/uk/customers/tips/category/:slug()",
    meta: _91slug_93hJhl3iYi08Meta || {},
    alias: _91slug_93hJhl3iYi08Meta?.alias || [],
    redirect: _91slug_93hJhl3iYi08Meta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/customers/tips/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93hJhl3iYi08Meta?.name ?? "customers-tips-category-slug___de",
    path: _91slug_93hJhl3iYi08Meta?.path ?? "/de/customers/tips/category/:slug()",
    meta: _91slug_93hJhl3iYi08Meta || {},
    alias: _91slug_93hJhl3iYi08Meta?.alias || [],
    redirect: _91slug_93hJhl3iYi08Meta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/customers/tips/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93hJhl3iYi08Meta?.name ?? "customers-tips-category-slug___mx",
    path: _91slug_93hJhl3iYi08Meta?.path ?? "/mx/customers/tips/category/:slug()",
    meta: _91slug_93hJhl3iYi08Meta || {},
    alias: _91slug_93hJhl3iYi08Meta?.alias || [],
    redirect: _91slug_93hJhl3iYi08Meta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/customers/tips/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93hJhl3iYi08Meta?.name ?? "customers-tips-category-slug___fr",
    path: _91slug_93hJhl3iYi08Meta?.path ?? "/fr/customers/tips/category/:slug()",
    meta: _91slug_93hJhl3iYi08Meta || {},
    alias: _91slug_93hJhl3iYi08Meta?.alias || [],
    redirect: _91slug_93hJhl3iYi08Meta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/customers/tips/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93hJhl3iYi08Meta?.name ?? "customers-tips-category-slug___fr-ca",
    path: _91slug_93hJhl3iYi08Meta?.path ?? "/fr-ca/customers/tips/category/:slug()",
    meta: _91slug_93hJhl3iYi08Meta || {},
    alias: _91slug_93hJhl3iYi08Meta?.alias || [],
    redirect: _91slug_93hJhl3iYi08Meta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/customers/tips/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: index6JoPwZbuKDMeta?.name ?? "customers-tips___en-US",
    path: index6JoPwZbuKDMeta?.path ?? "/customers/tips",
    meta: index6JoPwZbuKDMeta || {},
    alias: index6JoPwZbuKDMeta?.alias || [],
    redirect: index6JoPwZbuKDMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/customers/tips/index.vue").then(m => m.default || m)
  },
  {
    name: index6JoPwZbuKDMeta?.name ?? "customers-tips___ca",
    path: index6JoPwZbuKDMeta?.path ?? "/ca/customers/tips",
    meta: index6JoPwZbuKDMeta || {},
    alias: index6JoPwZbuKDMeta?.alias || [],
    redirect: index6JoPwZbuKDMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/customers/tips/index.vue").then(m => m.default || m)
  },
  {
    name: index6JoPwZbuKDMeta?.name ?? "customers-tips___nl",
    path: index6JoPwZbuKDMeta?.path ?? "/nl/customers/tips",
    meta: index6JoPwZbuKDMeta || {},
    alias: index6JoPwZbuKDMeta?.alias || [],
    redirect: index6JoPwZbuKDMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/customers/tips/index.vue").then(m => m.default || m)
  },
  {
    name: index6JoPwZbuKDMeta?.name ?? "customers-tips___uk",
    path: index6JoPwZbuKDMeta?.path ?? "/uk/customers/tips",
    meta: index6JoPwZbuKDMeta || {},
    alias: index6JoPwZbuKDMeta?.alias || [],
    redirect: index6JoPwZbuKDMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/customers/tips/index.vue").then(m => m.default || m)
  },
  {
    name: index6JoPwZbuKDMeta?.name ?? "customers-tips___de",
    path: index6JoPwZbuKDMeta?.path ?? "/de/customers/tips",
    meta: index6JoPwZbuKDMeta || {},
    alias: index6JoPwZbuKDMeta?.alias || [],
    redirect: index6JoPwZbuKDMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/customers/tips/index.vue").then(m => m.default || m)
  },
  {
    name: index6JoPwZbuKDMeta?.name ?? "customers-tips___mx",
    path: index6JoPwZbuKDMeta?.path ?? "/mx/customers/tips",
    meta: index6JoPwZbuKDMeta || {},
    alias: index6JoPwZbuKDMeta?.alias || [],
    redirect: index6JoPwZbuKDMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/customers/tips/index.vue").then(m => m.default || m)
  },
  {
    name: index6JoPwZbuKDMeta?.name ?? "customers-tips___fr",
    path: index6JoPwZbuKDMeta?.path ?? "/fr/customers/tips",
    meta: index6JoPwZbuKDMeta || {},
    alias: index6JoPwZbuKDMeta?.alias || [],
    redirect: index6JoPwZbuKDMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/customers/tips/index.vue").then(m => m.default || m)
  },
  {
    name: index6JoPwZbuKDMeta?.name ?? "customers-tips___fr-ca",
    path: index6JoPwZbuKDMeta?.path ?? "/fr-ca/customers/tips",
    meta: index6JoPwZbuKDMeta || {},
    alias: index6JoPwZbuKDMeta?.alias || [],
    redirect: index6JoPwZbuKDMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/customers/tips/index.vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93DwWpugBFi8Meta?.name ?? "customers-topfleets-prefix-slug___en-US",
    path: _91_46_46_46slug_93DwWpugBFi8Meta?.path ?? "/customers/topfleets/:prefix()/:slug(.*)*",
    meta: _91_46_46_46slug_93DwWpugBFi8Meta || {},
    alias: _91_46_46_46slug_93DwWpugBFi8Meta?.alias || [],
    redirect: _91_46_46_46slug_93DwWpugBFi8Meta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/customers/topfleets/[prefix]/[...slug].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93DwWpugBFi8Meta?.name ?? "customers-topfleets-prefix-slug___ca",
    path: _91_46_46_46slug_93DwWpugBFi8Meta?.path ?? "/ca/customers/topfleets/:prefix()/:slug(.*)*",
    meta: _91_46_46_46slug_93DwWpugBFi8Meta || {},
    alias: _91_46_46_46slug_93DwWpugBFi8Meta?.alias || [],
    redirect: _91_46_46_46slug_93DwWpugBFi8Meta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/customers/topfleets/[prefix]/[...slug].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93DwWpugBFi8Meta?.name ?? "customers-topfleets-prefix-slug___nl",
    path: _91_46_46_46slug_93DwWpugBFi8Meta?.path ?? "/nl/customers/topfleets/:prefix()/:slug(.*)*",
    meta: _91_46_46_46slug_93DwWpugBFi8Meta || {},
    alias: _91_46_46_46slug_93DwWpugBFi8Meta?.alias || [],
    redirect: _91_46_46_46slug_93DwWpugBFi8Meta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/customers/topfleets/[prefix]/[...slug].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93DwWpugBFi8Meta?.name ?? "customers-topfleets-prefix-slug___uk",
    path: _91_46_46_46slug_93DwWpugBFi8Meta?.path ?? "/uk/customers/topfleets/:prefix()/:slug(.*)*",
    meta: _91_46_46_46slug_93DwWpugBFi8Meta || {},
    alias: _91_46_46_46slug_93DwWpugBFi8Meta?.alias || [],
    redirect: _91_46_46_46slug_93DwWpugBFi8Meta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/customers/topfleets/[prefix]/[...slug].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93DwWpugBFi8Meta?.name ?? "customers-topfleets-prefix-slug___de",
    path: _91_46_46_46slug_93DwWpugBFi8Meta?.path ?? "/de/customers/topfleets/:prefix()/:slug(.*)*",
    meta: _91_46_46_46slug_93DwWpugBFi8Meta || {},
    alias: _91_46_46_46slug_93DwWpugBFi8Meta?.alias || [],
    redirect: _91_46_46_46slug_93DwWpugBFi8Meta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/customers/topfleets/[prefix]/[...slug].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93DwWpugBFi8Meta?.name ?? "customers-topfleets-prefix-slug___mx",
    path: _91_46_46_46slug_93DwWpugBFi8Meta?.path ?? "/mx/customers/topfleets/:prefix()/:slug(.*)*",
    meta: _91_46_46_46slug_93DwWpugBFi8Meta || {},
    alias: _91_46_46_46slug_93DwWpugBFi8Meta?.alias || [],
    redirect: _91_46_46_46slug_93DwWpugBFi8Meta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/customers/topfleets/[prefix]/[...slug].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93DwWpugBFi8Meta?.name ?? "customers-topfleets-prefix-slug___fr",
    path: _91_46_46_46slug_93DwWpugBFi8Meta?.path ?? "/fr/customers/topfleets/:prefix()/:slug(.*)*",
    meta: _91_46_46_46slug_93DwWpugBFi8Meta || {},
    alias: _91_46_46_46slug_93DwWpugBFi8Meta?.alias || [],
    redirect: _91_46_46_46slug_93DwWpugBFi8Meta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/customers/topfleets/[prefix]/[...slug].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93DwWpugBFi8Meta?.name ?? "customers-topfleets-prefix-slug___fr-ca",
    path: _91_46_46_46slug_93DwWpugBFi8Meta?.path ?? "/fr-ca/customers/topfleets/:prefix()/:slug(.*)*",
    meta: _91_46_46_46slug_93DwWpugBFi8Meta || {},
    alias: _91_46_46_46slug_93DwWpugBFi8Meta?.alias || [],
    redirect: _91_46_46_46slug_93DwWpugBFi8Meta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/customers/topfleets/[prefix]/[...slug].vue").then(m => m.default || m)
  },
  {
    name: above_45beyondlSVP4I3HolMeta?.name ?? "customers-topfleets-2019-above-beyond___en-US",
    path: above_45beyondlSVP4I3HolMeta?.path ?? "/customers/topfleets/2019/above-beyond",
    meta: above_45beyondlSVP4I3HolMeta || {},
    alias: above_45beyondlSVP4I3HolMeta?.alias || [],
    redirect: above_45beyondlSVP4I3HolMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/customers/topfleets/2019/above-beyond.vue").then(m => m.default || m)
  },
  {
    name: above_45beyondlSVP4I3HolMeta?.name ?? "customers-topfleets-2019-above-beyond___ca",
    path: above_45beyondlSVP4I3HolMeta?.path ?? "/ca/customers/topfleets/2019/above-beyond",
    meta: above_45beyondlSVP4I3HolMeta || {},
    alias: above_45beyondlSVP4I3HolMeta?.alias || [],
    redirect: above_45beyondlSVP4I3HolMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/customers/topfleets/2019/above-beyond.vue").then(m => m.default || m)
  },
  {
    name: above_45beyondlSVP4I3HolMeta?.name ?? "customers-topfleets-2019-above-beyond___nl",
    path: above_45beyondlSVP4I3HolMeta?.path ?? "/nl/customers/topfleets/2019/above-beyond",
    meta: above_45beyondlSVP4I3HolMeta || {},
    alias: above_45beyondlSVP4I3HolMeta?.alias || [],
    redirect: above_45beyondlSVP4I3HolMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/customers/topfleets/2019/above-beyond.vue").then(m => m.default || m)
  },
  {
    name: above_45beyondlSVP4I3HolMeta?.name ?? "customers-topfleets-2019-above-beyond___uk",
    path: above_45beyondlSVP4I3HolMeta?.path ?? "/uk/customers/topfleets/2019/above-beyond",
    meta: above_45beyondlSVP4I3HolMeta || {},
    alias: above_45beyondlSVP4I3HolMeta?.alias || [],
    redirect: above_45beyondlSVP4I3HolMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/customers/topfleets/2019/above-beyond.vue").then(m => m.default || m)
  },
  {
    name: above_45beyondlSVP4I3HolMeta?.name ?? "customers-topfleets-2019-above-beyond___de",
    path: above_45beyondlSVP4I3HolMeta?.path ?? "/de/customers/topfleets/2019/above-beyond",
    meta: above_45beyondlSVP4I3HolMeta || {},
    alias: above_45beyondlSVP4I3HolMeta?.alias || [],
    redirect: above_45beyondlSVP4I3HolMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/customers/topfleets/2019/above-beyond.vue").then(m => m.default || m)
  },
  {
    name: above_45beyondlSVP4I3HolMeta?.name ?? "customers-topfleets-2019-above-beyond___mx",
    path: above_45beyondlSVP4I3HolMeta?.path ?? "/mx/customers/topfleets/2019/above-beyond",
    meta: above_45beyondlSVP4I3HolMeta || {},
    alias: above_45beyondlSVP4I3HolMeta?.alias || [],
    redirect: above_45beyondlSVP4I3HolMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/customers/topfleets/2019/above-beyond.vue").then(m => m.default || m)
  },
  {
    name: above_45beyondlSVP4I3HolMeta?.name ?? "customers-topfleets-2019-above-beyond___fr",
    path: above_45beyondlSVP4I3HolMeta?.path ?? "/fr/customers/topfleets/2019/above-beyond",
    meta: above_45beyondlSVP4I3HolMeta || {},
    alias: above_45beyondlSVP4I3HolMeta?.alias || [],
    redirect: above_45beyondlSVP4I3HolMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/customers/topfleets/2019/above-beyond.vue").then(m => m.default || m)
  },
  {
    name: above_45beyondlSVP4I3HolMeta?.name ?? "customers-topfleets-2019-above-beyond___fr-ca",
    path: above_45beyondlSVP4I3HolMeta?.path ?? "/fr-ca/customers/topfleets/2019/above-beyond",
    meta: above_45beyondlSVP4I3HolMeta || {},
    alias: above_45beyondlSVP4I3HolMeta?.alias || [],
    redirect: above_45beyondlSVP4I3HolMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/customers/topfleets/2019/above-beyond.vue").then(m => m.default || m)
  },
  {
    name: excellence_45performanceEXX8aiq4GqMeta?.name ?? "customers-topfleets-2019-excellence-performance___en-US",
    path: excellence_45performanceEXX8aiq4GqMeta?.path ?? "/customers/topfleets/2019/excellence-performance",
    meta: excellence_45performanceEXX8aiq4GqMeta || {},
    alias: excellence_45performanceEXX8aiq4GqMeta?.alias || [],
    redirect: excellence_45performanceEXX8aiq4GqMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/customers/topfleets/2019/excellence-performance.vue").then(m => m.default || m)
  },
  {
    name: excellence_45performanceEXX8aiq4GqMeta?.name ?? "customers-topfleets-2019-excellence-performance___ca",
    path: excellence_45performanceEXX8aiq4GqMeta?.path ?? "/ca/customers/topfleets/2019/excellence-performance",
    meta: excellence_45performanceEXX8aiq4GqMeta || {},
    alias: excellence_45performanceEXX8aiq4GqMeta?.alias || [],
    redirect: excellence_45performanceEXX8aiq4GqMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/customers/topfleets/2019/excellence-performance.vue").then(m => m.default || m)
  },
  {
    name: excellence_45performanceEXX8aiq4GqMeta?.name ?? "customers-topfleets-2019-excellence-performance___nl",
    path: excellence_45performanceEXX8aiq4GqMeta?.path ?? "/nl/customers/topfleets/2019/excellence-performance",
    meta: excellence_45performanceEXX8aiq4GqMeta || {},
    alias: excellence_45performanceEXX8aiq4GqMeta?.alias || [],
    redirect: excellence_45performanceEXX8aiq4GqMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/customers/topfleets/2019/excellence-performance.vue").then(m => m.default || m)
  },
  {
    name: excellence_45performanceEXX8aiq4GqMeta?.name ?? "customers-topfleets-2019-excellence-performance___uk",
    path: excellence_45performanceEXX8aiq4GqMeta?.path ?? "/uk/customers/topfleets/2019/excellence-performance",
    meta: excellence_45performanceEXX8aiq4GqMeta || {},
    alias: excellence_45performanceEXX8aiq4GqMeta?.alias || [],
    redirect: excellence_45performanceEXX8aiq4GqMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/customers/topfleets/2019/excellence-performance.vue").then(m => m.default || m)
  },
  {
    name: excellence_45performanceEXX8aiq4GqMeta?.name ?? "customers-topfleets-2019-excellence-performance___de",
    path: excellence_45performanceEXX8aiq4GqMeta?.path ?? "/de/customers/topfleets/2019/excellence-performance",
    meta: excellence_45performanceEXX8aiq4GqMeta || {},
    alias: excellence_45performanceEXX8aiq4GqMeta?.alias || [],
    redirect: excellence_45performanceEXX8aiq4GqMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/customers/topfleets/2019/excellence-performance.vue").then(m => m.default || m)
  },
  {
    name: excellence_45performanceEXX8aiq4GqMeta?.name ?? "customers-topfleets-2019-excellence-performance___mx",
    path: excellence_45performanceEXX8aiq4GqMeta?.path ?? "/mx/customers/topfleets/2019/excellence-performance",
    meta: excellence_45performanceEXX8aiq4GqMeta || {},
    alias: excellence_45performanceEXX8aiq4GqMeta?.alias || [],
    redirect: excellence_45performanceEXX8aiq4GqMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/customers/topfleets/2019/excellence-performance.vue").then(m => m.default || m)
  },
  {
    name: excellence_45performanceEXX8aiq4GqMeta?.name ?? "customers-topfleets-2019-excellence-performance___fr",
    path: excellence_45performanceEXX8aiq4GqMeta?.path ?? "/fr/customers/topfleets/2019/excellence-performance",
    meta: excellence_45performanceEXX8aiq4GqMeta || {},
    alias: excellence_45performanceEXX8aiq4GqMeta?.alias || [],
    redirect: excellence_45performanceEXX8aiq4GqMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/customers/topfleets/2019/excellence-performance.vue").then(m => m.default || m)
  },
  {
    name: excellence_45performanceEXX8aiq4GqMeta?.name ?? "customers-topfleets-2019-excellence-performance___fr-ca",
    path: excellence_45performanceEXX8aiq4GqMeta?.path ?? "/fr-ca/customers/topfleets/2019/excellence-performance",
    meta: excellence_45performanceEXX8aiq4GqMeta || {},
    alias: excellence_45performanceEXX8aiq4GqMeta?.alias || [],
    redirect: excellence_45performanceEXX8aiq4GqMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/customers/topfleets/2019/excellence-performance.vue").then(m => m.default || m)
  },
  {
    name: excellence_45serviceI9LpwZxXagMeta?.name ?? "customers-topfleets-2019-excellence-service___en-US",
    path: excellence_45serviceI9LpwZxXagMeta?.path ?? "/customers/topfleets/2019/excellence-service",
    meta: excellence_45serviceI9LpwZxXagMeta || {},
    alias: excellence_45serviceI9LpwZxXagMeta?.alias || [],
    redirect: excellence_45serviceI9LpwZxXagMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/customers/topfleets/2019/excellence-service.vue").then(m => m.default || m)
  },
  {
    name: excellence_45serviceI9LpwZxXagMeta?.name ?? "customers-topfleets-2019-excellence-service___ca",
    path: excellence_45serviceI9LpwZxXagMeta?.path ?? "/ca/customers/topfleets/2019/excellence-service",
    meta: excellence_45serviceI9LpwZxXagMeta || {},
    alias: excellence_45serviceI9LpwZxXagMeta?.alias || [],
    redirect: excellence_45serviceI9LpwZxXagMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/customers/topfleets/2019/excellence-service.vue").then(m => m.default || m)
  },
  {
    name: excellence_45serviceI9LpwZxXagMeta?.name ?? "customers-topfleets-2019-excellence-service___nl",
    path: excellence_45serviceI9LpwZxXagMeta?.path ?? "/nl/customers/topfleets/2019/excellence-service",
    meta: excellence_45serviceI9LpwZxXagMeta || {},
    alias: excellence_45serviceI9LpwZxXagMeta?.alias || [],
    redirect: excellence_45serviceI9LpwZxXagMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/customers/topfleets/2019/excellence-service.vue").then(m => m.default || m)
  },
  {
    name: excellence_45serviceI9LpwZxXagMeta?.name ?? "customers-topfleets-2019-excellence-service___uk",
    path: excellence_45serviceI9LpwZxXagMeta?.path ?? "/uk/customers/topfleets/2019/excellence-service",
    meta: excellence_45serviceI9LpwZxXagMeta || {},
    alias: excellence_45serviceI9LpwZxXagMeta?.alias || [],
    redirect: excellence_45serviceI9LpwZxXagMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/customers/topfleets/2019/excellence-service.vue").then(m => m.default || m)
  },
  {
    name: excellence_45serviceI9LpwZxXagMeta?.name ?? "customers-topfleets-2019-excellence-service___de",
    path: excellence_45serviceI9LpwZxXagMeta?.path ?? "/de/customers/topfleets/2019/excellence-service",
    meta: excellence_45serviceI9LpwZxXagMeta || {},
    alias: excellence_45serviceI9LpwZxXagMeta?.alias || [],
    redirect: excellence_45serviceI9LpwZxXagMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/customers/topfleets/2019/excellence-service.vue").then(m => m.default || m)
  },
  {
    name: excellence_45serviceI9LpwZxXagMeta?.name ?? "customers-topfleets-2019-excellence-service___mx",
    path: excellence_45serviceI9LpwZxXagMeta?.path ?? "/mx/customers/topfleets/2019/excellence-service",
    meta: excellence_45serviceI9LpwZxXagMeta || {},
    alias: excellence_45serviceI9LpwZxXagMeta?.alias || [],
    redirect: excellence_45serviceI9LpwZxXagMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/customers/topfleets/2019/excellence-service.vue").then(m => m.default || m)
  },
  {
    name: excellence_45serviceI9LpwZxXagMeta?.name ?? "customers-topfleets-2019-excellence-service___fr",
    path: excellence_45serviceI9LpwZxXagMeta?.path ?? "/fr/customers/topfleets/2019/excellence-service",
    meta: excellence_45serviceI9LpwZxXagMeta || {},
    alias: excellence_45serviceI9LpwZxXagMeta?.alias || [],
    redirect: excellence_45serviceI9LpwZxXagMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/customers/topfleets/2019/excellence-service.vue").then(m => m.default || m)
  },
  {
    name: excellence_45serviceI9LpwZxXagMeta?.name ?? "customers-topfleets-2019-excellence-service___fr-ca",
    path: excellence_45serviceI9LpwZxXagMeta?.path ?? "/fr-ca/customers/topfleets/2019/excellence-service",
    meta: excellence_45serviceI9LpwZxXagMeta || {},
    alias: excellence_45serviceI9LpwZxXagMeta?.alias || [],
    redirect: excellence_45serviceI9LpwZxXagMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/customers/topfleets/2019/excellence-service.vue").then(m => m.default || m)
  },
  {
    name: indexlxrExgPnN5Meta?.name ?? "customers-topfleets-2019___en-US",
    path: indexlxrExgPnN5Meta?.path ?? "/customers/topfleets/2019",
    meta: indexlxrExgPnN5Meta || {},
    alias: indexlxrExgPnN5Meta?.alias || [],
    redirect: indexlxrExgPnN5Meta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/customers/topfleets/2019/index.vue").then(m => m.default || m)
  },
  {
    name: indexlxrExgPnN5Meta?.name ?? "customers-topfleets-2019___ca",
    path: indexlxrExgPnN5Meta?.path ?? "/ca/customers/topfleets/2019",
    meta: indexlxrExgPnN5Meta || {},
    alias: indexlxrExgPnN5Meta?.alias || [],
    redirect: indexlxrExgPnN5Meta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/customers/topfleets/2019/index.vue").then(m => m.default || m)
  },
  {
    name: indexlxrExgPnN5Meta?.name ?? "customers-topfleets-2019___nl",
    path: indexlxrExgPnN5Meta?.path ?? "/nl/customers/topfleets/2019",
    meta: indexlxrExgPnN5Meta || {},
    alias: indexlxrExgPnN5Meta?.alias || [],
    redirect: indexlxrExgPnN5Meta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/customers/topfleets/2019/index.vue").then(m => m.default || m)
  },
  {
    name: indexlxrExgPnN5Meta?.name ?? "customers-topfleets-2019___uk",
    path: indexlxrExgPnN5Meta?.path ?? "/uk/customers/topfleets/2019",
    meta: indexlxrExgPnN5Meta || {},
    alias: indexlxrExgPnN5Meta?.alias || [],
    redirect: indexlxrExgPnN5Meta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/customers/topfleets/2019/index.vue").then(m => m.default || m)
  },
  {
    name: indexlxrExgPnN5Meta?.name ?? "customers-topfleets-2019___de",
    path: indexlxrExgPnN5Meta?.path ?? "/de/customers/topfleets/2019",
    meta: indexlxrExgPnN5Meta || {},
    alias: indexlxrExgPnN5Meta?.alias || [],
    redirect: indexlxrExgPnN5Meta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/customers/topfleets/2019/index.vue").then(m => m.default || m)
  },
  {
    name: indexlxrExgPnN5Meta?.name ?? "customers-topfleets-2019___mx",
    path: indexlxrExgPnN5Meta?.path ?? "/mx/customers/topfleets/2019",
    meta: indexlxrExgPnN5Meta || {},
    alias: indexlxrExgPnN5Meta?.alias || [],
    redirect: indexlxrExgPnN5Meta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/customers/topfleets/2019/index.vue").then(m => m.default || m)
  },
  {
    name: indexlxrExgPnN5Meta?.name ?? "customers-topfleets-2019___fr",
    path: indexlxrExgPnN5Meta?.path ?? "/fr/customers/topfleets/2019",
    meta: indexlxrExgPnN5Meta || {},
    alias: indexlxrExgPnN5Meta?.alias || [],
    redirect: indexlxrExgPnN5Meta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/customers/topfleets/2019/index.vue").then(m => m.default || m)
  },
  {
    name: indexlxrExgPnN5Meta?.name ?? "customers-topfleets-2019___fr-ca",
    path: indexlxrExgPnN5Meta?.path ?? "/fr-ca/customers/topfleets/2019",
    meta: indexlxrExgPnN5Meta || {},
    alias: indexlxrExgPnN5Meta?.alias || [],
    redirect: indexlxrExgPnN5Meta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/customers/topfleets/2019/index.vue").then(m => m.default || m)
  },
  {
    name: safest_45fleetMQ4aWZSQtSMeta?.name ?? "customers-topfleets-2019-safest-fleet___en-US",
    path: safest_45fleetMQ4aWZSQtSMeta?.path ?? "/customers/topfleets/2019/safest-fleet",
    meta: safest_45fleetMQ4aWZSQtSMeta || {},
    alias: safest_45fleetMQ4aWZSQtSMeta?.alias || [],
    redirect: safest_45fleetMQ4aWZSQtSMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/customers/topfleets/2019/safest-fleet.vue").then(m => m.default || m)
  },
  {
    name: safest_45fleetMQ4aWZSQtSMeta?.name ?? "customers-topfleets-2019-safest-fleet___ca",
    path: safest_45fleetMQ4aWZSQtSMeta?.path ?? "/ca/customers/topfleets/2019/safest-fleet",
    meta: safest_45fleetMQ4aWZSQtSMeta || {},
    alias: safest_45fleetMQ4aWZSQtSMeta?.alias || [],
    redirect: safest_45fleetMQ4aWZSQtSMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/customers/topfleets/2019/safest-fleet.vue").then(m => m.default || m)
  },
  {
    name: safest_45fleetMQ4aWZSQtSMeta?.name ?? "customers-topfleets-2019-safest-fleet___nl",
    path: safest_45fleetMQ4aWZSQtSMeta?.path ?? "/nl/customers/topfleets/2019/safest-fleet",
    meta: safest_45fleetMQ4aWZSQtSMeta || {},
    alias: safest_45fleetMQ4aWZSQtSMeta?.alias || [],
    redirect: safest_45fleetMQ4aWZSQtSMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/customers/topfleets/2019/safest-fleet.vue").then(m => m.default || m)
  },
  {
    name: safest_45fleetMQ4aWZSQtSMeta?.name ?? "customers-topfleets-2019-safest-fleet___uk",
    path: safest_45fleetMQ4aWZSQtSMeta?.path ?? "/uk/customers/topfleets/2019/safest-fleet",
    meta: safest_45fleetMQ4aWZSQtSMeta || {},
    alias: safest_45fleetMQ4aWZSQtSMeta?.alias || [],
    redirect: safest_45fleetMQ4aWZSQtSMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/customers/topfleets/2019/safest-fleet.vue").then(m => m.default || m)
  },
  {
    name: safest_45fleetMQ4aWZSQtSMeta?.name ?? "customers-topfleets-2019-safest-fleet___de",
    path: safest_45fleetMQ4aWZSQtSMeta?.path ?? "/de/customers/topfleets/2019/safest-fleet",
    meta: safest_45fleetMQ4aWZSQtSMeta || {},
    alias: safest_45fleetMQ4aWZSQtSMeta?.alias || [],
    redirect: safest_45fleetMQ4aWZSQtSMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/customers/topfleets/2019/safest-fleet.vue").then(m => m.default || m)
  },
  {
    name: safest_45fleetMQ4aWZSQtSMeta?.name ?? "customers-topfleets-2019-safest-fleet___mx",
    path: safest_45fleetMQ4aWZSQtSMeta?.path ?? "/mx/customers/topfleets/2019/safest-fleet",
    meta: safest_45fleetMQ4aWZSQtSMeta || {},
    alias: safest_45fleetMQ4aWZSQtSMeta?.alias || [],
    redirect: safest_45fleetMQ4aWZSQtSMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/customers/topfleets/2019/safest-fleet.vue").then(m => m.default || m)
  },
  {
    name: safest_45fleetMQ4aWZSQtSMeta?.name ?? "customers-topfleets-2019-safest-fleet___fr",
    path: safest_45fleetMQ4aWZSQtSMeta?.path ?? "/fr/customers/topfleets/2019/safest-fleet",
    meta: safest_45fleetMQ4aWZSQtSMeta || {},
    alias: safest_45fleetMQ4aWZSQtSMeta?.alias || [],
    redirect: safest_45fleetMQ4aWZSQtSMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/customers/topfleets/2019/safest-fleet.vue").then(m => m.default || m)
  },
  {
    name: safest_45fleetMQ4aWZSQtSMeta?.name ?? "customers-topfleets-2019-safest-fleet___fr-ca",
    path: safest_45fleetMQ4aWZSQtSMeta?.path ?? "/fr-ca/customers/topfleets/2019/safest-fleet",
    meta: safest_45fleetMQ4aWZSQtSMeta || {},
    alias: safest_45fleetMQ4aWZSQtSMeta?.alias || [],
    redirect: safest_45fleetMQ4aWZSQtSMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/customers/topfleets/2019/safest-fleet.vue").then(m => m.default || m)
  },
  {
    name: driver_45performanceW3sS3LxK6cMeta?.name ?? "customers-topfleets-2020-driver-performance___en-US",
    path: driver_45performanceW3sS3LxK6cMeta?.path ?? "/customers/topfleets/2020/driver-performance",
    meta: driver_45performanceW3sS3LxK6cMeta || {},
    alias: driver_45performanceW3sS3LxK6cMeta?.alias || [],
    redirect: driver_45performanceW3sS3LxK6cMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/customers/topfleets/2020/driver-performance.vue").then(m => m.default || m)
  },
  {
    name: driver_45performanceW3sS3LxK6cMeta?.name ?? "customers-topfleets-2020-driver-performance___ca",
    path: driver_45performanceW3sS3LxK6cMeta?.path ?? "/ca/customers/topfleets/2020/driver-performance",
    meta: driver_45performanceW3sS3LxK6cMeta || {},
    alias: driver_45performanceW3sS3LxK6cMeta?.alias || [],
    redirect: driver_45performanceW3sS3LxK6cMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/customers/topfleets/2020/driver-performance.vue").then(m => m.default || m)
  },
  {
    name: driver_45performanceW3sS3LxK6cMeta?.name ?? "customers-topfleets-2020-driver-performance___nl",
    path: driver_45performanceW3sS3LxK6cMeta?.path ?? "/nl/customers/topfleets/2020/driver-performance",
    meta: driver_45performanceW3sS3LxK6cMeta || {},
    alias: driver_45performanceW3sS3LxK6cMeta?.alias || [],
    redirect: driver_45performanceW3sS3LxK6cMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/customers/topfleets/2020/driver-performance.vue").then(m => m.default || m)
  },
  {
    name: driver_45performanceW3sS3LxK6cMeta?.name ?? "customers-topfleets-2020-driver-performance___uk",
    path: driver_45performanceW3sS3LxK6cMeta?.path ?? "/uk/customers/topfleets/2020/driver-performance",
    meta: driver_45performanceW3sS3LxK6cMeta || {},
    alias: driver_45performanceW3sS3LxK6cMeta?.alias || [],
    redirect: driver_45performanceW3sS3LxK6cMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/customers/topfleets/2020/driver-performance.vue").then(m => m.default || m)
  },
  {
    name: driver_45performanceW3sS3LxK6cMeta?.name ?? "customers-topfleets-2020-driver-performance___de",
    path: driver_45performanceW3sS3LxK6cMeta?.path ?? "/de/customers/topfleets/2020/driver-performance",
    meta: driver_45performanceW3sS3LxK6cMeta || {},
    alias: driver_45performanceW3sS3LxK6cMeta?.alias || [],
    redirect: driver_45performanceW3sS3LxK6cMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/customers/topfleets/2020/driver-performance.vue").then(m => m.default || m)
  },
  {
    name: driver_45performanceW3sS3LxK6cMeta?.name ?? "customers-topfleets-2020-driver-performance___mx",
    path: driver_45performanceW3sS3LxK6cMeta?.path ?? "/mx/customers/topfleets/2020/driver-performance",
    meta: driver_45performanceW3sS3LxK6cMeta || {},
    alias: driver_45performanceW3sS3LxK6cMeta?.alias || [],
    redirect: driver_45performanceW3sS3LxK6cMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/customers/topfleets/2020/driver-performance.vue").then(m => m.default || m)
  },
  {
    name: driver_45performanceW3sS3LxK6cMeta?.name ?? "customers-topfleets-2020-driver-performance___fr",
    path: driver_45performanceW3sS3LxK6cMeta?.path ?? "/fr/customers/topfleets/2020/driver-performance",
    meta: driver_45performanceW3sS3LxK6cMeta || {},
    alias: driver_45performanceW3sS3LxK6cMeta?.alias || [],
    redirect: driver_45performanceW3sS3LxK6cMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/customers/topfleets/2020/driver-performance.vue").then(m => m.default || m)
  },
  {
    name: driver_45performanceW3sS3LxK6cMeta?.name ?? "customers-topfleets-2020-driver-performance___fr-ca",
    path: driver_45performanceW3sS3LxK6cMeta?.path ?? "/fr-ca/customers/topfleets/2020/driver-performance",
    meta: driver_45performanceW3sS3LxK6cMeta || {},
    alias: driver_45performanceW3sS3LxK6cMeta?.alias || [],
    redirect: driver_45performanceW3sS3LxK6cMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/customers/topfleets/2020/driver-performance.vue").then(m => m.default || m)
  },
  {
    name: excellence_45performanceOMcSwSwnyEMeta?.name ?? "customers-topfleets-2020-excellence-performance___en-US",
    path: excellence_45performanceOMcSwSwnyEMeta?.path ?? "/customers/topfleets/2020/excellence-performance",
    meta: excellence_45performanceOMcSwSwnyEMeta || {},
    alias: excellence_45performanceOMcSwSwnyEMeta?.alias || [],
    redirect: excellence_45performanceOMcSwSwnyEMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/customers/topfleets/2020/excellence-performance.vue").then(m => m.default || m)
  },
  {
    name: excellence_45performanceOMcSwSwnyEMeta?.name ?? "customers-topfleets-2020-excellence-performance___ca",
    path: excellence_45performanceOMcSwSwnyEMeta?.path ?? "/ca/customers/topfleets/2020/excellence-performance",
    meta: excellence_45performanceOMcSwSwnyEMeta || {},
    alias: excellence_45performanceOMcSwSwnyEMeta?.alias || [],
    redirect: excellence_45performanceOMcSwSwnyEMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/customers/topfleets/2020/excellence-performance.vue").then(m => m.default || m)
  },
  {
    name: excellence_45performanceOMcSwSwnyEMeta?.name ?? "customers-topfleets-2020-excellence-performance___nl",
    path: excellence_45performanceOMcSwSwnyEMeta?.path ?? "/nl/customers/topfleets/2020/excellence-performance",
    meta: excellence_45performanceOMcSwSwnyEMeta || {},
    alias: excellence_45performanceOMcSwSwnyEMeta?.alias || [],
    redirect: excellence_45performanceOMcSwSwnyEMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/customers/topfleets/2020/excellence-performance.vue").then(m => m.default || m)
  },
  {
    name: excellence_45performanceOMcSwSwnyEMeta?.name ?? "customers-topfleets-2020-excellence-performance___uk",
    path: excellence_45performanceOMcSwSwnyEMeta?.path ?? "/uk/customers/topfleets/2020/excellence-performance",
    meta: excellence_45performanceOMcSwSwnyEMeta || {},
    alias: excellence_45performanceOMcSwSwnyEMeta?.alias || [],
    redirect: excellence_45performanceOMcSwSwnyEMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/customers/topfleets/2020/excellence-performance.vue").then(m => m.default || m)
  },
  {
    name: excellence_45performanceOMcSwSwnyEMeta?.name ?? "customers-topfleets-2020-excellence-performance___de",
    path: excellence_45performanceOMcSwSwnyEMeta?.path ?? "/de/customers/topfleets/2020/excellence-performance",
    meta: excellence_45performanceOMcSwSwnyEMeta || {},
    alias: excellence_45performanceOMcSwSwnyEMeta?.alias || [],
    redirect: excellence_45performanceOMcSwSwnyEMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/customers/topfleets/2020/excellence-performance.vue").then(m => m.default || m)
  },
  {
    name: excellence_45performanceOMcSwSwnyEMeta?.name ?? "customers-topfleets-2020-excellence-performance___mx",
    path: excellence_45performanceOMcSwSwnyEMeta?.path ?? "/mx/customers/topfleets/2020/excellence-performance",
    meta: excellence_45performanceOMcSwSwnyEMeta || {},
    alias: excellence_45performanceOMcSwSwnyEMeta?.alias || [],
    redirect: excellence_45performanceOMcSwSwnyEMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/customers/topfleets/2020/excellence-performance.vue").then(m => m.default || m)
  },
  {
    name: excellence_45performanceOMcSwSwnyEMeta?.name ?? "customers-topfleets-2020-excellence-performance___fr",
    path: excellence_45performanceOMcSwSwnyEMeta?.path ?? "/fr/customers/topfleets/2020/excellence-performance",
    meta: excellence_45performanceOMcSwSwnyEMeta || {},
    alias: excellence_45performanceOMcSwSwnyEMeta?.alias || [],
    redirect: excellence_45performanceOMcSwSwnyEMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/customers/topfleets/2020/excellence-performance.vue").then(m => m.default || m)
  },
  {
    name: excellence_45performanceOMcSwSwnyEMeta?.name ?? "customers-topfleets-2020-excellence-performance___fr-ca",
    path: excellence_45performanceOMcSwSwnyEMeta?.path ?? "/fr-ca/customers/topfleets/2020/excellence-performance",
    meta: excellence_45performanceOMcSwSwnyEMeta || {},
    alias: excellence_45performanceOMcSwSwnyEMeta?.alias || [],
    redirect: excellence_45performanceOMcSwSwnyEMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/customers/topfleets/2020/excellence-performance.vue").then(m => m.default || m)
  },
  {
    name: fleet_45innovator3e9q5jgDGNMeta?.name ?? "customers-topfleets-2020-fleet-innovator___en-US",
    path: fleet_45innovator3e9q5jgDGNMeta?.path ?? "/customers/topfleets/2020/fleet-innovator",
    meta: fleet_45innovator3e9q5jgDGNMeta || {},
    alias: fleet_45innovator3e9q5jgDGNMeta?.alias || [],
    redirect: fleet_45innovator3e9q5jgDGNMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/customers/topfleets/2020/fleet-innovator.vue").then(m => m.default || m)
  },
  {
    name: fleet_45innovator3e9q5jgDGNMeta?.name ?? "customers-topfleets-2020-fleet-innovator___ca",
    path: fleet_45innovator3e9q5jgDGNMeta?.path ?? "/ca/customers/topfleets/2020/fleet-innovator",
    meta: fleet_45innovator3e9q5jgDGNMeta || {},
    alias: fleet_45innovator3e9q5jgDGNMeta?.alias || [],
    redirect: fleet_45innovator3e9q5jgDGNMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/customers/topfleets/2020/fleet-innovator.vue").then(m => m.default || m)
  },
  {
    name: fleet_45innovator3e9q5jgDGNMeta?.name ?? "customers-topfleets-2020-fleet-innovator___nl",
    path: fleet_45innovator3e9q5jgDGNMeta?.path ?? "/nl/customers/topfleets/2020/fleet-innovator",
    meta: fleet_45innovator3e9q5jgDGNMeta || {},
    alias: fleet_45innovator3e9q5jgDGNMeta?.alias || [],
    redirect: fleet_45innovator3e9q5jgDGNMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/customers/topfleets/2020/fleet-innovator.vue").then(m => m.default || m)
  },
  {
    name: fleet_45innovator3e9q5jgDGNMeta?.name ?? "customers-topfleets-2020-fleet-innovator___uk",
    path: fleet_45innovator3e9q5jgDGNMeta?.path ?? "/uk/customers/topfleets/2020/fleet-innovator",
    meta: fleet_45innovator3e9q5jgDGNMeta || {},
    alias: fleet_45innovator3e9q5jgDGNMeta?.alias || [],
    redirect: fleet_45innovator3e9q5jgDGNMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/customers/topfleets/2020/fleet-innovator.vue").then(m => m.default || m)
  },
  {
    name: fleet_45innovator3e9q5jgDGNMeta?.name ?? "customers-topfleets-2020-fleet-innovator___de",
    path: fleet_45innovator3e9q5jgDGNMeta?.path ?? "/de/customers/topfleets/2020/fleet-innovator",
    meta: fleet_45innovator3e9q5jgDGNMeta || {},
    alias: fleet_45innovator3e9q5jgDGNMeta?.alias || [],
    redirect: fleet_45innovator3e9q5jgDGNMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/customers/topfleets/2020/fleet-innovator.vue").then(m => m.default || m)
  },
  {
    name: fleet_45innovator3e9q5jgDGNMeta?.name ?? "customers-topfleets-2020-fleet-innovator___mx",
    path: fleet_45innovator3e9q5jgDGNMeta?.path ?? "/mx/customers/topfleets/2020/fleet-innovator",
    meta: fleet_45innovator3e9q5jgDGNMeta || {},
    alias: fleet_45innovator3e9q5jgDGNMeta?.alias || [],
    redirect: fleet_45innovator3e9q5jgDGNMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/customers/topfleets/2020/fleet-innovator.vue").then(m => m.default || m)
  },
  {
    name: fleet_45innovator3e9q5jgDGNMeta?.name ?? "customers-topfleets-2020-fleet-innovator___fr",
    path: fleet_45innovator3e9q5jgDGNMeta?.path ?? "/fr/customers/topfleets/2020/fleet-innovator",
    meta: fleet_45innovator3e9q5jgDGNMeta || {},
    alias: fleet_45innovator3e9q5jgDGNMeta?.alias || [],
    redirect: fleet_45innovator3e9q5jgDGNMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/customers/topfleets/2020/fleet-innovator.vue").then(m => m.default || m)
  },
  {
    name: fleet_45innovator3e9q5jgDGNMeta?.name ?? "customers-topfleets-2020-fleet-innovator___fr-ca",
    path: fleet_45innovator3e9q5jgDGNMeta?.path ?? "/fr-ca/customers/topfleets/2020/fleet-innovator",
    meta: fleet_45innovator3e9q5jgDGNMeta || {},
    alias: fleet_45innovator3e9q5jgDGNMeta?.alias || [],
    redirect: fleet_45innovator3e9q5jgDGNMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/customers/topfleets/2020/fleet-innovator.vue").then(m => m.default || m)
  },
  {
    name: index4QfC03ENSEMeta?.name ?? "customers-topfleets-2020___en-US",
    path: index4QfC03ENSEMeta?.path ?? "/customers/topfleets/2020",
    meta: index4QfC03ENSEMeta || {},
    alias: index4QfC03ENSEMeta?.alias || [],
    redirect: index4QfC03ENSEMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/customers/topfleets/2020/index.vue").then(m => m.default || m)
  },
  {
    name: index4QfC03ENSEMeta?.name ?? "customers-topfleets-2020___ca",
    path: index4QfC03ENSEMeta?.path ?? "/ca/customers/topfleets/2020",
    meta: index4QfC03ENSEMeta || {},
    alias: index4QfC03ENSEMeta?.alias || [],
    redirect: index4QfC03ENSEMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/customers/topfleets/2020/index.vue").then(m => m.default || m)
  },
  {
    name: index4QfC03ENSEMeta?.name ?? "customers-topfleets-2020___nl",
    path: index4QfC03ENSEMeta?.path ?? "/nl/customers/topfleets/2020",
    meta: index4QfC03ENSEMeta || {},
    alias: index4QfC03ENSEMeta?.alias || [],
    redirect: index4QfC03ENSEMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/customers/topfleets/2020/index.vue").then(m => m.default || m)
  },
  {
    name: index4QfC03ENSEMeta?.name ?? "customers-topfleets-2020___uk",
    path: index4QfC03ENSEMeta?.path ?? "/uk/customers/topfleets/2020",
    meta: index4QfC03ENSEMeta || {},
    alias: index4QfC03ENSEMeta?.alias || [],
    redirect: index4QfC03ENSEMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/customers/topfleets/2020/index.vue").then(m => m.default || m)
  },
  {
    name: index4QfC03ENSEMeta?.name ?? "customers-topfleets-2020___de",
    path: index4QfC03ENSEMeta?.path ?? "/de/customers/topfleets/2020",
    meta: index4QfC03ENSEMeta || {},
    alias: index4QfC03ENSEMeta?.alias || [],
    redirect: index4QfC03ENSEMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/customers/topfleets/2020/index.vue").then(m => m.default || m)
  },
  {
    name: index4QfC03ENSEMeta?.name ?? "customers-topfleets-2020___mx",
    path: index4QfC03ENSEMeta?.path ?? "/mx/customers/topfleets/2020",
    meta: index4QfC03ENSEMeta || {},
    alias: index4QfC03ENSEMeta?.alias || [],
    redirect: index4QfC03ENSEMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/customers/topfleets/2020/index.vue").then(m => m.default || m)
  },
  {
    name: index4QfC03ENSEMeta?.name ?? "customers-topfleets-2020___fr",
    path: index4QfC03ENSEMeta?.path ?? "/fr/customers/topfleets/2020",
    meta: index4QfC03ENSEMeta || {},
    alias: index4QfC03ENSEMeta?.alias || [],
    redirect: index4QfC03ENSEMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/customers/topfleets/2020/index.vue").then(m => m.default || m)
  },
  {
    name: index4QfC03ENSEMeta?.name ?? "customers-topfleets-2020___fr-ca",
    path: index4QfC03ENSEMeta?.path ?? "/fr-ca/customers/topfleets/2020",
    meta: index4QfC03ENSEMeta || {},
    alias: index4QfC03ENSEMeta?.alias || [],
    redirect: index4QfC03ENSEMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/customers/topfleets/2020/index.vue").then(m => m.default || m)
  },
  {
    name: public_45fleetGDNsNq40l3Meta?.name ?? "customers-topfleets-2020-public-fleet___en-US",
    path: public_45fleetGDNsNq40l3Meta?.path ?? "/customers/topfleets/2020/public-fleet",
    meta: public_45fleetGDNsNq40l3Meta || {},
    alias: public_45fleetGDNsNq40l3Meta?.alias || [],
    redirect: public_45fleetGDNsNq40l3Meta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/customers/topfleets/2020/public-fleet.vue").then(m => m.default || m)
  },
  {
    name: public_45fleetGDNsNq40l3Meta?.name ?? "customers-topfleets-2020-public-fleet___ca",
    path: public_45fleetGDNsNq40l3Meta?.path ?? "/ca/customers/topfleets/2020/public-fleet",
    meta: public_45fleetGDNsNq40l3Meta || {},
    alias: public_45fleetGDNsNq40l3Meta?.alias || [],
    redirect: public_45fleetGDNsNq40l3Meta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/customers/topfleets/2020/public-fleet.vue").then(m => m.default || m)
  },
  {
    name: public_45fleetGDNsNq40l3Meta?.name ?? "customers-topfleets-2020-public-fleet___nl",
    path: public_45fleetGDNsNq40l3Meta?.path ?? "/nl/customers/topfleets/2020/public-fleet",
    meta: public_45fleetGDNsNq40l3Meta || {},
    alias: public_45fleetGDNsNq40l3Meta?.alias || [],
    redirect: public_45fleetGDNsNq40l3Meta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/customers/topfleets/2020/public-fleet.vue").then(m => m.default || m)
  },
  {
    name: public_45fleetGDNsNq40l3Meta?.name ?? "customers-topfleets-2020-public-fleet___uk",
    path: public_45fleetGDNsNq40l3Meta?.path ?? "/uk/customers/topfleets/2020/public-fleet",
    meta: public_45fleetGDNsNq40l3Meta || {},
    alias: public_45fleetGDNsNq40l3Meta?.alias || [],
    redirect: public_45fleetGDNsNq40l3Meta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/customers/topfleets/2020/public-fleet.vue").then(m => m.default || m)
  },
  {
    name: public_45fleetGDNsNq40l3Meta?.name ?? "customers-topfleets-2020-public-fleet___de",
    path: public_45fleetGDNsNq40l3Meta?.path ?? "/de/customers/topfleets/2020/public-fleet",
    meta: public_45fleetGDNsNq40l3Meta || {},
    alias: public_45fleetGDNsNq40l3Meta?.alias || [],
    redirect: public_45fleetGDNsNq40l3Meta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/customers/topfleets/2020/public-fleet.vue").then(m => m.default || m)
  },
  {
    name: public_45fleetGDNsNq40l3Meta?.name ?? "customers-topfleets-2020-public-fleet___mx",
    path: public_45fleetGDNsNq40l3Meta?.path ?? "/mx/customers/topfleets/2020/public-fleet",
    meta: public_45fleetGDNsNq40l3Meta || {},
    alias: public_45fleetGDNsNq40l3Meta?.alias || [],
    redirect: public_45fleetGDNsNq40l3Meta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/customers/topfleets/2020/public-fleet.vue").then(m => m.default || m)
  },
  {
    name: public_45fleetGDNsNq40l3Meta?.name ?? "customers-topfleets-2020-public-fleet___fr",
    path: public_45fleetGDNsNq40l3Meta?.path ?? "/fr/customers/topfleets/2020/public-fleet",
    meta: public_45fleetGDNsNq40l3Meta || {},
    alias: public_45fleetGDNsNq40l3Meta?.alias || [],
    redirect: public_45fleetGDNsNq40l3Meta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/customers/topfleets/2020/public-fleet.vue").then(m => m.default || m)
  },
  {
    name: public_45fleetGDNsNq40l3Meta?.name ?? "customers-topfleets-2020-public-fleet___fr-ca",
    path: public_45fleetGDNsNq40l3Meta?.path ?? "/fr-ca/customers/topfleets/2020/public-fleet",
    meta: public_45fleetGDNsNq40l3Meta || {},
    alias: public_45fleetGDNsNq40l3Meta?.alias || [],
    redirect: public_45fleetGDNsNq40l3Meta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/customers/topfleets/2020/public-fleet.vue").then(m => m.default || m)
  },
  {
    name: safest_45fleetjlaoZpGWDuMeta?.name ?? "customers-topfleets-2020-safest-fleet___en-US",
    path: safest_45fleetjlaoZpGWDuMeta?.path ?? "/customers/topfleets/2020/safest-fleet",
    meta: safest_45fleetjlaoZpGWDuMeta || {},
    alias: safest_45fleetjlaoZpGWDuMeta?.alias || [],
    redirect: safest_45fleetjlaoZpGWDuMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/customers/topfleets/2020/safest-fleet.vue").then(m => m.default || m)
  },
  {
    name: safest_45fleetjlaoZpGWDuMeta?.name ?? "customers-topfleets-2020-safest-fleet___ca",
    path: safest_45fleetjlaoZpGWDuMeta?.path ?? "/ca/customers/topfleets/2020/safest-fleet",
    meta: safest_45fleetjlaoZpGWDuMeta || {},
    alias: safest_45fleetjlaoZpGWDuMeta?.alias || [],
    redirect: safest_45fleetjlaoZpGWDuMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/customers/topfleets/2020/safest-fleet.vue").then(m => m.default || m)
  },
  {
    name: indexp8CtNVJglLMeta?.name ?? "customers-topfleets___en-US",
    path: indexp8CtNVJglLMeta?.path ?? "/customers/topfleets",
    meta: indexp8CtNVJglLMeta || {},
    alias: indexp8CtNVJglLMeta?.alias || [],
    redirect: indexp8CtNVJglLMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/customers/topfleets/index.vue").then(m => m.default || m)
  },
  {
    name: indexp8CtNVJglLMeta?.name ?? "customers-topfleets___ca",
    path: indexp8CtNVJglLMeta?.path ?? "/ca/customers/topfleets",
    meta: indexp8CtNVJglLMeta || {},
    alias: indexp8CtNVJglLMeta?.alias || [],
    redirect: indexp8CtNVJglLMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/customers/topfleets/index.vue").then(m => m.default || m)
  },
  {
    name: indexp8CtNVJglLMeta?.name ?? "customers-topfleets___nl",
    path: indexp8CtNVJglLMeta?.path ?? "/nl/customers/topfleets",
    meta: indexp8CtNVJglLMeta || {},
    alias: indexp8CtNVJglLMeta?.alias || [],
    redirect: indexp8CtNVJglLMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/customers/topfleets/index.vue").then(m => m.default || m)
  },
  {
    name: indexp8CtNVJglLMeta?.name ?? "customers-topfleets___uk",
    path: indexp8CtNVJglLMeta?.path ?? "/uk/customers/topfleets",
    meta: indexp8CtNVJglLMeta || {},
    alias: indexp8CtNVJglLMeta?.alias || [],
    redirect: indexp8CtNVJglLMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/customers/topfleets/index.vue").then(m => m.default || m)
  },
  {
    name: indexp8CtNVJglLMeta?.name ?? "customers-topfleets___de",
    path: indexp8CtNVJglLMeta?.path ?? "/de/customers/topfleets",
    meta: indexp8CtNVJglLMeta || {},
    alias: indexp8CtNVJglLMeta?.alias || [],
    redirect: indexp8CtNVJglLMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/customers/topfleets/index.vue").then(m => m.default || m)
  },
  {
    name: indexp8CtNVJglLMeta?.name ?? "customers-topfleets___mx",
    path: indexp8CtNVJglLMeta?.path ?? "/mx/customers/topfleets",
    meta: indexp8CtNVJglLMeta || {},
    alias: indexp8CtNVJglLMeta?.alias || [],
    redirect: indexp8CtNVJglLMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/customers/topfleets/index.vue").then(m => m.default || m)
  },
  {
    name: indexp8CtNVJglLMeta?.name ?? "customers-topfleets___fr",
    path: indexp8CtNVJglLMeta?.path ?? "/fr/customers/topfleets",
    meta: indexp8CtNVJglLMeta || {},
    alias: indexp8CtNVJglLMeta?.alias || [],
    redirect: indexp8CtNVJglLMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/customers/topfleets/index.vue").then(m => m.default || m)
  },
  {
    name: indexp8CtNVJglLMeta?.name ?? "customers-topfleets___fr-ca",
    path: indexp8CtNVJglLMeta?.path ?? "/fr-ca/customers/topfleets",
    meta: indexp8CtNVJglLMeta || {},
    alias: indexp8CtNVJglLMeta?.alias || [],
    redirect: indexp8CtNVJglLMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/customers/topfleets/index.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93EiaoziqcoOMeta?.name ?? "events-slug___en-US",
    path: _91slug_93EiaoziqcoOMeta?.path ?? "/events/:slug()",
    meta: _91slug_93EiaoziqcoOMeta || {},
    alias: _91slug_93EiaoziqcoOMeta?.alias || [],
    redirect: _91slug_93EiaoziqcoOMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/events/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93EiaoziqcoOMeta?.name ?? "events-slug___ca",
    path: _91slug_93EiaoziqcoOMeta?.path ?? "/ca/events/:slug()",
    meta: _91slug_93EiaoziqcoOMeta || {},
    alias: _91slug_93EiaoziqcoOMeta?.alias || [],
    redirect: _91slug_93EiaoziqcoOMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/events/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93EiaoziqcoOMeta?.name ?? "events-slug___nl",
    path: _91slug_93EiaoziqcoOMeta?.path ?? "/nl/events/:slug()",
    meta: _91slug_93EiaoziqcoOMeta || {},
    alias: _91slug_93EiaoziqcoOMeta?.alias || [],
    redirect: _91slug_93EiaoziqcoOMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/events/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93EiaoziqcoOMeta?.name ?? "events-slug___uk",
    path: _91slug_93EiaoziqcoOMeta?.path ?? "/uk/events/:slug()",
    meta: _91slug_93EiaoziqcoOMeta || {},
    alias: _91slug_93EiaoziqcoOMeta?.alias || [],
    redirect: _91slug_93EiaoziqcoOMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/events/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93EiaoziqcoOMeta?.name ?? "events-slug___de",
    path: _91slug_93EiaoziqcoOMeta?.path ?? "/de/events/:slug()",
    meta: _91slug_93EiaoziqcoOMeta || {},
    alias: _91slug_93EiaoziqcoOMeta?.alias || [],
    redirect: _91slug_93EiaoziqcoOMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/events/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93EiaoziqcoOMeta?.name ?? "events-slug___mx",
    path: _91slug_93EiaoziqcoOMeta?.path ?? "/mx/events/:slug()",
    meta: _91slug_93EiaoziqcoOMeta || {},
    alias: _91slug_93EiaoziqcoOMeta?.alias || [],
    redirect: _91slug_93EiaoziqcoOMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/events/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93EiaoziqcoOMeta?.name ?? "events-slug___fr",
    path: _91slug_93EiaoziqcoOMeta?.path ?? "/fr/events/:slug()",
    meta: _91slug_93EiaoziqcoOMeta || {},
    alias: _91slug_93EiaoziqcoOMeta?.alias || [],
    redirect: _91slug_93EiaoziqcoOMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/events/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93EiaoziqcoOMeta?.name ?? "events-slug___fr-ca",
    path: _91slug_93EiaoziqcoOMeta?.path ?? "/fr-ca/events/:slug()",
    meta: _91slug_93EiaoziqcoOMeta || {},
    alias: _91slug_93EiaoziqcoOMeta?.alias || [],
    redirect: _91slug_93EiaoziqcoOMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/events/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexkQyzzsFxOgMeta?.name ?? "events___en-US",
    path: indexkQyzzsFxOgMeta?.path ?? "/events",
    meta: indexkQyzzsFxOgMeta || {},
    alias: indexkQyzzsFxOgMeta?.alias || [],
    redirect: indexkQyzzsFxOgMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/events/index.vue").then(m => m.default || m)
  },
  {
    name: indexkQyzzsFxOgMeta?.name ?? "events___ca",
    path: indexkQyzzsFxOgMeta?.path ?? "/ca/events",
    meta: indexkQyzzsFxOgMeta || {},
    alias: indexkQyzzsFxOgMeta?.alias || [],
    redirect: indexkQyzzsFxOgMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/events/index.vue").then(m => m.default || m)
  },
  {
    name: indexkQyzzsFxOgMeta?.name ?? "events___nl",
    path: indexkQyzzsFxOgMeta?.path ?? "/nl/events",
    meta: indexkQyzzsFxOgMeta || {},
    alias: indexkQyzzsFxOgMeta?.alias || [],
    redirect: indexkQyzzsFxOgMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/events/index.vue").then(m => m.default || m)
  },
  {
    name: indexkQyzzsFxOgMeta?.name ?? "events___uk",
    path: indexkQyzzsFxOgMeta?.path ?? "/uk/events",
    meta: indexkQyzzsFxOgMeta || {},
    alias: indexkQyzzsFxOgMeta?.alias || [],
    redirect: indexkQyzzsFxOgMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/events/index.vue").then(m => m.default || m)
  },
  {
    name: indexkQyzzsFxOgMeta?.name ?? "events___de",
    path: indexkQyzzsFxOgMeta?.path ?? "/de/events",
    meta: indexkQyzzsFxOgMeta || {},
    alias: indexkQyzzsFxOgMeta?.alias || [],
    redirect: indexkQyzzsFxOgMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/events/index.vue").then(m => m.default || m)
  },
  {
    name: indexkQyzzsFxOgMeta?.name ?? "events___mx",
    path: indexkQyzzsFxOgMeta?.path ?? "/mx/events",
    meta: indexkQyzzsFxOgMeta || {},
    alias: indexkQyzzsFxOgMeta?.alias || [],
    redirect: indexkQyzzsFxOgMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/events/index.vue").then(m => m.default || m)
  },
  {
    name: indexkQyzzsFxOgMeta?.name ?? "events___fr",
    path: indexkQyzzsFxOgMeta?.path ?? "/fr/events",
    meta: indexkQyzzsFxOgMeta || {},
    alias: indexkQyzzsFxOgMeta?.alias || [],
    redirect: indexkQyzzsFxOgMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/events/index.vue").then(m => m.default || m)
  },
  {
    name: indexkQyzzsFxOgMeta?.name ?? "events___fr-ca",
    path: indexkQyzzsFxOgMeta?.path ?? "/fr-ca/events",
    meta: indexkQyzzsFxOgMeta || {},
    alias: indexkQyzzsFxOgMeta?.alias || [],
    redirect: indexkQyzzsFxOgMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/events/index.vue").then(m => m.default || m)
  },
  {
    name: fleet_45app0u6FGkvDJyMeta?.name ?? "fleet-app___en-US",
    path: fleet_45app0u6FGkvDJyMeta?.path ?? "/fleet-app",
    meta: fleet_45app0u6FGkvDJyMeta || {},
    alias: fleet_45app0u6FGkvDJyMeta?.alias || [],
    redirect: fleet_45app0u6FGkvDJyMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/fleet-app.vue").then(m => m.default || m)
  },
  {
    name: fleet_45app0u6FGkvDJyMeta?.name ?? "fleet-app___ca",
    path: fleet_45app0u6FGkvDJyMeta?.path ?? "/ca/fleet-app",
    meta: fleet_45app0u6FGkvDJyMeta || {},
    alias: fleet_45app0u6FGkvDJyMeta?.alias || [],
    redirect: fleet_45app0u6FGkvDJyMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/fleet-app.vue").then(m => m.default || m)
  },
  {
    name: fleet_45app0u6FGkvDJyMeta?.name ?? "fleet-app___nl",
    path: fleet_45app0u6FGkvDJyMeta?.path ?? "/nl/fleet-app",
    meta: fleet_45app0u6FGkvDJyMeta || {},
    alias: fleet_45app0u6FGkvDJyMeta?.alias || [],
    redirect: fleet_45app0u6FGkvDJyMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/fleet-app.vue").then(m => m.default || m)
  },
  {
    name: fleet_45app0u6FGkvDJyMeta?.name ?? "fleet-app___uk",
    path: fleet_45app0u6FGkvDJyMeta?.path ?? "/uk/fleet-app",
    meta: fleet_45app0u6FGkvDJyMeta || {},
    alias: fleet_45app0u6FGkvDJyMeta?.alias || [],
    redirect: fleet_45app0u6FGkvDJyMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/fleet-app.vue").then(m => m.default || m)
  },
  {
    name: fleet_45app0u6FGkvDJyMeta?.name ?? "fleet-app___de",
    path: fleet_45app0u6FGkvDJyMeta?.path ?? "/de/fleet-app",
    meta: fleet_45app0u6FGkvDJyMeta || {},
    alias: fleet_45app0u6FGkvDJyMeta?.alias || [],
    redirect: fleet_45app0u6FGkvDJyMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/fleet-app.vue").then(m => m.default || m)
  },
  {
    name: fleet_45app0u6FGkvDJyMeta?.name ?? "fleet-app___mx",
    path: fleet_45app0u6FGkvDJyMeta?.path ?? "/mx/fleet-app",
    meta: fleet_45app0u6FGkvDJyMeta || {},
    alias: fleet_45app0u6FGkvDJyMeta?.alias || [],
    redirect: fleet_45app0u6FGkvDJyMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/fleet-app.vue").then(m => m.default || m)
  },
  {
    name: fleet_45app0u6FGkvDJyMeta?.name ?? "fleet-app___fr",
    path: fleet_45app0u6FGkvDJyMeta?.path ?? "/fr/fleet-app",
    meta: fleet_45app0u6FGkvDJyMeta || {},
    alias: fleet_45app0u6FGkvDJyMeta?.alias || [],
    redirect: fleet_45app0u6FGkvDJyMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/fleet-app.vue").then(m => m.default || m)
  },
  {
    name: fleet_45app0u6FGkvDJyMeta?.name ?? "fleet-app___fr-ca",
    path: fleet_45app0u6FGkvDJyMeta?.path ?? "/fr-ca/fleet-app",
    meta: fleet_45app0u6FGkvDJyMeta || {},
    alias: fleet_45app0u6FGkvDJyMeta?.alias || [],
    redirect: fleet_45app0u6FGkvDJyMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/fleet-app.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93ZWYfcoXahPMeta?.name ?? "fleet-prefix-slug___en-US",
    path: _91slug_93ZWYfcoXahPMeta?.path ?? "/fleet/:prefix()/:slug()",
    meta: _91slug_93ZWYfcoXahPMeta || {},
    alias: _91slug_93ZWYfcoXahPMeta?.alias || [],
    redirect: _91slug_93ZWYfcoXahPMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/fleet/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93ZWYfcoXahPMeta?.name ?? "fleet-prefix-slug___ca",
    path: _91slug_93ZWYfcoXahPMeta?.path ?? "/ca/fleet/:prefix()/:slug()",
    meta: _91slug_93ZWYfcoXahPMeta || {},
    alias: _91slug_93ZWYfcoXahPMeta?.alias || [],
    redirect: _91slug_93ZWYfcoXahPMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/fleet/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93ZWYfcoXahPMeta?.name ?? "fleet-prefix-slug___nl",
    path: _91slug_93ZWYfcoXahPMeta?.path ?? "/nl/fleet/:prefix()/:slug()",
    meta: _91slug_93ZWYfcoXahPMeta || {},
    alias: _91slug_93ZWYfcoXahPMeta?.alias || [],
    redirect: _91slug_93ZWYfcoXahPMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/fleet/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93ZWYfcoXahPMeta?.name ?? "fleet-prefix-slug___uk",
    path: _91slug_93ZWYfcoXahPMeta?.path ?? "/uk/fleet/:prefix()/:slug()",
    meta: _91slug_93ZWYfcoXahPMeta || {},
    alias: _91slug_93ZWYfcoXahPMeta?.alias || [],
    redirect: _91slug_93ZWYfcoXahPMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/fleet/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93ZWYfcoXahPMeta?.name ?? "fleet-prefix-slug___de",
    path: _91slug_93ZWYfcoXahPMeta?.path ?? "/de/fleet/:prefix()/:slug()",
    meta: _91slug_93ZWYfcoXahPMeta || {},
    alias: _91slug_93ZWYfcoXahPMeta?.alias || [],
    redirect: _91slug_93ZWYfcoXahPMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/fleet/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93ZWYfcoXahPMeta?.name ?? "fleet-prefix-slug___mx",
    path: _91slug_93ZWYfcoXahPMeta?.path ?? "/mx/fleet/:prefix()/:slug()",
    meta: _91slug_93ZWYfcoXahPMeta || {},
    alias: _91slug_93ZWYfcoXahPMeta?.alias || [],
    redirect: _91slug_93ZWYfcoXahPMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/fleet/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93ZWYfcoXahPMeta?.name ?? "fleet-prefix-slug___fr",
    path: _91slug_93ZWYfcoXahPMeta?.path ?? "/fr/fleet/:prefix()/:slug()",
    meta: _91slug_93ZWYfcoXahPMeta || {},
    alias: _91slug_93ZWYfcoXahPMeta?.alias || [],
    redirect: _91slug_93ZWYfcoXahPMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/fleet/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93ZWYfcoXahPMeta?.name ?? "fleet-prefix-slug___fr-ca",
    path: _91slug_93ZWYfcoXahPMeta?.path ?? "/fr-ca/fleet/:prefix()/:slug()",
    meta: _91slug_93ZWYfcoXahPMeta || {},
    alias: _91slug_93ZWYfcoXahPMeta?.alias || [],
    redirect: _91slug_93ZWYfcoXahPMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/fleet/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93dqbCaXDP42Meta?.name ?? "fleet-slug___en-US",
    path: _91slug_93dqbCaXDP42Meta?.path ?? "/fleet/:slug()",
    meta: _91slug_93dqbCaXDP42Meta || {},
    alias: _91slug_93dqbCaXDP42Meta?.alias || [],
    redirect: _91slug_93dqbCaXDP42Meta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/fleet/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93dqbCaXDP42Meta?.name ?? "fleet-slug___ca",
    path: _91slug_93dqbCaXDP42Meta?.path ?? "/ca/fleet/:slug()",
    meta: _91slug_93dqbCaXDP42Meta || {},
    alias: _91slug_93dqbCaXDP42Meta?.alias || [],
    redirect: _91slug_93dqbCaXDP42Meta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/fleet/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93dqbCaXDP42Meta?.name ?? "fleet-slug___nl",
    path: _91slug_93dqbCaXDP42Meta?.path ?? "/nl/fleet/:slug()",
    meta: _91slug_93dqbCaXDP42Meta || {},
    alias: _91slug_93dqbCaXDP42Meta?.alias || [],
    redirect: _91slug_93dqbCaXDP42Meta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/fleet/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93dqbCaXDP42Meta?.name ?? "fleet-slug___uk",
    path: _91slug_93dqbCaXDP42Meta?.path ?? "/uk/fleet/:slug()",
    meta: _91slug_93dqbCaXDP42Meta || {},
    alias: _91slug_93dqbCaXDP42Meta?.alias || [],
    redirect: _91slug_93dqbCaXDP42Meta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/fleet/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93dqbCaXDP42Meta?.name ?? "fleet-slug___de",
    path: _91slug_93dqbCaXDP42Meta?.path ?? "/de/fleet/:slug()",
    meta: _91slug_93dqbCaXDP42Meta || {},
    alias: _91slug_93dqbCaXDP42Meta?.alias || [],
    redirect: _91slug_93dqbCaXDP42Meta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/fleet/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93dqbCaXDP42Meta?.name ?? "fleet-slug___mx",
    path: _91slug_93dqbCaXDP42Meta?.path ?? "/mx/fleet/:slug()",
    meta: _91slug_93dqbCaXDP42Meta || {},
    alias: _91slug_93dqbCaXDP42Meta?.alias || [],
    redirect: _91slug_93dqbCaXDP42Meta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/fleet/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93dqbCaXDP42Meta?.name ?? "fleet-slug___fr",
    path: _91slug_93dqbCaXDP42Meta?.path ?? "/fr/fleet/:slug()",
    meta: _91slug_93dqbCaXDP42Meta || {},
    alias: _91slug_93dqbCaXDP42Meta?.alias || [],
    redirect: _91slug_93dqbCaXDP42Meta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/fleet/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93dqbCaXDP42Meta?.name ?? "fleet-slug___fr-ca",
    path: _91slug_93dqbCaXDP42Meta?.path ?? "/fr-ca/fleet/:slug()",
    meta: _91slug_93dqbCaXDP42Meta || {},
    alias: _91slug_93dqbCaXDP42Meta?.alias || [],
    redirect: _91slug_93dqbCaXDP42Meta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/fleet/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93Xjs7EdmLkMMeta?.name ?? "guides-slug___en-US",
    path: _91slug_93Xjs7EdmLkMMeta?.path ?? "/guides/:slug()",
    meta: _91slug_93Xjs7EdmLkMMeta || {},
    alias: _91slug_93Xjs7EdmLkMMeta?.alias || [],
    redirect: _91slug_93Xjs7EdmLkMMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/guides/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93Xjs7EdmLkMMeta?.name ?? "guides-slug___ca",
    path: _91slug_93Xjs7EdmLkMMeta?.path ?? "/ca/guides/:slug()",
    meta: _91slug_93Xjs7EdmLkMMeta || {},
    alias: _91slug_93Xjs7EdmLkMMeta?.alias || [],
    redirect: _91slug_93Xjs7EdmLkMMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/guides/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93Xjs7EdmLkMMeta?.name ?? "guides-slug___nl",
    path: _91slug_93Xjs7EdmLkMMeta?.path ?? "/nl/guides/:slug()",
    meta: _91slug_93Xjs7EdmLkMMeta || {},
    alias: _91slug_93Xjs7EdmLkMMeta?.alias || [],
    redirect: _91slug_93Xjs7EdmLkMMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/guides/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93Xjs7EdmLkMMeta?.name ?? "guides-slug___uk",
    path: _91slug_93Xjs7EdmLkMMeta?.path ?? "/uk/guides/:slug()",
    meta: _91slug_93Xjs7EdmLkMMeta || {},
    alias: _91slug_93Xjs7EdmLkMMeta?.alias || [],
    redirect: _91slug_93Xjs7EdmLkMMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/guides/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93Xjs7EdmLkMMeta?.name ?? "guides-slug___de",
    path: _91slug_93Xjs7EdmLkMMeta?.path ?? "/de/guides/:slug()",
    meta: _91slug_93Xjs7EdmLkMMeta || {},
    alias: _91slug_93Xjs7EdmLkMMeta?.alias || [],
    redirect: _91slug_93Xjs7EdmLkMMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/guides/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93Xjs7EdmLkMMeta?.name ?? "guides-slug___mx",
    path: _91slug_93Xjs7EdmLkMMeta?.path ?? "/mx/guides/:slug()",
    meta: _91slug_93Xjs7EdmLkMMeta || {},
    alias: _91slug_93Xjs7EdmLkMMeta?.alias || [],
    redirect: _91slug_93Xjs7EdmLkMMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/guides/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93Xjs7EdmLkMMeta?.name ?? "guides-slug___fr",
    path: _91slug_93Xjs7EdmLkMMeta?.path ?? "/fr/guides/:slug()",
    meta: _91slug_93Xjs7EdmLkMMeta || {},
    alias: _91slug_93Xjs7EdmLkMMeta?.alias || [],
    redirect: _91slug_93Xjs7EdmLkMMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/guides/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93Xjs7EdmLkMMeta?.name ?? "guides-slug___fr-ca",
    path: _91slug_93Xjs7EdmLkMMeta?.path ?? "/fr-ca/guides/:slug()",
    meta: _91slug_93Xjs7EdmLkMMeta || {},
    alias: _91slug_93Xjs7EdmLkMMeta?.alias || [],
    redirect: _91slug_93Xjs7EdmLkMMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/guides/[slug].vue").then(m => m.default || m)
  },
  {
    name: attract_45retain_45talent7UXYvqfaK8Meta?.name ?? "guides-build-business-resilience-attract-retain-talent___en-US",
    path: attract_45retain_45talent7UXYvqfaK8Meta?.path ?? "/guides/build-business-resilience/attract-retain-talent",
    meta: attract_45retain_45talent7UXYvqfaK8Meta || {},
    alias: attract_45retain_45talent7UXYvqfaK8Meta?.alias || [],
    redirect: attract_45retain_45talent7UXYvqfaK8Meta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/guides/build-business-resilience/attract-retain-talent.vue").then(m => m.default || m)
  },
  {
    name: attract_45retain_45talent7UXYvqfaK8Meta?.name ?? "guides-build-business-resilience-attract-retain-talent___ca",
    path: attract_45retain_45talent7UXYvqfaK8Meta?.path ?? "/ca/guides/build-business-resilience/attract-retain-talent",
    meta: attract_45retain_45talent7UXYvqfaK8Meta || {},
    alias: attract_45retain_45talent7UXYvqfaK8Meta?.alias || [],
    redirect: attract_45retain_45talent7UXYvqfaK8Meta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/guides/build-business-resilience/attract-retain-talent.vue").then(m => m.default || m)
  },
  {
    name: attract_45retain_45talent7UXYvqfaK8Meta?.name ?? "guides-build-business-resilience-attract-retain-talent___nl",
    path: attract_45retain_45talent7UXYvqfaK8Meta?.path ?? "/nl/guides/build-business-resilience/attract-retain-talent",
    meta: attract_45retain_45talent7UXYvqfaK8Meta || {},
    alias: attract_45retain_45talent7UXYvqfaK8Meta?.alias || [],
    redirect: attract_45retain_45talent7UXYvqfaK8Meta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/guides/build-business-resilience/attract-retain-talent.vue").then(m => m.default || m)
  },
  {
    name: attract_45retain_45talent7UXYvqfaK8Meta?.name ?? "guides-build-business-resilience-attract-retain-talent___uk",
    path: attract_45retain_45talent7UXYvqfaK8Meta?.path ?? "/uk/guides/build-business-resilience/attract-retain-talent",
    meta: attract_45retain_45talent7UXYvqfaK8Meta || {},
    alias: attract_45retain_45talent7UXYvqfaK8Meta?.alias || [],
    redirect: attract_45retain_45talent7UXYvqfaK8Meta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/guides/build-business-resilience/attract-retain-talent.vue").then(m => m.default || m)
  },
  {
    name: attract_45retain_45talent7UXYvqfaK8Meta?.name ?? "guides-build-business-resilience-attract-retain-talent___de",
    path: attract_45retain_45talent7UXYvqfaK8Meta?.path ?? "/de/guides/build-business-resilience/attract-retain-talent",
    meta: attract_45retain_45talent7UXYvqfaK8Meta || {},
    alias: attract_45retain_45talent7UXYvqfaK8Meta?.alias || [],
    redirect: attract_45retain_45talent7UXYvqfaK8Meta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/guides/build-business-resilience/attract-retain-talent.vue").then(m => m.default || m)
  },
  {
    name: attract_45retain_45talent7UXYvqfaK8Meta?.name ?? "guides-build-business-resilience-attract-retain-talent___mx",
    path: attract_45retain_45talent7UXYvqfaK8Meta?.path ?? "/mx/guides/build-business-resilience/attract-retain-talent",
    meta: attract_45retain_45talent7UXYvqfaK8Meta || {},
    alias: attract_45retain_45talent7UXYvqfaK8Meta?.alias || [],
    redirect: attract_45retain_45talent7UXYvqfaK8Meta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/guides/build-business-resilience/attract-retain-talent.vue").then(m => m.default || m)
  },
  {
    name: attract_45retain_45talent7UXYvqfaK8Meta?.name ?? "guides-build-business-resilience-attract-retain-talent___fr",
    path: attract_45retain_45talent7UXYvqfaK8Meta?.path ?? "/fr/guides/build-business-resilience/attract-retain-talent",
    meta: attract_45retain_45talent7UXYvqfaK8Meta || {},
    alias: attract_45retain_45talent7UXYvqfaK8Meta?.alias || [],
    redirect: attract_45retain_45talent7UXYvqfaK8Meta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/guides/build-business-resilience/attract-retain-talent.vue").then(m => m.default || m)
  },
  {
    name: attract_45retain_45talent7UXYvqfaK8Meta?.name ?? "guides-build-business-resilience-attract-retain-talent___fr-ca",
    path: attract_45retain_45talent7UXYvqfaK8Meta?.path ?? "/fr-ca/guides/build-business-resilience/attract-retain-talent",
    meta: attract_45retain_45talent7UXYvqfaK8Meta || {},
    alias: attract_45retain_45talent7UXYvqfaK8Meta?.alias || [],
    redirect: attract_45retain_45talent7UXYvqfaK8Meta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/guides/build-business-resilience/attract-retain-talent.vue").then(m => m.default || m)
  },
  {
    name: increase_45efficiency_45maximize_45profitsZDsMvJvWF3Meta?.name ?? "guides-build-business-resilience-increase-efficiency-maximize-profits___en-US",
    path: increase_45efficiency_45maximize_45profitsZDsMvJvWF3Meta?.path ?? "/guides/build-business-resilience/increase-efficiency-maximize-profits",
    meta: increase_45efficiency_45maximize_45profitsZDsMvJvWF3Meta || {},
    alias: increase_45efficiency_45maximize_45profitsZDsMvJvWF3Meta?.alias || [],
    redirect: increase_45efficiency_45maximize_45profitsZDsMvJvWF3Meta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/guides/build-business-resilience/increase-efficiency-maximize-profits.vue").then(m => m.default || m)
  },
  {
    name: increase_45efficiency_45maximize_45profitsZDsMvJvWF3Meta?.name ?? "guides-build-business-resilience-increase-efficiency-maximize-profits___ca",
    path: increase_45efficiency_45maximize_45profitsZDsMvJvWF3Meta?.path ?? "/ca/guides/build-business-resilience/increase-efficiency-maximize-profits",
    meta: increase_45efficiency_45maximize_45profitsZDsMvJvWF3Meta || {},
    alias: increase_45efficiency_45maximize_45profitsZDsMvJvWF3Meta?.alias || [],
    redirect: increase_45efficiency_45maximize_45profitsZDsMvJvWF3Meta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/guides/build-business-resilience/increase-efficiency-maximize-profits.vue").then(m => m.default || m)
  },
  {
    name: increase_45efficiency_45maximize_45profitsZDsMvJvWF3Meta?.name ?? "guides-build-business-resilience-increase-efficiency-maximize-profits___nl",
    path: increase_45efficiency_45maximize_45profitsZDsMvJvWF3Meta?.path ?? "/nl/guides/build-business-resilience/increase-efficiency-maximize-profits",
    meta: increase_45efficiency_45maximize_45profitsZDsMvJvWF3Meta || {},
    alias: increase_45efficiency_45maximize_45profitsZDsMvJvWF3Meta?.alias || [],
    redirect: increase_45efficiency_45maximize_45profitsZDsMvJvWF3Meta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/guides/build-business-resilience/increase-efficiency-maximize-profits.vue").then(m => m.default || m)
  },
  {
    name: increase_45efficiency_45maximize_45profitsZDsMvJvWF3Meta?.name ?? "guides-build-business-resilience-increase-efficiency-maximize-profits___uk",
    path: increase_45efficiency_45maximize_45profitsZDsMvJvWF3Meta?.path ?? "/uk/guides/build-business-resilience/increase-efficiency-maximize-profits",
    meta: increase_45efficiency_45maximize_45profitsZDsMvJvWF3Meta || {},
    alias: increase_45efficiency_45maximize_45profitsZDsMvJvWF3Meta?.alias || [],
    redirect: increase_45efficiency_45maximize_45profitsZDsMvJvWF3Meta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/guides/build-business-resilience/increase-efficiency-maximize-profits.vue").then(m => m.default || m)
  },
  {
    name: increase_45efficiency_45maximize_45profitsZDsMvJvWF3Meta?.name ?? "guides-build-business-resilience-increase-efficiency-maximize-profits___de",
    path: increase_45efficiency_45maximize_45profitsZDsMvJvWF3Meta?.path ?? "/de/guides/build-business-resilience/increase-efficiency-maximize-profits",
    meta: increase_45efficiency_45maximize_45profitsZDsMvJvWF3Meta || {},
    alias: increase_45efficiency_45maximize_45profitsZDsMvJvWF3Meta?.alias || [],
    redirect: increase_45efficiency_45maximize_45profitsZDsMvJvWF3Meta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/guides/build-business-resilience/increase-efficiency-maximize-profits.vue").then(m => m.default || m)
  },
  {
    name: increase_45efficiency_45maximize_45profitsZDsMvJvWF3Meta?.name ?? "guides-build-business-resilience-increase-efficiency-maximize-profits___mx",
    path: increase_45efficiency_45maximize_45profitsZDsMvJvWF3Meta?.path ?? "/mx/guides/build-business-resilience/increase-efficiency-maximize-profits",
    meta: increase_45efficiency_45maximize_45profitsZDsMvJvWF3Meta || {},
    alias: increase_45efficiency_45maximize_45profitsZDsMvJvWF3Meta?.alias || [],
    redirect: increase_45efficiency_45maximize_45profitsZDsMvJvWF3Meta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/guides/build-business-resilience/increase-efficiency-maximize-profits.vue").then(m => m.default || m)
  },
  {
    name: increase_45efficiency_45maximize_45profitsZDsMvJvWF3Meta?.name ?? "guides-build-business-resilience-increase-efficiency-maximize-profits___fr",
    path: increase_45efficiency_45maximize_45profitsZDsMvJvWF3Meta?.path ?? "/fr/guides/build-business-resilience/increase-efficiency-maximize-profits",
    meta: increase_45efficiency_45maximize_45profitsZDsMvJvWF3Meta || {},
    alias: increase_45efficiency_45maximize_45profitsZDsMvJvWF3Meta?.alias || [],
    redirect: increase_45efficiency_45maximize_45profitsZDsMvJvWF3Meta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/guides/build-business-resilience/increase-efficiency-maximize-profits.vue").then(m => m.default || m)
  },
  {
    name: increase_45efficiency_45maximize_45profitsZDsMvJvWF3Meta?.name ?? "guides-build-business-resilience-increase-efficiency-maximize-profits___fr-ca",
    path: increase_45efficiency_45maximize_45profitsZDsMvJvWF3Meta?.path ?? "/fr-ca/guides/build-business-resilience/increase-efficiency-maximize-profits",
    meta: increase_45efficiency_45maximize_45profitsZDsMvJvWF3Meta || {},
    alias: increase_45efficiency_45maximize_45profitsZDsMvJvWF3Meta?.alias || [],
    redirect: increase_45efficiency_45maximize_45profitsZDsMvJvWF3Meta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/guides/build-business-resilience/increase-efficiency-maximize-profits.vue").then(m => m.default || m)
  },
  {
    name: indext2UH8RZdvzMeta?.name ?? "guides-build-business-resilience___en-US",
    path: indext2UH8RZdvzMeta?.path ?? "/guides/build-business-resilience",
    meta: indext2UH8RZdvzMeta || {},
    alias: indext2UH8RZdvzMeta?.alias || [],
    redirect: indext2UH8RZdvzMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/guides/build-business-resilience/index.vue").then(m => m.default || m)
  },
  {
    name: indext2UH8RZdvzMeta?.name ?? "guides-build-business-resilience___ca",
    path: indext2UH8RZdvzMeta?.path ?? "/ca/guides/build-business-resilience",
    meta: indext2UH8RZdvzMeta || {},
    alias: indext2UH8RZdvzMeta?.alias || [],
    redirect: indext2UH8RZdvzMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/guides/build-business-resilience/index.vue").then(m => m.default || m)
  },
  {
    name: indext2UH8RZdvzMeta?.name ?? "guides-build-business-resilience___nl",
    path: indext2UH8RZdvzMeta?.path ?? "/nl/guides/build-business-resilience",
    meta: indext2UH8RZdvzMeta || {},
    alias: indext2UH8RZdvzMeta?.alias || [],
    redirect: indext2UH8RZdvzMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/guides/build-business-resilience/index.vue").then(m => m.default || m)
  },
  {
    name: indext2UH8RZdvzMeta?.name ?? "guides-build-business-resilience___uk",
    path: indext2UH8RZdvzMeta?.path ?? "/uk/guides/build-business-resilience",
    meta: indext2UH8RZdvzMeta || {},
    alias: indext2UH8RZdvzMeta?.alias || [],
    redirect: indext2UH8RZdvzMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/guides/build-business-resilience/index.vue").then(m => m.default || m)
  },
  {
    name: indext2UH8RZdvzMeta?.name ?? "guides-build-business-resilience___de",
    path: indext2UH8RZdvzMeta?.path ?? "/de/guides/build-business-resilience",
    meta: indext2UH8RZdvzMeta || {},
    alias: indext2UH8RZdvzMeta?.alias || [],
    redirect: indext2UH8RZdvzMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/guides/build-business-resilience/index.vue").then(m => m.default || m)
  },
  {
    name: indext2UH8RZdvzMeta?.name ?? "guides-build-business-resilience___mx",
    path: indext2UH8RZdvzMeta?.path ?? "/mx/guides/build-business-resilience",
    meta: indext2UH8RZdvzMeta || {},
    alias: indext2UH8RZdvzMeta?.alias || [],
    redirect: indext2UH8RZdvzMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/guides/build-business-resilience/index.vue").then(m => m.default || m)
  },
  {
    name: indext2UH8RZdvzMeta?.name ?? "guides-build-business-resilience___fr",
    path: indext2UH8RZdvzMeta?.path ?? "/fr/guides/build-business-resilience",
    meta: indext2UH8RZdvzMeta || {},
    alias: indext2UH8RZdvzMeta?.alias || [],
    redirect: indext2UH8RZdvzMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/guides/build-business-resilience/index.vue").then(m => m.default || m)
  },
  {
    name: indext2UH8RZdvzMeta?.name ?? "guides-build-business-resilience___fr-ca",
    path: indext2UH8RZdvzMeta?.path ?? "/fr-ca/guides/build-business-resilience",
    meta: indext2UH8RZdvzMeta || {},
    alias: indext2UH8RZdvzMeta?.alias || [],
    redirect: indext2UH8RZdvzMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/guides/build-business-resilience/index.vue").then(m => m.default || m)
  },
  {
    name: protect_45optimize_45assets0G1Hdz0J4yMeta?.name ?? "guides-build-business-resilience-protect-optimize-assets___en-US",
    path: protect_45optimize_45assets0G1Hdz0J4yMeta?.path ?? "/guides/build-business-resilience/protect-optimize-assets",
    meta: protect_45optimize_45assets0G1Hdz0J4yMeta || {},
    alias: protect_45optimize_45assets0G1Hdz0J4yMeta?.alias || [],
    redirect: protect_45optimize_45assets0G1Hdz0J4yMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/guides/build-business-resilience/protect-optimize-assets.vue").then(m => m.default || m)
  },
  {
    name: protect_45optimize_45assets0G1Hdz0J4yMeta?.name ?? "guides-build-business-resilience-protect-optimize-assets___ca",
    path: protect_45optimize_45assets0G1Hdz0J4yMeta?.path ?? "/ca/guides/build-business-resilience/protect-optimize-assets",
    meta: protect_45optimize_45assets0G1Hdz0J4yMeta || {},
    alias: protect_45optimize_45assets0G1Hdz0J4yMeta?.alias || [],
    redirect: protect_45optimize_45assets0G1Hdz0J4yMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/guides/build-business-resilience/protect-optimize-assets.vue").then(m => m.default || m)
  },
  {
    name: protect_45optimize_45assets0G1Hdz0J4yMeta?.name ?? "guides-build-business-resilience-protect-optimize-assets___nl",
    path: protect_45optimize_45assets0G1Hdz0J4yMeta?.path ?? "/nl/guides/build-business-resilience/protect-optimize-assets",
    meta: protect_45optimize_45assets0G1Hdz0J4yMeta || {},
    alias: protect_45optimize_45assets0G1Hdz0J4yMeta?.alias || [],
    redirect: protect_45optimize_45assets0G1Hdz0J4yMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/guides/build-business-resilience/protect-optimize-assets.vue").then(m => m.default || m)
  },
  {
    name: protect_45optimize_45assets0G1Hdz0J4yMeta?.name ?? "guides-build-business-resilience-protect-optimize-assets___uk",
    path: protect_45optimize_45assets0G1Hdz0J4yMeta?.path ?? "/uk/guides/build-business-resilience/protect-optimize-assets",
    meta: protect_45optimize_45assets0G1Hdz0J4yMeta || {},
    alias: protect_45optimize_45assets0G1Hdz0J4yMeta?.alias || [],
    redirect: protect_45optimize_45assets0G1Hdz0J4yMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/guides/build-business-resilience/protect-optimize-assets.vue").then(m => m.default || m)
  },
  {
    name: protect_45optimize_45assets0G1Hdz0J4yMeta?.name ?? "guides-build-business-resilience-protect-optimize-assets___de",
    path: protect_45optimize_45assets0G1Hdz0J4yMeta?.path ?? "/de/guides/build-business-resilience/protect-optimize-assets",
    meta: protect_45optimize_45assets0G1Hdz0J4yMeta || {},
    alias: protect_45optimize_45assets0G1Hdz0J4yMeta?.alias || [],
    redirect: protect_45optimize_45assets0G1Hdz0J4yMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/guides/build-business-resilience/protect-optimize-assets.vue").then(m => m.default || m)
  },
  {
    name: protect_45optimize_45assets0G1Hdz0J4yMeta?.name ?? "guides-build-business-resilience-protect-optimize-assets___mx",
    path: protect_45optimize_45assets0G1Hdz0J4yMeta?.path ?? "/mx/guides/build-business-resilience/protect-optimize-assets",
    meta: protect_45optimize_45assets0G1Hdz0J4yMeta || {},
    alias: protect_45optimize_45assets0G1Hdz0J4yMeta?.alias || [],
    redirect: protect_45optimize_45assets0G1Hdz0J4yMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/guides/build-business-resilience/protect-optimize-assets.vue").then(m => m.default || m)
  },
  {
    name: protect_45optimize_45assets0G1Hdz0J4yMeta?.name ?? "guides-build-business-resilience-protect-optimize-assets___fr",
    path: protect_45optimize_45assets0G1Hdz0J4yMeta?.path ?? "/fr/guides/build-business-resilience/protect-optimize-assets",
    meta: protect_45optimize_45assets0G1Hdz0J4yMeta || {},
    alias: protect_45optimize_45assets0G1Hdz0J4yMeta?.alias || [],
    redirect: protect_45optimize_45assets0G1Hdz0J4yMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/guides/build-business-resilience/protect-optimize-assets.vue").then(m => m.default || m)
  },
  {
    name: protect_45optimize_45assets0G1Hdz0J4yMeta?.name ?? "guides-build-business-resilience-protect-optimize-assets___fr-ca",
    path: protect_45optimize_45assets0G1Hdz0J4yMeta?.path ?? "/fr-ca/guides/build-business-resilience/protect-optimize-assets",
    meta: protect_45optimize_45assets0G1Hdz0J4yMeta || {},
    alias: protect_45optimize_45assets0G1Hdz0J4yMeta?.alias || [],
    redirect: protect_45optimize_45assets0G1Hdz0J4yMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/guides/build-business-resilience/protect-optimize-assets.vue").then(m => m.default || m)
  },
  {
    name: best_45dash_45camsUsXT017jROMeta?.name ?? "guides-fleet-safety-best-dash-cams___en-US",
    path: best_45dash_45camsUsXT017jROMeta?.path ?? "/guides/fleet-safety/best-dash-cams",
    meta: best_45dash_45camsUsXT017jROMeta || {},
    alias: best_45dash_45camsUsXT017jROMeta?.alias || [],
    redirect: best_45dash_45camsUsXT017jROMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/guides/fleet-safety/best-dash-cams.vue").then(m => m.default || m)
  },
  {
    name: best_45dash_45camsUsXT017jROMeta?.name ?? "guides-fleet-safety-best-dash-cams___ca",
    path: best_45dash_45camsUsXT017jROMeta?.path ?? "/ca/guides/fleet-safety/best-dash-cams",
    meta: best_45dash_45camsUsXT017jROMeta || {},
    alias: best_45dash_45camsUsXT017jROMeta?.alias || [],
    redirect: best_45dash_45camsUsXT017jROMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/guides/fleet-safety/best-dash-cams.vue").then(m => m.default || m)
  },
  {
    name: best_45dash_45camsUsXT017jROMeta?.name ?? "guides-fleet-safety-best-dash-cams___nl",
    path: best_45dash_45camsUsXT017jROMeta?.path ?? "/nl/guides/fleet-safety/best-dash-cams",
    meta: best_45dash_45camsUsXT017jROMeta || {},
    alias: best_45dash_45camsUsXT017jROMeta?.alias || [],
    redirect: best_45dash_45camsUsXT017jROMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/guides/fleet-safety/best-dash-cams.vue").then(m => m.default || m)
  },
  {
    name: best_45dash_45camsUsXT017jROMeta?.name ?? "guides-fleet-safety-best-dash-cams___uk",
    path: best_45dash_45camsUsXT017jROMeta?.path ?? "/uk/guides/fleet-safety/best-dash-cams",
    meta: best_45dash_45camsUsXT017jROMeta || {},
    alias: best_45dash_45camsUsXT017jROMeta?.alias || [],
    redirect: best_45dash_45camsUsXT017jROMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/guides/fleet-safety/best-dash-cams.vue").then(m => m.default || m)
  },
  {
    name: best_45dash_45camsUsXT017jROMeta?.name ?? "guides-fleet-safety-best-dash-cams___de",
    path: best_45dash_45camsUsXT017jROMeta?.path ?? "/de/guides/fleet-safety/best-dash-cams",
    meta: best_45dash_45camsUsXT017jROMeta || {},
    alias: best_45dash_45camsUsXT017jROMeta?.alias || [],
    redirect: best_45dash_45camsUsXT017jROMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/guides/fleet-safety/best-dash-cams.vue").then(m => m.default || m)
  },
  {
    name: best_45dash_45camsUsXT017jROMeta?.name ?? "guides-fleet-safety-best-dash-cams___mx",
    path: best_45dash_45camsUsXT017jROMeta?.path ?? "/mx/guides/fleet-safety/best-dash-cams",
    meta: best_45dash_45camsUsXT017jROMeta || {},
    alias: best_45dash_45camsUsXT017jROMeta?.alias || [],
    redirect: best_45dash_45camsUsXT017jROMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/guides/fleet-safety/best-dash-cams.vue").then(m => m.default || m)
  },
  {
    name: best_45dash_45camsUsXT017jROMeta?.name ?? "guides-fleet-safety-best-dash-cams___fr",
    path: best_45dash_45camsUsXT017jROMeta?.path ?? "/fr/guides/fleet-safety/best-dash-cams",
    meta: best_45dash_45camsUsXT017jROMeta || {},
    alias: best_45dash_45camsUsXT017jROMeta?.alias || [],
    redirect: best_45dash_45camsUsXT017jROMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/guides/fleet-safety/best-dash-cams.vue").then(m => m.default || m)
  },
  {
    name: best_45dash_45camsUsXT017jROMeta?.name ?? "guides-fleet-safety-best-dash-cams___fr-ca",
    path: best_45dash_45camsUsXT017jROMeta?.path ?? "/fr-ca/guides/fleet-safety/best-dash-cams",
    meta: best_45dash_45camsUsXT017jROMeta || {},
    alias: best_45dash_45camsUsXT017jROMeta?.alias || [],
    redirect: best_45dash_45camsUsXT017jROMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/guides/fleet-safety/best-dash-cams.vue").then(m => m.default || m)
  },
  {
    name: indexv6lGvDb5naMeta?.name ?? "guides-fleet-safety___en-US",
    path: indexv6lGvDb5naMeta?.path ?? "/guides/fleet-safety",
    meta: indexv6lGvDb5naMeta || {},
    alias: indexv6lGvDb5naMeta?.alias || [],
    redirect: indexv6lGvDb5naMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/guides/fleet-safety/index.vue").then(m => m.default || m)
  },
  {
    name: indexv6lGvDb5naMeta?.name ?? "guides-fleet-safety___ca",
    path: indexv6lGvDb5naMeta?.path ?? "/ca/guides/fleet-safety",
    meta: indexv6lGvDb5naMeta || {},
    alias: indexv6lGvDb5naMeta?.alias || [],
    redirect: indexv6lGvDb5naMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/guides/fleet-safety/index.vue").then(m => m.default || m)
  },
  {
    name: indexv6lGvDb5naMeta?.name ?? "guides-fleet-safety___nl",
    path: indexv6lGvDb5naMeta?.path ?? "/nl/guides/fleet-safety",
    meta: indexv6lGvDb5naMeta || {},
    alias: indexv6lGvDb5naMeta?.alias || [],
    redirect: indexv6lGvDb5naMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/guides/fleet-safety/index.vue").then(m => m.default || m)
  },
  {
    name: indexv6lGvDb5naMeta?.name ?? "guides-fleet-safety___uk",
    path: indexv6lGvDb5naMeta?.path ?? "/uk/guides/fleet-safety",
    meta: indexv6lGvDb5naMeta || {},
    alias: indexv6lGvDb5naMeta?.alias || [],
    redirect: indexv6lGvDb5naMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/guides/fleet-safety/index.vue").then(m => m.default || m)
  },
  {
    name: indexv6lGvDb5naMeta?.name ?? "guides-fleet-safety___de",
    path: indexv6lGvDb5naMeta?.path ?? "/de/guides/fleet-safety",
    meta: indexv6lGvDb5naMeta || {},
    alias: indexv6lGvDb5naMeta?.alias || [],
    redirect: indexv6lGvDb5naMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/guides/fleet-safety/index.vue").then(m => m.default || m)
  },
  {
    name: indexv6lGvDb5naMeta?.name ?? "guides-fleet-safety___mx",
    path: indexv6lGvDb5naMeta?.path ?? "/mx/guias/seguridad-flotas",
    meta: indexv6lGvDb5naMeta || {},
    alias: indexv6lGvDb5naMeta?.alias || [],
    redirect: indexv6lGvDb5naMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/guides/fleet-safety/index.vue").then(m => m.default || m)
  },
  {
    name: indexv6lGvDb5naMeta?.name ?? "guides-fleet-safety___fr",
    path: indexv6lGvDb5naMeta?.path ?? "/fr/guides/fleet-safety",
    meta: indexv6lGvDb5naMeta || {},
    alias: indexv6lGvDb5naMeta?.alias || [],
    redirect: indexv6lGvDb5naMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/guides/fleet-safety/index.vue").then(m => m.default || m)
  },
  {
    name: indexv6lGvDb5naMeta?.name ?? "guides-fleet-safety___fr-ca",
    path: indexv6lGvDb5naMeta?.path ?? "/fr-ca/guides/fleet-safety",
    meta: indexv6lGvDb5naMeta || {},
    alias: indexv6lGvDb5naMeta?.alias || [],
    redirect: indexv6lGvDb5naMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/guides/fleet-safety/index.vue").then(m => m.default || m)
  },
  {
    name: safety_45program0ZL7te0bIYMeta?.name ?? "guides-fleet-safety-safety-program___en-US",
    path: safety_45program0ZL7te0bIYMeta?.path ?? "/guides/fleet-safety/safety-program",
    meta: safety_45program0ZL7te0bIYMeta || {},
    alias: safety_45program0ZL7te0bIYMeta?.alias || [],
    redirect: safety_45program0ZL7te0bIYMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/guides/fleet-safety/safety-program.vue").then(m => m.default || m)
  },
  {
    name: safety_45program0ZL7te0bIYMeta?.name ?? "guides-fleet-safety-safety-program___ca",
    path: safety_45program0ZL7te0bIYMeta?.path ?? "/ca/guides/fleet-safety/safety-program",
    meta: safety_45program0ZL7te0bIYMeta || {},
    alias: safety_45program0ZL7te0bIYMeta?.alias || [],
    redirect: safety_45program0ZL7te0bIYMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/guides/fleet-safety/safety-program.vue").then(m => m.default || m)
  },
  {
    name: safety_45program0ZL7te0bIYMeta?.name ?? "guides-fleet-safety-safety-program___nl",
    path: safety_45program0ZL7te0bIYMeta?.path ?? "/nl/guides/fleet-safety/safety-program",
    meta: safety_45program0ZL7te0bIYMeta || {},
    alias: safety_45program0ZL7te0bIYMeta?.alias || [],
    redirect: safety_45program0ZL7te0bIYMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/guides/fleet-safety/safety-program.vue").then(m => m.default || m)
  },
  {
    name: safety_45program0ZL7te0bIYMeta?.name ?? "guides-fleet-safety-safety-program___uk",
    path: safety_45program0ZL7te0bIYMeta?.path ?? "/uk/guides/fleet-safety/safety-program",
    meta: safety_45program0ZL7te0bIYMeta || {},
    alias: safety_45program0ZL7te0bIYMeta?.alias || [],
    redirect: safety_45program0ZL7te0bIYMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/guides/fleet-safety/safety-program.vue").then(m => m.default || m)
  },
  {
    name: safety_45program0ZL7te0bIYMeta?.name ?? "guides-fleet-safety-safety-program___de",
    path: safety_45program0ZL7te0bIYMeta?.path ?? "/de/guides/fleet-safety/safety-program",
    meta: safety_45program0ZL7te0bIYMeta || {},
    alias: safety_45program0ZL7te0bIYMeta?.alias || [],
    redirect: safety_45program0ZL7te0bIYMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/guides/fleet-safety/safety-program.vue").then(m => m.default || m)
  },
  {
    name: safety_45program0ZL7te0bIYMeta?.name ?? "guides-fleet-safety-safety-program___mx",
    path: safety_45program0ZL7te0bIYMeta?.path ?? "/mx/guides/fleet-safety/safety-program",
    meta: safety_45program0ZL7te0bIYMeta || {},
    alias: safety_45program0ZL7te0bIYMeta?.alias || [],
    redirect: safety_45program0ZL7te0bIYMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/guides/fleet-safety/safety-program.vue").then(m => m.default || m)
  },
  {
    name: safety_45program0ZL7te0bIYMeta?.name ?? "guides-fleet-safety-safety-program___fr",
    path: safety_45program0ZL7te0bIYMeta?.path ?? "/fr/guides/fleet-safety/safety-program",
    meta: safety_45program0ZL7te0bIYMeta || {},
    alias: safety_45program0ZL7te0bIYMeta?.alias || [],
    redirect: safety_45program0ZL7te0bIYMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/guides/fleet-safety/safety-program.vue").then(m => m.default || m)
  },
  {
    name: safety_45program0ZL7te0bIYMeta?.name ?? "guides-fleet-safety-safety-program___fr-ca",
    path: safety_45program0ZL7te0bIYMeta?.path ?? "/fr-ca/guides/fleet-safety/safety-program",
    meta: safety_45program0ZL7te0bIYMeta || {},
    alias: safety_45program0ZL7te0bIYMeta?.alias || [],
    redirect: safety_45program0ZL7te0bIYMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/guides/fleet-safety/safety-program.vue").then(m => m.default || m)
  },
  {
    name: safety_45roiJguvdnPtUUMeta?.name ?? "guides-fleet-safety-safety-roi___en-US",
    path: safety_45roiJguvdnPtUUMeta?.path ?? "/guides/fleet-safety/safety-roi",
    meta: safety_45roiJguvdnPtUUMeta || {},
    alias: safety_45roiJguvdnPtUUMeta?.alias || [],
    redirect: safety_45roiJguvdnPtUUMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/guides/fleet-safety/safety-roi.vue").then(m => m.default || m)
  },
  {
    name: safety_45roiJguvdnPtUUMeta?.name ?? "guides-fleet-safety-safety-roi___ca",
    path: safety_45roiJguvdnPtUUMeta?.path ?? "/ca/guides/fleet-safety/safety-roi",
    meta: safety_45roiJguvdnPtUUMeta || {},
    alias: safety_45roiJguvdnPtUUMeta?.alias || [],
    redirect: safety_45roiJguvdnPtUUMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/guides/fleet-safety/safety-roi.vue").then(m => m.default || m)
  },
  {
    name: safety_45roiJguvdnPtUUMeta?.name ?? "guides-fleet-safety-safety-roi___nl",
    path: safety_45roiJguvdnPtUUMeta?.path ?? "/nl/guides/fleet-safety/safety-roi",
    meta: safety_45roiJguvdnPtUUMeta || {},
    alias: safety_45roiJguvdnPtUUMeta?.alias || [],
    redirect: safety_45roiJguvdnPtUUMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/guides/fleet-safety/safety-roi.vue").then(m => m.default || m)
  },
  {
    name: safety_45roiJguvdnPtUUMeta?.name ?? "guides-fleet-safety-safety-roi___uk",
    path: safety_45roiJguvdnPtUUMeta?.path ?? "/uk/guides/fleet-safety/safety-roi",
    meta: safety_45roiJguvdnPtUUMeta || {},
    alias: safety_45roiJguvdnPtUUMeta?.alias || [],
    redirect: safety_45roiJguvdnPtUUMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/guides/fleet-safety/safety-roi.vue").then(m => m.default || m)
  },
  {
    name: safety_45roiJguvdnPtUUMeta?.name ?? "guides-fleet-safety-safety-roi___de",
    path: safety_45roiJguvdnPtUUMeta?.path ?? "/de/guides/fleet-safety/safety-roi",
    meta: safety_45roiJguvdnPtUUMeta || {},
    alias: safety_45roiJguvdnPtUUMeta?.alias || [],
    redirect: safety_45roiJguvdnPtUUMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/guides/fleet-safety/safety-roi.vue").then(m => m.default || m)
  },
  {
    name: safety_45roiJguvdnPtUUMeta?.name ?? "guides-fleet-safety-safety-roi___mx",
    path: safety_45roiJguvdnPtUUMeta?.path ?? "/mx/guides/fleet-safety/safety-roi",
    meta: safety_45roiJguvdnPtUUMeta || {},
    alias: safety_45roiJguvdnPtUUMeta?.alias || [],
    redirect: safety_45roiJguvdnPtUUMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/guides/fleet-safety/safety-roi.vue").then(m => m.default || m)
  },
  {
    name: safety_45roiJguvdnPtUUMeta?.name ?? "guides-fleet-safety-safety-roi___fr",
    path: safety_45roiJguvdnPtUUMeta?.path ?? "/fr/guides/fleet-safety/safety-roi",
    meta: safety_45roiJguvdnPtUUMeta || {},
    alias: safety_45roiJguvdnPtUUMeta?.alias || [],
    redirect: safety_45roiJguvdnPtUUMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/guides/fleet-safety/safety-roi.vue").then(m => m.default || m)
  },
  {
    name: safety_45roiJguvdnPtUUMeta?.name ?? "guides-fleet-safety-safety-roi___fr-ca",
    path: safety_45roiJguvdnPtUUMeta?.path ?? "/fr-ca/guides/fleet-safety/safety-roi",
    meta: safety_45roiJguvdnPtUUMeta || {},
    alias: safety_45roiJguvdnPtUUMeta?.alias || [],
    redirect: safety_45roiJguvdnPtUUMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/guides/fleet-safety/safety-roi.vue").then(m => m.default || m)
  },
  {
    name: indexmx8gmFw4rpMeta?.name ?? "guides___en-US",
    path: indexmx8gmFw4rpMeta?.path ?? "/guides",
    meta: indexmx8gmFw4rpMeta || {},
    alias: indexmx8gmFw4rpMeta?.alias || [],
    redirect: indexmx8gmFw4rpMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/guides/index.vue").then(m => m.default || m)
  },
  {
    name: indexmx8gmFw4rpMeta?.name ?? "guides___ca",
    path: indexmx8gmFw4rpMeta?.path ?? "/ca/guides",
    meta: indexmx8gmFw4rpMeta || {},
    alias: indexmx8gmFw4rpMeta?.alias || [],
    redirect: indexmx8gmFw4rpMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/guides/index.vue").then(m => m.default || m)
  },
  {
    name: indexmx8gmFw4rpMeta?.name ?? "guides___nl",
    path: indexmx8gmFw4rpMeta?.path ?? "/nl/guides",
    meta: indexmx8gmFw4rpMeta || {},
    alias: indexmx8gmFw4rpMeta?.alias || [],
    redirect: indexmx8gmFw4rpMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/guides/index.vue").then(m => m.default || m)
  },
  {
    name: indexmx8gmFw4rpMeta?.name ?? "guides___uk",
    path: indexmx8gmFw4rpMeta?.path ?? "/uk/guides",
    meta: indexmx8gmFw4rpMeta || {},
    alias: indexmx8gmFw4rpMeta?.alias || [],
    redirect: indexmx8gmFw4rpMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/guides/index.vue").then(m => m.default || m)
  },
  {
    name: indexmx8gmFw4rpMeta?.name ?? "guides___de",
    path: indexmx8gmFw4rpMeta?.path ?? "/de/guides",
    meta: indexmx8gmFw4rpMeta || {},
    alias: indexmx8gmFw4rpMeta?.alias || [],
    redirect: indexmx8gmFw4rpMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/guides/index.vue").then(m => m.default || m)
  },
  {
    name: indexmx8gmFw4rpMeta?.name ?? "guides___mx",
    path: indexmx8gmFw4rpMeta?.path ?? "/mx/guides",
    meta: indexmx8gmFw4rpMeta || {},
    alias: indexmx8gmFw4rpMeta?.alias || [],
    redirect: indexmx8gmFw4rpMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/guides/index.vue").then(m => m.default || m)
  },
  {
    name: indexmx8gmFw4rpMeta?.name ?? "guides___fr",
    path: indexmx8gmFw4rpMeta?.path ?? "/fr/guides",
    meta: indexmx8gmFw4rpMeta || {},
    alias: indexmx8gmFw4rpMeta?.alias || [],
    redirect: indexmx8gmFw4rpMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/guides/index.vue").then(m => m.default || m)
  },
  {
    name: indexmx8gmFw4rpMeta?.name ?? "guides___fr-ca",
    path: indexmx8gmFw4rpMeta?.path ?? "/fr-ca/guides",
    meta: indexmx8gmFw4rpMeta || {},
    alias: indexmx8gmFw4rpMeta?.alias || [],
    redirect: indexmx8gmFw4rpMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/guides/index.vue").then(m => m.default || m)
  },
  {
    name: build_45for_45the_45futurevWvzK4ANyIMeta?.name ?? "guides-make-more-possible-build-for-the-future___en-US",
    path: build_45for_45the_45futurevWvzK4ANyIMeta?.path ?? "/guides/make-more-possible/build-for-the-future",
    meta: build_45for_45the_45futurevWvzK4ANyIMeta || {},
    alias: build_45for_45the_45futurevWvzK4ANyIMeta?.alias || [],
    redirect: build_45for_45the_45futurevWvzK4ANyIMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/guides/make-more-possible/build-for-the-future.vue").then(m => m.default || m)
  },
  {
    name: build_45for_45the_45futurevWvzK4ANyIMeta?.name ?? "guides-make-more-possible-build-for-the-future___ca",
    path: build_45for_45the_45futurevWvzK4ANyIMeta?.path ?? "/ca/guides/make-more-possible/build-for-the-future",
    meta: build_45for_45the_45futurevWvzK4ANyIMeta || {},
    alias: build_45for_45the_45futurevWvzK4ANyIMeta?.alias || [],
    redirect: build_45for_45the_45futurevWvzK4ANyIMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/guides/make-more-possible/build-for-the-future.vue").then(m => m.default || m)
  },
  {
    name: build_45for_45the_45futurevWvzK4ANyIMeta?.name ?? "guides-make-more-possible-build-for-the-future___nl",
    path: build_45for_45the_45futurevWvzK4ANyIMeta?.path ?? "/nl/guides/make-more-possible/build-for-the-future",
    meta: build_45for_45the_45futurevWvzK4ANyIMeta || {},
    alias: build_45for_45the_45futurevWvzK4ANyIMeta?.alias || [],
    redirect: build_45for_45the_45futurevWvzK4ANyIMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/guides/make-more-possible/build-for-the-future.vue").then(m => m.default || m)
  },
  {
    name: build_45for_45the_45futurevWvzK4ANyIMeta?.name ?? "guides-make-more-possible-build-for-the-future___uk",
    path: build_45for_45the_45futurevWvzK4ANyIMeta?.path ?? "/uk/guides/make-more-possible/build-for-the-future",
    meta: build_45for_45the_45futurevWvzK4ANyIMeta || {},
    alias: build_45for_45the_45futurevWvzK4ANyIMeta?.alias || [],
    redirect: build_45for_45the_45futurevWvzK4ANyIMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/guides/make-more-possible/build-for-the-future.vue").then(m => m.default || m)
  },
  {
    name: build_45for_45the_45futurevWvzK4ANyIMeta?.name ?? "guides-make-more-possible-build-for-the-future___de",
    path: build_45for_45the_45futurevWvzK4ANyIMeta?.path ?? "/de/guides/make-more-possible/build-for-the-future",
    meta: build_45for_45the_45futurevWvzK4ANyIMeta || {},
    alias: build_45for_45the_45futurevWvzK4ANyIMeta?.alias || [],
    redirect: build_45for_45the_45futurevWvzK4ANyIMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/guides/make-more-possible/build-for-the-future.vue").then(m => m.default || m)
  },
  {
    name: build_45for_45the_45futurevWvzK4ANyIMeta?.name ?? "guides-make-more-possible-build-for-the-future___mx",
    path: build_45for_45the_45futurevWvzK4ANyIMeta?.path ?? "/mx/guides/make-more-possible/build-for-the-future",
    meta: build_45for_45the_45futurevWvzK4ANyIMeta || {},
    alias: build_45for_45the_45futurevWvzK4ANyIMeta?.alias || [],
    redirect: build_45for_45the_45futurevWvzK4ANyIMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/guides/make-more-possible/build-for-the-future.vue").then(m => m.default || m)
  },
  {
    name: build_45for_45the_45futurevWvzK4ANyIMeta?.name ?? "guides-make-more-possible-build-for-the-future___fr",
    path: build_45for_45the_45futurevWvzK4ANyIMeta?.path ?? "/fr/guides/make-more-possible/build-for-the-future",
    meta: build_45for_45the_45futurevWvzK4ANyIMeta || {},
    alias: build_45for_45the_45futurevWvzK4ANyIMeta?.alias || [],
    redirect: build_45for_45the_45futurevWvzK4ANyIMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/guides/make-more-possible/build-for-the-future.vue").then(m => m.default || m)
  },
  {
    name: build_45for_45the_45futurevWvzK4ANyIMeta?.name ?? "guides-make-more-possible-build-for-the-future___fr-ca",
    path: build_45for_45the_45futurevWvzK4ANyIMeta?.path ?? "/fr-ca/guides/make-more-possible/build-for-the-future",
    meta: build_45for_45the_45futurevWvzK4ANyIMeta || {},
    alias: build_45for_45the_45futurevWvzK4ANyIMeta?.alias || [],
    redirect: build_45for_45the_45futurevWvzK4ANyIMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/guides/make-more-possible/build-for-the-future.vue").then(m => m.default || m)
  },
  {
    name: empower_45your_45teamsC5V8VNaSk6Meta?.name ?? "guides-make-more-possible-empower-your-teams___en-US",
    path: empower_45your_45teamsC5V8VNaSk6Meta?.path ?? "/guides/make-more-possible/empower-your-teams",
    meta: empower_45your_45teamsC5V8VNaSk6Meta || {},
    alias: empower_45your_45teamsC5V8VNaSk6Meta?.alias || [],
    redirect: empower_45your_45teamsC5V8VNaSk6Meta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/guides/make-more-possible/empower-your-teams.vue").then(m => m.default || m)
  },
  {
    name: empower_45your_45teamsC5V8VNaSk6Meta?.name ?? "guides-make-more-possible-empower-your-teams___ca",
    path: empower_45your_45teamsC5V8VNaSk6Meta?.path ?? "/ca/guides/make-more-possible/empower-your-teams",
    meta: empower_45your_45teamsC5V8VNaSk6Meta || {},
    alias: empower_45your_45teamsC5V8VNaSk6Meta?.alias || [],
    redirect: empower_45your_45teamsC5V8VNaSk6Meta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/guides/make-more-possible/empower-your-teams.vue").then(m => m.default || m)
  },
  {
    name: empower_45your_45teamsC5V8VNaSk6Meta?.name ?? "guides-make-more-possible-empower-your-teams___nl",
    path: empower_45your_45teamsC5V8VNaSk6Meta?.path ?? "/nl/guides/make-more-possible/empower-your-teams",
    meta: empower_45your_45teamsC5V8VNaSk6Meta || {},
    alias: empower_45your_45teamsC5V8VNaSk6Meta?.alias || [],
    redirect: empower_45your_45teamsC5V8VNaSk6Meta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/guides/make-more-possible/empower-your-teams.vue").then(m => m.default || m)
  },
  {
    name: empower_45your_45teamsC5V8VNaSk6Meta?.name ?? "guides-make-more-possible-empower-your-teams___uk",
    path: empower_45your_45teamsC5V8VNaSk6Meta?.path ?? "/uk/guides/make-more-possible/empower-your-teams",
    meta: empower_45your_45teamsC5V8VNaSk6Meta || {},
    alias: empower_45your_45teamsC5V8VNaSk6Meta?.alias || [],
    redirect: empower_45your_45teamsC5V8VNaSk6Meta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/guides/make-more-possible/empower-your-teams.vue").then(m => m.default || m)
  },
  {
    name: empower_45your_45teamsC5V8VNaSk6Meta?.name ?? "guides-make-more-possible-empower-your-teams___de",
    path: empower_45your_45teamsC5V8VNaSk6Meta?.path ?? "/de/guides/make-more-possible/empower-your-teams",
    meta: empower_45your_45teamsC5V8VNaSk6Meta || {},
    alias: empower_45your_45teamsC5V8VNaSk6Meta?.alias || [],
    redirect: empower_45your_45teamsC5V8VNaSk6Meta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/guides/make-more-possible/empower-your-teams.vue").then(m => m.default || m)
  },
  {
    name: empower_45your_45teamsC5V8VNaSk6Meta?.name ?? "guides-make-more-possible-empower-your-teams___mx",
    path: empower_45your_45teamsC5V8VNaSk6Meta?.path ?? "/mx/guides/make-more-possible/empower-your-teams",
    meta: empower_45your_45teamsC5V8VNaSk6Meta || {},
    alias: empower_45your_45teamsC5V8VNaSk6Meta?.alias || [],
    redirect: empower_45your_45teamsC5V8VNaSk6Meta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/guides/make-more-possible/empower-your-teams.vue").then(m => m.default || m)
  },
  {
    name: empower_45your_45teamsC5V8VNaSk6Meta?.name ?? "guides-make-more-possible-empower-your-teams___fr",
    path: empower_45your_45teamsC5V8VNaSk6Meta?.path ?? "/fr/guides/make-more-possible/empower-your-teams",
    meta: empower_45your_45teamsC5V8VNaSk6Meta || {},
    alias: empower_45your_45teamsC5V8VNaSk6Meta?.alias || [],
    redirect: empower_45your_45teamsC5V8VNaSk6Meta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/guides/make-more-possible/empower-your-teams.vue").then(m => m.default || m)
  },
  {
    name: empower_45your_45teamsC5V8VNaSk6Meta?.name ?? "guides-make-more-possible-empower-your-teams___fr-ca",
    path: empower_45your_45teamsC5V8VNaSk6Meta?.path ?? "/fr-ca/guides/make-more-possible/empower-your-teams",
    meta: empower_45your_45teamsC5V8VNaSk6Meta || {},
    alias: empower_45your_45teamsC5V8VNaSk6Meta?.alias || [],
    redirect: empower_45your_45teamsC5V8VNaSk6Meta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/guides/make-more-possible/empower-your-teams.vue").then(m => m.default || m)
  },
  {
    name: indexJmwB27lHrhMeta?.name ?? "guides-make-more-possible___en-US",
    path: indexJmwB27lHrhMeta?.path ?? "/guides/make-more-possible",
    meta: indexJmwB27lHrhMeta || {},
    alias: indexJmwB27lHrhMeta?.alias || [],
    redirect: indexJmwB27lHrhMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/guides/make-more-possible/index.vue").then(m => m.default || m)
  },
  {
    name: indexJmwB27lHrhMeta?.name ?? "guides-make-more-possible___ca",
    path: indexJmwB27lHrhMeta?.path ?? "/ca/guides/make-more-possible",
    meta: indexJmwB27lHrhMeta || {},
    alias: indexJmwB27lHrhMeta?.alias || [],
    redirect: indexJmwB27lHrhMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/guides/make-more-possible/index.vue").then(m => m.default || m)
  },
  {
    name: indexJmwB27lHrhMeta?.name ?? "guides-make-more-possible___nl",
    path: indexJmwB27lHrhMeta?.path ?? "/nl/guides/make-more-possible",
    meta: indexJmwB27lHrhMeta || {},
    alias: indexJmwB27lHrhMeta?.alias || [],
    redirect: indexJmwB27lHrhMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/guides/make-more-possible/index.vue").then(m => m.default || m)
  },
  {
    name: indexJmwB27lHrhMeta?.name ?? "guides-make-more-possible___uk",
    path: indexJmwB27lHrhMeta?.path ?? "/uk/guides/make-more-possible",
    meta: indexJmwB27lHrhMeta || {},
    alias: indexJmwB27lHrhMeta?.alias || [],
    redirect: indexJmwB27lHrhMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/guides/make-more-possible/index.vue").then(m => m.default || m)
  },
  {
    name: indexJmwB27lHrhMeta?.name ?? "guides-make-more-possible___de",
    path: indexJmwB27lHrhMeta?.path ?? "/de/guides/make-more-possible",
    meta: indexJmwB27lHrhMeta || {},
    alias: indexJmwB27lHrhMeta?.alias || [],
    redirect: indexJmwB27lHrhMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/guides/make-more-possible/index.vue").then(m => m.default || m)
  },
  {
    name: indexJmwB27lHrhMeta?.name ?? "guides-make-more-possible___mx",
    path: indexJmwB27lHrhMeta?.path ?? "/mx/guides/make-more-possible",
    meta: indexJmwB27lHrhMeta || {},
    alias: indexJmwB27lHrhMeta?.alias || [],
    redirect: indexJmwB27lHrhMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/guides/make-more-possible/index.vue").then(m => m.default || m)
  },
  {
    name: indexJmwB27lHrhMeta?.name ?? "guides-make-more-possible___fr",
    path: indexJmwB27lHrhMeta?.path ?? "/fr/guides/make-more-possible",
    meta: indexJmwB27lHrhMeta || {},
    alias: indexJmwB27lHrhMeta?.alias || [],
    redirect: indexJmwB27lHrhMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/guides/make-more-possible/index.vue").then(m => m.default || m)
  },
  {
    name: indexJmwB27lHrhMeta?.name ?? "guides-make-more-possible___fr-ca",
    path: indexJmwB27lHrhMeta?.path ?? "/fr-ca/guides/make-more-possible",
    meta: indexJmwB27lHrhMeta || {},
    alias: indexJmwB27lHrhMeta?.alias || [],
    redirect: indexJmwB27lHrhMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/guides/make-more-possible/index.vue").then(m => m.default || m)
  },
  {
    name: take_45action_45with_45dataHpUu3sJPgUMeta?.name ?? "guides-make-more-possible-take-action-with-data___en-US",
    path: take_45action_45with_45dataHpUu3sJPgUMeta?.path ?? "/guides/make-more-possible/take-action-with-data",
    meta: take_45action_45with_45dataHpUu3sJPgUMeta || {},
    alias: take_45action_45with_45dataHpUu3sJPgUMeta?.alias || [],
    redirect: take_45action_45with_45dataHpUu3sJPgUMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/guides/make-more-possible/take-action-with-data.vue").then(m => m.default || m)
  },
  {
    name: take_45action_45with_45dataHpUu3sJPgUMeta?.name ?? "guides-make-more-possible-take-action-with-data___ca",
    path: take_45action_45with_45dataHpUu3sJPgUMeta?.path ?? "/ca/guides/make-more-possible/take-action-with-data",
    meta: take_45action_45with_45dataHpUu3sJPgUMeta || {},
    alias: take_45action_45with_45dataHpUu3sJPgUMeta?.alias || [],
    redirect: take_45action_45with_45dataHpUu3sJPgUMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/guides/make-more-possible/take-action-with-data.vue").then(m => m.default || m)
  },
  {
    name: take_45action_45with_45dataHpUu3sJPgUMeta?.name ?? "guides-make-more-possible-take-action-with-data___nl",
    path: take_45action_45with_45dataHpUu3sJPgUMeta?.path ?? "/nl/guides/make-more-possible/take-action-with-data",
    meta: take_45action_45with_45dataHpUu3sJPgUMeta || {},
    alias: take_45action_45with_45dataHpUu3sJPgUMeta?.alias || [],
    redirect: take_45action_45with_45dataHpUu3sJPgUMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/guides/make-more-possible/take-action-with-data.vue").then(m => m.default || m)
  },
  {
    name: take_45action_45with_45dataHpUu3sJPgUMeta?.name ?? "guides-make-more-possible-take-action-with-data___uk",
    path: take_45action_45with_45dataHpUu3sJPgUMeta?.path ?? "/uk/guides/make-more-possible/take-action-with-data",
    meta: take_45action_45with_45dataHpUu3sJPgUMeta || {},
    alias: take_45action_45with_45dataHpUu3sJPgUMeta?.alias || [],
    redirect: take_45action_45with_45dataHpUu3sJPgUMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/guides/make-more-possible/take-action-with-data.vue").then(m => m.default || m)
  },
  {
    name: take_45action_45with_45dataHpUu3sJPgUMeta?.name ?? "guides-make-more-possible-take-action-with-data___de",
    path: take_45action_45with_45dataHpUu3sJPgUMeta?.path ?? "/de/guides/make-more-possible/take-action-with-data",
    meta: take_45action_45with_45dataHpUu3sJPgUMeta || {},
    alias: take_45action_45with_45dataHpUu3sJPgUMeta?.alias || [],
    redirect: take_45action_45with_45dataHpUu3sJPgUMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/guides/make-more-possible/take-action-with-data.vue").then(m => m.default || m)
  },
  {
    name: take_45action_45with_45dataHpUu3sJPgUMeta?.name ?? "guides-make-more-possible-take-action-with-data___mx",
    path: take_45action_45with_45dataHpUu3sJPgUMeta?.path ?? "/mx/guides/make-more-possible/take-action-with-data",
    meta: take_45action_45with_45dataHpUu3sJPgUMeta || {},
    alias: take_45action_45with_45dataHpUu3sJPgUMeta?.alias || [],
    redirect: take_45action_45with_45dataHpUu3sJPgUMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/guides/make-more-possible/take-action-with-data.vue").then(m => m.default || m)
  },
  {
    name: take_45action_45with_45dataHpUu3sJPgUMeta?.name ?? "guides-make-more-possible-take-action-with-data___fr",
    path: take_45action_45with_45dataHpUu3sJPgUMeta?.path ?? "/fr/guides/make-more-possible/take-action-with-data",
    meta: take_45action_45with_45dataHpUu3sJPgUMeta || {},
    alias: take_45action_45with_45dataHpUu3sJPgUMeta?.alias || [],
    redirect: take_45action_45with_45dataHpUu3sJPgUMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/guides/make-more-possible/take-action-with-data.vue").then(m => m.default || m)
  },
  {
    name: take_45action_45with_45dataHpUu3sJPgUMeta?.name ?? "guides-make-more-possible-take-action-with-data___fr-ca",
    path: take_45action_45with_45dataHpUu3sJPgUMeta?.path ?? "/fr-ca/guides/make-more-possible/take-action-with-data",
    meta: take_45action_45with_45dataHpUu3sJPgUMeta || {},
    alias: take_45action_45with_45dataHpUu3sJPgUMeta?.alias || [],
    redirect: take_45action_45with_45dataHpUu3sJPgUMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/guides/make-more-possible/take-action-with-data.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93ordRFYWO5kMeta?.name ?? "guides-your-partner-for-the-road-ahead-slug___en-US",
    path: _91slug_93ordRFYWO5kMeta?.path ?? "/guides/your-partner-for-the-road-ahead/:slug()",
    meta: _91slug_93ordRFYWO5kMeta || {},
    alias: _91slug_93ordRFYWO5kMeta?.alias || [],
    redirect: _91slug_93ordRFYWO5kMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/guides/your-partner-for-the-road-ahead/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93ordRFYWO5kMeta?.name ?? "guides-your-partner-for-the-road-ahead-slug___ca",
    path: _91slug_93ordRFYWO5kMeta?.path ?? "/ca/guides/your-partner-for-the-road-ahead/:slug()",
    meta: _91slug_93ordRFYWO5kMeta || {},
    alias: _91slug_93ordRFYWO5kMeta?.alias || [],
    redirect: _91slug_93ordRFYWO5kMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/guides/your-partner-for-the-road-ahead/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93ordRFYWO5kMeta?.name ?? "guides-your-partner-for-the-road-ahead-slug___nl",
    path: _91slug_93ordRFYWO5kMeta?.path ?? "/nl/guides/your-partner-for-the-road-ahead/:slug()",
    meta: _91slug_93ordRFYWO5kMeta || {},
    alias: _91slug_93ordRFYWO5kMeta?.alias || [],
    redirect: _91slug_93ordRFYWO5kMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/guides/your-partner-for-the-road-ahead/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93ordRFYWO5kMeta?.name ?? "guides-your-partner-for-the-road-ahead-slug___uk",
    path: _91slug_93ordRFYWO5kMeta?.path ?? "/uk/guides/your-partner-for-the-road-ahead/:slug()",
    meta: _91slug_93ordRFYWO5kMeta || {},
    alias: _91slug_93ordRFYWO5kMeta?.alias || [],
    redirect: _91slug_93ordRFYWO5kMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/guides/your-partner-for-the-road-ahead/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93ordRFYWO5kMeta?.name ?? "guides-your-partner-for-the-road-ahead-slug___de",
    path: _91slug_93ordRFYWO5kMeta?.path ?? "/de/guides/your-partner-for-the-road-ahead/:slug()",
    meta: _91slug_93ordRFYWO5kMeta || {},
    alias: _91slug_93ordRFYWO5kMeta?.alias || [],
    redirect: _91slug_93ordRFYWO5kMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/guides/your-partner-for-the-road-ahead/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93ordRFYWO5kMeta?.name ?? "guides-your-partner-for-the-road-ahead-slug___mx",
    path: _91slug_93ordRFYWO5kMeta?.path ?? "/mx/guides/your-partner-for-the-road-ahead/:slug()",
    meta: _91slug_93ordRFYWO5kMeta || {},
    alias: _91slug_93ordRFYWO5kMeta?.alias || [],
    redirect: _91slug_93ordRFYWO5kMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/guides/your-partner-for-the-road-ahead/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93ordRFYWO5kMeta?.name ?? "guides-your-partner-for-the-road-ahead-slug___fr",
    path: _91slug_93ordRFYWO5kMeta?.path ?? "/fr/guides/your-partner-for-the-road-ahead/:slug()",
    meta: _91slug_93ordRFYWO5kMeta || {},
    alias: _91slug_93ordRFYWO5kMeta?.alias || [],
    redirect: _91slug_93ordRFYWO5kMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/guides/your-partner-for-the-road-ahead/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93ordRFYWO5kMeta?.name ?? "guides-your-partner-for-the-road-ahead-slug___fr-ca",
    path: _91slug_93ordRFYWO5kMeta?.path ?? "/fr-ca/guides/your-partner-for-the-road-ahead/:slug()",
    meta: _91slug_93ordRFYWO5kMeta || {},
    alias: _91slug_93ordRFYWO5kMeta?.alias || [],
    redirect: _91slug_93ordRFYWO5kMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/guides/your-partner-for-the-road-ahead/[slug].vue").then(m => m.default || m)
  },
  {
    name: index11TsaPYgOkMeta?.name ?? "guides-your-partner-for-the-road-ahead___en-US",
    path: index11TsaPYgOkMeta?.path ?? "/guides/your-partner-for-the-road-ahead",
    meta: index11TsaPYgOkMeta || {},
    alias: index11TsaPYgOkMeta?.alias || [],
    redirect: index11TsaPYgOkMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/guides/your-partner-for-the-road-ahead/index.vue").then(m => m.default || m)
  },
  {
    name: index11TsaPYgOkMeta?.name ?? "guides-your-partner-for-the-road-ahead___ca",
    path: index11TsaPYgOkMeta?.path ?? "/ca/guides/your-partner-for-the-road-ahead",
    meta: index11TsaPYgOkMeta || {},
    alias: index11TsaPYgOkMeta?.alias || [],
    redirect: index11TsaPYgOkMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/guides/your-partner-for-the-road-ahead/index.vue").then(m => m.default || m)
  },
  {
    name: index11TsaPYgOkMeta?.name ?? "guides-your-partner-for-the-road-ahead___nl",
    path: index11TsaPYgOkMeta?.path ?? "/nl/guides/your-partner-for-the-road-ahead",
    meta: index11TsaPYgOkMeta || {},
    alias: index11TsaPYgOkMeta?.alias || [],
    redirect: index11TsaPYgOkMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/guides/your-partner-for-the-road-ahead/index.vue").then(m => m.default || m)
  },
  {
    name: index11TsaPYgOkMeta?.name ?? "guides-your-partner-for-the-road-ahead___uk",
    path: index11TsaPYgOkMeta?.path ?? "/uk/guides/your-partner-for-the-road-ahead",
    meta: index11TsaPYgOkMeta || {},
    alias: index11TsaPYgOkMeta?.alias || [],
    redirect: index11TsaPYgOkMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/guides/your-partner-for-the-road-ahead/index.vue").then(m => m.default || m)
  },
  {
    name: index11TsaPYgOkMeta?.name ?? "guides-your-partner-for-the-road-ahead___de",
    path: index11TsaPYgOkMeta?.path ?? "/de/guides/your-partner-for-the-road-ahead",
    meta: index11TsaPYgOkMeta || {},
    alias: index11TsaPYgOkMeta?.alias || [],
    redirect: index11TsaPYgOkMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/guides/your-partner-for-the-road-ahead/index.vue").then(m => m.default || m)
  },
  {
    name: index11TsaPYgOkMeta?.name ?? "guides-your-partner-for-the-road-ahead___mx",
    path: index11TsaPYgOkMeta?.path ?? "/mx/guides/your-partner-for-the-road-ahead",
    meta: index11TsaPYgOkMeta || {},
    alias: index11TsaPYgOkMeta?.alias || [],
    redirect: index11TsaPYgOkMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/guides/your-partner-for-the-road-ahead/index.vue").then(m => m.default || m)
  },
  {
    name: index11TsaPYgOkMeta?.name ?? "guides-your-partner-for-the-road-ahead___fr",
    path: index11TsaPYgOkMeta?.path ?? "/fr/guides/your-partner-for-the-road-ahead",
    meta: index11TsaPYgOkMeta || {},
    alias: index11TsaPYgOkMeta?.alias || [],
    redirect: index11TsaPYgOkMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/guides/your-partner-for-the-road-ahead/index.vue").then(m => m.default || m)
  },
  {
    name: index11TsaPYgOkMeta?.name ?? "guides-your-partner-for-the-road-ahead___fr-ca",
    path: index11TsaPYgOkMeta?.path ?? "/fr-ca/guides/your-partner-for-the-road-ahead",
    meta: index11TsaPYgOkMeta || {},
    alias: index11TsaPYgOkMeta?.alias || [],
    redirect: index11TsaPYgOkMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/guides/your-partner-for-the-road-ahead/index.vue").then(m => m.default || m)
  },
  {
    name: indexHx8hrwmgpZMeta?.name ?? "index___en-US",
    path: indexHx8hrwmgpZMeta?.path ?? "/",
    meta: indexHx8hrwmgpZMeta || {},
    alias: indexHx8hrwmgpZMeta?.alias || [],
    redirect: indexHx8hrwmgpZMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexHx8hrwmgpZMeta?.name ?? "index___ca",
    path: indexHx8hrwmgpZMeta?.path ?? "/ca",
    meta: indexHx8hrwmgpZMeta || {},
    alias: indexHx8hrwmgpZMeta?.alias || [],
    redirect: indexHx8hrwmgpZMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexHx8hrwmgpZMeta?.name ?? "index___nl",
    path: indexHx8hrwmgpZMeta?.path ?? "/nl",
    meta: indexHx8hrwmgpZMeta || {},
    alias: indexHx8hrwmgpZMeta?.alias || [],
    redirect: indexHx8hrwmgpZMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexHx8hrwmgpZMeta?.name ?? "index___uk",
    path: indexHx8hrwmgpZMeta?.path ?? "/uk",
    meta: indexHx8hrwmgpZMeta || {},
    alias: indexHx8hrwmgpZMeta?.alias || [],
    redirect: indexHx8hrwmgpZMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexHx8hrwmgpZMeta?.name ?? "index___de",
    path: indexHx8hrwmgpZMeta?.path ?? "/de",
    meta: indexHx8hrwmgpZMeta || {},
    alias: indexHx8hrwmgpZMeta?.alias || [],
    redirect: indexHx8hrwmgpZMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexHx8hrwmgpZMeta?.name ?? "index___mx",
    path: indexHx8hrwmgpZMeta?.path ?? "/mx",
    meta: indexHx8hrwmgpZMeta || {},
    alias: indexHx8hrwmgpZMeta?.alias || [],
    redirect: indexHx8hrwmgpZMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexHx8hrwmgpZMeta?.name ?? "index___fr",
    path: indexHx8hrwmgpZMeta?.path ?? "/fr",
    meta: indexHx8hrwmgpZMeta || {},
    alias: indexHx8hrwmgpZMeta?.alias || [],
    redirect: indexHx8hrwmgpZMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexHx8hrwmgpZMeta?.name ?? "index___fr-ca",
    path: indexHx8hrwmgpZMeta?.path ?? "/fr-ca",
    meta: indexHx8hrwmgpZMeta || {},
    alias: indexHx8hrwmgpZMeta?.alias || [],
    redirect: indexHx8hrwmgpZMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/index.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93UviZ3mhhUIMeta?.name ?? "industrial-slug___en-US",
    path: _91slug_93UviZ3mhhUIMeta?.path ?? "/industrial/:slug()",
    meta: _91slug_93UviZ3mhhUIMeta || {},
    alias: _91slug_93UviZ3mhhUIMeta?.alias || [],
    redirect: _91slug_93UviZ3mhhUIMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/industrial/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93UviZ3mhhUIMeta?.name ?? "industrial-slug___ca",
    path: _91slug_93UviZ3mhhUIMeta?.path ?? "/ca/industrial/:slug()",
    meta: _91slug_93UviZ3mhhUIMeta || {},
    alias: _91slug_93UviZ3mhhUIMeta?.alias || [],
    redirect: _91slug_93UviZ3mhhUIMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/industrial/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93UviZ3mhhUIMeta?.name ?? "industrial-slug___nl",
    path: _91slug_93UviZ3mhhUIMeta?.path ?? "/nl/industrial/:slug()",
    meta: _91slug_93UviZ3mhhUIMeta || {},
    alias: _91slug_93UviZ3mhhUIMeta?.alias || [],
    redirect: _91slug_93UviZ3mhhUIMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/industrial/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93UviZ3mhhUIMeta?.name ?? "industrial-slug___uk",
    path: _91slug_93UviZ3mhhUIMeta?.path ?? "/uk/industrial/:slug()",
    meta: _91slug_93UviZ3mhhUIMeta || {},
    alias: _91slug_93UviZ3mhhUIMeta?.alias || [],
    redirect: _91slug_93UviZ3mhhUIMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/industrial/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93UviZ3mhhUIMeta?.name ?? "industrial-slug___de",
    path: _91slug_93UviZ3mhhUIMeta?.path ?? "/de/industrial/:slug()",
    meta: _91slug_93UviZ3mhhUIMeta || {},
    alias: _91slug_93UviZ3mhhUIMeta?.alias || [],
    redirect: _91slug_93UviZ3mhhUIMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/industrial/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93UviZ3mhhUIMeta?.name ?? "industrial-slug___mx",
    path: _91slug_93UviZ3mhhUIMeta?.path ?? "/mx/industrial/:slug()",
    meta: _91slug_93UviZ3mhhUIMeta || {},
    alias: _91slug_93UviZ3mhhUIMeta?.alias || [],
    redirect: _91slug_93UviZ3mhhUIMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/industrial/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93UviZ3mhhUIMeta?.name ?? "industrial-slug___fr",
    path: _91slug_93UviZ3mhhUIMeta?.path ?? "/fr/industrial/:slug()",
    meta: _91slug_93UviZ3mhhUIMeta || {},
    alias: _91slug_93UviZ3mhhUIMeta?.alias || [],
    redirect: _91slug_93UviZ3mhhUIMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/industrial/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93UviZ3mhhUIMeta?.name ?? "industrial-slug___fr-ca",
    path: _91slug_93UviZ3mhhUIMeta?.path ?? "/fr-ca/industrial/:slug()",
    meta: _91slug_93UviZ3mhhUIMeta || {},
    alias: _91slug_93UviZ3mhhUIMeta?.alias || [],
    redirect: _91slug_93UviZ3mhhUIMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/industrial/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93UeZsyUbHjMMeta?.name ?? "industries-slug___en-US",
    path: _91slug_93UeZsyUbHjMMeta?.path ?? "/industries/:slug()",
    meta: _91slug_93UeZsyUbHjMMeta || {},
    alias: _91slug_93UeZsyUbHjMMeta?.alias || [],
    redirect: _91slug_93UeZsyUbHjMMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/industries/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93UeZsyUbHjMMeta?.name ?? "industries-slug___ca",
    path: _91slug_93UeZsyUbHjMMeta?.path ?? "/ca/industries/:slug()",
    meta: _91slug_93UeZsyUbHjMMeta || {},
    alias: _91slug_93UeZsyUbHjMMeta?.alias || [],
    redirect: _91slug_93UeZsyUbHjMMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/industries/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93UeZsyUbHjMMeta?.name ?? "industries-slug___nl",
    path: _91slug_93UeZsyUbHjMMeta?.path ?? "/nl/industries/:slug()",
    meta: _91slug_93UeZsyUbHjMMeta || {},
    alias: _91slug_93UeZsyUbHjMMeta?.alias || [],
    redirect: _91slug_93UeZsyUbHjMMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/industries/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93UeZsyUbHjMMeta?.name ?? "industries-slug___uk",
    path: _91slug_93UeZsyUbHjMMeta?.path ?? "/uk/industries/:slug()",
    meta: _91slug_93UeZsyUbHjMMeta || {},
    alias: _91slug_93UeZsyUbHjMMeta?.alias || [],
    redirect: _91slug_93UeZsyUbHjMMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/industries/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93UeZsyUbHjMMeta?.name ?? "industries-slug___de",
    path: _91slug_93UeZsyUbHjMMeta?.path ?? "/de/industries/:slug()",
    meta: _91slug_93UeZsyUbHjMMeta || {},
    alias: _91slug_93UeZsyUbHjMMeta?.alias || [],
    redirect: _91slug_93UeZsyUbHjMMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/industries/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93UeZsyUbHjMMeta?.name ?? "industries-slug___mx",
    path: _91slug_93UeZsyUbHjMMeta?.path ?? "/mx/industries/:slug()",
    meta: _91slug_93UeZsyUbHjMMeta || {},
    alias: _91slug_93UeZsyUbHjMMeta?.alias || [],
    redirect: _91slug_93UeZsyUbHjMMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/industries/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93UeZsyUbHjMMeta?.name ?? "industries-slug___fr",
    path: _91slug_93UeZsyUbHjMMeta?.path ?? "/fr/industries/:slug()",
    meta: _91slug_93UeZsyUbHjMMeta || {},
    alias: _91slug_93UeZsyUbHjMMeta?.alias || [],
    redirect: _91slug_93UeZsyUbHjMMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/industries/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93UeZsyUbHjMMeta?.name ?? "industries-slug___fr-ca",
    path: _91slug_93UeZsyUbHjMMeta?.path ?? "/fr-ca/industries/:slug()",
    meta: _91slug_93UeZsyUbHjMMeta || {},
    alias: _91slug_93UeZsyUbHjMMeta?.alias || [],
    redirect: _91slug_93UeZsyUbHjMMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/industries/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexqB4dDSXBZmMeta?.name ?? "industries___en-US",
    path: indexqB4dDSXBZmMeta?.path ?? "/industries",
    meta: indexqB4dDSXBZmMeta || {},
    alias: indexqB4dDSXBZmMeta?.alias || [],
    redirect: indexqB4dDSXBZmMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/industries/index.vue").then(m => m.default || m)
  },
  {
    name: indexqB4dDSXBZmMeta?.name ?? "industries___ca",
    path: indexqB4dDSXBZmMeta?.path ?? "/ca/industries",
    meta: indexqB4dDSXBZmMeta || {},
    alias: indexqB4dDSXBZmMeta?.alias || [],
    redirect: indexqB4dDSXBZmMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/industries/index.vue").then(m => m.default || m)
  },
  {
    name: indexqB4dDSXBZmMeta?.name ?? "industries___nl",
    path: indexqB4dDSXBZmMeta?.path ?? "/nl/industries",
    meta: indexqB4dDSXBZmMeta || {},
    alias: indexqB4dDSXBZmMeta?.alias || [],
    redirect: indexqB4dDSXBZmMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/industries/index.vue").then(m => m.default || m)
  },
  {
    name: indexqB4dDSXBZmMeta?.name ?? "industries___uk",
    path: indexqB4dDSXBZmMeta?.path ?? "/uk/industries",
    meta: indexqB4dDSXBZmMeta || {},
    alias: indexqB4dDSXBZmMeta?.alias || [],
    redirect: indexqB4dDSXBZmMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/industries/index.vue").then(m => m.default || m)
  },
  {
    name: indexqB4dDSXBZmMeta?.name ?? "industries___de",
    path: indexqB4dDSXBZmMeta?.path ?? "/de/industries",
    meta: indexqB4dDSXBZmMeta || {},
    alias: indexqB4dDSXBZmMeta?.alias || [],
    redirect: indexqB4dDSXBZmMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/industries/index.vue").then(m => m.default || m)
  },
  {
    name: indexqB4dDSXBZmMeta?.name ?? "industries___mx",
    path: indexqB4dDSXBZmMeta?.path ?? "/mx/industries",
    meta: indexqB4dDSXBZmMeta || {},
    alias: indexqB4dDSXBZmMeta?.alias || [],
    redirect: indexqB4dDSXBZmMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/industries/index.vue").then(m => m.default || m)
  },
  {
    name: indexqB4dDSXBZmMeta?.name ?? "industries___fr",
    path: indexqB4dDSXBZmMeta?.path ?? "/fr/industries",
    meta: indexqB4dDSXBZmMeta || {},
    alias: indexqB4dDSXBZmMeta?.alias || [],
    redirect: indexqB4dDSXBZmMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/industries/index.vue").then(m => m.default || m)
  },
  {
    name: indexqB4dDSXBZmMeta?.name ?? "industries___fr-ca",
    path: indexqB4dDSXBZmMeta?.path ?? "/fr-ca/industries",
    meta: indexqB4dDSXBZmMeta || {},
    alias: indexqB4dDSXBZmMeta?.alias || [],
    redirect: indexqB4dDSXBZmMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/industries/index.vue").then(m => m.default || m)
  },
  {
    name: cities4voDpIQAMrMeta?.name ?? "insights-cities___en-US",
    path: cities4voDpIQAMrMeta?.path ?? "/insights/cities",
    meta: cities4voDpIQAMrMeta || {},
    alias: cities4voDpIQAMrMeta?.alias || [],
    redirect: cities4voDpIQAMrMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/insights/cities.vue").then(m => m.default || m)
  },
  {
    name: cities4voDpIQAMrMeta?.name ?? "insights-cities___ca",
    path: cities4voDpIQAMrMeta?.path ?? "/ca/insights/cities",
    meta: cities4voDpIQAMrMeta || {},
    alias: cities4voDpIQAMrMeta?.alias || [],
    redirect: cities4voDpIQAMrMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/insights/cities.vue").then(m => m.default || m)
  },
  {
    name: cities4voDpIQAMrMeta?.name ?? "insights-cities___nl",
    path: cities4voDpIQAMrMeta?.path ?? "/nl/insights/cities",
    meta: cities4voDpIQAMrMeta || {},
    alias: cities4voDpIQAMrMeta?.alias || [],
    redirect: cities4voDpIQAMrMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/insights/cities.vue").then(m => m.default || m)
  },
  {
    name: cities4voDpIQAMrMeta?.name ?? "insights-cities___uk",
    path: cities4voDpIQAMrMeta?.path ?? "/uk/insights/cities",
    meta: cities4voDpIQAMrMeta || {},
    alias: cities4voDpIQAMrMeta?.alias || [],
    redirect: cities4voDpIQAMrMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/insights/cities.vue").then(m => m.default || m)
  },
  {
    name: cities4voDpIQAMrMeta?.name ?? "insights-cities___de",
    path: cities4voDpIQAMrMeta?.path ?? "/de/insights/cities",
    meta: cities4voDpIQAMrMeta || {},
    alias: cities4voDpIQAMrMeta?.alias || [],
    redirect: cities4voDpIQAMrMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/insights/cities.vue").then(m => m.default || m)
  },
  {
    name: cities4voDpIQAMrMeta?.name ?? "insights-cities___mx",
    path: cities4voDpIQAMrMeta?.path ?? "/mx/insights/cities",
    meta: cities4voDpIQAMrMeta || {},
    alias: cities4voDpIQAMrMeta?.alias || [],
    redirect: cities4voDpIQAMrMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/insights/cities.vue").then(m => m.default || m)
  },
  {
    name: cities4voDpIQAMrMeta?.name ?? "insights-cities___fr",
    path: cities4voDpIQAMrMeta?.path ?? "/fr/insights/cities",
    meta: cities4voDpIQAMrMeta || {},
    alias: cities4voDpIQAMrMeta?.alias || [],
    redirect: cities4voDpIQAMrMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/insights/cities.vue").then(m => m.default || m)
  },
  {
    name: cities4voDpIQAMrMeta?.name ?? "insights-cities___fr-ca",
    path: cities4voDpIQAMrMeta?.path ?? "/fr-ca/insights/cities",
    meta: cities4voDpIQAMrMeta || {},
    alias: cities4voDpIQAMrMeta?.alias || [],
    redirect: cities4voDpIQAMrMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/insights/cities.vue").then(m => m.default || m)
  },
  {
    name: indexU7bnLEL2ohMeta?.name ?? "integrations___en-US",
    path: indexU7bnLEL2ohMeta?.path ?? "/integrations",
    meta: indexU7bnLEL2ohMeta || {},
    alias: indexU7bnLEL2ohMeta?.alias || [],
    redirect: indexU7bnLEL2ohMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/integrations/index.vue").then(m => m.default || m)
  },
  {
    name: indexU7bnLEL2ohMeta?.name ?? "integrations___ca",
    path: indexU7bnLEL2ohMeta?.path ?? "/ca/integrations",
    meta: indexU7bnLEL2ohMeta || {},
    alias: indexU7bnLEL2ohMeta?.alias || [],
    redirect: indexU7bnLEL2ohMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/integrations/index.vue").then(m => m.default || m)
  },
  {
    name: indexU7bnLEL2ohMeta?.name ?? "integrations___nl",
    path: indexU7bnLEL2ohMeta?.path ?? "/nl/integrations",
    meta: indexU7bnLEL2ohMeta || {},
    alias: indexU7bnLEL2ohMeta?.alias || [],
    redirect: indexU7bnLEL2ohMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/integrations/index.vue").then(m => m.default || m)
  },
  {
    name: indexU7bnLEL2ohMeta?.name ?? "integrations___uk",
    path: indexU7bnLEL2ohMeta?.path ?? "/uk/integrations",
    meta: indexU7bnLEL2ohMeta || {},
    alias: indexU7bnLEL2ohMeta?.alias || [],
    redirect: indexU7bnLEL2ohMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/integrations/index.vue").then(m => m.default || m)
  },
  {
    name: indexU7bnLEL2ohMeta?.name ?? "integrations___de",
    path: indexU7bnLEL2ohMeta?.path ?? "/de/integrations",
    meta: indexU7bnLEL2ohMeta || {},
    alias: indexU7bnLEL2ohMeta?.alias || [],
    redirect: indexU7bnLEL2ohMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/integrations/index.vue").then(m => m.default || m)
  },
  {
    name: indexU7bnLEL2ohMeta?.name ?? "integrations___mx",
    path: indexU7bnLEL2ohMeta?.path ?? "/mx/integrations",
    meta: indexU7bnLEL2ohMeta || {},
    alias: indexU7bnLEL2ohMeta?.alias || [],
    redirect: indexU7bnLEL2ohMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/integrations/index.vue").then(m => m.default || m)
  },
  {
    name: indexU7bnLEL2ohMeta?.name ?? "integrations___fr",
    path: indexU7bnLEL2ohMeta?.path ?? "/fr/integrations",
    meta: indexU7bnLEL2ohMeta || {},
    alias: indexU7bnLEL2ohMeta?.alias || [],
    redirect: indexU7bnLEL2ohMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/integrations/index.vue").then(m => m.default || m)
  },
  {
    name: indexU7bnLEL2ohMeta?.name ?? "integrations___fr-ca",
    path: indexU7bnLEL2ohMeta?.path ?? "/fr-ca/integrations",
    meta: indexU7bnLEL2ohMeta || {},
    alias: indexU7bnLEL2ohMeta?.alias || [],
    redirect: indexU7bnLEL2ohMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/integrations/index.vue").then(m => m.default || m)
  },
  {
    name: indexAh8TdxxglxMeta?.name ?? "invite___en-US",
    path: indexAh8TdxxglxMeta?.path ?? "/invite",
    meta: indexAh8TdxxglxMeta || {},
    alias: indexAh8TdxxglxMeta?.alias || [],
    redirect: indexAh8TdxxglxMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/invite/index.vue").then(m => m.default || m)
  },
  {
    name: indexAh8TdxxglxMeta?.name ?? "invite___ca",
    path: indexAh8TdxxglxMeta?.path ?? "/ca/invite",
    meta: indexAh8TdxxglxMeta || {},
    alias: indexAh8TdxxglxMeta?.alias || [],
    redirect: indexAh8TdxxglxMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/invite/index.vue").then(m => m.default || m)
  },
  {
    name: indexAh8TdxxglxMeta?.name ?? "invite___nl",
    path: indexAh8TdxxglxMeta?.path ?? "/nl/invite",
    meta: indexAh8TdxxglxMeta || {},
    alias: indexAh8TdxxglxMeta?.alias || [],
    redirect: indexAh8TdxxglxMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/invite/index.vue").then(m => m.default || m)
  },
  {
    name: indexAh8TdxxglxMeta?.name ?? "invite___uk",
    path: indexAh8TdxxglxMeta?.path ?? "/uk/invite",
    meta: indexAh8TdxxglxMeta || {},
    alias: indexAh8TdxxglxMeta?.alias || [],
    redirect: indexAh8TdxxglxMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/invite/index.vue").then(m => m.default || m)
  },
  {
    name: indexAh8TdxxglxMeta?.name ?? "invite___de",
    path: indexAh8TdxxglxMeta?.path ?? "/de/invite",
    meta: indexAh8TdxxglxMeta || {},
    alias: indexAh8TdxxglxMeta?.alias || [],
    redirect: indexAh8TdxxglxMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/invite/index.vue").then(m => m.default || m)
  },
  {
    name: indexAh8TdxxglxMeta?.name ?? "invite___mx",
    path: indexAh8TdxxglxMeta?.path ?? "/mx/invite",
    meta: indexAh8TdxxglxMeta || {},
    alias: indexAh8TdxxglxMeta?.alias || [],
    redirect: indexAh8TdxxglxMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/invite/index.vue").then(m => m.default || m)
  },
  {
    name: indexAh8TdxxglxMeta?.name ?? "invite___fr",
    path: indexAh8TdxxglxMeta?.path ?? "/fr/invite",
    meta: indexAh8TdxxglxMeta || {},
    alias: indexAh8TdxxglxMeta?.alias || [],
    redirect: indexAh8TdxxglxMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/invite/index.vue").then(m => m.default || m)
  },
  {
    name: indexAh8TdxxglxMeta?.name ?? "invite___fr-ca",
    path: indexAh8TdxxglxMeta?.path ?? "/fr-ca/invite",
    meta: indexAh8TdxxglxMeta || {},
    alias: indexAh8TdxxglxMeta?.alias || [],
    redirect: indexAh8TdxxglxMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/invite/index.vue").then(m => m.default || m)
  },
  {
    name: learn_45morexRmhwblBOXMeta?.name ?? "learn-more___en-US",
    path: learn_45morexRmhwblBOXMeta?.path ?? "/learn-more",
    meta: learn_45morexRmhwblBOXMeta || {},
    alias: learn_45morexRmhwblBOXMeta?.alias || [],
    redirect: learn_45morexRmhwblBOXMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/learn-more.vue").then(m => m.default || m)
  },
  {
    name: learn_45morexRmhwblBOXMeta?.name ?? "learn-more___ca",
    path: learn_45morexRmhwblBOXMeta?.path ?? "/ca/learn-more",
    meta: learn_45morexRmhwblBOXMeta || {},
    alias: learn_45morexRmhwblBOXMeta?.alias || [],
    redirect: learn_45morexRmhwblBOXMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/learn-more.vue").then(m => m.default || m)
  },
  {
    name: learn_45morexRmhwblBOXMeta?.name ?? "learn-more___nl",
    path: learn_45morexRmhwblBOXMeta?.path ?? "/nl/learn-more",
    meta: learn_45morexRmhwblBOXMeta || {},
    alias: learn_45morexRmhwblBOXMeta?.alias || [],
    redirect: learn_45morexRmhwblBOXMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/learn-more.vue").then(m => m.default || m)
  },
  {
    name: learn_45morexRmhwblBOXMeta?.name ?? "learn-more___uk",
    path: learn_45morexRmhwblBOXMeta?.path ?? "/uk/learn-more",
    meta: learn_45morexRmhwblBOXMeta || {},
    alias: learn_45morexRmhwblBOXMeta?.alias || [],
    redirect: learn_45morexRmhwblBOXMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/learn-more.vue").then(m => m.default || m)
  },
  {
    name: learn_45morexRmhwblBOXMeta?.name ?? "learn-more___de",
    path: learn_45morexRmhwblBOXMeta?.path ?? "/de/learn-more",
    meta: learn_45morexRmhwblBOXMeta || {},
    alias: learn_45morexRmhwblBOXMeta?.alias || [],
    redirect: learn_45morexRmhwblBOXMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/learn-more.vue").then(m => m.default || m)
  },
  {
    name: learn_45morexRmhwblBOXMeta?.name ?? "learn-more___mx",
    path: learn_45morexRmhwblBOXMeta?.path ?? "/mx/learn-more",
    meta: learn_45morexRmhwblBOXMeta || {},
    alias: learn_45morexRmhwblBOXMeta?.alias || [],
    redirect: learn_45morexRmhwblBOXMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/learn-more.vue").then(m => m.default || m)
  },
  {
    name: learn_45morexRmhwblBOXMeta?.name ?? "learn-more___fr",
    path: learn_45morexRmhwblBOXMeta?.path ?? "/fr/learn-more",
    meta: learn_45morexRmhwblBOXMeta || {},
    alias: learn_45morexRmhwblBOXMeta?.alias || [],
    redirect: learn_45morexRmhwblBOXMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/learn-more.vue").then(m => m.default || m)
  },
  {
    name: learn_45morexRmhwblBOXMeta?.name ?? "learn-more___fr-ca",
    path: learn_45morexRmhwblBOXMeta?.path ?? "/fr-ca/learn-more",
    meta: learn_45morexRmhwblBOXMeta || {},
    alias: learn_45morexRmhwblBOXMeta?.alias || [],
    redirect: learn_45morexRmhwblBOXMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/learn-more.vue").then(m => m.default || m)
  },
  {
    name: candidate_45privacy_45noticeH9iblHOkyBMeta?.name ?? "legal-candidate-privacy-notice___en-US",
    path: candidate_45privacy_45noticeH9iblHOkyBMeta?.path ?? "/legal/candidate-privacy-notice",
    meta: candidate_45privacy_45noticeH9iblHOkyBMeta || {},
    alias: candidate_45privacy_45noticeH9iblHOkyBMeta?.alias || [],
    redirect: candidate_45privacy_45noticeH9iblHOkyBMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/legal/candidate-privacy-notice.vue").then(m => m.default || m)
  },
  {
    name: candidate_45privacy_45noticeH9iblHOkyBMeta?.name ?? "legal-candidate-privacy-notice___ca",
    path: candidate_45privacy_45noticeH9iblHOkyBMeta?.path ?? "/ca/legal/candidate-privacy-notice",
    meta: candidate_45privacy_45noticeH9iblHOkyBMeta || {},
    alias: candidate_45privacy_45noticeH9iblHOkyBMeta?.alias || [],
    redirect: candidate_45privacy_45noticeH9iblHOkyBMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/legal/candidate-privacy-notice.vue").then(m => m.default || m)
  },
  {
    name: candidate_45privacy_45noticeH9iblHOkyBMeta?.name ?? "legal-candidate-privacy-notice___nl",
    path: candidate_45privacy_45noticeH9iblHOkyBMeta?.path ?? "/nl/legal/candidate-privacy-notice",
    meta: candidate_45privacy_45noticeH9iblHOkyBMeta || {},
    alias: candidate_45privacy_45noticeH9iblHOkyBMeta?.alias || [],
    redirect: candidate_45privacy_45noticeH9iblHOkyBMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/legal/candidate-privacy-notice.vue").then(m => m.default || m)
  },
  {
    name: candidate_45privacy_45noticeH9iblHOkyBMeta?.name ?? "legal-candidate-privacy-notice___uk",
    path: candidate_45privacy_45noticeH9iblHOkyBMeta?.path ?? "/uk/legal/candidate-privacy-notice",
    meta: candidate_45privacy_45noticeH9iblHOkyBMeta || {},
    alias: candidate_45privacy_45noticeH9iblHOkyBMeta?.alias || [],
    redirect: candidate_45privacy_45noticeH9iblHOkyBMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/legal/candidate-privacy-notice.vue").then(m => m.default || m)
  },
  {
    name: candidate_45privacy_45noticeH9iblHOkyBMeta?.name ?? "legal-candidate-privacy-notice___de",
    path: candidate_45privacy_45noticeH9iblHOkyBMeta?.path ?? "/de/legal/candidate-privacy-notice",
    meta: candidate_45privacy_45noticeH9iblHOkyBMeta || {},
    alias: candidate_45privacy_45noticeH9iblHOkyBMeta?.alias || [],
    redirect: candidate_45privacy_45noticeH9iblHOkyBMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/legal/candidate-privacy-notice.vue").then(m => m.default || m)
  },
  {
    name: candidate_45privacy_45noticeH9iblHOkyBMeta?.name ?? "legal-candidate-privacy-notice___mx",
    path: candidate_45privacy_45noticeH9iblHOkyBMeta?.path ?? "/mx/legal/candidate-privacy-notice",
    meta: candidate_45privacy_45noticeH9iblHOkyBMeta || {},
    alias: candidate_45privacy_45noticeH9iblHOkyBMeta?.alias || [],
    redirect: candidate_45privacy_45noticeH9iblHOkyBMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/legal/candidate-privacy-notice.vue").then(m => m.default || m)
  },
  {
    name: candidate_45privacy_45noticeH9iblHOkyBMeta?.name ?? "legal-candidate-privacy-notice___fr",
    path: candidate_45privacy_45noticeH9iblHOkyBMeta?.path ?? "/fr/legal/candidate-privacy-notice",
    meta: candidate_45privacy_45noticeH9iblHOkyBMeta || {},
    alias: candidate_45privacy_45noticeH9iblHOkyBMeta?.alias || [],
    redirect: candidate_45privacy_45noticeH9iblHOkyBMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/legal/candidate-privacy-notice.vue").then(m => m.default || m)
  },
  {
    name: candidate_45privacy_45noticeH9iblHOkyBMeta?.name ?? "legal-candidate-privacy-notice___fr-ca",
    path: candidate_45privacy_45noticeH9iblHOkyBMeta?.path ?? "/fr-ca/legal/candidate-privacy-notice",
    meta: candidate_45privacy_45noticeH9iblHOkyBMeta || {},
    alias: candidate_45privacy_45noticeH9iblHOkyBMeta?.alias || [],
    redirect: candidate_45privacy_45noticeH9iblHOkyBMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/legal/candidate-privacy-notice.vue").then(m => m.default || m)
  },
  {
    name: cookie_45consenttwPJgFWVPjMeta?.name ?? "legal-cookie-consent___en-US",
    path: cookie_45consenttwPJgFWVPjMeta?.path ?? "/legal/cookie-consent",
    meta: cookie_45consenttwPJgFWVPjMeta || {},
    alias: cookie_45consenttwPJgFWVPjMeta?.alias || [],
    redirect: cookie_45consenttwPJgFWVPjMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/legal/cookie-consent.vue").then(m => m.default || m)
  },
  {
    name: cookie_45consenttwPJgFWVPjMeta?.name ?? "legal-cookie-consent___ca",
    path: cookie_45consenttwPJgFWVPjMeta?.path ?? "/ca/legal/cookie-consent",
    meta: cookie_45consenttwPJgFWVPjMeta || {},
    alias: cookie_45consenttwPJgFWVPjMeta?.alias || [],
    redirect: cookie_45consenttwPJgFWVPjMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/legal/cookie-consent.vue").then(m => m.default || m)
  },
  {
    name: cookie_45consenttwPJgFWVPjMeta?.name ?? "legal-cookie-consent___nl",
    path: cookie_45consenttwPJgFWVPjMeta?.path ?? "/nl/legal/cookie-consent",
    meta: cookie_45consenttwPJgFWVPjMeta || {},
    alias: cookie_45consenttwPJgFWVPjMeta?.alias || [],
    redirect: cookie_45consenttwPJgFWVPjMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/legal/cookie-consent.vue").then(m => m.default || m)
  },
  {
    name: cookie_45consenttwPJgFWVPjMeta?.name ?? "legal-cookie-consent___uk",
    path: cookie_45consenttwPJgFWVPjMeta?.path ?? "/uk/legal/cookie-consent",
    meta: cookie_45consenttwPJgFWVPjMeta || {},
    alias: cookie_45consenttwPJgFWVPjMeta?.alias || [],
    redirect: cookie_45consenttwPJgFWVPjMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/legal/cookie-consent.vue").then(m => m.default || m)
  },
  {
    name: cookie_45consenttwPJgFWVPjMeta?.name ?? "legal-cookie-consent___de",
    path: cookie_45consenttwPJgFWVPjMeta?.path ?? "/de/legal/cookie-consent",
    meta: cookie_45consenttwPJgFWVPjMeta || {},
    alias: cookie_45consenttwPJgFWVPjMeta?.alias || [],
    redirect: cookie_45consenttwPJgFWVPjMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/legal/cookie-consent.vue").then(m => m.default || m)
  },
  {
    name: cookie_45consenttwPJgFWVPjMeta?.name ?? "legal-cookie-consent___mx",
    path: cookie_45consenttwPJgFWVPjMeta?.path ?? "/mx/legal/cookie-consent",
    meta: cookie_45consenttwPJgFWVPjMeta || {},
    alias: cookie_45consenttwPJgFWVPjMeta?.alias || [],
    redirect: cookie_45consenttwPJgFWVPjMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/legal/cookie-consent.vue").then(m => m.default || m)
  },
  {
    name: cookie_45consenttwPJgFWVPjMeta?.name ?? "legal-cookie-consent___fr",
    path: cookie_45consenttwPJgFWVPjMeta?.path ?? "/fr/legal/cookie-consent",
    meta: cookie_45consenttwPJgFWVPjMeta || {},
    alias: cookie_45consenttwPJgFWVPjMeta?.alias || [],
    redirect: cookie_45consenttwPJgFWVPjMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/legal/cookie-consent.vue").then(m => m.default || m)
  },
  {
    name: cookie_45consenttwPJgFWVPjMeta?.name ?? "legal-cookie-consent___fr-ca",
    path: cookie_45consenttwPJgFWVPjMeta?.path ?? "/fr-ca/legal/cookie-consent",
    meta: cookie_45consenttwPJgFWVPjMeta || {},
    alias: cookie_45consenttwPJgFWVPjMeta?.alias || [],
    redirect: cookie_45consenttwPJgFWVPjMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/legal/cookie-consent.vue").then(m => m.default || m)
  },
  {
    name: cookie_45policy7OU3xr3js7Meta?.name ?? "legal-cookie-policy___en-US",
    path: cookie_45policy7OU3xr3js7Meta?.path ?? "/legal/cookie-policy",
    meta: cookie_45policy7OU3xr3js7Meta || {},
    alias: cookie_45policy7OU3xr3js7Meta?.alias || [],
    redirect: cookie_45policy7OU3xr3js7Meta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/legal/cookie-policy.vue").then(m => m.default || m)
  },
  {
    name: cookie_45policy7OU3xr3js7Meta?.name ?? "legal-cookie-policy___ca",
    path: cookie_45policy7OU3xr3js7Meta?.path ?? "/ca/legal/cookie-policy",
    meta: cookie_45policy7OU3xr3js7Meta || {},
    alias: cookie_45policy7OU3xr3js7Meta?.alias || [],
    redirect: cookie_45policy7OU3xr3js7Meta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/legal/cookie-policy.vue").then(m => m.default || m)
  },
  {
    name: cookie_45policy7OU3xr3js7Meta?.name ?? "legal-cookie-policy___nl",
    path: cookie_45policy7OU3xr3js7Meta?.path ?? "/nl/legal/cookie-policy",
    meta: cookie_45policy7OU3xr3js7Meta || {},
    alias: cookie_45policy7OU3xr3js7Meta?.alias || [],
    redirect: cookie_45policy7OU3xr3js7Meta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/legal/cookie-policy.vue").then(m => m.default || m)
  },
  {
    name: cookie_45policy7OU3xr3js7Meta?.name ?? "legal-cookie-policy___uk",
    path: cookie_45policy7OU3xr3js7Meta?.path ?? "/uk/legal/cookie-policy",
    meta: cookie_45policy7OU3xr3js7Meta || {},
    alias: cookie_45policy7OU3xr3js7Meta?.alias || [],
    redirect: cookie_45policy7OU3xr3js7Meta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/legal/cookie-policy.vue").then(m => m.default || m)
  },
  {
    name: cookie_45policy7OU3xr3js7Meta?.name ?? "legal-cookie-policy___de",
    path: cookie_45policy7OU3xr3js7Meta?.path ?? "/de/legal/cookie-policy",
    meta: cookie_45policy7OU3xr3js7Meta || {},
    alias: cookie_45policy7OU3xr3js7Meta?.alias || [],
    redirect: cookie_45policy7OU3xr3js7Meta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/legal/cookie-policy.vue").then(m => m.default || m)
  },
  {
    name: cookie_45policy7OU3xr3js7Meta?.name ?? "legal-cookie-policy___mx",
    path: cookie_45policy7OU3xr3js7Meta?.path ?? "/mx/legal/cookie-policy",
    meta: cookie_45policy7OU3xr3js7Meta || {},
    alias: cookie_45policy7OU3xr3js7Meta?.alias || [],
    redirect: cookie_45policy7OU3xr3js7Meta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/legal/cookie-policy.vue").then(m => m.default || m)
  },
  {
    name: cookie_45policy7OU3xr3js7Meta?.name ?? "legal-cookie-policy___fr",
    path: cookie_45policy7OU3xr3js7Meta?.path ?? "/fr/legal/cookie-policy",
    meta: cookie_45policy7OU3xr3js7Meta || {},
    alias: cookie_45policy7OU3xr3js7Meta?.alias || [],
    redirect: cookie_45policy7OU3xr3js7Meta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/legal/cookie-policy.vue").then(m => m.default || m)
  },
  {
    name: cookie_45policy7OU3xr3js7Meta?.name ?? "legal-cookie-policy___fr-ca",
    path: cookie_45policy7OU3xr3js7Meta?.path ?? "/fr-ca/legal/cookie-policy",
    meta: cookie_45policy7OU3xr3js7Meta || {},
    alias: cookie_45policy7OU3xr3js7Meta?.alias || [],
    redirect: cookie_45policy7OU3xr3js7Meta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/legal/cookie-policy.vue").then(m => m.default || m)
  },
  {
    name: data_45protection_45addendumlqXAW4ZuGUMeta?.name ?? "legal-data-protection-addendum___en-US",
    path: data_45protection_45addendumlqXAW4ZuGUMeta?.path ?? "/legal/data-protection-addendum",
    meta: data_45protection_45addendumlqXAW4ZuGUMeta || {},
    alias: data_45protection_45addendumlqXAW4ZuGUMeta?.alias || [],
    redirect: data_45protection_45addendumlqXAW4ZuGUMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/legal/data-protection-addendum.vue").then(m => m.default || m)
  },
  {
    name: data_45protection_45addendumlqXAW4ZuGUMeta?.name ?? "legal-data-protection-addendum___ca",
    path: data_45protection_45addendumlqXAW4ZuGUMeta?.path ?? "/ca/legal/data-protection-addendum",
    meta: data_45protection_45addendumlqXAW4ZuGUMeta || {},
    alias: data_45protection_45addendumlqXAW4ZuGUMeta?.alias || [],
    redirect: data_45protection_45addendumlqXAW4ZuGUMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/legal/data-protection-addendum.vue").then(m => m.default || m)
  },
  {
    name: data_45protection_45addendumlqXAW4ZuGUMeta?.name ?? "legal-data-protection-addendum___nl",
    path: data_45protection_45addendumlqXAW4ZuGUMeta?.path ?? "/nl/legal/data-protection-addendum",
    meta: data_45protection_45addendumlqXAW4ZuGUMeta || {},
    alias: data_45protection_45addendumlqXAW4ZuGUMeta?.alias || [],
    redirect: data_45protection_45addendumlqXAW4ZuGUMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/legal/data-protection-addendum.vue").then(m => m.default || m)
  },
  {
    name: data_45protection_45addendumlqXAW4ZuGUMeta?.name ?? "legal-data-protection-addendum___uk",
    path: data_45protection_45addendumlqXAW4ZuGUMeta?.path ?? "/uk/legal/data-protection-addendum",
    meta: data_45protection_45addendumlqXAW4ZuGUMeta || {},
    alias: data_45protection_45addendumlqXAW4ZuGUMeta?.alias || [],
    redirect: data_45protection_45addendumlqXAW4ZuGUMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/legal/data-protection-addendum.vue").then(m => m.default || m)
  },
  {
    name: data_45protection_45addendumlqXAW4ZuGUMeta?.name ?? "legal-data-protection-addendum___de",
    path: data_45protection_45addendumlqXAW4ZuGUMeta?.path ?? "/de/legal/data-protection-addendum",
    meta: data_45protection_45addendumlqXAW4ZuGUMeta || {},
    alias: data_45protection_45addendumlqXAW4ZuGUMeta?.alias || [],
    redirect: data_45protection_45addendumlqXAW4ZuGUMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/legal/data-protection-addendum.vue").then(m => m.default || m)
  },
  {
    name: data_45protection_45addendumlqXAW4ZuGUMeta?.name ?? "legal-data-protection-addendum___mx",
    path: data_45protection_45addendumlqXAW4ZuGUMeta?.path ?? "/mx/legal/data-protection-addendum",
    meta: data_45protection_45addendumlqXAW4ZuGUMeta || {},
    alias: data_45protection_45addendumlqXAW4ZuGUMeta?.alias || [],
    redirect: data_45protection_45addendumlqXAW4ZuGUMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/legal/data-protection-addendum.vue").then(m => m.default || m)
  },
  {
    name: data_45protection_45addendumlqXAW4ZuGUMeta?.name ?? "legal-data-protection-addendum___fr",
    path: data_45protection_45addendumlqXAW4ZuGUMeta?.path ?? "/fr/legal/data-protection-addendum",
    meta: data_45protection_45addendumlqXAW4ZuGUMeta || {},
    alias: data_45protection_45addendumlqXAW4ZuGUMeta?.alias || [],
    redirect: data_45protection_45addendumlqXAW4ZuGUMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/legal/data-protection-addendum.vue").then(m => m.default || m)
  },
  {
    name: data_45protection_45addendumlqXAW4ZuGUMeta?.name ?? "legal-data-protection-addendum___fr-ca",
    path: data_45protection_45addendumlqXAW4ZuGUMeta?.path ?? "/fr-ca/legal/data-protection-addendum",
    meta: data_45protection_45addendumlqXAW4ZuGUMeta || {},
    alias: data_45protection_45addendumlqXAW4ZuGUMeta?.alias || [],
    redirect: data_45protection_45addendumlqXAW4ZuGUMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/legal/data-protection-addendum.vue").then(m => m.default || m)
  },
  {
    name: express_45order_45termsTBLzYSLi61Meta?.name ?? "legal-express-order-terms___en-US",
    path: express_45order_45termsTBLzYSLi61Meta?.path ?? "/legal/express-order-terms",
    meta: express_45order_45termsTBLzYSLi61Meta || {},
    alias: express_45order_45termsTBLzYSLi61Meta?.alias || [],
    redirect: express_45order_45termsTBLzYSLi61Meta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/legal/express-order-terms.vue").then(m => m.default || m)
  },
  {
    name: express_45order_45termsTBLzYSLi61Meta?.name ?? "legal-express-order-terms___ca",
    path: express_45order_45termsTBLzYSLi61Meta?.path ?? "/ca/legal/express-order-terms",
    meta: express_45order_45termsTBLzYSLi61Meta || {},
    alias: express_45order_45termsTBLzYSLi61Meta?.alias || [],
    redirect: express_45order_45termsTBLzYSLi61Meta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/legal/express-order-terms.vue").then(m => m.default || m)
  },
  {
    name: express_45order_45termsTBLzYSLi61Meta?.name ?? "legal-express-order-terms___nl",
    path: express_45order_45termsTBLzYSLi61Meta?.path ?? "/nl/legal/express-order-terms",
    meta: express_45order_45termsTBLzYSLi61Meta || {},
    alias: express_45order_45termsTBLzYSLi61Meta?.alias || [],
    redirect: express_45order_45termsTBLzYSLi61Meta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/legal/express-order-terms.vue").then(m => m.default || m)
  },
  {
    name: express_45order_45termsTBLzYSLi61Meta?.name ?? "legal-express-order-terms___uk",
    path: express_45order_45termsTBLzYSLi61Meta?.path ?? "/uk/legal/express-order-terms",
    meta: express_45order_45termsTBLzYSLi61Meta || {},
    alias: express_45order_45termsTBLzYSLi61Meta?.alias || [],
    redirect: express_45order_45termsTBLzYSLi61Meta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/legal/express-order-terms.vue").then(m => m.default || m)
  },
  {
    name: express_45order_45termsTBLzYSLi61Meta?.name ?? "legal-express-order-terms___de",
    path: express_45order_45termsTBLzYSLi61Meta?.path ?? "/de/legal/express-order-terms",
    meta: express_45order_45termsTBLzYSLi61Meta || {},
    alias: express_45order_45termsTBLzYSLi61Meta?.alias || [],
    redirect: express_45order_45termsTBLzYSLi61Meta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/legal/express-order-terms.vue").then(m => m.default || m)
  },
  {
    name: express_45order_45termsTBLzYSLi61Meta?.name ?? "legal-express-order-terms___mx",
    path: express_45order_45termsTBLzYSLi61Meta?.path ?? "/mx/legal/express-order-terms",
    meta: express_45order_45termsTBLzYSLi61Meta || {},
    alias: express_45order_45termsTBLzYSLi61Meta?.alias || [],
    redirect: express_45order_45termsTBLzYSLi61Meta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/legal/express-order-terms.vue").then(m => m.default || m)
  },
  {
    name: express_45order_45termsTBLzYSLi61Meta?.name ?? "legal-express-order-terms___fr",
    path: express_45order_45termsTBLzYSLi61Meta?.path ?? "/fr/legal/express-order-terms",
    meta: express_45order_45termsTBLzYSLi61Meta || {},
    alias: express_45order_45termsTBLzYSLi61Meta?.alias || [],
    redirect: express_45order_45termsTBLzYSLi61Meta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/legal/express-order-terms.vue").then(m => m.default || m)
  },
  {
    name: express_45order_45termsTBLzYSLi61Meta?.name ?? "legal-express-order-terms___fr-ca",
    path: express_45order_45termsTBLzYSLi61Meta?.path ?? "/fr-ca/legal/express-order-terms",
    meta: express_45order_45termsTBLzYSLi61Meta || {},
    alias: express_45order_45termsTBLzYSLi61Meta?.alias || [],
    redirect: express_45order_45termsTBLzYSLi61Meta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/legal/express-order-terms.vue").then(m => m.default || m)
  },
  {
    name: hardware_45warrantyruWm9bpXgTMeta?.name ?? "legal-hardware-warranty___en-US",
    path: hardware_45warrantyruWm9bpXgTMeta?.path ?? "/legal/hardware-warranty",
    meta: hardware_45warrantyruWm9bpXgTMeta || {},
    alias: hardware_45warrantyruWm9bpXgTMeta?.alias || [],
    redirect: hardware_45warrantyruWm9bpXgTMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/legal/hardware-warranty.vue").then(m => m.default || m)
  },
  {
    name: hardware_45warrantyruWm9bpXgTMeta?.name ?? "legal-hardware-warranty___ca",
    path: hardware_45warrantyruWm9bpXgTMeta?.path ?? "/ca/legal/hardware-warranty",
    meta: hardware_45warrantyruWm9bpXgTMeta || {},
    alias: hardware_45warrantyruWm9bpXgTMeta?.alias || [],
    redirect: hardware_45warrantyruWm9bpXgTMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/legal/hardware-warranty.vue").then(m => m.default || m)
  },
  {
    name: hardware_45warrantyruWm9bpXgTMeta?.name ?? "legal-hardware-warranty___nl",
    path: hardware_45warrantyruWm9bpXgTMeta?.path ?? "/nl/legal/hardware-warranty",
    meta: hardware_45warrantyruWm9bpXgTMeta || {},
    alias: hardware_45warrantyruWm9bpXgTMeta?.alias || [],
    redirect: hardware_45warrantyruWm9bpXgTMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/legal/hardware-warranty.vue").then(m => m.default || m)
  },
  {
    name: hardware_45warrantyruWm9bpXgTMeta?.name ?? "legal-hardware-warranty___uk",
    path: hardware_45warrantyruWm9bpXgTMeta?.path ?? "/uk/legal/hardware-warranty",
    meta: hardware_45warrantyruWm9bpXgTMeta || {},
    alias: hardware_45warrantyruWm9bpXgTMeta?.alias || [],
    redirect: hardware_45warrantyruWm9bpXgTMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/legal/hardware-warranty.vue").then(m => m.default || m)
  },
  {
    name: hardware_45warrantyruWm9bpXgTMeta?.name ?? "legal-hardware-warranty___de",
    path: hardware_45warrantyruWm9bpXgTMeta?.path ?? "/de/legal/hardware-warranty",
    meta: hardware_45warrantyruWm9bpXgTMeta || {},
    alias: hardware_45warrantyruWm9bpXgTMeta?.alias || [],
    redirect: hardware_45warrantyruWm9bpXgTMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/legal/hardware-warranty.vue").then(m => m.default || m)
  },
  {
    name: hardware_45warrantyruWm9bpXgTMeta?.name ?? "legal-hardware-warranty___mx",
    path: hardware_45warrantyruWm9bpXgTMeta?.path ?? "/mx/legal/hardware-warranty",
    meta: hardware_45warrantyruWm9bpXgTMeta || {},
    alias: hardware_45warrantyruWm9bpXgTMeta?.alias || [],
    redirect: hardware_45warrantyruWm9bpXgTMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/legal/hardware-warranty.vue").then(m => m.default || m)
  },
  {
    name: hardware_45warrantyruWm9bpXgTMeta?.name ?? "legal-hardware-warranty___fr",
    path: hardware_45warrantyruWm9bpXgTMeta?.path ?? "/fr/legal/hardware-warranty",
    meta: hardware_45warrantyruWm9bpXgTMeta || {},
    alias: hardware_45warrantyruWm9bpXgTMeta?.alias || [],
    redirect: hardware_45warrantyruWm9bpXgTMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/legal/hardware-warranty.vue").then(m => m.default || m)
  },
  {
    name: hardware_45warrantyruWm9bpXgTMeta?.name ?? "legal-hardware-warranty___fr-ca",
    path: hardware_45warrantyruWm9bpXgTMeta?.path ?? "/fr-ca/legal/hardware-warranty",
    meta: hardware_45warrantyruWm9bpXgTMeta || {},
    alias: hardware_45warrantyruWm9bpXgTMeta?.alias || [],
    redirect: hardware_45warrantyruWm9bpXgTMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/legal/hardware-warranty.vue").then(m => m.default || m)
  },
  {
    name: hosted_45software_45slauSTQRbT8LsMeta?.name ?? "legal-hosted-software-sla___en-US",
    path: hosted_45software_45slauSTQRbT8LsMeta?.path ?? "/legal/hosted-software-sla",
    meta: hosted_45software_45slauSTQRbT8LsMeta || {},
    alias: hosted_45software_45slauSTQRbT8LsMeta?.alias || [],
    redirect: hosted_45software_45slauSTQRbT8LsMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/legal/hosted-software-sla.vue").then(m => m.default || m)
  },
  {
    name: hosted_45software_45slauSTQRbT8LsMeta?.name ?? "legal-hosted-software-sla___ca",
    path: hosted_45software_45slauSTQRbT8LsMeta?.path ?? "/ca/legal/hosted-software-sla",
    meta: hosted_45software_45slauSTQRbT8LsMeta || {},
    alias: hosted_45software_45slauSTQRbT8LsMeta?.alias || [],
    redirect: hosted_45software_45slauSTQRbT8LsMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/legal/hosted-software-sla.vue").then(m => m.default || m)
  },
  {
    name: hosted_45software_45slauSTQRbT8LsMeta?.name ?? "legal-hosted-software-sla___nl",
    path: hosted_45software_45slauSTQRbT8LsMeta?.path ?? "/nl/legal/hosted-software-sla",
    meta: hosted_45software_45slauSTQRbT8LsMeta || {},
    alias: hosted_45software_45slauSTQRbT8LsMeta?.alias || [],
    redirect: hosted_45software_45slauSTQRbT8LsMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/legal/hosted-software-sla.vue").then(m => m.default || m)
  },
  {
    name: hosted_45software_45slauSTQRbT8LsMeta?.name ?? "legal-hosted-software-sla___uk",
    path: hosted_45software_45slauSTQRbT8LsMeta?.path ?? "/uk/legal/hosted-software-sla",
    meta: hosted_45software_45slauSTQRbT8LsMeta || {},
    alias: hosted_45software_45slauSTQRbT8LsMeta?.alias || [],
    redirect: hosted_45software_45slauSTQRbT8LsMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/legal/hosted-software-sla.vue").then(m => m.default || m)
  },
  {
    name: hosted_45software_45slauSTQRbT8LsMeta?.name ?? "legal-hosted-software-sla___de",
    path: hosted_45software_45slauSTQRbT8LsMeta?.path ?? "/de/legal/hosted-software-sla",
    meta: hosted_45software_45slauSTQRbT8LsMeta || {},
    alias: hosted_45software_45slauSTQRbT8LsMeta?.alias || [],
    redirect: hosted_45software_45slauSTQRbT8LsMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/legal/hosted-software-sla.vue").then(m => m.default || m)
  },
  {
    name: hosted_45software_45slauSTQRbT8LsMeta?.name ?? "legal-hosted-software-sla___mx",
    path: hosted_45software_45slauSTQRbT8LsMeta?.path ?? "/mx/legal/hosted-software-sla",
    meta: hosted_45software_45slauSTQRbT8LsMeta || {},
    alias: hosted_45software_45slauSTQRbT8LsMeta?.alias || [],
    redirect: hosted_45software_45slauSTQRbT8LsMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/legal/hosted-software-sla.vue").then(m => m.default || m)
  },
  {
    name: hosted_45software_45slauSTQRbT8LsMeta?.name ?? "legal-hosted-software-sla___fr",
    path: hosted_45software_45slauSTQRbT8LsMeta?.path ?? "/fr/legal/hosted-software-sla",
    meta: hosted_45software_45slauSTQRbT8LsMeta || {},
    alias: hosted_45software_45slauSTQRbT8LsMeta?.alias || [],
    redirect: hosted_45software_45slauSTQRbT8LsMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/legal/hosted-software-sla.vue").then(m => m.default || m)
  },
  {
    name: hosted_45software_45slauSTQRbT8LsMeta?.name ?? "legal-hosted-software-sla___fr-ca",
    path: hosted_45software_45slauSTQRbT8LsMeta?.path ?? "/fr-ca/legal/hosted-software-sla",
    meta: hosted_45software_45slauSTQRbT8LsMeta || {},
    alias: hosted_45software_45slauSTQRbT8LsMeta?.alias || [],
    redirect: hosted_45software_45slauSTQRbT8LsMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/legal/hosted-software-sla.vue").then(m => m.default || m)
  },
  {
    name: partner_45code_45of_45conductcjdmU1oEUCMeta?.name ?? "legal-partner-code-of-conduct___en-US",
    path: partner_45code_45of_45conductcjdmU1oEUCMeta?.path ?? "/legal/partner-code-of-conduct",
    meta: partner_45code_45of_45conductcjdmU1oEUCMeta || {},
    alias: partner_45code_45of_45conductcjdmU1oEUCMeta?.alias || [],
    redirect: partner_45code_45of_45conductcjdmU1oEUCMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/legal/partner-code-of-conduct.vue").then(m => m.default || m)
  },
  {
    name: partner_45code_45of_45conductcjdmU1oEUCMeta?.name ?? "legal-partner-code-of-conduct___ca",
    path: partner_45code_45of_45conductcjdmU1oEUCMeta?.path ?? "/ca/legal/partner-code-of-conduct",
    meta: partner_45code_45of_45conductcjdmU1oEUCMeta || {},
    alias: partner_45code_45of_45conductcjdmU1oEUCMeta?.alias || [],
    redirect: partner_45code_45of_45conductcjdmU1oEUCMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/legal/partner-code-of-conduct.vue").then(m => m.default || m)
  },
  {
    name: partner_45code_45of_45conductcjdmU1oEUCMeta?.name ?? "legal-partner-code-of-conduct___nl",
    path: partner_45code_45of_45conductcjdmU1oEUCMeta?.path ?? "/nl/legal/partner-code-of-conduct",
    meta: partner_45code_45of_45conductcjdmU1oEUCMeta || {},
    alias: partner_45code_45of_45conductcjdmU1oEUCMeta?.alias || [],
    redirect: partner_45code_45of_45conductcjdmU1oEUCMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/legal/partner-code-of-conduct.vue").then(m => m.default || m)
  },
  {
    name: partner_45code_45of_45conductcjdmU1oEUCMeta?.name ?? "legal-partner-code-of-conduct___uk",
    path: partner_45code_45of_45conductcjdmU1oEUCMeta?.path ?? "/uk/legal/partner-code-of-conduct",
    meta: partner_45code_45of_45conductcjdmU1oEUCMeta || {},
    alias: partner_45code_45of_45conductcjdmU1oEUCMeta?.alias || [],
    redirect: partner_45code_45of_45conductcjdmU1oEUCMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/legal/partner-code-of-conduct.vue").then(m => m.default || m)
  },
  {
    name: partner_45code_45of_45conductcjdmU1oEUCMeta?.name ?? "legal-partner-code-of-conduct___de",
    path: partner_45code_45of_45conductcjdmU1oEUCMeta?.path ?? "/de/legal/partner-code-of-conduct",
    meta: partner_45code_45of_45conductcjdmU1oEUCMeta || {},
    alias: partner_45code_45of_45conductcjdmU1oEUCMeta?.alias || [],
    redirect: partner_45code_45of_45conductcjdmU1oEUCMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/legal/partner-code-of-conduct.vue").then(m => m.default || m)
  },
  {
    name: partner_45code_45of_45conductcjdmU1oEUCMeta?.name ?? "legal-partner-code-of-conduct___mx",
    path: partner_45code_45of_45conductcjdmU1oEUCMeta?.path ?? "/mx/legal/partner-code-of-conduct",
    meta: partner_45code_45of_45conductcjdmU1oEUCMeta || {},
    alias: partner_45code_45of_45conductcjdmU1oEUCMeta?.alias || [],
    redirect: partner_45code_45of_45conductcjdmU1oEUCMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/legal/partner-code-of-conduct.vue").then(m => m.default || m)
  },
  {
    name: partner_45code_45of_45conductcjdmU1oEUCMeta?.name ?? "legal-partner-code-of-conduct___fr",
    path: partner_45code_45of_45conductcjdmU1oEUCMeta?.path ?? "/fr/legal/partner-code-of-conduct",
    meta: partner_45code_45of_45conductcjdmU1oEUCMeta || {},
    alias: partner_45code_45of_45conductcjdmU1oEUCMeta?.alias || [],
    redirect: partner_45code_45of_45conductcjdmU1oEUCMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/legal/partner-code-of-conduct.vue").then(m => m.default || m)
  },
  {
    name: partner_45code_45of_45conductcjdmU1oEUCMeta?.name ?? "legal-partner-code-of-conduct___fr-ca",
    path: partner_45code_45of_45conductcjdmU1oEUCMeta?.path ?? "/fr-ca/legal/partner-code-of-conduct",
    meta: partner_45code_45of_45conductcjdmU1oEUCMeta || {},
    alias: partner_45code_45of_45conductcjdmU1oEUCMeta?.alias || [],
    redirect: partner_45code_45of_45conductcjdmU1oEUCMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/legal/partner-code-of-conduct.vue").then(m => m.default || m)
  },
  {
    name: platform_45terms_45of_45serviceEfj4xYO50rMeta?.name ?? "legal-platform-terms-of-service___en-US",
    path: platform_45terms_45of_45serviceEfj4xYO50rMeta?.path ?? "/legal/platform-terms-of-service",
    meta: platform_45terms_45of_45serviceEfj4xYO50rMeta || {},
    alias: platform_45terms_45of_45serviceEfj4xYO50rMeta?.alias || [],
    redirect: platform_45terms_45of_45serviceEfj4xYO50rMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/legal/platform-terms-of-service.vue").then(m => m.default || m)
  },
  {
    name: platform_45terms_45of_45serviceEfj4xYO50rMeta?.name ?? "legal-platform-terms-of-service___ca",
    path: platform_45terms_45of_45serviceEfj4xYO50rMeta?.path ?? "/ca/legal/platform-terms-of-service",
    meta: platform_45terms_45of_45serviceEfj4xYO50rMeta || {},
    alias: platform_45terms_45of_45serviceEfj4xYO50rMeta?.alias || [],
    redirect: platform_45terms_45of_45serviceEfj4xYO50rMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/legal/platform-terms-of-service.vue").then(m => m.default || m)
  },
  {
    name: platform_45terms_45of_45serviceEfj4xYO50rMeta?.name ?? "legal-platform-terms-of-service___nl",
    path: platform_45terms_45of_45serviceEfj4xYO50rMeta?.path ?? "/nl/legal/platform-terms-of-service",
    meta: platform_45terms_45of_45serviceEfj4xYO50rMeta || {},
    alias: platform_45terms_45of_45serviceEfj4xYO50rMeta?.alias || [],
    redirect: platform_45terms_45of_45serviceEfj4xYO50rMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/legal/platform-terms-of-service.vue").then(m => m.default || m)
  },
  {
    name: platform_45terms_45of_45serviceEfj4xYO50rMeta?.name ?? "legal-platform-terms-of-service___uk",
    path: platform_45terms_45of_45serviceEfj4xYO50rMeta?.path ?? "/uk/legal/platform-terms-of-service",
    meta: platform_45terms_45of_45serviceEfj4xYO50rMeta || {},
    alias: platform_45terms_45of_45serviceEfj4xYO50rMeta?.alias || [],
    redirect: platform_45terms_45of_45serviceEfj4xYO50rMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/legal/platform-terms-of-service.vue").then(m => m.default || m)
  },
  {
    name: platform_45terms_45of_45serviceEfj4xYO50rMeta?.name ?? "legal-platform-terms-of-service___de",
    path: platform_45terms_45of_45serviceEfj4xYO50rMeta?.path ?? "/de/legal/platform-terms-of-service",
    meta: platform_45terms_45of_45serviceEfj4xYO50rMeta || {},
    alias: platform_45terms_45of_45serviceEfj4xYO50rMeta?.alias || [],
    redirect: platform_45terms_45of_45serviceEfj4xYO50rMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/legal/platform-terms-of-service.vue").then(m => m.default || m)
  },
  {
    name: platform_45terms_45of_45serviceEfj4xYO50rMeta?.name ?? "legal-platform-terms-of-service___mx",
    path: platform_45terms_45of_45serviceEfj4xYO50rMeta?.path ?? "/mx/legal/platform-terms-of-service",
    meta: platform_45terms_45of_45serviceEfj4xYO50rMeta || {},
    alias: platform_45terms_45of_45serviceEfj4xYO50rMeta?.alias || [],
    redirect: platform_45terms_45of_45serviceEfj4xYO50rMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/legal/platform-terms-of-service.vue").then(m => m.default || m)
  },
  {
    name: platform_45terms_45of_45serviceEfj4xYO50rMeta?.name ?? "legal-platform-terms-of-service___fr",
    path: platform_45terms_45of_45serviceEfj4xYO50rMeta?.path ?? "/fr/legal/platform-terms-of-service",
    meta: platform_45terms_45of_45serviceEfj4xYO50rMeta || {},
    alias: platform_45terms_45of_45serviceEfj4xYO50rMeta?.alias || [],
    redirect: platform_45terms_45of_45serviceEfj4xYO50rMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/legal/platform-terms-of-service.vue").then(m => m.default || m)
  },
  {
    name: platform_45terms_45of_45serviceEfj4xYO50rMeta?.name ?? "legal-platform-terms-of-service___fr-ca",
    path: platform_45terms_45of_45serviceEfj4xYO50rMeta?.path ?? "/fr-ca/legal/platform-terms-of-service",
    meta: platform_45terms_45of_45serviceEfj4xYO50rMeta || {},
    alias: platform_45terms_45of_45serviceEfj4xYO50rMeta?.alias || [],
    redirect: platform_45terms_45of_45serviceEfj4xYO50rMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/legal/platform-terms-of-service.vue").then(m => m.default || m)
  },
  {
    name: privacy8wShXwBjmqMeta?.name ?? "legal-privacy___en-US",
    path: privacy8wShXwBjmqMeta?.path ?? "/legal/privacy",
    meta: privacy8wShXwBjmqMeta || {},
    alias: privacy8wShXwBjmqMeta?.alias || [],
    redirect: privacy8wShXwBjmqMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/legal/privacy.vue").then(m => m.default || m)
  },
  {
    name: privacy8wShXwBjmqMeta?.name ?? "legal-privacy___ca",
    path: privacy8wShXwBjmqMeta?.path ?? "/ca/legal/privacy",
    meta: privacy8wShXwBjmqMeta || {},
    alias: privacy8wShXwBjmqMeta?.alias || [],
    redirect: privacy8wShXwBjmqMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/legal/privacy.vue").then(m => m.default || m)
  },
  {
    name: privacy8wShXwBjmqMeta?.name ?? "legal-privacy___nl",
    path: privacy8wShXwBjmqMeta?.path ?? "/nl/legal/privacy",
    meta: privacy8wShXwBjmqMeta || {},
    alias: privacy8wShXwBjmqMeta?.alias || [],
    redirect: privacy8wShXwBjmqMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/legal/privacy.vue").then(m => m.default || m)
  },
  {
    name: privacy8wShXwBjmqMeta?.name ?? "legal-privacy___uk",
    path: privacy8wShXwBjmqMeta?.path ?? "/uk/legal/privacy",
    meta: privacy8wShXwBjmqMeta || {},
    alias: privacy8wShXwBjmqMeta?.alias || [],
    redirect: privacy8wShXwBjmqMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/legal/privacy.vue").then(m => m.default || m)
  },
  {
    name: privacy8wShXwBjmqMeta?.name ?? "legal-privacy___de",
    path: privacy8wShXwBjmqMeta?.path ?? "/de/legal/privacy",
    meta: privacy8wShXwBjmqMeta || {},
    alias: privacy8wShXwBjmqMeta?.alias || [],
    redirect: privacy8wShXwBjmqMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/legal/privacy.vue").then(m => m.default || m)
  },
  {
    name: privacy8wShXwBjmqMeta?.name ?? "legal-privacy___mx",
    path: privacy8wShXwBjmqMeta?.path ?? "/mx/legal/privacy",
    meta: privacy8wShXwBjmqMeta || {},
    alias: privacy8wShXwBjmqMeta?.alias || [],
    redirect: privacy8wShXwBjmqMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/legal/privacy.vue").then(m => m.default || m)
  },
  {
    name: privacy8wShXwBjmqMeta?.name ?? "legal-privacy___fr",
    path: privacy8wShXwBjmqMeta?.path ?? "/fr/legal/privacy",
    meta: privacy8wShXwBjmqMeta || {},
    alias: privacy8wShXwBjmqMeta?.alias || [],
    redirect: privacy8wShXwBjmqMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/legal/privacy.vue").then(m => m.default || m)
  },
  {
    name: privacy8wShXwBjmqMeta?.name ?? "legal-privacy___fr-ca",
    path: privacy8wShXwBjmqMeta?.path ?? "/fr-ca/legal/privacy",
    meta: privacy8wShXwBjmqMeta || {},
    alias: privacy8wShXwBjmqMeta?.alias || [],
    redirect: privacy8wShXwBjmqMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/legal/privacy.vue").then(m => m.default || m)
  },
  {
    name: public_45sector_45customers_45platform_45terms_45of_45servicegHO0LTGgzHMeta?.name ?? "legal-public-sector-customers-platform-terms-of-service___en-US",
    path: public_45sector_45customers_45platform_45terms_45of_45servicegHO0LTGgzHMeta?.path ?? "/legal/public-sector-customers-platform-terms-of-service",
    meta: public_45sector_45customers_45platform_45terms_45of_45servicegHO0LTGgzHMeta || {},
    alias: public_45sector_45customers_45platform_45terms_45of_45servicegHO0LTGgzHMeta?.alias || [],
    redirect: public_45sector_45customers_45platform_45terms_45of_45servicegHO0LTGgzHMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/legal/public-sector-customers-platform-terms-of-service.vue").then(m => m.default || m)
  },
  {
    name: public_45sector_45customers_45platform_45terms_45of_45servicegHO0LTGgzHMeta?.name ?? "legal-public-sector-customers-platform-terms-of-service___ca",
    path: public_45sector_45customers_45platform_45terms_45of_45servicegHO0LTGgzHMeta?.path ?? "/ca/legal/public-sector-customers-platform-terms-of-service",
    meta: public_45sector_45customers_45platform_45terms_45of_45servicegHO0LTGgzHMeta || {},
    alias: public_45sector_45customers_45platform_45terms_45of_45servicegHO0LTGgzHMeta?.alias || [],
    redirect: public_45sector_45customers_45platform_45terms_45of_45servicegHO0LTGgzHMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/legal/public-sector-customers-platform-terms-of-service.vue").then(m => m.default || m)
  },
  {
    name: public_45sector_45customers_45platform_45terms_45of_45servicegHO0LTGgzHMeta?.name ?? "legal-public-sector-customers-platform-terms-of-service___nl",
    path: public_45sector_45customers_45platform_45terms_45of_45servicegHO0LTGgzHMeta?.path ?? "/nl/legal/public-sector-customers-platform-terms-of-service",
    meta: public_45sector_45customers_45platform_45terms_45of_45servicegHO0LTGgzHMeta || {},
    alias: public_45sector_45customers_45platform_45terms_45of_45servicegHO0LTGgzHMeta?.alias || [],
    redirect: public_45sector_45customers_45platform_45terms_45of_45servicegHO0LTGgzHMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/legal/public-sector-customers-platform-terms-of-service.vue").then(m => m.default || m)
  },
  {
    name: public_45sector_45customers_45platform_45terms_45of_45servicegHO0LTGgzHMeta?.name ?? "legal-public-sector-customers-platform-terms-of-service___uk",
    path: public_45sector_45customers_45platform_45terms_45of_45servicegHO0LTGgzHMeta?.path ?? "/uk/legal/public-sector-customers-platform-terms-of-service",
    meta: public_45sector_45customers_45platform_45terms_45of_45servicegHO0LTGgzHMeta || {},
    alias: public_45sector_45customers_45platform_45terms_45of_45servicegHO0LTGgzHMeta?.alias || [],
    redirect: public_45sector_45customers_45platform_45terms_45of_45servicegHO0LTGgzHMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/legal/public-sector-customers-platform-terms-of-service.vue").then(m => m.default || m)
  },
  {
    name: public_45sector_45customers_45platform_45terms_45of_45servicegHO0LTGgzHMeta?.name ?? "legal-public-sector-customers-platform-terms-of-service___de",
    path: public_45sector_45customers_45platform_45terms_45of_45servicegHO0LTGgzHMeta?.path ?? "/de/legal/public-sector-customers-platform-terms-of-service",
    meta: public_45sector_45customers_45platform_45terms_45of_45servicegHO0LTGgzHMeta || {},
    alias: public_45sector_45customers_45platform_45terms_45of_45servicegHO0LTGgzHMeta?.alias || [],
    redirect: public_45sector_45customers_45platform_45terms_45of_45servicegHO0LTGgzHMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/legal/public-sector-customers-platform-terms-of-service.vue").then(m => m.default || m)
  },
  {
    name: public_45sector_45customers_45platform_45terms_45of_45servicegHO0LTGgzHMeta?.name ?? "legal-public-sector-customers-platform-terms-of-service___mx",
    path: public_45sector_45customers_45platform_45terms_45of_45servicegHO0LTGgzHMeta?.path ?? "/mx/legal/public-sector-customers-platform-terms-of-service",
    meta: public_45sector_45customers_45platform_45terms_45of_45servicegHO0LTGgzHMeta || {},
    alias: public_45sector_45customers_45platform_45terms_45of_45servicegHO0LTGgzHMeta?.alias || [],
    redirect: public_45sector_45customers_45platform_45terms_45of_45servicegHO0LTGgzHMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/legal/public-sector-customers-platform-terms-of-service.vue").then(m => m.default || m)
  },
  {
    name: public_45sector_45customers_45platform_45terms_45of_45servicegHO0LTGgzHMeta?.name ?? "legal-public-sector-customers-platform-terms-of-service___fr",
    path: public_45sector_45customers_45platform_45terms_45of_45servicegHO0LTGgzHMeta?.path ?? "/fr/legal/public-sector-customers-platform-terms-of-service",
    meta: public_45sector_45customers_45platform_45terms_45of_45servicegHO0LTGgzHMeta || {},
    alias: public_45sector_45customers_45platform_45terms_45of_45servicegHO0LTGgzHMeta?.alias || [],
    redirect: public_45sector_45customers_45platform_45terms_45of_45servicegHO0LTGgzHMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/legal/public-sector-customers-platform-terms-of-service.vue").then(m => m.default || m)
  },
  {
    name: public_45sector_45customers_45platform_45terms_45of_45servicegHO0LTGgzHMeta?.name ?? "legal-public-sector-customers-platform-terms-of-service___fr-ca",
    path: public_45sector_45customers_45platform_45terms_45of_45servicegHO0LTGgzHMeta?.path ?? "/fr-ca/legal/public-sector-customers-platform-terms-of-service",
    meta: public_45sector_45customers_45platform_45terms_45of_45servicegHO0LTGgzHMeta || {},
    alias: public_45sector_45customers_45platform_45terms_45of_45servicegHO0LTGgzHMeta?.alias || [],
    redirect: public_45sector_45customers_45platform_45terms_45of_45servicegHO0LTGgzHMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/legal/public-sector-customers-platform-terms-of-service.vue").then(m => m.default || m)
  },
  {
    name: requestingcustomerdata3jPS3TV1fsMeta?.name ?? "legal-requestingcustomerdata___en-US",
    path: requestingcustomerdata3jPS3TV1fsMeta?.path ?? "/legal/requestingcustomerdata",
    meta: requestingcustomerdata3jPS3TV1fsMeta || {},
    alias: requestingcustomerdata3jPS3TV1fsMeta?.alias || [],
    redirect: requestingcustomerdata3jPS3TV1fsMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/legal/requestingcustomerdata.vue").then(m => m.default || m)
  },
  {
    name: requestingcustomerdata3jPS3TV1fsMeta?.name ?? "legal-requestingcustomerdata___ca",
    path: requestingcustomerdata3jPS3TV1fsMeta?.path ?? "/ca/legal/requestingcustomerdata",
    meta: requestingcustomerdata3jPS3TV1fsMeta || {},
    alias: requestingcustomerdata3jPS3TV1fsMeta?.alias || [],
    redirect: requestingcustomerdata3jPS3TV1fsMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/legal/requestingcustomerdata.vue").then(m => m.default || m)
  },
  {
    name: requestingcustomerdata3jPS3TV1fsMeta?.name ?? "legal-requestingcustomerdata___nl",
    path: requestingcustomerdata3jPS3TV1fsMeta?.path ?? "/nl/legal/requestingcustomerdata",
    meta: requestingcustomerdata3jPS3TV1fsMeta || {},
    alias: requestingcustomerdata3jPS3TV1fsMeta?.alias || [],
    redirect: requestingcustomerdata3jPS3TV1fsMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/legal/requestingcustomerdata.vue").then(m => m.default || m)
  },
  {
    name: requestingcustomerdata3jPS3TV1fsMeta?.name ?? "legal-requestingcustomerdata___uk",
    path: requestingcustomerdata3jPS3TV1fsMeta?.path ?? "/uk/legal/requestingcustomerdata",
    meta: requestingcustomerdata3jPS3TV1fsMeta || {},
    alias: requestingcustomerdata3jPS3TV1fsMeta?.alias || [],
    redirect: requestingcustomerdata3jPS3TV1fsMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/legal/requestingcustomerdata.vue").then(m => m.default || m)
  },
  {
    name: requestingcustomerdata3jPS3TV1fsMeta?.name ?? "legal-requestingcustomerdata___de",
    path: requestingcustomerdata3jPS3TV1fsMeta?.path ?? "/de/legal/requestingcustomerdata",
    meta: requestingcustomerdata3jPS3TV1fsMeta || {},
    alias: requestingcustomerdata3jPS3TV1fsMeta?.alias || [],
    redirect: requestingcustomerdata3jPS3TV1fsMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/legal/requestingcustomerdata.vue").then(m => m.default || m)
  },
  {
    name: requestingcustomerdata3jPS3TV1fsMeta?.name ?? "legal-requestingcustomerdata___mx",
    path: requestingcustomerdata3jPS3TV1fsMeta?.path ?? "/mx/legal/requestingcustomerdata",
    meta: requestingcustomerdata3jPS3TV1fsMeta || {},
    alias: requestingcustomerdata3jPS3TV1fsMeta?.alias || [],
    redirect: requestingcustomerdata3jPS3TV1fsMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/legal/requestingcustomerdata.vue").then(m => m.default || m)
  },
  {
    name: requestingcustomerdata3jPS3TV1fsMeta?.name ?? "legal-requestingcustomerdata___fr",
    path: requestingcustomerdata3jPS3TV1fsMeta?.path ?? "/fr/legal/requestingcustomerdata",
    meta: requestingcustomerdata3jPS3TV1fsMeta || {},
    alias: requestingcustomerdata3jPS3TV1fsMeta?.alias || [],
    redirect: requestingcustomerdata3jPS3TV1fsMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/legal/requestingcustomerdata.vue").then(m => m.default || m)
  },
  {
    name: requestingcustomerdata3jPS3TV1fsMeta?.name ?? "legal-requestingcustomerdata___fr-ca",
    path: requestingcustomerdata3jPS3TV1fsMeta?.path ?? "/fr-ca/legal/requestingcustomerdata",
    meta: requestingcustomerdata3jPS3TV1fsMeta || {},
    alias: requestingcustomerdata3jPS3TV1fsMeta?.alias || [],
    redirect: requestingcustomerdata3jPS3TV1fsMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/legal/requestingcustomerdata.vue").then(m => m.default || m)
  },
  {
    name: securityivOpVI0QPpMeta?.name ?? "legal-security___en-US",
    path: securityivOpVI0QPpMeta?.path ?? "/legal/security",
    meta: securityivOpVI0QPpMeta || {},
    alias: securityivOpVI0QPpMeta?.alias || [],
    redirect: securityivOpVI0QPpMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/legal/security.vue").then(m => m.default || m)
  },
  {
    name: securityivOpVI0QPpMeta?.name ?? "legal-security___ca",
    path: securityivOpVI0QPpMeta?.path ?? "/ca/legal/security",
    meta: securityivOpVI0QPpMeta || {},
    alias: securityivOpVI0QPpMeta?.alias || [],
    redirect: securityivOpVI0QPpMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/legal/security.vue").then(m => m.default || m)
  },
  {
    name: securityivOpVI0QPpMeta?.name ?? "legal-security___nl",
    path: securityivOpVI0QPpMeta?.path ?? "/nl/legal/security",
    meta: securityivOpVI0QPpMeta || {},
    alias: securityivOpVI0QPpMeta?.alias || [],
    redirect: securityivOpVI0QPpMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/legal/security.vue").then(m => m.default || m)
  },
  {
    name: securityivOpVI0QPpMeta?.name ?? "legal-security___uk",
    path: securityivOpVI0QPpMeta?.path ?? "/uk/legal/security",
    meta: securityivOpVI0QPpMeta || {},
    alias: securityivOpVI0QPpMeta?.alias || [],
    redirect: securityivOpVI0QPpMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/legal/security.vue").then(m => m.default || m)
  },
  {
    name: securityivOpVI0QPpMeta?.name ?? "legal-security___de",
    path: securityivOpVI0QPpMeta?.path ?? "/de/legal/security",
    meta: securityivOpVI0QPpMeta || {},
    alias: securityivOpVI0QPpMeta?.alias || [],
    redirect: securityivOpVI0QPpMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/legal/security.vue").then(m => m.default || m)
  },
  {
    name: securityivOpVI0QPpMeta?.name ?? "legal-security___mx",
    path: securityivOpVI0QPpMeta?.path ?? "/mx/legal/security",
    meta: securityivOpVI0QPpMeta || {},
    alias: securityivOpVI0QPpMeta?.alias || [],
    redirect: securityivOpVI0QPpMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/legal/security.vue").then(m => m.default || m)
  },
  {
    name: securityivOpVI0QPpMeta?.name ?? "legal-security___fr",
    path: securityivOpVI0QPpMeta?.path ?? "/fr/legal/security",
    meta: securityivOpVI0QPpMeta || {},
    alias: securityivOpVI0QPpMeta?.alias || [],
    redirect: securityivOpVI0QPpMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/legal/security.vue").then(m => m.default || m)
  },
  {
    name: securityivOpVI0QPpMeta?.name ?? "legal-security___fr-ca",
    path: securityivOpVI0QPpMeta?.path ?? "/fr-ca/legal/security",
    meta: securityivOpVI0QPpMeta || {},
    alias: securityivOpVI0QPpMeta?.alias || [],
    redirect: securityivOpVI0QPpMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/legal/security.vue").then(m => m.default || m)
  },
  {
    name: transparencyreportizaaIFByKVMeta?.name ?? "legal-transparencyreport___en-US",
    path: transparencyreportizaaIFByKVMeta?.path ?? "/legal/transparencyreport",
    meta: transparencyreportizaaIFByKVMeta || {},
    alias: transparencyreportizaaIFByKVMeta?.alias || [],
    redirect: transparencyreportizaaIFByKVMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/legal/transparencyreport.vue").then(m => m.default || m)
  },
  {
    name: transparencyreportizaaIFByKVMeta?.name ?? "legal-transparencyreport___ca",
    path: transparencyreportizaaIFByKVMeta?.path ?? "/ca/legal/transparencyreport",
    meta: transparencyreportizaaIFByKVMeta || {},
    alias: transparencyreportizaaIFByKVMeta?.alias || [],
    redirect: transparencyreportizaaIFByKVMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/legal/transparencyreport.vue").then(m => m.default || m)
  },
  {
    name: transparencyreportizaaIFByKVMeta?.name ?? "legal-transparencyreport___nl",
    path: transparencyreportizaaIFByKVMeta?.path ?? "/nl/legal/transparencyreport",
    meta: transparencyreportizaaIFByKVMeta || {},
    alias: transparencyreportizaaIFByKVMeta?.alias || [],
    redirect: transparencyreportizaaIFByKVMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/legal/transparencyreport.vue").then(m => m.default || m)
  },
  {
    name: transparencyreportizaaIFByKVMeta?.name ?? "legal-transparencyreport___uk",
    path: transparencyreportizaaIFByKVMeta?.path ?? "/uk/legal/transparencyreport",
    meta: transparencyreportizaaIFByKVMeta || {},
    alias: transparencyreportizaaIFByKVMeta?.alias || [],
    redirect: transparencyreportizaaIFByKVMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/legal/transparencyreport.vue").then(m => m.default || m)
  },
  {
    name: transparencyreportizaaIFByKVMeta?.name ?? "legal-transparencyreport___de",
    path: transparencyreportizaaIFByKVMeta?.path ?? "/de/legal/transparencyreport",
    meta: transparencyreportizaaIFByKVMeta || {},
    alias: transparencyreportizaaIFByKVMeta?.alias || [],
    redirect: transparencyreportizaaIFByKVMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/legal/transparencyreport.vue").then(m => m.default || m)
  },
  {
    name: transparencyreportizaaIFByKVMeta?.name ?? "legal-transparencyreport___mx",
    path: transparencyreportizaaIFByKVMeta?.path ?? "/mx/legal/transparencyreport",
    meta: transparencyreportizaaIFByKVMeta || {},
    alias: transparencyreportizaaIFByKVMeta?.alias || [],
    redirect: transparencyreportizaaIFByKVMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/legal/transparencyreport.vue").then(m => m.default || m)
  },
  {
    name: transparencyreportizaaIFByKVMeta?.name ?? "legal-transparencyreport___fr",
    path: transparencyreportizaaIFByKVMeta?.path ?? "/fr/legal/transparencyreport",
    meta: transparencyreportizaaIFByKVMeta || {},
    alias: transparencyreportizaaIFByKVMeta?.alias || [],
    redirect: transparencyreportizaaIFByKVMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/legal/transparencyreport.vue").then(m => m.default || m)
  },
  {
    name: transparencyreportizaaIFByKVMeta?.name ?? "legal-transparencyreport___fr-ca",
    path: transparencyreportizaaIFByKVMeta?.path ?? "/fr-ca/legal/transparencyreport",
    meta: transparencyreportizaaIFByKVMeta || {},
    alias: transparencyreportizaaIFByKVMeta?.alias || [],
    redirect: transparencyreportizaaIFByKVMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/legal/transparencyreport.vue").then(m => m.default || m)
  },
  {
    name: website_45terms_45of_45servicem4FVSkEWtKMeta?.name ?? "legal-website-terms-of-service___en-US",
    path: website_45terms_45of_45servicem4FVSkEWtKMeta?.path ?? "/legal/website-terms-of-service",
    meta: website_45terms_45of_45servicem4FVSkEWtKMeta || {},
    alias: website_45terms_45of_45servicem4FVSkEWtKMeta?.alias || [],
    redirect: website_45terms_45of_45servicem4FVSkEWtKMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/legal/website-terms-of-service.vue").then(m => m.default || m)
  },
  {
    name: website_45terms_45of_45servicem4FVSkEWtKMeta?.name ?? "legal-website-terms-of-service___ca",
    path: website_45terms_45of_45servicem4FVSkEWtKMeta?.path ?? "/ca/legal/website-terms-of-service",
    meta: website_45terms_45of_45servicem4FVSkEWtKMeta || {},
    alias: website_45terms_45of_45servicem4FVSkEWtKMeta?.alias || [],
    redirect: website_45terms_45of_45servicem4FVSkEWtKMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/legal/website-terms-of-service.vue").then(m => m.default || m)
  },
  {
    name: website_45terms_45of_45servicem4FVSkEWtKMeta?.name ?? "legal-website-terms-of-service___nl",
    path: website_45terms_45of_45servicem4FVSkEWtKMeta?.path ?? "/nl/legal/website-terms-of-service",
    meta: website_45terms_45of_45servicem4FVSkEWtKMeta || {},
    alias: website_45terms_45of_45servicem4FVSkEWtKMeta?.alias || [],
    redirect: website_45terms_45of_45servicem4FVSkEWtKMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/legal/website-terms-of-service.vue").then(m => m.default || m)
  },
  {
    name: website_45terms_45of_45servicem4FVSkEWtKMeta?.name ?? "legal-website-terms-of-service___uk",
    path: website_45terms_45of_45servicem4FVSkEWtKMeta?.path ?? "/uk/legal/website-terms-of-service",
    meta: website_45terms_45of_45servicem4FVSkEWtKMeta || {},
    alias: website_45terms_45of_45servicem4FVSkEWtKMeta?.alias || [],
    redirect: website_45terms_45of_45servicem4FVSkEWtKMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/legal/website-terms-of-service.vue").then(m => m.default || m)
  },
  {
    name: website_45terms_45of_45servicem4FVSkEWtKMeta?.name ?? "legal-website-terms-of-service___de",
    path: website_45terms_45of_45servicem4FVSkEWtKMeta?.path ?? "/de/legal/website-terms-of-service",
    meta: website_45terms_45of_45servicem4FVSkEWtKMeta || {},
    alias: website_45terms_45of_45servicem4FVSkEWtKMeta?.alias || [],
    redirect: website_45terms_45of_45servicem4FVSkEWtKMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/legal/website-terms-of-service.vue").then(m => m.default || m)
  },
  {
    name: website_45terms_45of_45servicem4FVSkEWtKMeta?.name ?? "legal-website-terms-of-service___mx",
    path: website_45terms_45of_45servicem4FVSkEWtKMeta?.path ?? "/mx/legal/website-terms-of-service",
    meta: website_45terms_45of_45servicem4FVSkEWtKMeta || {},
    alias: website_45terms_45of_45servicem4FVSkEWtKMeta?.alias || [],
    redirect: website_45terms_45of_45servicem4FVSkEWtKMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/legal/website-terms-of-service.vue").then(m => m.default || m)
  },
  {
    name: website_45terms_45of_45servicem4FVSkEWtKMeta?.name ?? "legal-website-terms-of-service___fr",
    path: website_45terms_45of_45servicem4FVSkEWtKMeta?.path ?? "/fr/legal/website-terms-of-service",
    meta: website_45terms_45of_45servicem4FVSkEWtKMeta || {},
    alias: website_45terms_45of_45servicem4FVSkEWtKMeta?.alias || [],
    redirect: website_45terms_45of_45servicem4FVSkEWtKMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/legal/website-terms-of-service.vue").then(m => m.default || m)
  },
  {
    name: website_45terms_45of_45servicem4FVSkEWtKMeta?.name ?? "legal-website-terms-of-service___fr-ca",
    path: website_45terms_45of_45servicem4FVSkEWtKMeta?.path ?? "/fr-ca/legal/website-terms-of-service",
    meta: website_45terms_45of_45servicem4FVSkEWtKMeta || {},
    alias: website_45terms_45of_45servicem4FVSkEWtKMeta?.alias || [],
    redirect: website_45terms_45of_45servicem4FVSkEWtKMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/legal/website-terms-of-service.vue").then(m => m.default || m)
  },
  {
    name: your_45privacy_45choices4FGw6PXpR1Meta?.name ?? "legal-your-privacy-choices___en-US",
    path: your_45privacy_45choices4FGw6PXpR1Meta?.path ?? "/legal/your-privacy-choices",
    meta: your_45privacy_45choices4FGw6PXpR1Meta || {},
    alias: your_45privacy_45choices4FGw6PXpR1Meta?.alias || [],
    redirect: your_45privacy_45choices4FGw6PXpR1Meta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/legal/your-privacy-choices.vue").then(m => m.default || m)
  },
  {
    name: your_45privacy_45choices4FGw6PXpR1Meta?.name ?? "legal-your-privacy-choices___ca",
    path: your_45privacy_45choices4FGw6PXpR1Meta?.path ?? "/ca/legal/your-privacy-choices",
    meta: your_45privacy_45choices4FGw6PXpR1Meta || {},
    alias: your_45privacy_45choices4FGw6PXpR1Meta?.alias || [],
    redirect: your_45privacy_45choices4FGw6PXpR1Meta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/legal/your-privacy-choices.vue").then(m => m.default || m)
  },
  {
    name: your_45privacy_45choices4FGw6PXpR1Meta?.name ?? "legal-your-privacy-choices___nl",
    path: your_45privacy_45choices4FGw6PXpR1Meta?.path ?? "/nl/legal/your-privacy-choices",
    meta: your_45privacy_45choices4FGw6PXpR1Meta || {},
    alias: your_45privacy_45choices4FGw6PXpR1Meta?.alias || [],
    redirect: your_45privacy_45choices4FGw6PXpR1Meta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/legal/your-privacy-choices.vue").then(m => m.default || m)
  },
  {
    name: your_45privacy_45choices4FGw6PXpR1Meta?.name ?? "legal-your-privacy-choices___uk",
    path: your_45privacy_45choices4FGw6PXpR1Meta?.path ?? "/uk/legal/your-privacy-choices",
    meta: your_45privacy_45choices4FGw6PXpR1Meta || {},
    alias: your_45privacy_45choices4FGw6PXpR1Meta?.alias || [],
    redirect: your_45privacy_45choices4FGw6PXpR1Meta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/legal/your-privacy-choices.vue").then(m => m.default || m)
  },
  {
    name: your_45privacy_45choices4FGw6PXpR1Meta?.name ?? "legal-your-privacy-choices___de",
    path: your_45privacy_45choices4FGw6PXpR1Meta?.path ?? "/de/legal/your-privacy-choices",
    meta: your_45privacy_45choices4FGw6PXpR1Meta || {},
    alias: your_45privacy_45choices4FGw6PXpR1Meta?.alias || [],
    redirect: your_45privacy_45choices4FGw6PXpR1Meta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/legal/your-privacy-choices.vue").then(m => m.default || m)
  },
  {
    name: your_45privacy_45choices4FGw6PXpR1Meta?.name ?? "legal-your-privacy-choices___mx",
    path: your_45privacy_45choices4FGw6PXpR1Meta?.path ?? "/mx/legal/your-privacy-choices",
    meta: your_45privacy_45choices4FGw6PXpR1Meta || {},
    alias: your_45privacy_45choices4FGw6PXpR1Meta?.alias || [],
    redirect: your_45privacy_45choices4FGw6PXpR1Meta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/legal/your-privacy-choices.vue").then(m => m.default || m)
  },
  {
    name: your_45privacy_45choices4FGw6PXpR1Meta?.name ?? "legal-your-privacy-choices___fr",
    path: your_45privacy_45choices4FGw6PXpR1Meta?.path ?? "/fr/legal/your-privacy-choices",
    meta: your_45privacy_45choices4FGw6PXpR1Meta || {},
    alias: your_45privacy_45choices4FGw6PXpR1Meta?.alias || [],
    redirect: your_45privacy_45choices4FGw6PXpR1Meta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/legal/your-privacy-choices.vue").then(m => m.default || m)
  },
  {
    name: your_45privacy_45choices4FGw6PXpR1Meta?.name ?? "legal-your-privacy-choices___fr-ca",
    path: your_45privacy_45choices4FGw6PXpR1Meta?.path ?? "/fr-ca/legal/your-privacy-choices",
    meta: your_45privacy_45choices4FGw6PXpR1Meta || {},
    alias: your_45privacy_45choices4FGw6PXpR1Meta?.alias || [],
    redirect: your_45privacy_45choices4FGw6PXpR1Meta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/legal/your-privacy-choices.vue").then(m => m.default || m)
  },
  {
    name: pricingh6ERH8b6n1Meta?.name ?? "page-pricing___en-US",
    path: pricingh6ERH8b6n1Meta?.path ?? "/page/pricing",
    meta: pricingh6ERH8b6n1Meta || {},
    alias: pricingh6ERH8b6n1Meta?.alias || [],
    redirect: pricingh6ERH8b6n1Meta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/page/pricing.vue").then(m => m.default || m)
  },
  {
    name: pricingh6ERH8b6n1Meta?.name ?? "page-pricing___ca",
    path: pricingh6ERH8b6n1Meta?.path ?? "/ca/page/pricing",
    meta: pricingh6ERH8b6n1Meta || {},
    alias: pricingh6ERH8b6n1Meta?.alias || [],
    redirect: pricingh6ERH8b6n1Meta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/page/pricing.vue").then(m => m.default || m)
  },
  {
    name: pricingh6ERH8b6n1Meta?.name ?? "page-pricing___nl",
    path: pricingh6ERH8b6n1Meta?.path ?? "/nl/page/pricing",
    meta: pricingh6ERH8b6n1Meta || {},
    alias: pricingh6ERH8b6n1Meta?.alias || [],
    redirect: pricingh6ERH8b6n1Meta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/page/pricing.vue").then(m => m.default || m)
  },
  {
    name: pricingh6ERH8b6n1Meta?.name ?? "page-pricing___uk",
    path: pricingh6ERH8b6n1Meta?.path ?? "/uk/page/pricing",
    meta: pricingh6ERH8b6n1Meta || {},
    alias: pricingh6ERH8b6n1Meta?.alias || [],
    redirect: pricingh6ERH8b6n1Meta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/page/pricing.vue").then(m => m.default || m)
  },
  {
    name: pricingh6ERH8b6n1Meta?.name ?? "page-pricing___de",
    path: pricingh6ERH8b6n1Meta?.path ?? "/de/page/pricing",
    meta: pricingh6ERH8b6n1Meta || {},
    alias: pricingh6ERH8b6n1Meta?.alias || [],
    redirect: pricingh6ERH8b6n1Meta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/page/pricing.vue").then(m => m.default || m)
  },
  {
    name: pricingh6ERH8b6n1Meta?.name ?? "page-pricing___mx",
    path: pricingh6ERH8b6n1Meta?.path ?? "/mx/page/pricing",
    meta: pricingh6ERH8b6n1Meta || {},
    alias: pricingh6ERH8b6n1Meta?.alias || [],
    redirect: pricingh6ERH8b6n1Meta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/page/pricing.vue").then(m => m.default || m)
  },
  {
    name: pricingh6ERH8b6n1Meta?.name ?? "page-pricing___fr",
    path: pricingh6ERH8b6n1Meta?.path ?? "/fr/page/pricing",
    meta: pricingh6ERH8b6n1Meta || {},
    alias: pricingh6ERH8b6n1Meta?.alias || [],
    redirect: pricingh6ERH8b6n1Meta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/page/pricing.vue").then(m => m.default || m)
  },
  {
    name: pricingh6ERH8b6n1Meta?.name ?? "page-pricing___fr-ca",
    path: pricingh6ERH8b6n1Meta?.path ?? "/fr-ca/page/pricing",
    meta: pricingh6ERH8b6n1Meta || {},
    alias: pricingh6ERH8b6n1Meta?.alias || [],
    redirect: pricingh6ERH8b6n1Meta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/page/pricing.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93qcfkMijG8xMeta?.name ?? "pages-prefix-slug___en-US",
    path: _91slug_93qcfkMijG8xMeta?.path ?? "/pages/:prefix()/:slug()",
    meta: _91slug_93qcfkMijG8xMeta || {},
    alias: _91slug_93qcfkMijG8xMeta?.alias || [],
    redirect: _91slug_93qcfkMijG8xMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/pages/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93qcfkMijG8xMeta?.name ?? "pages-prefix-slug___ca",
    path: _91slug_93qcfkMijG8xMeta?.path ?? "/ca/pages/:prefix()/:slug()",
    meta: _91slug_93qcfkMijG8xMeta || {},
    alias: _91slug_93qcfkMijG8xMeta?.alias || [],
    redirect: _91slug_93qcfkMijG8xMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/pages/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93qcfkMijG8xMeta?.name ?? "pages-prefix-slug___nl",
    path: _91slug_93qcfkMijG8xMeta?.path ?? "/nl/pages/:prefix()/:slug()",
    meta: _91slug_93qcfkMijG8xMeta || {},
    alias: _91slug_93qcfkMijG8xMeta?.alias || [],
    redirect: _91slug_93qcfkMijG8xMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/pages/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93qcfkMijG8xMeta?.name ?? "pages-prefix-slug___uk",
    path: _91slug_93qcfkMijG8xMeta?.path ?? "/uk/pages/:prefix()/:slug()",
    meta: _91slug_93qcfkMijG8xMeta || {},
    alias: _91slug_93qcfkMijG8xMeta?.alias || [],
    redirect: _91slug_93qcfkMijG8xMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/pages/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93qcfkMijG8xMeta?.name ?? "pages-prefix-slug___de",
    path: _91slug_93qcfkMijG8xMeta?.path ?? "/de/pages/:prefix()/:slug()",
    meta: _91slug_93qcfkMijG8xMeta || {},
    alias: _91slug_93qcfkMijG8xMeta?.alias || [],
    redirect: _91slug_93qcfkMijG8xMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/pages/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93qcfkMijG8xMeta?.name ?? "pages-prefix-slug___mx",
    path: _91slug_93qcfkMijG8xMeta?.path ?? "/mx/pages/:prefix()/:slug()",
    meta: _91slug_93qcfkMijG8xMeta || {},
    alias: _91slug_93qcfkMijG8xMeta?.alias || [],
    redirect: _91slug_93qcfkMijG8xMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/pages/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93qcfkMijG8xMeta?.name ?? "pages-prefix-slug___fr",
    path: _91slug_93qcfkMijG8xMeta?.path ?? "/fr/pages/:prefix()/:slug()",
    meta: _91slug_93qcfkMijG8xMeta || {},
    alias: _91slug_93qcfkMijG8xMeta?.alias || [],
    redirect: _91slug_93qcfkMijG8xMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/pages/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93qcfkMijG8xMeta?.name ?? "pages-prefix-slug___fr-ca",
    path: _91slug_93qcfkMijG8xMeta?.path ?? "/fr-ca/pages/:prefix()/:slug()",
    meta: _91slug_93qcfkMijG8xMeta || {},
    alias: _91slug_93qcfkMijG8xMeta?.alias || [],
    redirect: _91slug_93qcfkMijG8xMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/pages/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_937Dq3Hwl6WaMeta?.name ?? "pages-slug___en-US",
    path: _91slug_937Dq3Hwl6WaMeta?.path ?? "/pages/:slug()",
    meta: _91slug_937Dq3Hwl6WaMeta || {},
    alias: _91slug_937Dq3Hwl6WaMeta?.alias || [],
    redirect: _91slug_937Dq3Hwl6WaMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/pages/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_937Dq3Hwl6WaMeta?.name ?? "pages-slug___ca",
    path: _91slug_937Dq3Hwl6WaMeta?.path ?? "/ca/pages/:slug()",
    meta: _91slug_937Dq3Hwl6WaMeta || {},
    alias: _91slug_937Dq3Hwl6WaMeta?.alias || [],
    redirect: _91slug_937Dq3Hwl6WaMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/pages/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_937Dq3Hwl6WaMeta?.name ?? "pages-slug___nl",
    path: _91slug_937Dq3Hwl6WaMeta?.path ?? "/nl/pages/:slug()",
    meta: _91slug_937Dq3Hwl6WaMeta || {},
    alias: _91slug_937Dq3Hwl6WaMeta?.alias || [],
    redirect: _91slug_937Dq3Hwl6WaMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/pages/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_937Dq3Hwl6WaMeta?.name ?? "pages-slug___uk",
    path: _91slug_937Dq3Hwl6WaMeta?.path ?? "/uk/pages/:slug()",
    meta: _91slug_937Dq3Hwl6WaMeta || {},
    alias: _91slug_937Dq3Hwl6WaMeta?.alias || [],
    redirect: _91slug_937Dq3Hwl6WaMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/pages/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_937Dq3Hwl6WaMeta?.name ?? "pages-slug___de",
    path: _91slug_937Dq3Hwl6WaMeta?.path ?? "/de/pages/:slug()",
    meta: _91slug_937Dq3Hwl6WaMeta || {},
    alias: _91slug_937Dq3Hwl6WaMeta?.alias || [],
    redirect: _91slug_937Dq3Hwl6WaMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/pages/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_937Dq3Hwl6WaMeta?.name ?? "pages-slug___mx",
    path: _91slug_937Dq3Hwl6WaMeta?.path ?? "/mx/pages/:slug()",
    meta: _91slug_937Dq3Hwl6WaMeta || {},
    alias: _91slug_937Dq3Hwl6WaMeta?.alias || [],
    redirect: _91slug_937Dq3Hwl6WaMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/pages/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_937Dq3Hwl6WaMeta?.name ?? "pages-slug___fr",
    path: _91slug_937Dq3Hwl6WaMeta?.path ?? "/fr/pages/:slug()",
    meta: _91slug_937Dq3Hwl6WaMeta || {},
    alias: _91slug_937Dq3Hwl6WaMeta?.alias || [],
    redirect: _91slug_937Dq3Hwl6WaMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/pages/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_937Dq3Hwl6WaMeta?.name ?? "pages-slug___fr-ca",
    path: _91slug_937Dq3Hwl6WaMeta?.path ?? "/fr-ca/pages/:slug()",
    meta: _91slug_937Dq3Hwl6WaMeta || {},
    alias: _91slug_937Dq3Hwl6WaMeta?.alias || [],
    redirect: _91slug_937Dq3Hwl6WaMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/pages/[slug].vue").then(m => m.default || m)
  },
  {
    name: asset_45tracking1Unsb8mTH4Meta?.name ?? "pages-asset-tracking___en-US",
    path: asset_45tracking1Unsb8mTH4Meta?.path ?? "/pages/asset-tracking",
    meta: asset_45tracking1Unsb8mTH4Meta || {},
    alias: asset_45tracking1Unsb8mTH4Meta?.alias || [],
    redirect: asset_45tracking1Unsb8mTH4Meta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/pages/asset-tracking.vue").then(m => m.default || m)
  },
  {
    name: asset_45tracking1Unsb8mTH4Meta?.name ?? "pages-asset-tracking___ca",
    path: asset_45tracking1Unsb8mTH4Meta?.path ?? "/ca/pages/asset-tracking",
    meta: asset_45tracking1Unsb8mTH4Meta || {},
    alias: asset_45tracking1Unsb8mTH4Meta?.alias || [],
    redirect: asset_45tracking1Unsb8mTH4Meta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/pages/asset-tracking.vue").then(m => m.default || m)
  },
  {
    name: asset_45tracking1Unsb8mTH4Meta?.name ?? "pages-asset-tracking___nl",
    path: asset_45tracking1Unsb8mTH4Meta?.path ?? "/nl/pages/asset-tracking",
    meta: asset_45tracking1Unsb8mTH4Meta || {},
    alias: asset_45tracking1Unsb8mTH4Meta?.alias || [],
    redirect: asset_45tracking1Unsb8mTH4Meta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/pages/asset-tracking.vue").then(m => m.default || m)
  },
  {
    name: asset_45tracking1Unsb8mTH4Meta?.name ?? "pages-asset-tracking___uk",
    path: asset_45tracking1Unsb8mTH4Meta?.path ?? "/uk/pages/asset-tracking",
    meta: asset_45tracking1Unsb8mTH4Meta || {},
    alias: asset_45tracking1Unsb8mTH4Meta?.alias || [],
    redirect: asset_45tracking1Unsb8mTH4Meta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/pages/asset-tracking.vue").then(m => m.default || m)
  },
  {
    name: asset_45tracking1Unsb8mTH4Meta?.name ?? "pages-asset-tracking___de",
    path: asset_45tracking1Unsb8mTH4Meta?.path ?? "/de/pages/asset-tracking",
    meta: asset_45tracking1Unsb8mTH4Meta || {},
    alias: asset_45tracking1Unsb8mTH4Meta?.alias || [],
    redirect: asset_45tracking1Unsb8mTH4Meta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/pages/asset-tracking.vue").then(m => m.default || m)
  },
  {
    name: asset_45tracking1Unsb8mTH4Meta?.name ?? "pages-asset-tracking___mx",
    path: asset_45tracking1Unsb8mTH4Meta?.path ?? "/mx/pages/asset-tracking",
    meta: asset_45tracking1Unsb8mTH4Meta || {},
    alias: asset_45tracking1Unsb8mTH4Meta?.alias || [],
    redirect: asset_45tracking1Unsb8mTH4Meta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/pages/asset-tracking.vue").then(m => m.default || m)
  },
  {
    name: asset_45tracking1Unsb8mTH4Meta?.name ?? "pages-asset-tracking___fr",
    path: asset_45tracking1Unsb8mTH4Meta?.path ?? "/fr/pages/asset-tracking",
    meta: asset_45tracking1Unsb8mTH4Meta || {},
    alias: asset_45tracking1Unsb8mTH4Meta?.alias || [],
    redirect: asset_45tracking1Unsb8mTH4Meta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/pages/asset-tracking.vue").then(m => m.default || m)
  },
  {
    name: asset_45tracking1Unsb8mTH4Meta?.name ?? "pages-asset-tracking___fr-ca",
    path: asset_45tracking1Unsb8mTH4Meta?.path ?? "/fr-ca/pages/asset-tracking",
    meta: asset_45tracking1Unsb8mTH4Meta || {},
    alias: asset_45tracking1Unsb8mTH4Meta?.alias || [],
    redirect: asset_45tracking1Unsb8mTH4Meta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/pages/asset-tracking.vue").then(m => m.default || m)
  },
  {
    name: best_45dash_45camsZsVCsbkXStMeta?.name ?? "pages-best-dash-cams___en-US",
    path: best_45dash_45camsZsVCsbkXStMeta?.path ?? "/pages/best-dash-cams",
    meta: best_45dash_45camsZsVCsbkXStMeta || {},
    alias: best_45dash_45camsZsVCsbkXStMeta?.alias || [],
    redirect: best_45dash_45camsZsVCsbkXStMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/pages/best-dash-cams.vue").then(m => m.default || m)
  },
  {
    name: best_45dash_45camsZsVCsbkXStMeta?.name ?? "pages-best-dash-cams___ca",
    path: best_45dash_45camsZsVCsbkXStMeta?.path ?? "/ca/pages/best-dash-cams",
    meta: best_45dash_45camsZsVCsbkXStMeta || {},
    alias: best_45dash_45camsZsVCsbkXStMeta?.alias || [],
    redirect: best_45dash_45camsZsVCsbkXStMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/pages/best-dash-cams.vue").then(m => m.default || m)
  },
  {
    name: best_45dash_45camsZsVCsbkXStMeta?.name ?? "pages-best-dash-cams___nl",
    path: best_45dash_45camsZsVCsbkXStMeta?.path ?? "/nl/pages/best-dash-cams",
    meta: best_45dash_45camsZsVCsbkXStMeta || {},
    alias: best_45dash_45camsZsVCsbkXStMeta?.alias || [],
    redirect: best_45dash_45camsZsVCsbkXStMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/pages/best-dash-cams.vue").then(m => m.default || m)
  },
  {
    name: best_45dash_45camsZsVCsbkXStMeta?.name ?? "pages-best-dash-cams___uk",
    path: best_45dash_45camsZsVCsbkXStMeta?.path ?? "/uk/pages/best-dash-cams",
    meta: best_45dash_45camsZsVCsbkXStMeta || {},
    alias: best_45dash_45camsZsVCsbkXStMeta?.alias || [],
    redirect: best_45dash_45camsZsVCsbkXStMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/pages/best-dash-cams.vue").then(m => m.default || m)
  },
  {
    name: best_45dash_45camsZsVCsbkXStMeta?.name ?? "pages-best-dash-cams___de",
    path: best_45dash_45camsZsVCsbkXStMeta?.path ?? "/de/pages/best-dash-cams",
    meta: best_45dash_45camsZsVCsbkXStMeta || {},
    alias: best_45dash_45camsZsVCsbkXStMeta?.alias || [],
    redirect: best_45dash_45camsZsVCsbkXStMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/pages/best-dash-cams.vue").then(m => m.default || m)
  },
  {
    name: best_45dash_45camsZsVCsbkXStMeta?.name ?? "pages-best-dash-cams___mx",
    path: best_45dash_45camsZsVCsbkXStMeta?.path ?? "/mx/pages/best-dash-cams",
    meta: best_45dash_45camsZsVCsbkXStMeta || {},
    alias: best_45dash_45camsZsVCsbkXStMeta?.alias || [],
    redirect: best_45dash_45camsZsVCsbkXStMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/pages/best-dash-cams.vue").then(m => m.default || m)
  },
  {
    name: best_45dash_45camsZsVCsbkXStMeta?.name ?? "pages-best-dash-cams___fr",
    path: best_45dash_45camsZsVCsbkXStMeta?.path ?? "/fr/pages/best-dash-cams",
    meta: best_45dash_45camsZsVCsbkXStMeta || {},
    alias: best_45dash_45camsZsVCsbkXStMeta?.alias || [],
    redirect: best_45dash_45camsZsVCsbkXStMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/pages/best-dash-cams.vue").then(m => m.default || m)
  },
  {
    name: best_45dash_45camsZsVCsbkXStMeta?.name ?? "pages-best-dash-cams___fr-ca",
    path: best_45dash_45camsZsVCsbkXStMeta?.path ?? "/fr-ca/pages/best-dash-cams",
    meta: best_45dash_45camsZsVCsbkXStMeta || {},
    alias: best_45dash_45camsZsVCsbkXStMeta?.alias || [],
    redirect: best_45dash_45camsZsVCsbkXStMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/pages/best-dash-cams.vue").then(m => m.default || m)
  },
  {
    name: constructionP3xhlm7RYFMeta?.name ?? "pages-construction___en-US",
    path: constructionP3xhlm7RYFMeta?.path ?? "/pages/construction",
    meta: constructionP3xhlm7RYFMeta || {},
    alias: constructionP3xhlm7RYFMeta?.alias || [],
    redirect: constructionP3xhlm7RYFMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/pages/construction.vue").then(m => m.default || m)
  },
  {
    name: constructionP3xhlm7RYFMeta?.name ?? "pages-construction___ca",
    path: constructionP3xhlm7RYFMeta?.path ?? "/ca/pages/construction",
    meta: constructionP3xhlm7RYFMeta || {},
    alias: constructionP3xhlm7RYFMeta?.alias || [],
    redirect: constructionP3xhlm7RYFMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/pages/construction.vue").then(m => m.default || m)
  },
  {
    name: constructionP3xhlm7RYFMeta?.name ?? "pages-construction___nl",
    path: constructionP3xhlm7RYFMeta?.path ?? "/nl/pages/construction",
    meta: constructionP3xhlm7RYFMeta || {},
    alias: constructionP3xhlm7RYFMeta?.alias || [],
    redirect: constructionP3xhlm7RYFMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/pages/construction.vue").then(m => m.default || m)
  },
  {
    name: constructionP3xhlm7RYFMeta?.name ?? "pages-construction___uk",
    path: constructionP3xhlm7RYFMeta?.path ?? "/uk/pages/construction",
    meta: constructionP3xhlm7RYFMeta || {},
    alias: constructionP3xhlm7RYFMeta?.alias || [],
    redirect: constructionP3xhlm7RYFMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/pages/construction.vue").then(m => m.default || m)
  },
  {
    name: constructionP3xhlm7RYFMeta?.name ?? "pages-construction___de",
    path: constructionP3xhlm7RYFMeta?.path ?? "/de/pages/construction",
    meta: constructionP3xhlm7RYFMeta || {},
    alias: constructionP3xhlm7RYFMeta?.alias || [],
    redirect: constructionP3xhlm7RYFMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/pages/construction.vue").then(m => m.default || m)
  },
  {
    name: constructionP3xhlm7RYFMeta?.name ?? "pages-construction___mx",
    path: constructionP3xhlm7RYFMeta?.path ?? "/mx/pages/construction",
    meta: constructionP3xhlm7RYFMeta || {},
    alias: constructionP3xhlm7RYFMeta?.alias || [],
    redirect: constructionP3xhlm7RYFMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/pages/construction.vue").then(m => m.default || m)
  },
  {
    name: constructionP3xhlm7RYFMeta?.name ?? "pages-construction___fr",
    path: constructionP3xhlm7RYFMeta?.path ?? "/fr/pages/construction",
    meta: constructionP3xhlm7RYFMeta || {},
    alias: constructionP3xhlm7RYFMeta?.alias || [],
    redirect: constructionP3xhlm7RYFMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/pages/construction.vue").then(m => m.default || m)
  },
  {
    name: constructionP3xhlm7RYFMeta?.name ?? "pages-construction___fr-ca",
    path: constructionP3xhlm7RYFMeta?.path ?? "/fr-ca/pages/construction",
    meta: constructionP3xhlm7RYFMeta || {},
    alias: constructionP3xhlm7RYFMeta?.alias || [],
    redirect: constructionP3xhlm7RYFMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/pages/construction.vue").then(m => m.default || m)
  },
  {
    name: fleet_45managementEjnwZ8juF2Meta?.name ?? "pages-fleet-management___en-US",
    path: fleet_45managementEjnwZ8juF2Meta?.path ?? "/pages/fleet-management",
    meta: fleet_45managementEjnwZ8juF2Meta || {},
    alias: fleet_45managementEjnwZ8juF2Meta?.alias || [],
    redirect: fleet_45managementEjnwZ8juF2Meta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/pages/fleet-management.vue").then(m => m.default || m)
  },
  {
    name: fleet_45managementEjnwZ8juF2Meta?.name ?? "pages-fleet-management___ca",
    path: fleet_45managementEjnwZ8juF2Meta?.path ?? "/ca/pages/fleet-management",
    meta: fleet_45managementEjnwZ8juF2Meta || {},
    alias: fleet_45managementEjnwZ8juF2Meta?.alias || [],
    redirect: fleet_45managementEjnwZ8juF2Meta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/pages/fleet-management.vue").then(m => m.default || m)
  },
  {
    name: fleet_45managementEjnwZ8juF2Meta?.name ?? "pages-fleet-management___nl",
    path: fleet_45managementEjnwZ8juF2Meta?.path ?? "/nl/pages/fleet-management",
    meta: fleet_45managementEjnwZ8juF2Meta || {},
    alias: fleet_45managementEjnwZ8juF2Meta?.alias || [],
    redirect: fleet_45managementEjnwZ8juF2Meta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/pages/fleet-management.vue").then(m => m.default || m)
  },
  {
    name: fleet_45managementEjnwZ8juF2Meta?.name ?? "pages-fleet-management___uk",
    path: fleet_45managementEjnwZ8juF2Meta?.path ?? "/uk/pages/fleet-management",
    meta: fleet_45managementEjnwZ8juF2Meta || {},
    alias: fleet_45managementEjnwZ8juF2Meta?.alias || [],
    redirect: fleet_45managementEjnwZ8juF2Meta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/pages/fleet-management.vue").then(m => m.default || m)
  },
  {
    name: fleet_45managementEjnwZ8juF2Meta?.name ?? "pages-fleet-management___de",
    path: fleet_45managementEjnwZ8juF2Meta?.path ?? "/de/pages/fleet-management",
    meta: fleet_45managementEjnwZ8juF2Meta || {},
    alias: fleet_45managementEjnwZ8juF2Meta?.alias || [],
    redirect: fleet_45managementEjnwZ8juF2Meta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/pages/fleet-management.vue").then(m => m.default || m)
  },
  {
    name: fleet_45managementEjnwZ8juF2Meta?.name ?? "pages-fleet-management___mx",
    path: fleet_45managementEjnwZ8juF2Meta?.path ?? "/mx/pages/fleet-management",
    meta: fleet_45managementEjnwZ8juF2Meta || {},
    alias: fleet_45managementEjnwZ8juF2Meta?.alias || [],
    redirect: fleet_45managementEjnwZ8juF2Meta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/pages/fleet-management.vue").then(m => m.default || m)
  },
  {
    name: fleet_45managementEjnwZ8juF2Meta?.name ?? "pages-fleet-management___fr",
    path: fleet_45managementEjnwZ8juF2Meta?.path ?? "/fr/pages/fleet-management",
    meta: fleet_45managementEjnwZ8juF2Meta || {},
    alias: fleet_45managementEjnwZ8juF2Meta?.alias || [],
    redirect: fleet_45managementEjnwZ8juF2Meta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/pages/fleet-management.vue").then(m => m.default || m)
  },
  {
    name: fleet_45managementEjnwZ8juF2Meta?.name ?? "pages-fleet-management___fr-ca",
    path: fleet_45managementEjnwZ8juF2Meta?.path ?? "/fr-ca/pages/fleet-management",
    meta: fleet_45managementEjnwZ8juF2Meta || {},
    alias: fleet_45managementEjnwZ8juF2Meta?.alias || [],
    redirect: fleet_45managementEjnwZ8juF2Meta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/pages/fleet-management.vue").then(m => m.default || m)
  },
  {
    name: nationwideqqFg3SLTfIMeta?.name ?? "pages-nationwide___en-US",
    path: nationwideqqFg3SLTfIMeta?.path ?? "/pages/nationwide",
    meta: nationwideqqFg3SLTfIMeta || {},
    alias: nationwideqqFg3SLTfIMeta?.alias || [],
    redirect: nationwideqqFg3SLTfIMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/pages/nationwide.vue").then(m => m.default || m)
  },
  {
    name: nationwideqqFg3SLTfIMeta?.name ?? "pages-nationwide___ca",
    path: nationwideqqFg3SLTfIMeta?.path ?? "/ca/pages/nationwide",
    meta: nationwideqqFg3SLTfIMeta || {},
    alias: nationwideqqFg3SLTfIMeta?.alias || [],
    redirect: nationwideqqFg3SLTfIMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/pages/nationwide.vue").then(m => m.default || m)
  },
  {
    name: nationwideqqFg3SLTfIMeta?.name ?? "pages-nationwide___nl",
    path: nationwideqqFg3SLTfIMeta?.path ?? "/nl/pages/nationwide",
    meta: nationwideqqFg3SLTfIMeta || {},
    alias: nationwideqqFg3SLTfIMeta?.alias || [],
    redirect: nationwideqqFg3SLTfIMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/pages/nationwide.vue").then(m => m.default || m)
  },
  {
    name: nationwideqqFg3SLTfIMeta?.name ?? "pages-nationwide___uk",
    path: nationwideqqFg3SLTfIMeta?.path ?? "/uk/pages/nationwide",
    meta: nationwideqqFg3SLTfIMeta || {},
    alias: nationwideqqFg3SLTfIMeta?.alias || [],
    redirect: nationwideqqFg3SLTfIMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/pages/nationwide.vue").then(m => m.default || m)
  },
  {
    name: nationwideqqFg3SLTfIMeta?.name ?? "pages-nationwide___de",
    path: nationwideqqFg3SLTfIMeta?.path ?? "/de/pages/nationwide",
    meta: nationwideqqFg3SLTfIMeta || {},
    alias: nationwideqqFg3SLTfIMeta?.alias || [],
    redirect: nationwideqqFg3SLTfIMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/pages/nationwide.vue").then(m => m.default || m)
  },
  {
    name: nationwideqqFg3SLTfIMeta?.name ?? "pages-nationwide___mx",
    path: nationwideqqFg3SLTfIMeta?.path ?? "/mx/pages/nationwide",
    meta: nationwideqqFg3SLTfIMeta || {},
    alias: nationwideqqFg3SLTfIMeta?.alias || [],
    redirect: nationwideqqFg3SLTfIMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/pages/nationwide.vue").then(m => m.default || m)
  },
  {
    name: nationwideqqFg3SLTfIMeta?.name ?? "pages-nationwide___fr",
    path: nationwideqqFg3SLTfIMeta?.path ?? "/fr/pages/nationwide",
    meta: nationwideqqFg3SLTfIMeta || {},
    alias: nationwideqqFg3SLTfIMeta?.alias || [],
    redirect: nationwideqqFg3SLTfIMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/pages/nationwide.vue").then(m => m.default || m)
  },
  {
    name: nationwideqqFg3SLTfIMeta?.name ?? "pages-nationwide___fr-ca",
    path: nationwideqqFg3SLTfIMeta?.path ?? "/fr-ca/pages/nationwide",
    meta: nationwideqqFg3SLTfIMeta || {},
    alias: nationwideqqFg3SLTfIMeta?.alias || [],
    redirect: nationwideqqFg3SLTfIMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/pages/nationwide.vue").then(m => m.default || m)
  },
  {
    name: safety_45coachingeLNBiTU7nWMeta?.name ?? "pages-safety-coaching___en-US",
    path: safety_45coachingeLNBiTU7nWMeta?.path ?? "/pages/safety-coaching",
    meta: safety_45coachingeLNBiTU7nWMeta || {},
    alias: safety_45coachingeLNBiTU7nWMeta?.alias || [],
    redirect: safety_45coachingeLNBiTU7nWMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/pages/safety-coaching.vue").then(m => m.default || m)
  },
  {
    name: safety_45coachingeLNBiTU7nWMeta?.name ?? "pages-safety-coaching___ca",
    path: safety_45coachingeLNBiTU7nWMeta?.path ?? "/ca/pages/safety-coaching",
    meta: safety_45coachingeLNBiTU7nWMeta || {},
    alias: safety_45coachingeLNBiTU7nWMeta?.alias || [],
    redirect: safety_45coachingeLNBiTU7nWMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/pages/safety-coaching.vue").then(m => m.default || m)
  },
  {
    name: safety_45coachingeLNBiTU7nWMeta?.name ?? "pages-safety-coaching___nl",
    path: safety_45coachingeLNBiTU7nWMeta?.path ?? "/nl/pages/safety-coaching",
    meta: safety_45coachingeLNBiTU7nWMeta || {},
    alias: safety_45coachingeLNBiTU7nWMeta?.alias || [],
    redirect: safety_45coachingeLNBiTU7nWMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/pages/safety-coaching.vue").then(m => m.default || m)
  },
  {
    name: safety_45coachingeLNBiTU7nWMeta?.name ?? "pages-safety-coaching___uk",
    path: safety_45coachingeLNBiTU7nWMeta?.path ?? "/uk/pages/safety-coaching",
    meta: safety_45coachingeLNBiTU7nWMeta || {},
    alias: safety_45coachingeLNBiTU7nWMeta?.alias || [],
    redirect: safety_45coachingeLNBiTU7nWMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/pages/safety-coaching.vue").then(m => m.default || m)
  },
  {
    name: safety_45coachingeLNBiTU7nWMeta?.name ?? "pages-safety-coaching___de",
    path: safety_45coachingeLNBiTU7nWMeta?.path ?? "/de/pages/safety-coaching",
    meta: safety_45coachingeLNBiTU7nWMeta || {},
    alias: safety_45coachingeLNBiTU7nWMeta?.alias || [],
    redirect: safety_45coachingeLNBiTU7nWMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/pages/safety-coaching.vue").then(m => m.default || m)
  },
  {
    name: safety_45coachingeLNBiTU7nWMeta?.name ?? "pages-safety-coaching___mx",
    path: safety_45coachingeLNBiTU7nWMeta?.path ?? "/mx/pages/safety-coaching",
    meta: safety_45coachingeLNBiTU7nWMeta || {},
    alias: safety_45coachingeLNBiTU7nWMeta?.alias || [],
    redirect: safety_45coachingeLNBiTU7nWMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/pages/safety-coaching.vue").then(m => m.default || m)
  },
  {
    name: safety_45coachingeLNBiTU7nWMeta?.name ?? "pages-safety-coaching___fr",
    path: safety_45coachingeLNBiTU7nWMeta?.path ?? "/fr/pages/safety-coaching",
    meta: safety_45coachingeLNBiTU7nWMeta || {},
    alias: safety_45coachingeLNBiTU7nWMeta?.alias || [],
    redirect: safety_45coachingeLNBiTU7nWMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/pages/safety-coaching.vue").then(m => m.default || m)
  },
  {
    name: safety_45coachingeLNBiTU7nWMeta?.name ?? "pages-safety-coaching___fr-ca",
    path: safety_45coachingeLNBiTU7nWMeta?.path ?? "/fr-ca/pages/safety-coaching",
    meta: safety_45coachingeLNBiTU7nWMeta || {},
    alias: safety_45coachingeLNBiTU7nWMeta?.alias || [],
    redirect: safety_45coachingeLNBiTU7nWMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/pages/safety-coaching.vue").then(m => m.default || m)
  },
  {
    name: pricing7zBqv6aTH6Meta?.name ?? "pricing___en-US",
    path: pricing7zBqv6aTH6Meta?.path ?? "/pricing",
    meta: pricing7zBqv6aTH6Meta || {},
    alias: pricing7zBqv6aTH6Meta?.alias || [],
    redirect: pricing7zBqv6aTH6Meta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/pricing.vue").then(m => m.default || m)
  },
  {
    name: pricing7zBqv6aTH6Meta?.name ?? "pricing___ca",
    path: pricing7zBqv6aTH6Meta?.path ?? "/ca/pricing",
    meta: pricing7zBqv6aTH6Meta || {},
    alias: pricing7zBqv6aTH6Meta?.alias || [],
    redirect: pricing7zBqv6aTH6Meta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/pricing.vue").then(m => m.default || m)
  },
  {
    name: pricing7zBqv6aTH6Meta?.name ?? "pricing___nl",
    path: pricing7zBqv6aTH6Meta?.path ?? "/nl/pricing",
    meta: pricing7zBqv6aTH6Meta || {},
    alias: pricing7zBqv6aTH6Meta?.alias || [],
    redirect: pricing7zBqv6aTH6Meta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/pricing.vue").then(m => m.default || m)
  },
  {
    name: pricing7zBqv6aTH6Meta?.name ?? "pricing___uk",
    path: pricing7zBqv6aTH6Meta?.path ?? "/uk/pricing",
    meta: pricing7zBqv6aTH6Meta || {},
    alias: pricing7zBqv6aTH6Meta?.alias || [],
    redirect: pricing7zBqv6aTH6Meta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/pricing.vue").then(m => m.default || m)
  },
  {
    name: pricing7zBqv6aTH6Meta?.name ?? "pricing___de",
    path: pricing7zBqv6aTH6Meta?.path ?? "/de/pricing",
    meta: pricing7zBqv6aTH6Meta || {},
    alias: pricing7zBqv6aTH6Meta?.alias || [],
    redirect: pricing7zBqv6aTH6Meta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/pricing.vue").then(m => m.default || m)
  },
  {
    name: pricing7zBqv6aTH6Meta?.name ?? "pricing___mx",
    path: pricing7zBqv6aTH6Meta?.path ?? "/mx/prueba-gratis",
    meta: pricing7zBqv6aTH6Meta || {},
    alias: pricing7zBqv6aTH6Meta?.alias || [],
    redirect: pricing7zBqv6aTH6Meta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/pricing.vue").then(m => m.default || m)
  },
  {
    name: pricing7zBqv6aTH6Meta?.name ?? "pricing___fr",
    path: pricing7zBqv6aTH6Meta?.path ?? "/fr/pricing",
    meta: pricing7zBqv6aTH6Meta || {},
    alias: pricing7zBqv6aTH6Meta?.alias || [],
    redirect: pricing7zBqv6aTH6Meta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/pricing.vue").then(m => m.default || m)
  },
  {
    name: pricing7zBqv6aTH6Meta?.name ?? "pricing___fr-ca",
    path: pricing7zBqv6aTH6Meta?.path ?? "/fr-ca/pricing",
    meta: pricing7zBqv6aTH6Meta || {},
    alias: pricing7zBqv6aTH6Meta?.alias || [],
    redirect: pricing7zBqv6aTH6Meta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/pricing.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93INM8dMTc2UMeta?.name ?? "products-prefix-slug___en-US",
    path: _91slug_93INM8dMTc2UMeta?.path ?? "/products/:prefix()/:slug()",
    meta: _91slug_93INM8dMTc2UMeta || {},
    alias: _91slug_93INM8dMTc2UMeta?.alias || [],
    redirect: _91slug_93INM8dMTc2UMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/products/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93INM8dMTc2UMeta?.name ?? "products-prefix-slug___ca",
    path: _91slug_93INM8dMTc2UMeta?.path ?? "/ca/products/:prefix()/:slug()",
    meta: _91slug_93INM8dMTc2UMeta || {},
    alias: _91slug_93INM8dMTc2UMeta?.alias || [],
    redirect: _91slug_93INM8dMTc2UMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/products/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93INM8dMTc2UMeta?.name ?? "products-prefix-slug___nl",
    path: _91slug_93INM8dMTc2UMeta?.path ?? "/nl/products/:prefix()/:slug()",
    meta: _91slug_93INM8dMTc2UMeta || {},
    alias: _91slug_93INM8dMTc2UMeta?.alias || [],
    redirect: _91slug_93INM8dMTc2UMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/products/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93INM8dMTc2UMeta?.name ?? "products-prefix-slug___uk",
    path: _91slug_93INM8dMTc2UMeta?.path ?? "/uk/products/:prefix()/:slug()",
    meta: _91slug_93INM8dMTc2UMeta || {},
    alias: _91slug_93INM8dMTc2UMeta?.alias || [],
    redirect: _91slug_93INM8dMTc2UMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/products/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93INM8dMTc2UMeta?.name ?? "products-prefix-slug___de",
    path: _91slug_93INM8dMTc2UMeta?.path ?? "/de/products/:prefix()/:slug()",
    meta: _91slug_93INM8dMTc2UMeta || {},
    alias: _91slug_93INM8dMTc2UMeta?.alias || [],
    redirect: _91slug_93INM8dMTc2UMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/products/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93INM8dMTc2UMeta?.name ?? "products-prefix-slug___mx",
    path: _91slug_93INM8dMTc2UMeta?.path ?? "/mx/products/:prefix()/:slug()",
    meta: _91slug_93INM8dMTc2UMeta || {},
    alias: _91slug_93INM8dMTc2UMeta?.alias || [],
    redirect: _91slug_93INM8dMTc2UMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/products/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93INM8dMTc2UMeta?.name ?? "products-prefix-slug___fr",
    path: _91slug_93INM8dMTc2UMeta?.path ?? "/fr/products/:prefix()/:slug()",
    meta: _91slug_93INM8dMTc2UMeta || {},
    alias: _91slug_93INM8dMTc2UMeta?.alias || [],
    redirect: _91slug_93INM8dMTc2UMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/products/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93INM8dMTc2UMeta?.name ?? "products-prefix-slug___fr-ca",
    path: _91slug_93INM8dMTc2UMeta?.path ?? "/fr-ca/products/:prefix()/:slug()",
    meta: _91slug_93INM8dMTc2UMeta || {},
    alias: _91slug_93INM8dMTc2UMeta?.alias || [],
    redirect: _91slug_93INM8dMTc2UMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/products/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93Rb6XzrMqGvMeta?.name ?? "products-slug___en-US",
    path: _91slug_93Rb6XzrMqGvMeta?.path ?? "/products/:slug()",
    meta: _91slug_93Rb6XzrMqGvMeta || {},
    alias: _91slug_93Rb6XzrMqGvMeta?.alias || [],
    redirect: _91slug_93Rb6XzrMqGvMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/products/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93Rb6XzrMqGvMeta?.name ?? "products-slug___ca",
    path: _91slug_93Rb6XzrMqGvMeta?.path ?? "/ca/products/:slug()",
    meta: _91slug_93Rb6XzrMqGvMeta || {},
    alias: _91slug_93Rb6XzrMqGvMeta?.alias || [],
    redirect: _91slug_93Rb6XzrMqGvMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/products/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93Rb6XzrMqGvMeta?.name ?? "products-slug___nl",
    path: _91slug_93Rb6XzrMqGvMeta?.path ?? "/nl/products/:slug()",
    meta: _91slug_93Rb6XzrMqGvMeta || {},
    alias: _91slug_93Rb6XzrMqGvMeta?.alias || [],
    redirect: _91slug_93Rb6XzrMqGvMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/products/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93Rb6XzrMqGvMeta?.name ?? "products-slug___uk",
    path: _91slug_93Rb6XzrMqGvMeta?.path ?? "/uk/products/:slug()",
    meta: _91slug_93Rb6XzrMqGvMeta || {},
    alias: _91slug_93Rb6XzrMqGvMeta?.alias || [],
    redirect: _91slug_93Rb6XzrMqGvMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/products/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93Rb6XzrMqGvMeta?.name ?? "products-slug___de",
    path: _91slug_93Rb6XzrMqGvMeta?.path ?? "/de/products/:slug()",
    meta: _91slug_93Rb6XzrMqGvMeta || {},
    alias: _91slug_93Rb6XzrMqGvMeta?.alias || [],
    redirect: _91slug_93Rb6XzrMqGvMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/products/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93Rb6XzrMqGvMeta?.name ?? "products-slug___mx",
    path: _91slug_93Rb6XzrMqGvMeta?.path ?? "/mx/products/:slug()",
    meta: _91slug_93Rb6XzrMqGvMeta || {},
    alias: _91slug_93Rb6XzrMqGvMeta?.alias || [],
    redirect: _91slug_93Rb6XzrMqGvMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/products/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93Rb6XzrMqGvMeta?.name ?? "products-slug___fr",
    path: _91slug_93Rb6XzrMqGvMeta?.path ?? "/fr/products/:slug()",
    meta: _91slug_93Rb6XzrMqGvMeta || {},
    alias: _91slug_93Rb6XzrMqGvMeta?.alias || [],
    redirect: _91slug_93Rb6XzrMqGvMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/products/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93Rb6XzrMqGvMeta?.name ?? "products-slug___fr-ca",
    path: _91slug_93Rb6XzrMqGvMeta?.path ?? "/fr-ca/products/:slug()",
    meta: _91slug_93Rb6XzrMqGvMeta || {},
    alias: _91slug_93Rb6XzrMqGvMeta?.alias || [],
    redirect: _91slug_93Rb6XzrMqGvMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/products/[slug].vue").then(m => m.default || m)
  },
  {
    name: asset_45trackingm1VUYdEWrHMeta?.name ?? "products-equipment-monitoring-asset-tracking___en-US",
    path: asset_45trackingm1VUYdEWrHMeta?.path ?? "/products/equipment-monitoring/asset-tracking",
    meta: asset_45trackingm1VUYdEWrHMeta || {},
    alias: asset_45trackingm1VUYdEWrHMeta?.alias || [],
    redirect: asset_45trackingm1VUYdEWrHMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/products/equipment-monitoring/asset-tracking.vue").then(m => m.default || m)
  },
  {
    name: asset_45trackingm1VUYdEWrHMeta?.name ?? "products-equipment-monitoring-asset-tracking___ca",
    path: asset_45trackingm1VUYdEWrHMeta?.path ?? "/ca/products/equipment-monitoring/asset-tracking",
    meta: asset_45trackingm1VUYdEWrHMeta || {},
    alias: asset_45trackingm1VUYdEWrHMeta?.alias || [],
    redirect: asset_45trackingm1VUYdEWrHMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/products/equipment-monitoring/asset-tracking.vue").then(m => m.default || m)
  },
  {
    name: asset_45trackingm1VUYdEWrHMeta?.name ?? "products-equipment-monitoring-asset-tracking___nl",
    path: asset_45trackingm1VUYdEWrHMeta?.path ?? "/nl/products/equipment-monitoring/asset-tracking",
    meta: asset_45trackingm1VUYdEWrHMeta || {},
    alias: asset_45trackingm1VUYdEWrHMeta?.alias || [],
    redirect: asset_45trackingm1VUYdEWrHMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/products/equipment-monitoring/asset-tracking.vue").then(m => m.default || m)
  },
  {
    name: asset_45trackingm1VUYdEWrHMeta?.name ?? "products-equipment-monitoring-asset-tracking___uk",
    path: asset_45trackingm1VUYdEWrHMeta?.path ?? "/uk/products/equipment-monitoring/asset-tracking",
    meta: asset_45trackingm1VUYdEWrHMeta || {},
    alias: asset_45trackingm1VUYdEWrHMeta?.alias || [],
    redirect: asset_45trackingm1VUYdEWrHMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/products/equipment-monitoring/asset-tracking.vue").then(m => m.default || m)
  },
  {
    name: asset_45trackingm1VUYdEWrHMeta?.name ?? "products-equipment-monitoring-asset-tracking___de",
    path: asset_45trackingm1VUYdEWrHMeta?.path ?? "/de/products/equipment-monitoring/asset-tracking",
    meta: asset_45trackingm1VUYdEWrHMeta || {},
    alias: asset_45trackingm1VUYdEWrHMeta?.alias || [],
    redirect: asset_45trackingm1VUYdEWrHMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/products/equipment-monitoring/asset-tracking.vue").then(m => m.default || m)
  },
  {
    name: asset_45trackingm1VUYdEWrHMeta?.name ?? "products-equipment-monitoring-asset-tracking___mx",
    path: asset_45trackingm1VUYdEWrHMeta?.path ?? "/mx/products/equipment-monitoring/asset-tracking",
    meta: asset_45trackingm1VUYdEWrHMeta || {},
    alias: asset_45trackingm1VUYdEWrHMeta?.alias || [],
    redirect: asset_45trackingm1VUYdEWrHMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/products/equipment-monitoring/asset-tracking.vue").then(m => m.default || m)
  },
  {
    name: asset_45trackingm1VUYdEWrHMeta?.name ?? "products-equipment-monitoring-asset-tracking___fr",
    path: asset_45trackingm1VUYdEWrHMeta?.path ?? "/fr/products/equipment-monitoring/asset-tracking",
    meta: asset_45trackingm1VUYdEWrHMeta || {},
    alias: asset_45trackingm1VUYdEWrHMeta?.alias || [],
    redirect: asset_45trackingm1VUYdEWrHMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/products/equipment-monitoring/asset-tracking.vue").then(m => m.default || m)
  },
  {
    name: asset_45trackingm1VUYdEWrHMeta?.name ?? "products-equipment-monitoring-asset-tracking___fr-ca",
    path: asset_45trackingm1VUYdEWrHMeta?.path ?? "/fr-ca/products/equipment-monitoring/asset-tracking",
    meta: asset_45trackingm1VUYdEWrHMeta || {},
    alias: asset_45trackingm1VUYdEWrHMeta?.alias || [],
    redirect: asset_45trackingm1VUYdEWrHMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/products/equipment-monitoring/asset-tracking.vue").then(m => m.default || m)
  },
  {
    name: equipment_45tracking2Lv2LsOnuUMeta?.name ?? "products-equipment-monitoring-equipment-tracking___en-US",
    path: equipment_45tracking2Lv2LsOnuUMeta?.path ?? "/products/equipment-monitoring/equipment-tracking",
    meta: equipment_45tracking2Lv2LsOnuUMeta || {},
    alias: equipment_45tracking2Lv2LsOnuUMeta?.alias || [],
    redirect: equipment_45tracking2Lv2LsOnuUMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/products/equipment-monitoring/equipment-tracking.vue").then(m => m.default || m)
  },
  {
    name: equipment_45tracking2Lv2LsOnuUMeta?.name ?? "products-equipment-monitoring-equipment-tracking___ca",
    path: equipment_45tracking2Lv2LsOnuUMeta?.path ?? "/ca/products/equipment-monitoring/equipment-tracking",
    meta: equipment_45tracking2Lv2LsOnuUMeta || {},
    alias: equipment_45tracking2Lv2LsOnuUMeta?.alias || [],
    redirect: equipment_45tracking2Lv2LsOnuUMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/products/equipment-monitoring/equipment-tracking.vue").then(m => m.default || m)
  },
  {
    name: equipment_45tracking2Lv2LsOnuUMeta?.name ?? "products-equipment-monitoring-equipment-tracking___nl",
    path: equipment_45tracking2Lv2LsOnuUMeta?.path ?? "/nl/products/equipment-monitoring/equipment-tracking",
    meta: equipment_45tracking2Lv2LsOnuUMeta || {},
    alias: equipment_45tracking2Lv2LsOnuUMeta?.alias || [],
    redirect: equipment_45tracking2Lv2LsOnuUMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/products/equipment-monitoring/equipment-tracking.vue").then(m => m.default || m)
  },
  {
    name: equipment_45tracking2Lv2LsOnuUMeta?.name ?? "products-equipment-monitoring-equipment-tracking___uk",
    path: equipment_45tracking2Lv2LsOnuUMeta?.path ?? "/uk/products/equipment-monitoring/equipment-tracking",
    meta: equipment_45tracking2Lv2LsOnuUMeta || {},
    alias: equipment_45tracking2Lv2LsOnuUMeta?.alias || [],
    redirect: equipment_45tracking2Lv2LsOnuUMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/products/equipment-monitoring/equipment-tracking.vue").then(m => m.default || m)
  },
  {
    name: equipment_45tracking2Lv2LsOnuUMeta?.name ?? "products-equipment-monitoring-equipment-tracking___de",
    path: equipment_45tracking2Lv2LsOnuUMeta?.path ?? "/de/products/equipment-monitoring/equipment-tracking",
    meta: equipment_45tracking2Lv2LsOnuUMeta || {},
    alias: equipment_45tracking2Lv2LsOnuUMeta?.alias || [],
    redirect: equipment_45tracking2Lv2LsOnuUMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/products/equipment-monitoring/equipment-tracking.vue").then(m => m.default || m)
  },
  {
    name: equipment_45tracking2Lv2LsOnuUMeta?.name ?? "products-equipment-monitoring-equipment-tracking___mx",
    path: equipment_45tracking2Lv2LsOnuUMeta?.path ?? "/mx/products/equipment-monitoring/equipment-tracking",
    meta: equipment_45tracking2Lv2LsOnuUMeta || {},
    alias: equipment_45tracking2Lv2LsOnuUMeta?.alias || [],
    redirect: equipment_45tracking2Lv2LsOnuUMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/products/equipment-monitoring/equipment-tracking.vue").then(m => m.default || m)
  },
  {
    name: equipment_45tracking2Lv2LsOnuUMeta?.name ?? "products-equipment-monitoring-equipment-tracking___fr",
    path: equipment_45tracking2Lv2LsOnuUMeta?.path ?? "/fr/products/equipment-monitoring/equipment-tracking",
    meta: equipment_45tracking2Lv2LsOnuUMeta || {},
    alias: equipment_45tracking2Lv2LsOnuUMeta?.alias || [],
    redirect: equipment_45tracking2Lv2LsOnuUMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/products/equipment-monitoring/equipment-tracking.vue").then(m => m.default || m)
  },
  {
    name: equipment_45tracking2Lv2LsOnuUMeta?.name ?? "products-equipment-monitoring-equipment-tracking___fr-ca",
    path: equipment_45tracking2Lv2LsOnuUMeta?.path ?? "/fr-ca/products/equipment-monitoring/equipment-tracking",
    meta: equipment_45tracking2Lv2LsOnuUMeta || {},
    alias: equipment_45tracking2Lv2LsOnuUMeta?.alias || [],
    redirect: equipment_45tracking2Lv2LsOnuUMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/products/equipment-monitoring/equipment-tracking.vue").then(m => m.default || m)
  },
  {
    name: reefer_45monitoringAPivHuBNAIMeta?.name ?? "products-equipment-monitoring-reefer-monitoring___en-US",
    path: reefer_45monitoringAPivHuBNAIMeta?.path ?? "/products/equipment-monitoring/reefer-monitoring",
    meta: reefer_45monitoringAPivHuBNAIMeta || {},
    alias: reefer_45monitoringAPivHuBNAIMeta?.alias || [],
    redirect: reefer_45monitoringAPivHuBNAIMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/products/equipment-monitoring/reefer-monitoring.vue").then(m => m.default || m)
  },
  {
    name: reefer_45monitoringAPivHuBNAIMeta?.name ?? "products-equipment-monitoring-reefer-monitoring___ca",
    path: reefer_45monitoringAPivHuBNAIMeta?.path ?? "/ca/products/equipment-monitoring/reefer-monitoring",
    meta: reefer_45monitoringAPivHuBNAIMeta || {},
    alias: reefer_45monitoringAPivHuBNAIMeta?.alias || [],
    redirect: reefer_45monitoringAPivHuBNAIMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/products/equipment-monitoring/reefer-monitoring.vue").then(m => m.default || m)
  },
  {
    name: reefer_45monitoringAPivHuBNAIMeta?.name ?? "products-equipment-monitoring-reefer-monitoring___nl",
    path: reefer_45monitoringAPivHuBNAIMeta?.path ?? "/nl/products/equipment-monitoring/reefer-monitoring",
    meta: reefer_45monitoringAPivHuBNAIMeta || {},
    alias: reefer_45monitoringAPivHuBNAIMeta?.alias || [],
    redirect: reefer_45monitoringAPivHuBNAIMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/products/equipment-monitoring/reefer-monitoring.vue").then(m => m.default || m)
  },
  {
    name: reefer_45monitoringAPivHuBNAIMeta?.name ?? "products-equipment-monitoring-reefer-monitoring___uk",
    path: reefer_45monitoringAPivHuBNAIMeta?.path ?? "/uk/products/equipment-monitoring/reefer-monitoring",
    meta: reefer_45monitoringAPivHuBNAIMeta || {},
    alias: reefer_45monitoringAPivHuBNAIMeta?.alias || [],
    redirect: reefer_45monitoringAPivHuBNAIMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/products/equipment-monitoring/reefer-monitoring.vue").then(m => m.default || m)
  },
  {
    name: reefer_45monitoringAPivHuBNAIMeta?.name ?? "products-equipment-monitoring-reefer-monitoring___de",
    path: reefer_45monitoringAPivHuBNAIMeta?.path ?? "/de/products/equipment-monitoring/reefer-monitoring",
    meta: reefer_45monitoringAPivHuBNAIMeta || {},
    alias: reefer_45monitoringAPivHuBNAIMeta?.alias || [],
    redirect: reefer_45monitoringAPivHuBNAIMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/products/equipment-monitoring/reefer-monitoring.vue").then(m => m.default || m)
  },
  {
    name: reefer_45monitoringAPivHuBNAIMeta?.name ?? "products-equipment-monitoring-reefer-monitoring___mx",
    path: reefer_45monitoringAPivHuBNAIMeta?.path ?? "/mx/productos/rastreo-equipos/refrigerados",
    meta: reefer_45monitoringAPivHuBNAIMeta || {},
    alias: reefer_45monitoringAPivHuBNAIMeta?.alias || [],
    redirect: reefer_45monitoringAPivHuBNAIMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/products/equipment-monitoring/reefer-monitoring.vue").then(m => m.default || m)
  },
  {
    name: reefer_45monitoringAPivHuBNAIMeta?.name ?? "products-equipment-monitoring-reefer-monitoring___fr",
    path: reefer_45monitoringAPivHuBNAIMeta?.path ?? "/fr/products/equipment-monitoring/reefer-monitoring",
    meta: reefer_45monitoringAPivHuBNAIMeta || {},
    alias: reefer_45monitoringAPivHuBNAIMeta?.alias || [],
    redirect: reefer_45monitoringAPivHuBNAIMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/products/equipment-monitoring/reefer-monitoring.vue").then(m => m.default || m)
  },
  {
    name: reefer_45monitoringAPivHuBNAIMeta?.name ?? "products-equipment-monitoring-reefer-monitoring___fr-ca",
    path: reefer_45monitoringAPivHuBNAIMeta?.path ?? "/fr-ca/products/equipment-monitoring/reefer-monitoring",
    meta: reefer_45monitoringAPivHuBNAIMeta || {},
    alias: reefer_45monitoringAPivHuBNAIMeta?.alias || [],
    redirect: reefer_45monitoringAPivHuBNAIMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/products/equipment-monitoring/reefer-monitoring.vue").then(m => m.default || m)
  },
  {
    name: smart_45trailersBYXQNAAF4VMeta?.name ?? "products-equipment-monitoring-smart-trailers___en-US",
    path: smart_45trailersBYXQNAAF4VMeta?.path ?? "/products/equipment-monitoring/smart-trailers",
    meta: smart_45trailersBYXQNAAF4VMeta || {},
    alias: smart_45trailersBYXQNAAF4VMeta?.alias || [],
    redirect: smart_45trailersBYXQNAAF4VMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/products/equipment-monitoring/smart-trailers.vue").then(m => m.default || m)
  },
  {
    name: smart_45trailersBYXQNAAF4VMeta?.name ?? "products-equipment-monitoring-smart-trailers___ca",
    path: smart_45trailersBYXQNAAF4VMeta?.path ?? "/ca/products/equipment-monitoring/smart-trailers",
    meta: smart_45trailersBYXQNAAF4VMeta || {},
    alias: smart_45trailersBYXQNAAF4VMeta?.alias || [],
    redirect: smart_45trailersBYXQNAAF4VMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/products/equipment-monitoring/smart-trailers.vue").then(m => m.default || m)
  },
  {
    name: smart_45trailersBYXQNAAF4VMeta?.name ?? "products-equipment-monitoring-smart-trailers___nl",
    path: smart_45trailersBYXQNAAF4VMeta?.path ?? "/nl/products/equipment-monitoring/smart-trailers",
    meta: smart_45trailersBYXQNAAF4VMeta || {},
    alias: smart_45trailersBYXQNAAF4VMeta?.alias || [],
    redirect: smart_45trailersBYXQNAAF4VMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/products/equipment-monitoring/smart-trailers.vue").then(m => m.default || m)
  },
  {
    name: smart_45trailersBYXQNAAF4VMeta?.name ?? "products-equipment-monitoring-smart-trailers___uk",
    path: smart_45trailersBYXQNAAF4VMeta?.path ?? "/uk/products/equipment-monitoring/smart-trailers",
    meta: smart_45trailersBYXQNAAF4VMeta || {},
    alias: smart_45trailersBYXQNAAF4VMeta?.alias || [],
    redirect: smart_45trailersBYXQNAAF4VMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/products/equipment-monitoring/smart-trailers.vue").then(m => m.default || m)
  },
  {
    name: smart_45trailersBYXQNAAF4VMeta?.name ?? "products-equipment-monitoring-smart-trailers___de",
    path: smart_45trailersBYXQNAAF4VMeta?.path ?? "/de/products/equipment-monitoring/smart-trailers",
    meta: smart_45trailersBYXQNAAF4VMeta || {},
    alias: smart_45trailersBYXQNAAF4VMeta?.alias || [],
    redirect: smart_45trailersBYXQNAAF4VMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/products/equipment-monitoring/smart-trailers.vue").then(m => m.default || m)
  },
  {
    name: smart_45trailersBYXQNAAF4VMeta?.name ?? "products-equipment-monitoring-smart-trailers___mx",
    path: smart_45trailersBYXQNAAF4VMeta?.path ?? "/mx/products/equipment-monitoring/smart-trailers",
    meta: smart_45trailersBYXQNAAF4VMeta || {},
    alias: smart_45trailersBYXQNAAF4VMeta?.alias || [],
    redirect: smart_45trailersBYXQNAAF4VMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/products/equipment-monitoring/smart-trailers.vue").then(m => m.default || m)
  },
  {
    name: smart_45trailersBYXQNAAF4VMeta?.name ?? "products-equipment-monitoring-smart-trailers___fr",
    path: smart_45trailersBYXQNAAF4VMeta?.path ?? "/fr/products/equipment-monitoring/smart-trailers",
    meta: smart_45trailersBYXQNAAF4VMeta || {},
    alias: smart_45trailersBYXQNAAF4VMeta?.alias || [],
    redirect: smart_45trailersBYXQNAAF4VMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/products/equipment-monitoring/smart-trailers.vue").then(m => m.default || m)
  },
  {
    name: smart_45trailersBYXQNAAF4VMeta?.name ?? "products-equipment-monitoring-smart-trailers___fr-ca",
    path: smart_45trailersBYXQNAAF4VMeta?.path ?? "/fr-ca/products/equipment-monitoring/smart-trailers",
    meta: smart_45trailersBYXQNAAF4VMeta || {},
    alias: smart_45trailersBYXQNAAF4VMeta?.alias || [],
    redirect: smart_45trailersBYXQNAAF4VMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/products/equipment-monitoring/smart-trailers.vue").then(m => m.default || m)
  },
  {
    name: trailer_45trackingdQJXRwFiJNMeta?.name ?? "products-equipment-monitoring-trailer-tracking___en-US",
    path: trailer_45trackingdQJXRwFiJNMeta?.path ?? "/products/equipment-monitoring/trailer-tracking",
    meta: trailer_45trackingdQJXRwFiJNMeta || {},
    alias: trailer_45trackingdQJXRwFiJNMeta?.alias || [],
    redirect: trailer_45trackingdQJXRwFiJNMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/products/equipment-monitoring/trailer-tracking.vue").then(m => m.default || m)
  },
  {
    name: trailer_45trackingdQJXRwFiJNMeta?.name ?? "products-equipment-monitoring-trailer-tracking___ca",
    path: trailer_45trackingdQJXRwFiJNMeta?.path ?? "/ca/products/equipment-monitoring/trailer-tracking",
    meta: trailer_45trackingdQJXRwFiJNMeta || {},
    alias: trailer_45trackingdQJXRwFiJNMeta?.alias || [],
    redirect: trailer_45trackingdQJXRwFiJNMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/products/equipment-monitoring/trailer-tracking.vue").then(m => m.default || m)
  },
  {
    name: trailer_45trackingdQJXRwFiJNMeta?.name ?? "products-equipment-monitoring-trailer-tracking___nl",
    path: trailer_45trackingdQJXRwFiJNMeta?.path ?? "/nl/products/equipment-monitoring/trailer-tracking",
    meta: trailer_45trackingdQJXRwFiJNMeta || {},
    alias: trailer_45trackingdQJXRwFiJNMeta?.alias || [],
    redirect: trailer_45trackingdQJXRwFiJNMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/products/equipment-monitoring/trailer-tracking.vue").then(m => m.default || m)
  },
  {
    name: trailer_45trackingdQJXRwFiJNMeta?.name ?? "products-equipment-monitoring-trailer-tracking___uk",
    path: trailer_45trackingdQJXRwFiJNMeta?.path ?? "/uk/products/equipment-monitoring/trailer-tracking",
    meta: trailer_45trackingdQJXRwFiJNMeta || {},
    alias: trailer_45trackingdQJXRwFiJNMeta?.alias || [],
    redirect: trailer_45trackingdQJXRwFiJNMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/products/equipment-monitoring/trailer-tracking.vue").then(m => m.default || m)
  },
  {
    name: trailer_45trackingdQJXRwFiJNMeta?.name ?? "products-equipment-monitoring-trailer-tracking___de",
    path: trailer_45trackingdQJXRwFiJNMeta?.path ?? "/de/products/equipment-monitoring/trailer-tracking",
    meta: trailer_45trackingdQJXRwFiJNMeta || {},
    alias: trailer_45trackingdQJXRwFiJNMeta?.alias || [],
    redirect: trailer_45trackingdQJXRwFiJNMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/products/equipment-monitoring/trailer-tracking.vue").then(m => m.default || m)
  },
  {
    name: trailer_45trackingdQJXRwFiJNMeta?.name ?? "products-equipment-monitoring-trailer-tracking___mx",
    path: trailer_45trackingdQJXRwFiJNMeta?.path ?? "/mx/products/equipment-monitoring/trailer-tracking",
    meta: trailer_45trackingdQJXRwFiJNMeta || {},
    alias: trailer_45trackingdQJXRwFiJNMeta?.alias || [],
    redirect: trailer_45trackingdQJXRwFiJNMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/products/equipment-monitoring/trailer-tracking.vue").then(m => m.default || m)
  },
  {
    name: trailer_45trackingdQJXRwFiJNMeta?.name ?? "products-equipment-monitoring-trailer-tracking___fr",
    path: trailer_45trackingdQJXRwFiJNMeta?.path ?? "/fr/products/equipment-monitoring/trailer-tracking",
    meta: trailer_45trackingdQJXRwFiJNMeta || {},
    alias: trailer_45trackingdQJXRwFiJNMeta?.alias || [],
    redirect: trailer_45trackingdQJXRwFiJNMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/products/equipment-monitoring/trailer-tracking.vue").then(m => m.default || m)
  },
  {
    name: trailer_45trackingdQJXRwFiJNMeta?.name ?? "products-equipment-monitoring-trailer-tracking___fr-ca",
    path: trailer_45trackingdQJXRwFiJNMeta?.path ?? "/fr-ca/products/equipment-monitoring/trailer-tracking",
    meta: trailer_45trackingdQJXRwFiJNMeta || {},
    alias: trailer_45trackingdQJXRwFiJNMeta?.alias || [],
    redirect: trailer_45trackingdQJXRwFiJNMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/products/equipment-monitoring/trailer-tracking.vue").then(m => m.default || m)
  },
  {
    name: indexgkFzoV68xNMeta?.name ?? "products___en-US",
    path: indexgkFzoV68xNMeta?.path ?? "/products",
    meta: indexgkFzoV68xNMeta || {},
    alias: indexgkFzoV68xNMeta?.alias || [],
    redirect: indexgkFzoV68xNMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/products/index.vue").then(m => m.default || m)
  },
  {
    name: indexgkFzoV68xNMeta?.name ?? "products___ca",
    path: indexgkFzoV68xNMeta?.path ?? "/ca/products",
    meta: indexgkFzoV68xNMeta || {},
    alias: indexgkFzoV68xNMeta?.alias || [],
    redirect: indexgkFzoV68xNMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/products/index.vue").then(m => m.default || m)
  },
  {
    name: indexgkFzoV68xNMeta?.name ?? "products___nl",
    path: indexgkFzoV68xNMeta?.path ?? "/nl/products",
    meta: indexgkFzoV68xNMeta || {},
    alias: indexgkFzoV68xNMeta?.alias || [],
    redirect: indexgkFzoV68xNMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/products/index.vue").then(m => m.default || m)
  },
  {
    name: indexgkFzoV68xNMeta?.name ?? "products___uk",
    path: indexgkFzoV68xNMeta?.path ?? "/uk/products",
    meta: indexgkFzoV68xNMeta || {},
    alias: indexgkFzoV68xNMeta?.alias || [],
    redirect: indexgkFzoV68xNMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/products/index.vue").then(m => m.default || m)
  },
  {
    name: indexgkFzoV68xNMeta?.name ?? "products___de",
    path: indexgkFzoV68xNMeta?.path ?? "/de/products",
    meta: indexgkFzoV68xNMeta || {},
    alias: indexgkFzoV68xNMeta?.alias || [],
    redirect: indexgkFzoV68xNMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/products/index.vue").then(m => m.default || m)
  },
  {
    name: indexgkFzoV68xNMeta?.name ?? "products___mx",
    path: indexgkFzoV68xNMeta?.path ?? "/mx/products",
    meta: indexgkFzoV68xNMeta || {},
    alias: indexgkFzoV68xNMeta?.alias || [],
    redirect: indexgkFzoV68xNMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/products/index.vue").then(m => m.default || m)
  },
  {
    name: indexgkFzoV68xNMeta?.name ?? "products___fr",
    path: indexgkFzoV68xNMeta?.path ?? "/fr/products",
    meta: indexgkFzoV68xNMeta || {},
    alias: indexgkFzoV68xNMeta?.alias || [],
    redirect: indexgkFzoV68xNMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/products/index.vue").then(m => m.default || m)
  },
  {
    name: indexgkFzoV68xNMeta?.name ?? "products___fr-ca",
    path: indexgkFzoV68xNMeta?.path ?? "/fr-ca/products",
    meta: indexgkFzoV68xNMeta || {},
    alias: indexgkFzoV68xNMeta?.alias || [],
    redirect: indexgkFzoV68xNMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/products/index.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93WlVdMlHbLDMeta?.name ?? "products-models-slug___en-US",
    path: _91slug_93WlVdMlHbLDMeta?.path ?? "/products/models/:slug()",
    meta: _91slug_93WlVdMlHbLDMeta || {},
    alias: _91slug_93WlVdMlHbLDMeta?.alias || [],
    redirect: _91slug_93WlVdMlHbLDMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/products/models/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93WlVdMlHbLDMeta?.name ?? "products-models-slug___ca",
    path: _91slug_93WlVdMlHbLDMeta?.path ?? "/ca/products/models/:slug()",
    meta: _91slug_93WlVdMlHbLDMeta || {},
    alias: _91slug_93WlVdMlHbLDMeta?.alias || [],
    redirect: _91slug_93WlVdMlHbLDMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/products/models/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93WlVdMlHbLDMeta?.name ?? "products-models-slug___nl",
    path: _91slug_93WlVdMlHbLDMeta?.path ?? "/nl/products/models/:slug()",
    meta: _91slug_93WlVdMlHbLDMeta || {},
    alias: _91slug_93WlVdMlHbLDMeta?.alias || [],
    redirect: _91slug_93WlVdMlHbLDMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/products/models/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93WlVdMlHbLDMeta?.name ?? "products-models-slug___uk",
    path: _91slug_93WlVdMlHbLDMeta?.path ?? "/uk/products/models/:slug()",
    meta: _91slug_93WlVdMlHbLDMeta || {},
    alias: _91slug_93WlVdMlHbLDMeta?.alias || [],
    redirect: _91slug_93WlVdMlHbLDMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/products/models/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93WlVdMlHbLDMeta?.name ?? "products-models-slug___de",
    path: _91slug_93WlVdMlHbLDMeta?.path ?? "/de/products/models/:slug()",
    meta: _91slug_93WlVdMlHbLDMeta || {},
    alias: _91slug_93WlVdMlHbLDMeta?.alias || [],
    redirect: _91slug_93WlVdMlHbLDMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/products/models/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93WlVdMlHbLDMeta?.name ?? "products-models-slug___mx",
    path: _91slug_93WlVdMlHbLDMeta?.path ?? "/mx/products/models/:slug()",
    meta: _91slug_93WlVdMlHbLDMeta || {},
    alias: _91slug_93WlVdMlHbLDMeta?.alias || [],
    redirect: _91slug_93WlVdMlHbLDMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/products/models/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93WlVdMlHbLDMeta?.name ?? "products-models-slug___fr",
    path: _91slug_93WlVdMlHbLDMeta?.path ?? "/fr/products/models/:slug()",
    meta: _91slug_93WlVdMlHbLDMeta || {},
    alias: _91slug_93WlVdMlHbLDMeta?.alias || [],
    redirect: _91slug_93WlVdMlHbLDMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/products/models/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93WlVdMlHbLDMeta?.name ?? "products-models-slug___fr-ca",
    path: _91slug_93WlVdMlHbLDMeta?.path ?? "/fr-ca/products/models/:slug()",
    meta: _91slug_93WlVdMlHbLDMeta || {},
    alias: _91slug_93WlVdMlHbLDMeta?.alias || [],
    redirect: _91slug_93WlVdMlHbLDMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/products/models/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexF6zXiwXgnwMeta?.name ?? "products-models___en-US",
    path: indexF6zXiwXgnwMeta?.path ?? "/products/models",
    meta: indexF6zXiwXgnwMeta || {},
    alias: indexF6zXiwXgnwMeta?.alias || [],
    redirect: indexF6zXiwXgnwMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/products/models/index.vue").then(m => m.default || m)
  },
  {
    name: indexF6zXiwXgnwMeta?.name ?? "products-models___ca",
    path: indexF6zXiwXgnwMeta?.path ?? "/ca/products/models",
    meta: indexF6zXiwXgnwMeta || {},
    alias: indexF6zXiwXgnwMeta?.alias || [],
    redirect: indexF6zXiwXgnwMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/products/models/index.vue").then(m => m.default || m)
  },
  {
    name: indexF6zXiwXgnwMeta?.name ?? "products-models___nl",
    path: indexF6zXiwXgnwMeta?.path ?? "/nl/products/models",
    meta: indexF6zXiwXgnwMeta || {},
    alias: indexF6zXiwXgnwMeta?.alias || [],
    redirect: indexF6zXiwXgnwMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/products/models/index.vue").then(m => m.default || m)
  },
  {
    name: indexF6zXiwXgnwMeta?.name ?? "products-models___uk",
    path: indexF6zXiwXgnwMeta?.path ?? "/uk/products/models",
    meta: indexF6zXiwXgnwMeta || {},
    alias: indexF6zXiwXgnwMeta?.alias || [],
    redirect: indexF6zXiwXgnwMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/products/models/index.vue").then(m => m.default || m)
  },
  {
    name: indexF6zXiwXgnwMeta?.name ?? "products-models___de",
    path: indexF6zXiwXgnwMeta?.path ?? "/de/products/models",
    meta: indexF6zXiwXgnwMeta || {},
    alias: indexF6zXiwXgnwMeta?.alias || [],
    redirect: indexF6zXiwXgnwMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/products/models/index.vue").then(m => m.default || m)
  },
  {
    name: indexF6zXiwXgnwMeta?.name ?? "products-models___mx",
    path: indexF6zXiwXgnwMeta?.path ?? "/mx/products/models",
    meta: indexF6zXiwXgnwMeta || {},
    alias: indexF6zXiwXgnwMeta?.alias || [],
    redirect: indexF6zXiwXgnwMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/products/models/index.vue").then(m => m.default || m)
  },
  {
    name: indexF6zXiwXgnwMeta?.name ?? "products-models___fr",
    path: indexF6zXiwXgnwMeta?.path ?? "/fr/products/models",
    meta: indexF6zXiwXgnwMeta || {},
    alias: indexF6zXiwXgnwMeta?.alias || [],
    redirect: indexF6zXiwXgnwMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/products/models/index.vue").then(m => m.default || m)
  },
  {
    name: indexF6zXiwXgnwMeta?.name ?? "products-models___fr-ca",
    path: indexF6zXiwXgnwMeta?.path ?? "/fr-ca/products/models",
    meta: indexF6zXiwXgnwMeta || {},
    alias: indexF6zXiwXgnwMeta?.alias || [],
    redirect: indexF6zXiwXgnwMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/products/models/index.vue").then(m => m.default || m)
  },
  {
    name: gps_45fleet_45tracking3JICIlie1kMeta?.name ?? "products-telematics-gps-fleet-tracking___en-US",
    path: gps_45fleet_45tracking3JICIlie1kMeta?.path ?? "/products/telematics/gps-fleet-tracking",
    meta: gps_45fleet_45tracking3JICIlie1kMeta || {},
    alias: gps_45fleet_45tracking3JICIlie1kMeta?.alias || [],
    redirect: gps_45fleet_45tracking3JICIlie1kMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/products/telematics/gps-fleet-tracking.vue").then(m => m.default || m)
  },
  {
    name: gps_45fleet_45tracking3JICIlie1kMeta?.name ?? "products-telematics-gps-fleet-tracking___ca",
    path: gps_45fleet_45tracking3JICIlie1kMeta?.path ?? "/ca/products/telematics/gps-fleet-tracking",
    meta: gps_45fleet_45tracking3JICIlie1kMeta || {},
    alias: gps_45fleet_45tracking3JICIlie1kMeta?.alias || [],
    redirect: gps_45fleet_45tracking3JICIlie1kMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/products/telematics/gps-fleet-tracking.vue").then(m => m.default || m)
  },
  {
    name: gps_45fleet_45tracking3JICIlie1kMeta?.name ?? "products-telematics-gps-fleet-tracking___nl",
    path: gps_45fleet_45tracking3JICIlie1kMeta?.path ?? "/nl/products/telematics/gps-fleet-tracking",
    meta: gps_45fleet_45tracking3JICIlie1kMeta || {},
    alias: gps_45fleet_45tracking3JICIlie1kMeta?.alias || [],
    redirect: gps_45fleet_45tracking3JICIlie1kMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/products/telematics/gps-fleet-tracking.vue").then(m => m.default || m)
  },
  {
    name: gps_45fleet_45tracking3JICIlie1kMeta?.name ?? "products-telematics-gps-fleet-tracking___uk",
    path: gps_45fleet_45tracking3JICIlie1kMeta?.path ?? "/uk/products/telematics/gps-fleet-tracking",
    meta: gps_45fleet_45tracking3JICIlie1kMeta || {},
    alias: gps_45fleet_45tracking3JICIlie1kMeta?.alias || [],
    redirect: gps_45fleet_45tracking3JICIlie1kMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/products/telematics/gps-fleet-tracking.vue").then(m => m.default || m)
  },
  {
    name: gps_45fleet_45tracking3JICIlie1kMeta?.name ?? "products-telematics-gps-fleet-tracking___de",
    path: gps_45fleet_45tracking3JICIlie1kMeta?.path ?? "/de/products/telematics/gps-fleet-tracking",
    meta: gps_45fleet_45tracking3JICIlie1kMeta || {},
    alias: gps_45fleet_45tracking3JICIlie1kMeta?.alias || [],
    redirect: gps_45fleet_45tracking3JICIlie1kMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/products/telematics/gps-fleet-tracking.vue").then(m => m.default || m)
  },
  {
    name: gps_45fleet_45tracking3JICIlie1kMeta?.name ?? "products-telematics-gps-fleet-tracking___mx",
    path: gps_45fleet_45tracking3JICIlie1kMeta?.path ?? "/mx/productos/telematica/rastreo-gps-flota",
    meta: gps_45fleet_45tracking3JICIlie1kMeta || {},
    alias: gps_45fleet_45tracking3JICIlie1kMeta?.alias || [],
    redirect: gps_45fleet_45tracking3JICIlie1kMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/products/telematics/gps-fleet-tracking.vue").then(m => m.default || m)
  },
  {
    name: gps_45fleet_45tracking3JICIlie1kMeta?.name ?? "products-telematics-gps-fleet-tracking___fr",
    path: gps_45fleet_45tracking3JICIlie1kMeta?.path ?? "/fr/products/telematics/gps-fleet-tracking",
    meta: gps_45fleet_45tracking3JICIlie1kMeta || {},
    alias: gps_45fleet_45tracking3JICIlie1kMeta?.alias || [],
    redirect: gps_45fleet_45tracking3JICIlie1kMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/products/telematics/gps-fleet-tracking.vue").then(m => m.default || m)
  },
  {
    name: gps_45fleet_45tracking3JICIlie1kMeta?.name ?? "products-telematics-gps-fleet-tracking___fr-ca",
    path: gps_45fleet_45tracking3JICIlie1kMeta?.path ?? "/fr-ca/products/telematics/gps-fleet-tracking",
    meta: gps_45fleet_45tracking3JICIlie1kMeta || {},
    alias: gps_45fleet_45tracking3JICIlie1kMeta?.alias || [],
    redirect: gps_45fleet_45tracking3JICIlie1kMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/products/telematics/gps-fleet-tracking.vue").then(m => m.default || m)
  },
  {
    name: indexknv7u3gwodMeta?.name ?? "products-telematics___en-US",
    path: indexknv7u3gwodMeta?.path ?? "/products/telematics",
    meta: indexknv7u3gwodMeta || {},
    alias: indexknv7u3gwodMeta?.alias || [],
    redirect: indexknv7u3gwodMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/products/telematics/index.vue").then(m => m.default || m)
  },
  {
    name: indexknv7u3gwodMeta?.name ?? "products-telematics___ca",
    path: indexknv7u3gwodMeta?.path ?? "/ca/products/telematics",
    meta: indexknv7u3gwodMeta || {},
    alias: indexknv7u3gwodMeta?.alias || [],
    redirect: indexknv7u3gwodMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/products/telematics/index.vue").then(m => m.default || m)
  },
  {
    name: indexknv7u3gwodMeta?.name ?? "products-telematics___nl",
    path: indexknv7u3gwodMeta?.path ?? "/nl/products/telematics",
    meta: indexknv7u3gwodMeta || {},
    alias: indexknv7u3gwodMeta?.alias || [],
    redirect: indexknv7u3gwodMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/products/telematics/index.vue").then(m => m.default || m)
  },
  {
    name: indexknv7u3gwodMeta?.name ?? "products-telematics___uk",
    path: indexknv7u3gwodMeta?.path ?? "/uk/products/telematics",
    meta: indexknv7u3gwodMeta || {},
    alias: indexknv7u3gwodMeta?.alias || [],
    redirect: indexknv7u3gwodMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/products/telematics/index.vue").then(m => m.default || m)
  },
  {
    name: indexknv7u3gwodMeta?.name ?? "products-telematics___de",
    path: indexknv7u3gwodMeta?.path ?? "/de/products/telematics",
    meta: indexknv7u3gwodMeta || {},
    alias: indexknv7u3gwodMeta?.alias || [],
    redirect: indexknv7u3gwodMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/products/telematics/index.vue").then(m => m.default || m)
  },
  {
    name: indexknv7u3gwodMeta?.name ?? "products-telematics___mx",
    path: indexknv7u3gwodMeta?.path ?? "/mx/products/flota",
    meta: indexknv7u3gwodMeta || {},
    alias: indexknv7u3gwodMeta?.alias || [],
    redirect: indexknv7u3gwodMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/products/telematics/index.vue").then(m => m.default || m)
  },
  {
    name: indexknv7u3gwodMeta?.name ?? "products-telematics___fr",
    path: indexknv7u3gwodMeta?.path ?? "/fr/products/telematics",
    meta: indexknv7u3gwodMeta || {},
    alias: indexknv7u3gwodMeta?.alias || [],
    redirect: indexknv7u3gwodMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/products/telematics/index.vue").then(m => m.default || m)
  },
  {
    name: indexknv7u3gwodMeta?.name ?? "products-telematics___fr-ca",
    path: indexknv7u3gwodMeta?.path ?? "/fr-ca/products/telematics",
    meta: indexknv7u3gwodMeta || {},
    alias: indexknv7u3gwodMeta?.alias || [],
    redirect: indexknv7u3gwodMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/products/telematics/index.vue").then(m => m.default || m)
  },
  {
    name: referralsKjh4lGdYi6Meta?.name ?? "referrals___en-US",
    path: referralsKjh4lGdYi6Meta?.path ?? "/referrals",
    meta: referralsKjh4lGdYi6Meta || {},
    alias: referralsKjh4lGdYi6Meta?.alias || [],
    redirect: referralsKjh4lGdYi6Meta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/referrals.vue").then(m => m.default || m)
  },
  {
    name: referralsKjh4lGdYi6Meta?.name ?? "referrals___ca",
    path: referralsKjh4lGdYi6Meta?.path ?? "/ca/referrals",
    meta: referralsKjh4lGdYi6Meta || {},
    alias: referralsKjh4lGdYi6Meta?.alias || [],
    redirect: referralsKjh4lGdYi6Meta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/referrals.vue").then(m => m.default || m)
  },
  {
    name: referralsKjh4lGdYi6Meta?.name ?? "referrals___nl",
    path: referralsKjh4lGdYi6Meta?.path ?? "/nl/referrals",
    meta: referralsKjh4lGdYi6Meta || {},
    alias: referralsKjh4lGdYi6Meta?.alias || [],
    redirect: referralsKjh4lGdYi6Meta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/referrals.vue").then(m => m.default || m)
  },
  {
    name: referralsKjh4lGdYi6Meta?.name ?? "referrals___uk",
    path: referralsKjh4lGdYi6Meta?.path ?? "/uk/referrals",
    meta: referralsKjh4lGdYi6Meta || {},
    alias: referralsKjh4lGdYi6Meta?.alias || [],
    redirect: referralsKjh4lGdYi6Meta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/referrals.vue").then(m => m.default || m)
  },
  {
    name: referralsKjh4lGdYi6Meta?.name ?? "referrals___de",
    path: referralsKjh4lGdYi6Meta?.path ?? "/de/referrals",
    meta: referralsKjh4lGdYi6Meta || {},
    alias: referralsKjh4lGdYi6Meta?.alias || [],
    redirect: referralsKjh4lGdYi6Meta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/referrals.vue").then(m => m.default || m)
  },
  {
    name: referralsKjh4lGdYi6Meta?.name ?? "referrals___mx",
    path: referralsKjh4lGdYi6Meta?.path ?? "/mx/referrals",
    meta: referralsKjh4lGdYi6Meta || {},
    alias: referralsKjh4lGdYi6Meta?.alias || [],
    redirect: referralsKjh4lGdYi6Meta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/referrals.vue").then(m => m.default || m)
  },
  {
    name: referralsKjh4lGdYi6Meta?.name ?? "referrals___fr",
    path: referralsKjh4lGdYi6Meta?.path ?? "/fr/referrals",
    meta: referralsKjh4lGdYi6Meta || {},
    alias: referralsKjh4lGdYi6Meta?.alias || [],
    redirect: referralsKjh4lGdYi6Meta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/referrals.vue").then(m => m.default || m)
  },
  {
    name: referralsKjh4lGdYi6Meta?.name ?? "referrals___fr-ca",
    path: referralsKjh4lGdYi6Meta?.path ?? "/fr-ca/referrals",
    meta: referralsKjh4lGdYi6Meta || {},
    alias: referralsKjh4lGdYi6Meta?.alias || [],
    redirect: referralsKjh4lGdYi6Meta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/referrals.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93NHhHq3wujiMeta?.name ?? "resources-slug___en-US",
    path: _91slug_93NHhHq3wujiMeta?.path ?? "/resources/:slug()",
    meta: _91slug_93NHhHq3wujiMeta || {},
    alias: _91slug_93NHhHq3wujiMeta?.alias || [],
    redirect: _91slug_93NHhHq3wujiMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/resources/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93NHhHq3wujiMeta?.name ?? "resources-slug___ca",
    path: _91slug_93NHhHq3wujiMeta?.path ?? "/ca/resources/:slug()",
    meta: _91slug_93NHhHq3wujiMeta || {},
    alias: _91slug_93NHhHq3wujiMeta?.alias || [],
    redirect: _91slug_93NHhHq3wujiMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/resources/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93NHhHq3wujiMeta?.name ?? "resources-slug___nl",
    path: _91slug_93NHhHq3wujiMeta?.path ?? "/nl/resources/:slug()",
    meta: _91slug_93NHhHq3wujiMeta || {},
    alias: _91slug_93NHhHq3wujiMeta?.alias || [],
    redirect: _91slug_93NHhHq3wujiMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/resources/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93NHhHq3wujiMeta?.name ?? "resources-slug___uk",
    path: _91slug_93NHhHq3wujiMeta?.path ?? "/uk/resources/:slug()",
    meta: _91slug_93NHhHq3wujiMeta || {},
    alias: _91slug_93NHhHq3wujiMeta?.alias || [],
    redirect: _91slug_93NHhHq3wujiMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/resources/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93NHhHq3wujiMeta?.name ?? "resources-slug___de",
    path: _91slug_93NHhHq3wujiMeta?.path ?? "/de/resources/:slug()",
    meta: _91slug_93NHhHq3wujiMeta || {},
    alias: _91slug_93NHhHq3wujiMeta?.alias || [],
    redirect: _91slug_93NHhHq3wujiMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/resources/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93NHhHq3wujiMeta?.name ?? "resources-slug___mx",
    path: _91slug_93NHhHq3wujiMeta?.path ?? "/mx/resources/:slug()",
    meta: _91slug_93NHhHq3wujiMeta || {},
    alias: _91slug_93NHhHq3wujiMeta?.alias || [],
    redirect: _91slug_93NHhHq3wujiMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/resources/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93NHhHq3wujiMeta?.name ?? "resources-slug___fr",
    path: _91slug_93NHhHq3wujiMeta?.path ?? "/fr/resources/:slug()",
    meta: _91slug_93NHhHq3wujiMeta || {},
    alias: _91slug_93NHhHq3wujiMeta?.alias || [],
    redirect: _91slug_93NHhHq3wujiMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/resources/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93NHhHq3wujiMeta?.name ?? "resources-slug___fr-ca",
    path: _91slug_93NHhHq3wujiMeta?.path ?? "/fr-ca/resources/:slug()",
    meta: _91slug_93NHhHq3wujiMeta || {},
    alias: _91slug_93NHhHq3wujiMeta?.alias || [],
    redirect: _91slug_93NHhHq3wujiMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/resources/[slug].vue").then(m => m.default || m)
  },
  {
    name: brand_45assets8DzPCr39K6Meta?.name ?? "resources-brand-assets___en-US",
    path: brand_45assets8DzPCr39K6Meta?.path ?? "/resources/brand-assets",
    meta: brand_45assets8DzPCr39K6Meta || {},
    alias: brand_45assets8DzPCr39K6Meta?.alias || [],
    redirect: brand_45assets8DzPCr39K6Meta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/resources/brand-assets.vue").then(m => m.default || m)
  },
  {
    name: brand_45assets8DzPCr39K6Meta?.name ?? "resources-brand-assets___ca",
    path: brand_45assets8DzPCr39K6Meta?.path ?? "/ca/resources/brand-assets",
    meta: brand_45assets8DzPCr39K6Meta || {},
    alias: brand_45assets8DzPCr39K6Meta?.alias || [],
    redirect: brand_45assets8DzPCr39K6Meta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/resources/brand-assets.vue").then(m => m.default || m)
  },
  {
    name: brand_45assets8DzPCr39K6Meta?.name ?? "resources-brand-assets___nl",
    path: brand_45assets8DzPCr39K6Meta?.path ?? "/nl/resources/brand-assets",
    meta: brand_45assets8DzPCr39K6Meta || {},
    alias: brand_45assets8DzPCr39K6Meta?.alias || [],
    redirect: brand_45assets8DzPCr39K6Meta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/resources/brand-assets.vue").then(m => m.default || m)
  },
  {
    name: brand_45assets8DzPCr39K6Meta?.name ?? "resources-brand-assets___uk",
    path: brand_45assets8DzPCr39K6Meta?.path ?? "/uk/resources/brand-assets",
    meta: brand_45assets8DzPCr39K6Meta || {},
    alias: brand_45assets8DzPCr39K6Meta?.alias || [],
    redirect: brand_45assets8DzPCr39K6Meta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/resources/brand-assets.vue").then(m => m.default || m)
  },
  {
    name: brand_45assets8DzPCr39K6Meta?.name ?? "resources-brand-assets___de",
    path: brand_45assets8DzPCr39K6Meta?.path ?? "/de/resources/brand-assets",
    meta: brand_45assets8DzPCr39K6Meta || {},
    alias: brand_45assets8DzPCr39K6Meta?.alias || [],
    redirect: brand_45assets8DzPCr39K6Meta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/resources/brand-assets.vue").then(m => m.default || m)
  },
  {
    name: brand_45assets8DzPCr39K6Meta?.name ?? "resources-brand-assets___mx",
    path: brand_45assets8DzPCr39K6Meta?.path ?? "/mx/resources/brand-assets",
    meta: brand_45assets8DzPCr39K6Meta || {},
    alias: brand_45assets8DzPCr39K6Meta?.alias || [],
    redirect: brand_45assets8DzPCr39K6Meta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/resources/brand-assets.vue").then(m => m.default || m)
  },
  {
    name: brand_45assets8DzPCr39K6Meta?.name ?? "resources-brand-assets___fr",
    path: brand_45assets8DzPCr39K6Meta?.path ?? "/fr/resources/brand-assets",
    meta: brand_45assets8DzPCr39K6Meta || {},
    alias: brand_45assets8DzPCr39K6Meta?.alias || [],
    redirect: brand_45assets8DzPCr39K6Meta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/resources/brand-assets.vue").then(m => m.default || m)
  },
  {
    name: brand_45assets8DzPCr39K6Meta?.name ?? "resources-brand-assets___fr-ca",
    path: brand_45assets8DzPCr39K6Meta?.path ?? "/fr-ca/resources/brand-assets",
    meta: brand_45assets8DzPCr39K6Meta || {},
    alias: brand_45assets8DzPCr39K6Meta?.alias || [],
    redirect: brand_45assets8DzPCr39K6Meta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/resources/brand-assets.vue").then(m => m.default || m)
  },
  {
    name: _91slug_9387gtdn15qFMeta?.name ?? "resources-content-slug___en-US",
    path: _91slug_9387gtdn15qFMeta?.path ?? "/resources/content/:slug()",
    meta: _91slug_9387gtdn15qFMeta || {},
    alias: _91slug_9387gtdn15qFMeta?.alias || [],
    redirect: _91slug_9387gtdn15qFMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/resources/content/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_9387gtdn15qFMeta?.name ?? "resources-content-slug___ca",
    path: _91slug_9387gtdn15qFMeta?.path ?? "/ca/resources/content/:slug()",
    meta: _91slug_9387gtdn15qFMeta || {},
    alias: _91slug_9387gtdn15qFMeta?.alias || [],
    redirect: _91slug_9387gtdn15qFMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/resources/content/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_9387gtdn15qFMeta?.name ?? "resources-content-slug___nl",
    path: _91slug_9387gtdn15qFMeta?.path ?? "/nl/resources/content/:slug()",
    meta: _91slug_9387gtdn15qFMeta || {},
    alias: _91slug_9387gtdn15qFMeta?.alias || [],
    redirect: _91slug_9387gtdn15qFMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/resources/content/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_9387gtdn15qFMeta?.name ?? "resources-content-slug___uk",
    path: _91slug_9387gtdn15qFMeta?.path ?? "/uk/resources/content/:slug()",
    meta: _91slug_9387gtdn15qFMeta || {},
    alias: _91slug_9387gtdn15qFMeta?.alias || [],
    redirect: _91slug_9387gtdn15qFMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/resources/content/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_9387gtdn15qFMeta?.name ?? "resources-content-slug___de",
    path: _91slug_9387gtdn15qFMeta?.path ?? "/de/resources/content/:slug()",
    meta: _91slug_9387gtdn15qFMeta || {},
    alias: _91slug_9387gtdn15qFMeta?.alias || [],
    redirect: _91slug_9387gtdn15qFMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/resources/content/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_9387gtdn15qFMeta?.name ?? "resources-content-slug___mx",
    path: _91slug_9387gtdn15qFMeta?.path ?? "/mx/resources/content/:slug()",
    meta: _91slug_9387gtdn15qFMeta || {},
    alias: _91slug_9387gtdn15qFMeta?.alias || [],
    redirect: _91slug_9387gtdn15qFMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/resources/content/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_9387gtdn15qFMeta?.name ?? "resources-content-slug___fr",
    path: _91slug_9387gtdn15qFMeta?.path ?? "/fr/resources/content/:slug()",
    meta: _91slug_9387gtdn15qFMeta || {},
    alias: _91slug_9387gtdn15qFMeta?.alias || [],
    redirect: _91slug_9387gtdn15qFMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/resources/content/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_9387gtdn15qFMeta?.name ?? "resources-content-slug___fr-ca",
    path: _91slug_9387gtdn15qFMeta?.path ?? "/fr-ca/resources/content/:slug()",
    meta: _91slug_9387gtdn15qFMeta || {},
    alias: _91slug_9387gtdn15qFMeta?.alias || [],
    redirect: _91slug_9387gtdn15qFMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/resources/content/[slug].vue").then(m => m.default || m)
  },
  {
    name: email_45preferences_45center97T2B5g3Z8Meta?.name ?? "resources-content-email-preferences-center___en-US",
    path: email_45preferences_45center97T2B5g3Z8Meta?.path ?? "/resources/content/email-preferences-center",
    meta: email_45preferences_45center97T2B5g3Z8Meta || {},
    alias: email_45preferences_45center97T2B5g3Z8Meta?.alias || [],
    redirect: email_45preferences_45center97T2B5g3Z8Meta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/resources/content/email-preferences-center.vue").then(m => m.default || m)
  },
  {
    name: email_45preferences_45center97T2B5g3Z8Meta?.name ?? "resources-content-email-preferences-center___ca",
    path: email_45preferences_45center97T2B5g3Z8Meta?.path ?? "/ca/resources/content/email-preferences-center",
    meta: email_45preferences_45center97T2B5g3Z8Meta || {},
    alias: email_45preferences_45center97T2B5g3Z8Meta?.alias || [],
    redirect: email_45preferences_45center97T2B5g3Z8Meta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/resources/content/email-preferences-center.vue").then(m => m.default || m)
  },
  {
    name: email_45preferences_45center97T2B5g3Z8Meta?.name ?? "resources-content-email-preferences-center___nl",
    path: email_45preferences_45center97T2B5g3Z8Meta?.path ?? "/nl/resources/content/email-preferences-center",
    meta: email_45preferences_45center97T2B5g3Z8Meta || {},
    alias: email_45preferences_45center97T2B5g3Z8Meta?.alias || [],
    redirect: email_45preferences_45center97T2B5g3Z8Meta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/resources/content/email-preferences-center.vue").then(m => m.default || m)
  },
  {
    name: email_45preferences_45center97T2B5g3Z8Meta?.name ?? "resources-content-email-preferences-center___uk",
    path: email_45preferences_45center97T2B5g3Z8Meta?.path ?? "/uk/resources/content/email-preferences-center",
    meta: email_45preferences_45center97T2B5g3Z8Meta || {},
    alias: email_45preferences_45center97T2B5g3Z8Meta?.alias || [],
    redirect: email_45preferences_45center97T2B5g3Z8Meta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/resources/content/email-preferences-center.vue").then(m => m.default || m)
  },
  {
    name: email_45preferences_45center97T2B5g3Z8Meta?.name ?? "resources-content-email-preferences-center___de",
    path: email_45preferences_45center97T2B5g3Z8Meta?.path ?? "/de/resources/content/email-preferences-center",
    meta: email_45preferences_45center97T2B5g3Z8Meta || {},
    alias: email_45preferences_45center97T2B5g3Z8Meta?.alias || [],
    redirect: email_45preferences_45center97T2B5g3Z8Meta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/resources/content/email-preferences-center.vue").then(m => m.default || m)
  },
  {
    name: email_45preferences_45center97T2B5g3Z8Meta?.name ?? "resources-content-email-preferences-center___mx",
    path: email_45preferences_45center97T2B5g3Z8Meta?.path ?? "/mx/resources/content/email-preferences-center",
    meta: email_45preferences_45center97T2B5g3Z8Meta || {},
    alias: email_45preferences_45center97T2B5g3Z8Meta?.alias || [],
    redirect: email_45preferences_45center97T2B5g3Z8Meta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/resources/content/email-preferences-center.vue").then(m => m.default || m)
  },
  {
    name: email_45preferences_45center97T2B5g3Z8Meta?.name ?? "resources-content-email-preferences-center___fr",
    path: email_45preferences_45center97T2B5g3Z8Meta?.path ?? "/fr/resources/content/email-preferences-center",
    meta: email_45preferences_45center97T2B5g3Z8Meta || {},
    alias: email_45preferences_45center97T2B5g3Z8Meta?.alias || [],
    redirect: email_45preferences_45center97T2B5g3Z8Meta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/resources/content/email-preferences-center.vue").then(m => m.default || m)
  },
  {
    name: email_45preferences_45center97T2B5g3Z8Meta?.name ?? "resources-content-email-preferences-center___fr-ca",
    path: email_45preferences_45center97T2B5g3Z8Meta?.path ?? "/fr-ca/resources/content/email-preferences-center",
    meta: email_45preferences_45center97T2B5g3Z8Meta || {},
    alias: email_45preferences_45center97T2B5g3Z8Meta?.alias || [],
    redirect: email_45preferences_45center97T2B5g3Z8Meta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/resources/content/email-preferences-center.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93zWXmXcQC6nMeta?.name ?? "resources-experts-slug___en-US",
    path: _91slug_93zWXmXcQC6nMeta?.path ?? "/resources/experts/:slug()",
    meta: _91slug_93zWXmXcQC6nMeta || {},
    alias: _91slug_93zWXmXcQC6nMeta?.alias || [],
    redirect: _91slug_93zWXmXcQC6nMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/resources/experts/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93zWXmXcQC6nMeta?.name ?? "resources-experts-slug___ca",
    path: _91slug_93zWXmXcQC6nMeta?.path ?? "/ca/resources/experts/:slug()",
    meta: _91slug_93zWXmXcQC6nMeta || {},
    alias: _91slug_93zWXmXcQC6nMeta?.alias || [],
    redirect: _91slug_93zWXmXcQC6nMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/resources/experts/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93zWXmXcQC6nMeta?.name ?? "resources-experts-slug___nl",
    path: _91slug_93zWXmXcQC6nMeta?.path ?? "/nl/resources/experts/:slug()",
    meta: _91slug_93zWXmXcQC6nMeta || {},
    alias: _91slug_93zWXmXcQC6nMeta?.alias || [],
    redirect: _91slug_93zWXmXcQC6nMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/resources/experts/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93zWXmXcQC6nMeta?.name ?? "resources-experts-slug___uk",
    path: _91slug_93zWXmXcQC6nMeta?.path ?? "/uk/resources/experts/:slug()",
    meta: _91slug_93zWXmXcQC6nMeta || {},
    alias: _91slug_93zWXmXcQC6nMeta?.alias || [],
    redirect: _91slug_93zWXmXcQC6nMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/resources/experts/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93zWXmXcQC6nMeta?.name ?? "resources-experts-slug___de",
    path: _91slug_93zWXmXcQC6nMeta?.path ?? "/de/resources/experts/:slug()",
    meta: _91slug_93zWXmXcQC6nMeta || {},
    alias: _91slug_93zWXmXcQC6nMeta?.alias || [],
    redirect: _91slug_93zWXmXcQC6nMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/resources/experts/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93zWXmXcQC6nMeta?.name ?? "resources-experts-slug___mx",
    path: _91slug_93zWXmXcQC6nMeta?.path ?? "/mx/resources/experts/:slug()",
    meta: _91slug_93zWXmXcQC6nMeta || {},
    alias: _91slug_93zWXmXcQC6nMeta?.alias || [],
    redirect: _91slug_93zWXmXcQC6nMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/resources/experts/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93zWXmXcQC6nMeta?.name ?? "resources-experts-slug___fr",
    path: _91slug_93zWXmXcQC6nMeta?.path ?? "/fr/resources/experts/:slug()",
    meta: _91slug_93zWXmXcQC6nMeta || {},
    alias: _91slug_93zWXmXcQC6nMeta?.alias || [],
    redirect: _91slug_93zWXmXcQC6nMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/resources/experts/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93zWXmXcQC6nMeta?.name ?? "resources-experts-slug___fr-ca",
    path: _91slug_93zWXmXcQC6nMeta?.path ?? "/fr-ca/resources/experts/:slug()",
    meta: _91slug_93zWXmXcQC6nMeta || {},
    alias: _91slug_93zWXmXcQC6nMeta?.alias || [],
    redirect: _91slug_93zWXmXcQC6nMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/resources/experts/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93yEOe1IsQw2Meta?.name ?? "resources-experts-category-slug___en-US",
    path: _91slug_93yEOe1IsQw2Meta?.path ?? "/resources/experts/category/:slug()",
    meta: _91slug_93yEOe1IsQw2Meta || {},
    alias: _91slug_93yEOe1IsQw2Meta?.alias || [],
    redirect: _91slug_93yEOe1IsQw2Meta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/resources/experts/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93yEOe1IsQw2Meta?.name ?? "resources-experts-category-slug___ca",
    path: _91slug_93yEOe1IsQw2Meta?.path ?? "/ca/resources/experts/category/:slug()",
    meta: _91slug_93yEOe1IsQw2Meta || {},
    alias: _91slug_93yEOe1IsQw2Meta?.alias || [],
    redirect: _91slug_93yEOe1IsQw2Meta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/resources/experts/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93yEOe1IsQw2Meta?.name ?? "resources-experts-category-slug___nl",
    path: _91slug_93yEOe1IsQw2Meta?.path ?? "/nl/resources/experts/category/:slug()",
    meta: _91slug_93yEOe1IsQw2Meta || {},
    alias: _91slug_93yEOe1IsQw2Meta?.alias || [],
    redirect: _91slug_93yEOe1IsQw2Meta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/resources/experts/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93yEOe1IsQw2Meta?.name ?? "resources-experts-category-slug___uk",
    path: _91slug_93yEOe1IsQw2Meta?.path ?? "/uk/resources/experts/category/:slug()",
    meta: _91slug_93yEOe1IsQw2Meta || {},
    alias: _91slug_93yEOe1IsQw2Meta?.alias || [],
    redirect: _91slug_93yEOe1IsQw2Meta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/resources/experts/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93yEOe1IsQw2Meta?.name ?? "resources-experts-category-slug___de",
    path: _91slug_93yEOe1IsQw2Meta?.path ?? "/de/resources/experts/category/:slug()",
    meta: _91slug_93yEOe1IsQw2Meta || {},
    alias: _91slug_93yEOe1IsQw2Meta?.alias || [],
    redirect: _91slug_93yEOe1IsQw2Meta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/resources/experts/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93yEOe1IsQw2Meta?.name ?? "resources-experts-category-slug___mx",
    path: _91slug_93yEOe1IsQw2Meta?.path ?? "/mx/resources/experts/category/:slug()",
    meta: _91slug_93yEOe1IsQw2Meta || {},
    alias: _91slug_93yEOe1IsQw2Meta?.alias || [],
    redirect: _91slug_93yEOe1IsQw2Meta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/resources/experts/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93yEOe1IsQw2Meta?.name ?? "resources-experts-category-slug___fr",
    path: _91slug_93yEOe1IsQw2Meta?.path ?? "/fr/resources/experts/category/:slug()",
    meta: _91slug_93yEOe1IsQw2Meta || {},
    alias: _91slug_93yEOe1IsQw2Meta?.alias || [],
    redirect: _91slug_93yEOe1IsQw2Meta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/resources/experts/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93yEOe1IsQw2Meta?.name ?? "resources-experts-category-slug___fr-ca",
    path: _91slug_93yEOe1IsQw2Meta?.path ?? "/fr-ca/resources/experts/category/:slug()",
    meta: _91slug_93yEOe1IsQw2Meta || {},
    alias: _91slug_93yEOe1IsQw2Meta?.alias || [],
    redirect: _91slug_93yEOe1IsQw2Meta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/resources/experts/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexps3sDvveepMeta?.name ?? "resources-experts___en-US",
    path: indexps3sDvveepMeta?.path ?? "/resources/experts",
    meta: indexps3sDvveepMeta || {},
    alias: indexps3sDvveepMeta?.alias || [],
    redirect: indexps3sDvveepMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/resources/experts/index.vue").then(m => m.default || m)
  },
  {
    name: indexps3sDvveepMeta?.name ?? "resources-experts___ca",
    path: indexps3sDvveepMeta?.path ?? "/ca/resources/experts",
    meta: indexps3sDvveepMeta || {},
    alias: indexps3sDvveepMeta?.alias || [],
    redirect: indexps3sDvveepMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/resources/experts/index.vue").then(m => m.default || m)
  },
  {
    name: indexps3sDvveepMeta?.name ?? "resources-experts___nl",
    path: indexps3sDvveepMeta?.path ?? "/nl/resources/experts",
    meta: indexps3sDvveepMeta || {},
    alias: indexps3sDvveepMeta?.alias || [],
    redirect: indexps3sDvveepMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/resources/experts/index.vue").then(m => m.default || m)
  },
  {
    name: indexps3sDvveepMeta?.name ?? "resources-experts___uk",
    path: indexps3sDvveepMeta?.path ?? "/uk/resources/experts",
    meta: indexps3sDvveepMeta || {},
    alias: indexps3sDvveepMeta?.alias || [],
    redirect: indexps3sDvveepMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/resources/experts/index.vue").then(m => m.default || m)
  },
  {
    name: indexps3sDvveepMeta?.name ?? "resources-experts___de",
    path: indexps3sDvveepMeta?.path ?? "/de/resources/experts",
    meta: indexps3sDvveepMeta || {},
    alias: indexps3sDvveepMeta?.alias || [],
    redirect: indexps3sDvveepMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/resources/experts/index.vue").then(m => m.default || m)
  },
  {
    name: indexps3sDvveepMeta?.name ?? "resources-experts___mx",
    path: indexps3sDvveepMeta?.path ?? "/mx/resources/experts",
    meta: indexps3sDvveepMeta || {},
    alias: indexps3sDvveepMeta?.alias || [],
    redirect: indexps3sDvveepMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/resources/experts/index.vue").then(m => m.default || m)
  },
  {
    name: indexps3sDvveepMeta?.name ?? "resources-experts___fr",
    path: indexps3sDvveepMeta?.path ?? "/fr/resources/experts",
    meta: indexps3sDvveepMeta || {},
    alias: indexps3sDvveepMeta?.alias || [],
    redirect: indexps3sDvveepMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/resources/experts/index.vue").then(m => m.default || m)
  },
  {
    name: indexps3sDvveepMeta?.name ?? "resources-experts___fr-ca",
    path: indexps3sDvveepMeta?.path ?? "/fr-ca/resources/experts",
    meta: indexps3sDvveepMeta || {},
    alias: indexps3sDvveepMeta?.alias || [],
    redirect: indexps3sDvveepMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/resources/experts/index.vue").then(m => m.default || m)
  },
  {
    name: indexGyPpifrVFwMeta?.name ?? "resources___en-US",
    path: indexGyPpifrVFwMeta?.path ?? "/resources",
    meta: indexGyPpifrVFwMeta || {},
    alias: indexGyPpifrVFwMeta?.alias || [],
    redirect: indexGyPpifrVFwMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/resources/index.vue").then(m => m.default || m)
  },
  {
    name: indexGyPpifrVFwMeta?.name ?? "resources___ca",
    path: indexGyPpifrVFwMeta?.path ?? "/ca/resources",
    meta: indexGyPpifrVFwMeta || {},
    alias: indexGyPpifrVFwMeta?.alias || [],
    redirect: indexGyPpifrVFwMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/resources/index.vue").then(m => m.default || m)
  },
  {
    name: indexGyPpifrVFwMeta?.name ?? "resources___nl",
    path: indexGyPpifrVFwMeta?.path ?? "/nl/resources",
    meta: indexGyPpifrVFwMeta || {},
    alias: indexGyPpifrVFwMeta?.alias || [],
    redirect: indexGyPpifrVFwMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/resources/index.vue").then(m => m.default || m)
  },
  {
    name: indexGyPpifrVFwMeta?.name ?? "resources___uk",
    path: indexGyPpifrVFwMeta?.path ?? "/uk/resources",
    meta: indexGyPpifrVFwMeta || {},
    alias: indexGyPpifrVFwMeta?.alias || [],
    redirect: indexGyPpifrVFwMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/resources/index.vue").then(m => m.default || m)
  },
  {
    name: indexGyPpifrVFwMeta?.name ?? "resources___de",
    path: indexGyPpifrVFwMeta?.path ?? "/de/resources",
    meta: indexGyPpifrVFwMeta || {},
    alias: indexGyPpifrVFwMeta?.alias || [],
    redirect: indexGyPpifrVFwMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/resources/index.vue").then(m => m.default || m)
  },
  {
    name: indexGyPpifrVFwMeta?.name ?? "resources___mx",
    path: indexGyPpifrVFwMeta?.path ?? "/mx/resources",
    meta: indexGyPpifrVFwMeta || {},
    alias: indexGyPpifrVFwMeta?.alias || [],
    redirect: indexGyPpifrVFwMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/resources/index.vue").then(m => m.default || m)
  },
  {
    name: indexGyPpifrVFwMeta?.name ?? "resources___fr",
    path: indexGyPpifrVFwMeta?.path ?? "/fr/resources",
    meta: indexGyPpifrVFwMeta || {},
    alias: indexGyPpifrVFwMeta?.alias || [],
    redirect: indexGyPpifrVFwMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/resources/index.vue").then(m => m.default || m)
  },
  {
    name: indexGyPpifrVFwMeta?.name ?? "resources___fr-ca",
    path: indexGyPpifrVFwMeta?.path ?? "/fr-ca/resources",
    meta: indexGyPpifrVFwMeta || {},
    alias: indexGyPpifrVFwMeta?.alias || [],
    redirect: indexGyPpifrVFwMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/resources/index.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93y6WGvY67A9Meta?.name ?? "resources-marketplace-slug___en-US",
    path: _91slug_93y6WGvY67A9Meta?.path ?? "/resources/marketplace/:slug()",
    meta: _91slug_93y6WGvY67A9Meta || {},
    alias: _91slug_93y6WGvY67A9Meta?.alias || [],
    redirect: _91slug_93y6WGvY67A9Meta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/resources/marketplace/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93y6WGvY67A9Meta?.name ?? "resources-marketplace-slug___ca",
    path: _91slug_93y6WGvY67A9Meta?.path ?? "/ca/resources/marketplace/:slug()",
    meta: _91slug_93y6WGvY67A9Meta || {},
    alias: _91slug_93y6WGvY67A9Meta?.alias || [],
    redirect: _91slug_93y6WGvY67A9Meta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/resources/marketplace/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93y6WGvY67A9Meta?.name ?? "resources-marketplace-slug___nl",
    path: _91slug_93y6WGvY67A9Meta?.path ?? "/nl/resources/marketplace/:slug()",
    meta: _91slug_93y6WGvY67A9Meta || {},
    alias: _91slug_93y6WGvY67A9Meta?.alias || [],
    redirect: _91slug_93y6WGvY67A9Meta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/resources/marketplace/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93y6WGvY67A9Meta?.name ?? "resources-marketplace-slug___uk",
    path: _91slug_93y6WGvY67A9Meta?.path ?? "/uk/resources/marketplace/:slug()",
    meta: _91slug_93y6WGvY67A9Meta || {},
    alias: _91slug_93y6WGvY67A9Meta?.alias || [],
    redirect: _91slug_93y6WGvY67A9Meta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/resources/marketplace/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93y6WGvY67A9Meta?.name ?? "resources-marketplace-slug___de",
    path: _91slug_93y6WGvY67A9Meta?.path ?? "/de/resources/marketplace/:slug()",
    meta: _91slug_93y6WGvY67A9Meta || {},
    alias: _91slug_93y6WGvY67A9Meta?.alias || [],
    redirect: _91slug_93y6WGvY67A9Meta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/resources/marketplace/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93y6WGvY67A9Meta?.name ?? "resources-marketplace-slug___mx",
    path: _91slug_93y6WGvY67A9Meta?.path ?? "/mx/resources/marketplace/:slug()",
    meta: _91slug_93y6WGvY67A9Meta || {},
    alias: _91slug_93y6WGvY67A9Meta?.alias || [],
    redirect: _91slug_93y6WGvY67A9Meta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/resources/marketplace/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93y6WGvY67A9Meta?.name ?? "resources-marketplace-slug___fr",
    path: _91slug_93y6WGvY67A9Meta?.path ?? "/fr/resources/marketplace/:slug()",
    meta: _91slug_93y6WGvY67A9Meta || {},
    alias: _91slug_93y6WGvY67A9Meta?.alias || [],
    redirect: _91slug_93y6WGvY67A9Meta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/resources/marketplace/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93y6WGvY67A9Meta?.name ?? "resources-marketplace-slug___fr-ca",
    path: _91slug_93y6WGvY67A9Meta?.path ?? "/fr-ca/resources/marketplace/:slug()",
    meta: _91slug_93y6WGvY67A9Meta || {},
    alias: _91slug_93y6WGvY67A9Meta?.alias || [],
    redirect: _91slug_93y6WGvY67A9Meta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/resources/marketplace/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93yUTC1omFMAMeta?.name ?? "resources-marketplace-category-slug___en-US",
    path: _91slug_93yUTC1omFMAMeta?.path ?? "/resources/marketplace/category/:slug()",
    meta: _91slug_93yUTC1omFMAMeta || {},
    alias: _91slug_93yUTC1omFMAMeta?.alias || [],
    redirect: _91slug_93yUTC1omFMAMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/resources/marketplace/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93yUTC1omFMAMeta?.name ?? "resources-marketplace-category-slug___ca",
    path: _91slug_93yUTC1omFMAMeta?.path ?? "/ca/resources/marketplace/category/:slug()",
    meta: _91slug_93yUTC1omFMAMeta || {},
    alias: _91slug_93yUTC1omFMAMeta?.alias || [],
    redirect: _91slug_93yUTC1omFMAMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/resources/marketplace/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93yUTC1omFMAMeta?.name ?? "resources-marketplace-category-slug___nl",
    path: _91slug_93yUTC1omFMAMeta?.path ?? "/nl/resources/marketplace/category/:slug()",
    meta: _91slug_93yUTC1omFMAMeta || {},
    alias: _91slug_93yUTC1omFMAMeta?.alias || [],
    redirect: _91slug_93yUTC1omFMAMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/resources/marketplace/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93yUTC1omFMAMeta?.name ?? "resources-marketplace-category-slug___uk",
    path: _91slug_93yUTC1omFMAMeta?.path ?? "/uk/resources/marketplace/category/:slug()",
    meta: _91slug_93yUTC1omFMAMeta || {},
    alias: _91slug_93yUTC1omFMAMeta?.alias || [],
    redirect: _91slug_93yUTC1omFMAMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/resources/marketplace/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93yUTC1omFMAMeta?.name ?? "resources-marketplace-category-slug___de",
    path: _91slug_93yUTC1omFMAMeta?.path ?? "/de/resources/marketplace/category/:slug()",
    meta: _91slug_93yUTC1omFMAMeta || {},
    alias: _91slug_93yUTC1omFMAMeta?.alias || [],
    redirect: _91slug_93yUTC1omFMAMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/resources/marketplace/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93yUTC1omFMAMeta?.name ?? "resources-marketplace-category-slug___mx",
    path: _91slug_93yUTC1omFMAMeta?.path ?? "/mx/resources/marketplace/category/:slug()",
    meta: _91slug_93yUTC1omFMAMeta || {},
    alias: _91slug_93yUTC1omFMAMeta?.alias || [],
    redirect: _91slug_93yUTC1omFMAMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/resources/marketplace/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93yUTC1omFMAMeta?.name ?? "resources-marketplace-category-slug___fr",
    path: _91slug_93yUTC1omFMAMeta?.path ?? "/fr/resources/marketplace/category/:slug()",
    meta: _91slug_93yUTC1omFMAMeta || {},
    alias: _91slug_93yUTC1omFMAMeta?.alias || [],
    redirect: _91slug_93yUTC1omFMAMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/resources/marketplace/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93yUTC1omFMAMeta?.name ?? "resources-marketplace-category-slug___fr-ca",
    path: _91slug_93yUTC1omFMAMeta?.path ?? "/fr-ca/resources/marketplace/category/:slug()",
    meta: _91slug_93yUTC1omFMAMeta || {},
    alias: _91slug_93yUTC1omFMAMeta?.alias || [],
    redirect: _91slug_93yUTC1omFMAMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/resources/marketplace/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexT1dsTkqZB8Meta?.name ?? "resources-marketplace___en-US",
    path: indexT1dsTkqZB8Meta?.path ?? "/resources/marketplace",
    meta: indexT1dsTkqZB8Meta || {},
    alias: indexT1dsTkqZB8Meta?.alias || [],
    redirect: indexT1dsTkqZB8Meta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/resources/marketplace/index.vue").then(m => m.default || m)
  },
  {
    name: indexT1dsTkqZB8Meta?.name ?? "resources-marketplace___ca",
    path: indexT1dsTkqZB8Meta?.path ?? "/ca/resources/marketplace",
    meta: indexT1dsTkqZB8Meta || {},
    alias: indexT1dsTkqZB8Meta?.alias || [],
    redirect: indexT1dsTkqZB8Meta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/resources/marketplace/index.vue").then(m => m.default || m)
  },
  {
    name: indexT1dsTkqZB8Meta?.name ?? "resources-marketplace___nl",
    path: indexT1dsTkqZB8Meta?.path ?? "/nl/resources/marketplace",
    meta: indexT1dsTkqZB8Meta || {},
    alias: indexT1dsTkqZB8Meta?.alias || [],
    redirect: indexT1dsTkqZB8Meta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/resources/marketplace/index.vue").then(m => m.default || m)
  },
  {
    name: indexT1dsTkqZB8Meta?.name ?? "resources-marketplace___uk",
    path: indexT1dsTkqZB8Meta?.path ?? "/uk/resources/marketplace",
    meta: indexT1dsTkqZB8Meta || {},
    alias: indexT1dsTkqZB8Meta?.alias || [],
    redirect: indexT1dsTkqZB8Meta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/resources/marketplace/index.vue").then(m => m.default || m)
  },
  {
    name: indexT1dsTkqZB8Meta?.name ?? "resources-marketplace___de",
    path: indexT1dsTkqZB8Meta?.path ?? "/de/resources/marketplace",
    meta: indexT1dsTkqZB8Meta || {},
    alias: indexT1dsTkqZB8Meta?.alias || [],
    redirect: indexT1dsTkqZB8Meta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/resources/marketplace/index.vue").then(m => m.default || m)
  },
  {
    name: indexT1dsTkqZB8Meta?.name ?? "resources-marketplace___mx",
    path: indexT1dsTkqZB8Meta?.path ?? "/mx/resources/marketplace",
    meta: indexT1dsTkqZB8Meta || {},
    alias: indexT1dsTkqZB8Meta?.alias || [],
    redirect: indexT1dsTkqZB8Meta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/resources/marketplace/index.vue").then(m => m.default || m)
  },
  {
    name: indexT1dsTkqZB8Meta?.name ?? "resources-marketplace___fr",
    path: indexT1dsTkqZB8Meta?.path ?? "/fr/resources/marketplace",
    meta: indexT1dsTkqZB8Meta || {},
    alias: indexT1dsTkqZB8Meta?.alias || [],
    redirect: indexT1dsTkqZB8Meta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/resources/marketplace/index.vue").then(m => m.default || m)
  },
  {
    name: indexT1dsTkqZB8Meta?.name ?? "resources-marketplace___fr-ca",
    path: indexT1dsTkqZB8Meta?.path ?? "/fr-ca/resources/marketplace",
    meta: indexT1dsTkqZB8Meta || {},
    alias: indexT1dsTkqZB8Meta?.alias || [],
    redirect: indexT1dsTkqZB8Meta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/resources/marketplace/index.vue").then(m => m.default || m)
  },
  {
    name: build_45with_45samsarayqzEzKmprRMeta?.name ?? "resources-partner-programs-build-with-samsara___en-US",
    path: build_45with_45samsarayqzEzKmprRMeta?.path ?? "/resources/partner-programs/build-with-samsara",
    meta: build_45with_45samsarayqzEzKmprRMeta || {},
    alias: build_45with_45samsarayqzEzKmprRMeta?.alias || [],
    redirect: build_45with_45samsarayqzEzKmprRMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/resources/partner-programs/build-with-samsara.vue").then(m => m.default || m)
  },
  {
    name: build_45with_45samsarayqzEzKmprRMeta?.name ?? "resources-partner-programs-build-with-samsara___ca",
    path: build_45with_45samsarayqzEzKmprRMeta?.path ?? "/ca/resources/partner-programs/build-with-samsara",
    meta: build_45with_45samsarayqzEzKmprRMeta || {},
    alias: build_45with_45samsarayqzEzKmprRMeta?.alias || [],
    redirect: build_45with_45samsarayqzEzKmprRMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/resources/partner-programs/build-with-samsara.vue").then(m => m.default || m)
  },
  {
    name: build_45with_45samsarayqzEzKmprRMeta?.name ?? "resources-partner-programs-build-with-samsara___nl",
    path: build_45with_45samsarayqzEzKmprRMeta?.path ?? "/nl/resources/partner-programs/build-with-samsara",
    meta: build_45with_45samsarayqzEzKmprRMeta || {},
    alias: build_45with_45samsarayqzEzKmprRMeta?.alias || [],
    redirect: build_45with_45samsarayqzEzKmprRMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/resources/partner-programs/build-with-samsara.vue").then(m => m.default || m)
  },
  {
    name: build_45with_45samsarayqzEzKmprRMeta?.name ?? "resources-partner-programs-build-with-samsara___uk",
    path: build_45with_45samsarayqzEzKmprRMeta?.path ?? "/uk/resources/partner-programs/build-with-samsara",
    meta: build_45with_45samsarayqzEzKmprRMeta || {},
    alias: build_45with_45samsarayqzEzKmprRMeta?.alias || [],
    redirect: build_45with_45samsarayqzEzKmprRMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/resources/partner-programs/build-with-samsara.vue").then(m => m.default || m)
  },
  {
    name: build_45with_45samsarayqzEzKmprRMeta?.name ?? "resources-partner-programs-build-with-samsara___de",
    path: build_45with_45samsarayqzEzKmprRMeta?.path ?? "/de/resources/partner-programs/build-with-samsara",
    meta: build_45with_45samsarayqzEzKmprRMeta || {},
    alias: build_45with_45samsarayqzEzKmprRMeta?.alias || [],
    redirect: build_45with_45samsarayqzEzKmprRMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/resources/partner-programs/build-with-samsara.vue").then(m => m.default || m)
  },
  {
    name: build_45with_45samsarayqzEzKmprRMeta?.name ?? "resources-partner-programs-build-with-samsara___mx",
    path: build_45with_45samsarayqzEzKmprRMeta?.path ?? "/mx/resources/partner-programs/build-with-samsara",
    meta: build_45with_45samsarayqzEzKmprRMeta || {},
    alias: build_45with_45samsarayqzEzKmprRMeta?.alias || [],
    redirect: build_45with_45samsarayqzEzKmprRMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/resources/partner-programs/build-with-samsara.vue").then(m => m.default || m)
  },
  {
    name: build_45with_45samsarayqzEzKmprRMeta?.name ?? "resources-partner-programs-build-with-samsara___fr",
    path: build_45with_45samsarayqzEzKmprRMeta?.path ?? "/fr/resources/partner-programs/build-with-samsara",
    meta: build_45with_45samsarayqzEzKmprRMeta || {},
    alias: build_45with_45samsarayqzEzKmprRMeta?.alias || [],
    redirect: build_45with_45samsarayqzEzKmprRMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/resources/partner-programs/build-with-samsara.vue").then(m => m.default || m)
  },
  {
    name: build_45with_45samsarayqzEzKmprRMeta?.name ?? "resources-partner-programs-build-with-samsara___fr-ca",
    path: build_45with_45samsarayqzEzKmprRMeta?.path ?? "/fr-ca/resources/partner-programs/build-with-samsara",
    meta: build_45with_45samsarayqzEzKmprRMeta || {},
    alias: build_45with_45samsarayqzEzKmprRMeta?.alias || [],
    redirect: build_45with_45samsarayqzEzKmprRMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/resources/partner-programs/build-with-samsara.vue").then(m => m.default || m)
  },
  {
    name: evolve_45insurance_45programskUOg3lTuDXMeta?.name ?? "resources-partner-programs-evolve-insurance-programs___en-US",
    path: evolve_45insurance_45programskUOg3lTuDXMeta?.path ?? "/resources/partner-programs/evolve-insurance-programs",
    meta: evolve_45insurance_45programskUOg3lTuDXMeta || {},
    alias: evolve_45insurance_45programskUOg3lTuDXMeta?.alias || [],
    redirect: evolve_45insurance_45programskUOg3lTuDXMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/resources/partner-programs/evolve-insurance-programs.vue").then(m => m.default || m)
  },
  {
    name: evolve_45insurance_45programskUOg3lTuDXMeta?.name ?? "resources-partner-programs-evolve-insurance-programs___ca",
    path: evolve_45insurance_45programskUOg3lTuDXMeta?.path ?? "/ca/resources/partner-programs/evolve-insurance-programs",
    meta: evolve_45insurance_45programskUOg3lTuDXMeta || {},
    alias: evolve_45insurance_45programskUOg3lTuDXMeta?.alias || [],
    redirect: evolve_45insurance_45programskUOg3lTuDXMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/resources/partner-programs/evolve-insurance-programs.vue").then(m => m.default || m)
  },
  {
    name: evolve_45insurance_45programskUOg3lTuDXMeta?.name ?? "resources-partner-programs-evolve-insurance-programs___nl",
    path: evolve_45insurance_45programskUOg3lTuDXMeta?.path ?? "/nl/resources/partner-programs/evolve-insurance-programs",
    meta: evolve_45insurance_45programskUOg3lTuDXMeta || {},
    alias: evolve_45insurance_45programskUOg3lTuDXMeta?.alias || [],
    redirect: evolve_45insurance_45programskUOg3lTuDXMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/resources/partner-programs/evolve-insurance-programs.vue").then(m => m.default || m)
  },
  {
    name: evolve_45insurance_45programskUOg3lTuDXMeta?.name ?? "resources-partner-programs-evolve-insurance-programs___uk",
    path: evolve_45insurance_45programskUOg3lTuDXMeta?.path ?? "/uk/resources/partner-programs/evolve-insurance-programs",
    meta: evolve_45insurance_45programskUOg3lTuDXMeta || {},
    alias: evolve_45insurance_45programskUOg3lTuDXMeta?.alias || [],
    redirect: evolve_45insurance_45programskUOg3lTuDXMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/resources/partner-programs/evolve-insurance-programs.vue").then(m => m.default || m)
  },
  {
    name: evolve_45insurance_45programskUOg3lTuDXMeta?.name ?? "resources-partner-programs-evolve-insurance-programs___de",
    path: evolve_45insurance_45programskUOg3lTuDXMeta?.path ?? "/de/resources/partner-programs/evolve-insurance-programs",
    meta: evolve_45insurance_45programskUOg3lTuDXMeta || {},
    alias: evolve_45insurance_45programskUOg3lTuDXMeta?.alias || [],
    redirect: evolve_45insurance_45programskUOg3lTuDXMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/resources/partner-programs/evolve-insurance-programs.vue").then(m => m.default || m)
  },
  {
    name: evolve_45insurance_45programskUOg3lTuDXMeta?.name ?? "resources-partner-programs-evolve-insurance-programs___mx",
    path: evolve_45insurance_45programskUOg3lTuDXMeta?.path ?? "/mx/resources/partner-programs/evolve-insurance-programs",
    meta: evolve_45insurance_45programskUOg3lTuDXMeta || {},
    alias: evolve_45insurance_45programskUOg3lTuDXMeta?.alias || [],
    redirect: evolve_45insurance_45programskUOg3lTuDXMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/resources/partner-programs/evolve-insurance-programs.vue").then(m => m.default || m)
  },
  {
    name: evolve_45insurance_45programskUOg3lTuDXMeta?.name ?? "resources-partner-programs-evolve-insurance-programs___fr",
    path: evolve_45insurance_45programskUOg3lTuDXMeta?.path ?? "/fr/resources/partner-programs/evolve-insurance-programs",
    meta: evolve_45insurance_45programskUOg3lTuDXMeta || {},
    alias: evolve_45insurance_45programskUOg3lTuDXMeta?.alias || [],
    redirect: evolve_45insurance_45programskUOg3lTuDXMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/resources/partner-programs/evolve-insurance-programs.vue").then(m => m.default || m)
  },
  {
    name: evolve_45insurance_45programskUOg3lTuDXMeta?.name ?? "resources-partner-programs-evolve-insurance-programs___fr-ca",
    path: evolve_45insurance_45programskUOg3lTuDXMeta?.path ?? "/fr-ca/resources/partner-programs/evolve-insurance-programs",
    meta: evolve_45insurance_45programskUOg3lTuDXMeta || {},
    alias: evolve_45insurance_45programskUOg3lTuDXMeta?.alias || [],
    redirect: evolve_45insurance_45programskUOg3lTuDXMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/resources/partner-programs/evolve-insurance-programs.vue").then(m => m.default || m)
  },
  {
    name: indexM4UqZ2xKlUMeta?.name ?? "resources-partner-programs___en-US",
    path: indexM4UqZ2xKlUMeta?.path ?? "/resources/partner-programs",
    meta: indexM4UqZ2xKlUMeta || {},
    alias: indexM4UqZ2xKlUMeta?.alias || [],
    redirect: indexM4UqZ2xKlUMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/resources/partner-programs/index.vue").then(m => m.default || m)
  },
  {
    name: indexM4UqZ2xKlUMeta?.name ?? "resources-partner-programs___ca",
    path: indexM4UqZ2xKlUMeta?.path ?? "/ca/resources/partner-programs",
    meta: indexM4UqZ2xKlUMeta || {},
    alias: indexM4UqZ2xKlUMeta?.alias || [],
    redirect: indexM4UqZ2xKlUMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/resources/partner-programs/index.vue").then(m => m.default || m)
  },
  {
    name: indexM4UqZ2xKlUMeta?.name ?? "resources-partner-programs___nl",
    path: indexM4UqZ2xKlUMeta?.path ?? "/nl/resources/partner-programs",
    meta: indexM4UqZ2xKlUMeta || {},
    alias: indexM4UqZ2xKlUMeta?.alias || [],
    redirect: indexM4UqZ2xKlUMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/resources/partner-programs/index.vue").then(m => m.default || m)
  },
  {
    name: indexM4UqZ2xKlUMeta?.name ?? "resources-partner-programs___uk",
    path: indexM4UqZ2xKlUMeta?.path ?? "/uk/resources/partner-programs",
    meta: indexM4UqZ2xKlUMeta || {},
    alias: indexM4UqZ2xKlUMeta?.alias || [],
    redirect: indexM4UqZ2xKlUMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/resources/partner-programs/index.vue").then(m => m.default || m)
  },
  {
    name: indexM4UqZ2xKlUMeta?.name ?? "resources-partner-programs___de",
    path: indexM4UqZ2xKlUMeta?.path ?? "/de/resources/partner-programs",
    meta: indexM4UqZ2xKlUMeta || {},
    alias: indexM4UqZ2xKlUMeta?.alias || [],
    redirect: indexM4UqZ2xKlUMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/resources/partner-programs/index.vue").then(m => m.default || m)
  },
  {
    name: indexM4UqZ2xKlUMeta?.name ?? "resources-partner-programs___mx",
    path: indexM4UqZ2xKlUMeta?.path ?? "/mx/resources/partner-programs",
    meta: indexM4UqZ2xKlUMeta || {},
    alias: indexM4UqZ2xKlUMeta?.alias || [],
    redirect: indexM4UqZ2xKlUMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/resources/partner-programs/index.vue").then(m => m.default || m)
  },
  {
    name: indexM4UqZ2xKlUMeta?.name ?? "resources-partner-programs___fr",
    path: indexM4UqZ2xKlUMeta?.path ?? "/fr/resources/partner-programs",
    meta: indexM4UqZ2xKlUMeta || {},
    alias: indexM4UqZ2xKlUMeta?.alias || [],
    redirect: indexM4UqZ2xKlUMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/resources/partner-programs/index.vue").then(m => m.default || m)
  },
  {
    name: indexM4UqZ2xKlUMeta?.name ?? "resources-partner-programs___fr-ca",
    path: indexM4UqZ2xKlUMeta?.path ?? "/fr-ca/resources/partner-programs",
    meta: indexM4UqZ2xKlUMeta || {},
    alias: indexM4UqZ2xKlUMeta?.alias || [],
    redirect: indexM4UqZ2xKlUMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/resources/partner-programs/index.vue").then(m => m.default || m)
  },
  {
    name: provide_45servicesXuQHIaDk0MMeta?.name ?? "resources-partner-programs-provide-services___en-US",
    path: provide_45servicesXuQHIaDk0MMeta?.path ?? "/resources/partner-programs/provide-services",
    meta: provide_45servicesXuQHIaDk0MMeta || {},
    alias: provide_45servicesXuQHIaDk0MMeta?.alias || [],
    redirect: provide_45servicesXuQHIaDk0MMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/resources/partner-programs/provide-services.vue").then(m => m.default || m)
  },
  {
    name: provide_45servicesXuQHIaDk0MMeta?.name ?? "resources-partner-programs-provide-services___ca",
    path: provide_45servicesXuQHIaDk0MMeta?.path ?? "/ca/resources/partner-programs/provide-services",
    meta: provide_45servicesXuQHIaDk0MMeta || {},
    alias: provide_45servicesXuQHIaDk0MMeta?.alias || [],
    redirect: provide_45servicesXuQHIaDk0MMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/resources/partner-programs/provide-services.vue").then(m => m.default || m)
  },
  {
    name: provide_45servicesXuQHIaDk0MMeta?.name ?? "resources-partner-programs-provide-services___nl",
    path: provide_45servicesXuQHIaDk0MMeta?.path ?? "/nl/resources/partner-programs/provide-services",
    meta: provide_45servicesXuQHIaDk0MMeta || {},
    alias: provide_45servicesXuQHIaDk0MMeta?.alias || [],
    redirect: provide_45servicesXuQHIaDk0MMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/resources/partner-programs/provide-services.vue").then(m => m.default || m)
  },
  {
    name: provide_45servicesXuQHIaDk0MMeta?.name ?? "resources-partner-programs-provide-services___uk",
    path: provide_45servicesXuQHIaDk0MMeta?.path ?? "/uk/resources/partner-programs/provide-services",
    meta: provide_45servicesXuQHIaDk0MMeta || {},
    alias: provide_45servicesXuQHIaDk0MMeta?.alias || [],
    redirect: provide_45servicesXuQHIaDk0MMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/resources/partner-programs/provide-services.vue").then(m => m.default || m)
  },
  {
    name: provide_45servicesXuQHIaDk0MMeta?.name ?? "resources-partner-programs-provide-services___de",
    path: provide_45servicesXuQHIaDk0MMeta?.path ?? "/de/resources/partner-programs/provide-services",
    meta: provide_45servicesXuQHIaDk0MMeta || {},
    alias: provide_45servicesXuQHIaDk0MMeta?.alias || [],
    redirect: provide_45servicesXuQHIaDk0MMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/resources/partner-programs/provide-services.vue").then(m => m.default || m)
  },
  {
    name: provide_45servicesXuQHIaDk0MMeta?.name ?? "resources-partner-programs-provide-services___mx",
    path: provide_45servicesXuQHIaDk0MMeta?.path ?? "/mx/resources/partner-programs/provide-services",
    meta: provide_45servicesXuQHIaDk0MMeta || {},
    alias: provide_45servicesXuQHIaDk0MMeta?.alias || [],
    redirect: provide_45servicesXuQHIaDk0MMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/resources/partner-programs/provide-services.vue").then(m => m.default || m)
  },
  {
    name: provide_45servicesXuQHIaDk0MMeta?.name ?? "resources-partner-programs-provide-services___fr",
    path: provide_45servicesXuQHIaDk0MMeta?.path ?? "/fr/resources/partner-programs/provide-services",
    meta: provide_45servicesXuQHIaDk0MMeta || {},
    alias: provide_45servicesXuQHIaDk0MMeta?.alias || [],
    redirect: provide_45servicesXuQHIaDk0MMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/resources/partner-programs/provide-services.vue").then(m => m.default || m)
  },
  {
    name: provide_45servicesXuQHIaDk0MMeta?.name ?? "resources-partner-programs-provide-services___fr-ca",
    path: provide_45servicesXuQHIaDk0MMeta?.path ?? "/fr-ca/resources/partner-programs/provide-services",
    meta: provide_45servicesXuQHIaDk0MMeta || {},
    alias: provide_45servicesXuQHIaDk0MMeta?.alias || [],
    redirect: provide_45servicesXuQHIaDk0MMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/resources/partner-programs/provide-services.vue").then(m => m.default || m)
  },
  {
    name: sell_45samsaraoGpoWOgZUeMeta?.name ?? "resources-partner-programs-sell-samsara___en-US",
    path: sell_45samsaraoGpoWOgZUeMeta?.path ?? "/resources/partner-programs/sell-samsara",
    meta: sell_45samsaraoGpoWOgZUeMeta || {},
    alias: sell_45samsaraoGpoWOgZUeMeta?.alias || [],
    redirect: sell_45samsaraoGpoWOgZUeMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/resources/partner-programs/sell-samsara.vue").then(m => m.default || m)
  },
  {
    name: sell_45samsaraoGpoWOgZUeMeta?.name ?? "resources-partner-programs-sell-samsara___ca",
    path: sell_45samsaraoGpoWOgZUeMeta?.path ?? "/ca/resources/partner-programs/sell-samsara",
    meta: sell_45samsaraoGpoWOgZUeMeta || {},
    alias: sell_45samsaraoGpoWOgZUeMeta?.alias || [],
    redirect: sell_45samsaraoGpoWOgZUeMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/resources/partner-programs/sell-samsara.vue").then(m => m.default || m)
  },
  {
    name: sell_45samsaraoGpoWOgZUeMeta?.name ?? "resources-partner-programs-sell-samsara___nl",
    path: sell_45samsaraoGpoWOgZUeMeta?.path ?? "/nl/resources/partner-programs/sell-samsara",
    meta: sell_45samsaraoGpoWOgZUeMeta || {},
    alias: sell_45samsaraoGpoWOgZUeMeta?.alias || [],
    redirect: sell_45samsaraoGpoWOgZUeMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/resources/partner-programs/sell-samsara.vue").then(m => m.default || m)
  },
  {
    name: sell_45samsaraoGpoWOgZUeMeta?.name ?? "resources-partner-programs-sell-samsara___uk",
    path: sell_45samsaraoGpoWOgZUeMeta?.path ?? "/uk/resources/partner-programs/sell-samsara",
    meta: sell_45samsaraoGpoWOgZUeMeta || {},
    alias: sell_45samsaraoGpoWOgZUeMeta?.alias || [],
    redirect: sell_45samsaraoGpoWOgZUeMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/resources/partner-programs/sell-samsara.vue").then(m => m.default || m)
  },
  {
    name: sell_45samsaraoGpoWOgZUeMeta?.name ?? "resources-partner-programs-sell-samsara___de",
    path: sell_45samsaraoGpoWOgZUeMeta?.path ?? "/de/resources/partner-programs/sell-samsara",
    meta: sell_45samsaraoGpoWOgZUeMeta || {},
    alias: sell_45samsaraoGpoWOgZUeMeta?.alias || [],
    redirect: sell_45samsaraoGpoWOgZUeMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/resources/partner-programs/sell-samsara.vue").then(m => m.default || m)
  },
  {
    name: sell_45samsaraoGpoWOgZUeMeta?.name ?? "resources-partner-programs-sell-samsara___mx",
    path: sell_45samsaraoGpoWOgZUeMeta?.path ?? "/mx/resources/partner-programs/sell-samsara",
    meta: sell_45samsaraoGpoWOgZUeMeta || {},
    alias: sell_45samsaraoGpoWOgZUeMeta?.alias || [],
    redirect: sell_45samsaraoGpoWOgZUeMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/resources/partner-programs/sell-samsara.vue").then(m => m.default || m)
  },
  {
    name: sell_45samsaraoGpoWOgZUeMeta?.name ?? "resources-partner-programs-sell-samsara___fr",
    path: sell_45samsaraoGpoWOgZUeMeta?.path ?? "/fr/resources/partner-programs/sell-samsara",
    meta: sell_45samsaraoGpoWOgZUeMeta || {},
    alias: sell_45samsaraoGpoWOgZUeMeta?.alias || [],
    redirect: sell_45samsaraoGpoWOgZUeMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/resources/partner-programs/sell-samsara.vue").then(m => m.default || m)
  },
  {
    name: sell_45samsaraoGpoWOgZUeMeta?.name ?? "resources-partner-programs-sell-samsara___fr-ca",
    path: sell_45samsaraoGpoWOgZUeMeta?.path ?? "/fr-ca/resources/partner-programs/sell-samsara",
    meta: sell_45samsaraoGpoWOgZUeMeta || {},
    alias: sell_45samsaraoGpoWOgZUeMeta?.alias || [],
    redirect: sell_45samsaraoGpoWOgZUeMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/resources/partner-programs/sell-samsara.vue").then(m => m.default || m)
  },
  {
    name: tour5D7FRGO5PoMeta?.name ?? "resources-tour___en-US",
    path: tour5D7FRGO5PoMeta?.path ?? "/resources/tour",
    meta: tour5D7FRGO5PoMeta || {},
    alias: tour5D7FRGO5PoMeta?.alias || [],
    redirect: tour5D7FRGO5PoMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/resources/tour.vue").then(m => m.default || m)
  },
  {
    name: tour5D7FRGO5PoMeta?.name ?? "resources-tour___ca",
    path: tour5D7FRGO5PoMeta?.path ?? "/ca/resources/tour",
    meta: tour5D7FRGO5PoMeta || {},
    alias: tour5D7FRGO5PoMeta?.alias || [],
    redirect: tour5D7FRGO5PoMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/resources/tour.vue").then(m => m.default || m)
  },
  {
    name: tour5D7FRGO5PoMeta?.name ?? "resources-tour___nl",
    path: tour5D7FRGO5PoMeta?.path ?? "/nl/resources/tour",
    meta: tour5D7FRGO5PoMeta || {},
    alias: tour5D7FRGO5PoMeta?.alias || [],
    redirect: tour5D7FRGO5PoMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/resources/tour.vue").then(m => m.default || m)
  },
  {
    name: tour5D7FRGO5PoMeta?.name ?? "resources-tour___uk",
    path: tour5D7FRGO5PoMeta?.path ?? "/uk/resources/tour",
    meta: tour5D7FRGO5PoMeta || {},
    alias: tour5D7FRGO5PoMeta?.alias || [],
    redirect: tour5D7FRGO5PoMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/resources/tour.vue").then(m => m.default || m)
  },
  {
    name: tour5D7FRGO5PoMeta?.name ?? "resources-tour___de",
    path: tour5D7FRGO5PoMeta?.path ?? "/de/resources/tour",
    meta: tour5D7FRGO5PoMeta || {},
    alias: tour5D7FRGO5PoMeta?.alias || [],
    redirect: tour5D7FRGO5PoMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/resources/tour.vue").then(m => m.default || m)
  },
  {
    name: tour5D7FRGO5PoMeta?.name ?? "resources-tour___mx",
    path: tour5D7FRGO5PoMeta?.path ?? "/mx/resources/tour",
    meta: tour5D7FRGO5PoMeta || {},
    alias: tour5D7FRGO5PoMeta?.alias || [],
    redirect: tour5D7FRGO5PoMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/resources/tour.vue").then(m => m.default || m)
  },
  {
    name: tour5D7FRGO5PoMeta?.name ?? "resources-tour___fr",
    path: tour5D7FRGO5PoMeta?.path ?? "/fr/resources/tour",
    meta: tour5D7FRGO5PoMeta || {},
    alias: tour5D7FRGO5PoMeta?.alias || [],
    redirect: tour5D7FRGO5PoMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/resources/tour.vue").then(m => m.default || m)
  },
  {
    name: tour5D7FRGO5PoMeta?.name ?? "resources-tour___fr-ca",
    path: tour5D7FRGO5PoMeta?.path ?? "/fr-ca/resources/tour",
    meta: tour5D7FRGO5PoMeta || {},
    alias: tour5D7FRGO5PoMeta?.alias || [],
    redirect: tour5D7FRGO5PoMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/resources/tour.vue").then(m => m.default || m)
  },
  {
    name: videosg60JwpxGjcMeta?.name ?? "resources-videos___en-US",
    path: videosg60JwpxGjcMeta?.path ?? "/resources/videos",
    meta: videosg60JwpxGjcMeta || {},
    alias: videosg60JwpxGjcMeta?.alias || [],
    redirect: videosg60JwpxGjcMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/resources/videos.vue").then(m => m.default || m)
  },
  {
    name: videosg60JwpxGjcMeta?.name ?? "resources-videos___ca",
    path: videosg60JwpxGjcMeta?.path ?? "/ca/resources/videos",
    meta: videosg60JwpxGjcMeta || {},
    alias: videosg60JwpxGjcMeta?.alias || [],
    redirect: videosg60JwpxGjcMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/resources/videos.vue").then(m => m.default || m)
  },
  {
    name: videosg60JwpxGjcMeta?.name ?? "resources-videos___nl",
    path: videosg60JwpxGjcMeta?.path ?? "/nl/resources/videos",
    meta: videosg60JwpxGjcMeta || {},
    alias: videosg60JwpxGjcMeta?.alias || [],
    redirect: videosg60JwpxGjcMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/resources/videos.vue").then(m => m.default || m)
  },
  {
    name: videosg60JwpxGjcMeta?.name ?? "resources-videos___uk",
    path: videosg60JwpxGjcMeta?.path ?? "/uk/resources/videos",
    meta: videosg60JwpxGjcMeta || {},
    alias: videosg60JwpxGjcMeta?.alias || [],
    redirect: videosg60JwpxGjcMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/resources/videos.vue").then(m => m.default || m)
  },
  {
    name: videosg60JwpxGjcMeta?.name ?? "resources-videos___de",
    path: videosg60JwpxGjcMeta?.path ?? "/de/resources/videos",
    meta: videosg60JwpxGjcMeta || {},
    alias: videosg60JwpxGjcMeta?.alias || [],
    redirect: videosg60JwpxGjcMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/resources/videos.vue").then(m => m.default || m)
  },
  {
    name: videosg60JwpxGjcMeta?.name ?? "resources-videos___mx",
    path: videosg60JwpxGjcMeta?.path ?? "/mx/resources/videos",
    meta: videosg60JwpxGjcMeta || {},
    alias: videosg60JwpxGjcMeta?.alias || [],
    redirect: videosg60JwpxGjcMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/resources/videos.vue").then(m => m.default || m)
  },
  {
    name: videosg60JwpxGjcMeta?.name ?? "resources-videos___fr",
    path: videosg60JwpxGjcMeta?.path ?? "/fr/resources/videos",
    meta: videosg60JwpxGjcMeta || {},
    alias: videosg60JwpxGjcMeta?.alias || [],
    redirect: videosg60JwpxGjcMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/resources/videos.vue").then(m => m.default || m)
  },
  {
    name: videosg60JwpxGjcMeta?.name ?? "resources-videos___fr-ca",
    path: videosg60JwpxGjcMeta?.path ?? "/fr-ca/resources/videos",
    meta: videosg60JwpxGjcMeta || {},
    alias: videosg60JwpxGjcMeta?.alias || [],
    redirect: videosg60JwpxGjcMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/resources/videos.vue").then(m => m.default || m)
  },
  {
    name: index0z1gkwV7WBMeta?.name ?? "roi-calculator___en-US",
    path: index0z1gkwV7WBMeta?.path ?? "/roi-calculator",
    meta: index0z1gkwV7WBMeta || {},
    alias: index0z1gkwV7WBMeta?.alias || [],
    redirect: index0z1gkwV7WBMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/roi-calculator/index.vue").then(m => m.default || m)
  },
  {
    name: index0z1gkwV7WBMeta?.name ?? "roi-calculator___ca",
    path: index0z1gkwV7WBMeta?.path ?? "/ca/roi-calculator",
    meta: index0z1gkwV7WBMeta || {},
    alias: index0z1gkwV7WBMeta?.alias || [],
    redirect: index0z1gkwV7WBMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/roi-calculator/index.vue").then(m => m.default || m)
  },
  {
    name: index0z1gkwV7WBMeta?.name ?? "roi-calculator___nl",
    path: index0z1gkwV7WBMeta?.path ?? "/nl/roi-calculator",
    meta: index0z1gkwV7WBMeta || {},
    alias: index0z1gkwV7WBMeta?.alias || [],
    redirect: index0z1gkwV7WBMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/roi-calculator/index.vue").then(m => m.default || m)
  },
  {
    name: index0z1gkwV7WBMeta?.name ?? "roi-calculator___uk",
    path: index0z1gkwV7WBMeta?.path ?? "/uk/roi-calculator",
    meta: index0z1gkwV7WBMeta || {},
    alias: index0z1gkwV7WBMeta?.alias || [],
    redirect: index0z1gkwV7WBMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/roi-calculator/index.vue").then(m => m.default || m)
  },
  {
    name: index0z1gkwV7WBMeta?.name ?? "roi-calculator___de",
    path: index0z1gkwV7WBMeta?.path ?? "/de/roi-calculator",
    meta: index0z1gkwV7WBMeta || {},
    alias: index0z1gkwV7WBMeta?.alias || [],
    redirect: index0z1gkwV7WBMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/roi-calculator/index.vue").then(m => m.default || m)
  },
  {
    name: index0z1gkwV7WBMeta?.name ?? "roi-calculator___mx",
    path: index0z1gkwV7WBMeta?.path ?? "/mx/roi-calculator",
    meta: index0z1gkwV7WBMeta || {},
    alias: index0z1gkwV7WBMeta?.alias || [],
    redirect: index0z1gkwV7WBMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/roi-calculator/index.vue").then(m => m.default || m)
  },
  {
    name: index0z1gkwV7WBMeta?.name ?? "roi-calculator___fr",
    path: index0z1gkwV7WBMeta?.path ?? "/fr/roi-calculator",
    meta: index0z1gkwV7WBMeta || {},
    alias: index0z1gkwV7WBMeta?.alias || [],
    redirect: index0z1gkwV7WBMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/roi-calculator/index.vue").then(m => m.default || m)
  },
  {
    name: index0z1gkwV7WBMeta?.name ?? "roi-calculator___fr-ca",
    path: index0z1gkwV7WBMeta?.path ?? "/fr-ca/roi-calculator",
    meta: index0z1gkwV7WBMeta || {},
    alias: index0z1gkwV7WBMeta?.alias || [],
    redirect: index0z1gkwV7WBMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/roi-calculator/index.vue").then(m => m.default || m)
  },
  {
    name: samsara_45venturesN2a3saOJIJMeta?.name ?? "samsara-ventures___en-US",
    path: samsara_45venturesN2a3saOJIJMeta?.path ?? "/samsara-ventures",
    meta: samsara_45venturesN2a3saOJIJMeta || {},
    alias: samsara_45venturesN2a3saOJIJMeta?.alias || [],
    redirect: samsara_45venturesN2a3saOJIJMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/samsara-ventures.vue").then(m => m.default || m)
  },
  {
    name: samsara_45venturesN2a3saOJIJMeta?.name ?? "samsara-ventures___ca",
    path: samsara_45venturesN2a3saOJIJMeta?.path ?? "/ca/samsara-ventures",
    meta: samsara_45venturesN2a3saOJIJMeta || {},
    alias: samsara_45venturesN2a3saOJIJMeta?.alias || [],
    redirect: samsara_45venturesN2a3saOJIJMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/samsara-ventures.vue").then(m => m.default || m)
  },
  {
    name: samsara_45venturesN2a3saOJIJMeta?.name ?? "samsara-ventures___nl",
    path: samsara_45venturesN2a3saOJIJMeta?.path ?? "/nl/samsara-ventures",
    meta: samsara_45venturesN2a3saOJIJMeta || {},
    alias: samsara_45venturesN2a3saOJIJMeta?.alias || [],
    redirect: samsara_45venturesN2a3saOJIJMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/samsara-ventures.vue").then(m => m.default || m)
  },
  {
    name: samsara_45venturesN2a3saOJIJMeta?.name ?? "samsara-ventures___uk",
    path: samsara_45venturesN2a3saOJIJMeta?.path ?? "/uk/samsara-ventures",
    meta: samsara_45venturesN2a3saOJIJMeta || {},
    alias: samsara_45venturesN2a3saOJIJMeta?.alias || [],
    redirect: samsara_45venturesN2a3saOJIJMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/samsara-ventures.vue").then(m => m.default || m)
  },
  {
    name: samsara_45venturesN2a3saOJIJMeta?.name ?? "samsara-ventures___de",
    path: samsara_45venturesN2a3saOJIJMeta?.path ?? "/de/samsara-ventures",
    meta: samsara_45venturesN2a3saOJIJMeta || {},
    alias: samsara_45venturesN2a3saOJIJMeta?.alias || [],
    redirect: samsara_45venturesN2a3saOJIJMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/samsara-ventures.vue").then(m => m.default || m)
  },
  {
    name: samsara_45venturesN2a3saOJIJMeta?.name ?? "samsara-ventures___mx",
    path: samsara_45venturesN2a3saOJIJMeta?.path ?? "/mx/samsara-ventures",
    meta: samsara_45venturesN2a3saOJIJMeta || {},
    alias: samsara_45venturesN2a3saOJIJMeta?.alias || [],
    redirect: samsara_45venturesN2a3saOJIJMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/samsara-ventures.vue").then(m => m.default || m)
  },
  {
    name: samsara_45venturesN2a3saOJIJMeta?.name ?? "samsara-ventures___fr",
    path: samsara_45venturesN2a3saOJIJMeta?.path ?? "/fr/samsara-ventures",
    meta: samsara_45venturesN2a3saOJIJMeta || {},
    alias: samsara_45venturesN2a3saOJIJMeta?.alias || [],
    redirect: samsara_45venturesN2a3saOJIJMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/samsara-ventures.vue").then(m => m.default || m)
  },
  {
    name: samsara_45venturesN2a3saOJIJMeta?.name ?? "samsara-ventures___fr-ca",
    path: samsara_45venturesN2a3saOJIJMeta?.path ?? "/fr-ca/samsara-ventures",
    meta: samsara_45venturesN2a3saOJIJMeta || {},
    alias: samsara_45venturesN2a3saOJIJMeta?.alias || [],
    redirect: samsara_45venturesN2a3saOJIJMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/samsara-ventures.vue").then(m => m.default || m)
  },
  {
    name: security_45summit1SxdN6mo1RMeta?.name ?? "security-summit___en-US",
    path: security_45summit1SxdN6mo1RMeta?.path ?? "/security-summit",
    meta: security_45summit1SxdN6mo1RMeta || {},
    alias: security_45summit1SxdN6mo1RMeta?.alias || [],
    redirect: security_45summit1SxdN6mo1RMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/security-summit.vue").then(m => m.default || m)
  },
  {
    name: security_45summit1SxdN6mo1RMeta?.name ?? "security-summit___ca",
    path: security_45summit1SxdN6mo1RMeta?.path ?? "/ca/security-summit",
    meta: security_45summit1SxdN6mo1RMeta || {},
    alias: security_45summit1SxdN6mo1RMeta?.alias || [],
    redirect: security_45summit1SxdN6mo1RMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/security-summit.vue").then(m => m.default || m)
  },
  {
    name: security_45summit1SxdN6mo1RMeta?.name ?? "security-summit___nl",
    path: security_45summit1SxdN6mo1RMeta?.path ?? "/nl/security-summit",
    meta: security_45summit1SxdN6mo1RMeta || {},
    alias: security_45summit1SxdN6mo1RMeta?.alias || [],
    redirect: security_45summit1SxdN6mo1RMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/security-summit.vue").then(m => m.default || m)
  },
  {
    name: security_45summit1SxdN6mo1RMeta?.name ?? "security-summit___uk",
    path: security_45summit1SxdN6mo1RMeta?.path ?? "/uk/security-summit",
    meta: security_45summit1SxdN6mo1RMeta || {},
    alias: security_45summit1SxdN6mo1RMeta?.alias || [],
    redirect: security_45summit1SxdN6mo1RMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/security-summit.vue").then(m => m.default || m)
  },
  {
    name: security_45summit1SxdN6mo1RMeta?.name ?? "security-summit___de",
    path: security_45summit1SxdN6mo1RMeta?.path ?? "/de/security-summit",
    meta: security_45summit1SxdN6mo1RMeta || {},
    alias: security_45summit1SxdN6mo1RMeta?.alias || [],
    redirect: security_45summit1SxdN6mo1RMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/security-summit.vue").then(m => m.default || m)
  },
  {
    name: security_45summit1SxdN6mo1RMeta?.name ?? "security-summit___mx",
    path: security_45summit1SxdN6mo1RMeta?.path ?? "/mx/security-summit",
    meta: security_45summit1SxdN6mo1RMeta || {},
    alias: security_45summit1SxdN6mo1RMeta?.alias || [],
    redirect: security_45summit1SxdN6mo1RMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/security-summit.vue").then(m => m.default || m)
  },
  {
    name: security_45summit1SxdN6mo1RMeta?.name ?? "security-summit___fr",
    path: security_45summit1SxdN6mo1RMeta?.path ?? "/fr/security-summit",
    meta: security_45summit1SxdN6mo1RMeta || {},
    alias: security_45summit1SxdN6mo1RMeta?.alias || [],
    redirect: security_45summit1SxdN6mo1RMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/security-summit.vue").then(m => m.default || m)
  },
  {
    name: security_45summit1SxdN6mo1RMeta?.name ?? "security-summit___fr-ca",
    path: security_45summit1SxdN6mo1RMeta?.path ?? "/fr-ca/security-summit",
    meta: security_45summit1SxdN6mo1RMeta || {},
    alias: security_45summit1SxdN6mo1RMeta?.alias || [],
    redirect: security_45summit1SxdN6mo1RMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/security-summit.vue").then(m => m.default || m)
  },
  {
    name: _91slug_932Dl9kvOCW3Meta?.name ?? "solutions-slug___en-US",
    path: _91slug_932Dl9kvOCW3Meta?.path ?? "/solutions/:slug()",
    meta: _91slug_932Dl9kvOCW3Meta || {},
    alias: _91slug_932Dl9kvOCW3Meta?.alias || [],
    redirect: _91slug_932Dl9kvOCW3Meta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/solutions/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_932Dl9kvOCW3Meta?.name ?? "solutions-slug___ca",
    path: _91slug_932Dl9kvOCW3Meta?.path ?? "/ca/solutions/:slug()",
    meta: _91slug_932Dl9kvOCW3Meta || {},
    alias: _91slug_932Dl9kvOCW3Meta?.alias || [],
    redirect: _91slug_932Dl9kvOCW3Meta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/solutions/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_932Dl9kvOCW3Meta?.name ?? "solutions-slug___nl",
    path: _91slug_932Dl9kvOCW3Meta?.path ?? "/nl/solutions/:slug()",
    meta: _91slug_932Dl9kvOCW3Meta || {},
    alias: _91slug_932Dl9kvOCW3Meta?.alias || [],
    redirect: _91slug_932Dl9kvOCW3Meta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/solutions/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_932Dl9kvOCW3Meta?.name ?? "solutions-slug___uk",
    path: _91slug_932Dl9kvOCW3Meta?.path ?? "/uk/solutions/:slug()",
    meta: _91slug_932Dl9kvOCW3Meta || {},
    alias: _91slug_932Dl9kvOCW3Meta?.alias || [],
    redirect: _91slug_932Dl9kvOCW3Meta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/solutions/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_932Dl9kvOCW3Meta?.name ?? "solutions-slug___de",
    path: _91slug_932Dl9kvOCW3Meta?.path ?? "/de/solutions/:slug()",
    meta: _91slug_932Dl9kvOCW3Meta || {},
    alias: _91slug_932Dl9kvOCW3Meta?.alias || [],
    redirect: _91slug_932Dl9kvOCW3Meta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/solutions/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_932Dl9kvOCW3Meta?.name ?? "solutions-slug___mx",
    path: _91slug_932Dl9kvOCW3Meta?.path ?? "/mx/solutions/:slug()",
    meta: _91slug_932Dl9kvOCW3Meta || {},
    alias: _91slug_932Dl9kvOCW3Meta?.alias || [],
    redirect: _91slug_932Dl9kvOCW3Meta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/solutions/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_932Dl9kvOCW3Meta?.name ?? "solutions-slug___fr",
    path: _91slug_932Dl9kvOCW3Meta?.path ?? "/fr/solutions/:slug()",
    meta: _91slug_932Dl9kvOCW3Meta || {},
    alias: _91slug_932Dl9kvOCW3Meta?.alias || [],
    redirect: _91slug_932Dl9kvOCW3Meta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/solutions/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_932Dl9kvOCW3Meta?.name ?? "solutions-slug___fr-ca",
    path: _91slug_932Dl9kvOCW3Meta?.path ?? "/fr-ca/solutions/:slug()",
    meta: _91slug_932Dl9kvOCW3Meta || {},
    alias: _91slug_932Dl9kvOCW3Meta?.alias || [],
    redirect: _91slug_932Dl9kvOCW3Meta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/solutions/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexsQQZY9b02hMeta?.name ?? "solutions___en-US",
    path: indexsQQZY9b02hMeta?.path ?? "/solutions",
    meta: indexsQQZY9b02hMeta || {},
    alias: indexsQQZY9b02hMeta?.alias || [],
    redirect: indexsQQZY9b02hMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/solutions/index.vue").then(m => m.default || m)
  },
  {
    name: indexsQQZY9b02hMeta?.name ?? "solutions___ca",
    path: indexsQQZY9b02hMeta?.path ?? "/ca/solutions",
    meta: indexsQQZY9b02hMeta || {},
    alias: indexsQQZY9b02hMeta?.alias || [],
    redirect: indexsQQZY9b02hMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/solutions/index.vue").then(m => m.default || m)
  },
  {
    name: indexsQQZY9b02hMeta?.name ?? "solutions___nl",
    path: indexsQQZY9b02hMeta?.path ?? "/nl/solutions",
    meta: indexsQQZY9b02hMeta || {},
    alias: indexsQQZY9b02hMeta?.alias || [],
    redirect: indexsQQZY9b02hMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/solutions/index.vue").then(m => m.default || m)
  },
  {
    name: indexsQQZY9b02hMeta?.name ?? "solutions___uk",
    path: indexsQQZY9b02hMeta?.path ?? "/uk/solutions",
    meta: indexsQQZY9b02hMeta || {},
    alias: indexsQQZY9b02hMeta?.alias || [],
    redirect: indexsQQZY9b02hMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/solutions/index.vue").then(m => m.default || m)
  },
  {
    name: indexsQQZY9b02hMeta?.name ?? "solutions___de",
    path: indexsQQZY9b02hMeta?.path ?? "/de/solutions",
    meta: indexsQQZY9b02hMeta || {},
    alias: indexsQQZY9b02hMeta?.alias || [],
    redirect: indexsQQZY9b02hMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/solutions/index.vue").then(m => m.default || m)
  },
  {
    name: indexsQQZY9b02hMeta?.name ?? "solutions___mx",
    path: indexsQQZY9b02hMeta?.path ?? "/mx/solutions",
    meta: indexsQQZY9b02hMeta || {},
    alias: indexsQQZY9b02hMeta?.alias || [],
    redirect: indexsQQZY9b02hMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/solutions/index.vue").then(m => m.default || m)
  },
  {
    name: indexsQQZY9b02hMeta?.name ?? "solutions___fr",
    path: indexsQQZY9b02hMeta?.path ?? "/fr/solutions",
    meta: indexsQQZY9b02hMeta || {},
    alias: indexsQQZY9b02hMeta?.alias || [],
    redirect: indexsQQZY9b02hMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/solutions/index.vue").then(m => m.default || m)
  },
  {
    name: indexsQQZY9b02hMeta?.name ?? "solutions___fr-ca",
    path: indexsQQZY9b02hMeta?.path ?? "/fr-ca/solutions",
    meta: indexsQQZY9b02hMeta || {},
    alias: indexsQQZY9b02hMeta?.alias || [],
    redirect: indexsQQZY9b02hMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/solutions/index.vue").then(m => m.default || m)
  },
  {
    name: securityBMyEMOKrBVMeta?.name ?? "solutions-security___en-US",
    path: securityBMyEMOKrBVMeta?.path ?? "/solutions/security",
    meta: securityBMyEMOKrBVMeta || {},
    alias: securityBMyEMOKrBVMeta?.alias || [],
    redirect: securityBMyEMOKrBVMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/solutions/security.vue").then(m => m.default || m)
  },
  {
    name: securityBMyEMOKrBVMeta?.name ?? "solutions-security___ca",
    path: securityBMyEMOKrBVMeta?.path ?? "/ca/solutions/security",
    meta: securityBMyEMOKrBVMeta || {},
    alias: securityBMyEMOKrBVMeta?.alias || [],
    redirect: securityBMyEMOKrBVMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/solutions/security.vue").then(m => m.default || m)
  },
  {
    name: securityBMyEMOKrBVMeta?.name ?? "solutions-security___nl",
    path: securityBMyEMOKrBVMeta?.path ?? "/nl/solutions/security",
    meta: securityBMyEMOKrBVMeta || {},
    alias: securityBMyEMOKrBVMeta?.alias || [],
    redirect: securityBMyEMOKrBVMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/solutions/security.vue").then(m => m.default || m)
  },
  {
    name: securityBMyEMOKrBVMeta?.name ?? "solutions-security___uk",
    path: securityBMyEMOKrBVMeta?.path ?? "/uk/solutions/security",
    meta: securityBMyEMOKrBVMeta || {},
    alias: securityBMyEMOKrBVMeta?.alias || [],
    redirect: securityBMyEMOKrBVMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/solutions/security.vue").then(m => m.default || m)
  },
  {
    name: securityBMyEMOKrBVMeta?.name ?? "solutions-security___de",
    path: securityBMyEMOKrBVMeta?.path ?? "/de/solutions/security",
    meta: securityBMyEMOKrBVMeta || {},
    alias: securityBMyEMOKrBVMeta?.alias || [],
    redirect: securityBMyEMOKrBVMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/solutions/security.vue").then(m => m.default || m)
  },
  {
    name: securityBMyEMOKrBVMeta?.name ?? "solutions-security___mx",
    path: securityBMyEMOKrBVMeta?.path ?? "/mx/soluciones/seguridad-patrimonial",
    meta: securityBMyEMOKrBVMeta || {},
    alias: securityBMyEMOKrBVMeta?.alias || [],
    redirect: securityBMyEMOKrBVMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/solutions/security.vue").then(m => m.default || m)
  },
  {
    name: securityBMyEMOKrBVMeta?.name ?? "solutions-security___fr",
    path: securityBMyEMOKrBVMeta?.path ?? "/fr/solutions/security",
    meta: securityBMyEMOKrBVMeta || {},
    alias: securityBMyEMOKrBVMeta?.alias || [],
    redirect: securityBMyEMOKrBVMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/solutions/security.vue").then(m => m.default || m)
  },
  {
    name: securityBMyEMOKrBVMeta?.name ?? "solutions-security___fr-ca",
    path: securityBMyEMOKrBVMeta?.path ?? "/fr-ca/solutions/security",
    meta: securityBMyEMOKrBVMeta || {},
    alias: securityBMyEMOKrBVMeta?.alias || [],
    redirect: securityBMyEMOKrBVMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/solutions/security.vue").then(m => m.default || m)
  },
  {
    name: spacei69V9NjYTgMeta?.name ?? "space___en-US",
    path: spacei69V9NjYTgMeta?.path ?? "/space",
    meta: spacei69V9NjYTgMeta || {},
    alias: spacei69V9NjYTgMeta?.alias || [],
    redirect: spacei69V9NjYTgMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/space.vue").then(m => m.default || m)
  },
  {
    name: spacei69V9NjYTgMeta?.name ?? "space___ca",
    path: spacei69V9NjYTgMeta?.path ?? "/ca/space",
    meta: spacei69V9NjYTgMeta || {},
    alias: spacei69V9NjYTgMeta?.alias || [],
    redirect: spacei69V9NjYTgMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/space.vue").then(m => m.default || m)
  },
  {
    name: spacei69V9NjYTgMeta?.name ?? "space___nl",
    path: spacei69V9NjYTgMeta?.path ?? "/nl/space",
    meta: spacei69V9NjYTgMeta || {},
    alias: spacei69V9NjYTgMeta?.alias || [],
    redirect: spacei69V9NjYTgMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/space.vue").then(m => m.default || m)
  },
  {
    name: spacei69V9NjYTgMeta?.name ?? "space___uk",
    path: spacei69V9NjYTgMeta?.path ?? "/uk/space",
    meta: spacei69V9NjYTgMeta || {},
    alias: spacei69V9NjYTgMeta?.alias || [],
    redirect: spacei69V9NjYTgMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/space.vue").then(m => m.default || m)
  },
  {
    name: spacei69V9NjYTgMeta?.name ?? "space___de",
    path: spacei69V9NjYTgMeta?.path ?? "/de/space",
    meta: spacei69V9NjYTgMeta || {},
    alias: spacei69V9NjYTgMeta?.alias || [],
    redirect: spacei69V9NjYTgMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/space.vue").then(m => m.default || m)
  },
  {
    name: spacei69V9NjYTgMeta?.name ?? "space___mx",
    path: spacei69V9NjYTgMeta?.path ?? "/mx/space",
    meta: spacei69V9NjYTgMeta || {},
    alias: spacei69V9NjYTgMeta?.alias || [],
    redirect: spacei69V9NjYTgMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/space.vue").then(m => m.default || m)
  },
  {
    name: spacei69V9NjYTgMeta?.name ?? "space___fr",
    path: spacei69V9NjYTgMeta?.path ?? "/fr/space",
    meta: spacei69V9NjYTgMeta || {},
    alias: spacei69V9NjYTgMeta?.alias || [],
    redirect: spacei69V9NjYTgMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/space.vue").then(m => m.default || m)
  },
  {
    name: spacei69V9NjYTgMeta?.name ?? "space___fr-ca",
    path: spacei69V9NjYTgMeta?.path ?? "/fr-ca/space",
    meta: spacei69V9NjYTgMeta || {},
    alias: spacei69V9NjYTgMeta?.alias || [],
    redirect: spacei69V9NjYTgMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/space.vue").then(m => m.default || m)
  },
  {
    name: sparkwyaToaiM00Meta?.name ?? "spark___en-US",
    path: sparkwyaToaiM00Meta?.path ?? "/spark",
    meta: sparkwyaToaiM00Meta || {},
    alias: sparkwyaToaiM00Meta?.alias || [],
    redirect: sparkwyaToaiM00Meta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/spark.vue").then(m => m.default || m)
  },
  {
    name: sparkwyaToaiM00Meta?.name ?? "spark___ca",
    path: sparkwyaToaiM00Meta?.path ?? "/ca/spark",
    meta: sparkwyaToaiM00Meta || {},
    alias: sparkwyaToaiM00Meta?.alias || [],
    redirect: sparkwyaToaiM00Meta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/spark.vue").then(m => m.default || m)
  },
  {
    name: sparkwyaToaiM00Meta?.name ?? "spark___nl",
    path: sparkwyaToaiM00Meta?.path ?? "/nl/spark",
    meta: sparkwyaToaiM00Meta || {},
    alias: sparkwyaToaiM00Meta?.alias || [],
    redirect: sparkwyaToaiM00Meta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/spark.vue").then(m => m.default || m)
  },
  {
    name: sparkwyaToaiM00Meta?.name ?? "spark___uk",
    path: sparkwyaToaiM00Meta?.path ?? "/uk/spark",
    meta: sparkwyaToaiM00Meta || {},
    alias: sparkwyaToaiM00Meta?.alias || [],
    redirect: sparkwyaToaiM00Meta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/spark.vue").then(m => m.default || m)
  },
  {
    name: sparkwyaToaiM00Meta?.name ?? "spark___de",
    path: sparkwyaToaiM00Meta?.path ?? "/de/spark",
    meta: sparkwyaToaiM00Meta || {},
    alias: sparkwyaToaiM00Meta?.alias || [],
    redirect: sparkwyaToaiM00Meta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/spark.vue").then(m => m.default || m)
  },
  {
    name: sparkwyaToaiM00Meta?.name ?? "spark___mx",
    path: sparkwyaToaiM00Meta?.path ?? "/mx/spark",
    meta: sparkwyaToaiM00Meta || {},
    alias: sparkwyaToaiM00Meta?.alias || [],
    redirect: sparkwyaToaiM00Meta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/spark.vue").then(m => m.default || m)
  },
  {
    name: sparkwyaToaiM00Meta?.name ?? "spark___fr",
    path: sparkwyaToaiM00Meta?.path ?? "/fr/spark",
    meta: sparkwyaToaiM00Meta || {},
    alias: sparkwyaToaiM00Meta?.alias || [],
    redirect: sparkwyaToaiM00Meta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/spark.vue").then(m => m.default || m)
  },
  {
    name: sparkwyaToaiM00Meta?.name ?? "spark___fr-ca",
    path: sparkwyaToaiM00Meta?.path ?? "/fr-ca/spark",
    meta: sparkwyaToaiM00Meta || {},
    alias: sparkwyaToaiM00Meta?.alias || [],
    redirect: sparkwyaToaiM00Meta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/spark.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93nrjb4f4cDpMeta?.name ?? "styles-slug___en-US",
    path: _91slug_93nrjb4f4cDpMeta?.path ?? "/styles/:slug()",
    meta: _91slug_93nrjb4f4cDpMeta || {},
    alias: _91slug_93nrjb4f4cDpMeta?.alias || [],
    redirect: _91slug_93nrjb4f4cDpMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/styles/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93nrjb4f4cDpMeta?.name ?? "styles-slug___ca",
    path: _91slug_93nrjb4f4cDpMeta?.path ?? "/ca/styles/:slug()",
    meta: _91slug_93nrjb4f4cDpMeta || {},
    alias: _91slug_93nrjb4f4cDpMeta?.alias || [],
    redirect: _91slug_93nrjb4f4cDpMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/styles/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93nrjb4f4cDpMeta?.name ?? "styles-slug___nl",
    path: _91slug_93nrjb4f4cDpMeta?.path ?? "/nl/styles/:slug()",
    meta: _91slug_93nrjb4f4cDpMeta || {},
    alias: _91slug_93nrjb4f4cDpMeta?.alias || [],
    redirect: _91slug_93nrjb4f4cDpMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/styles/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93nrjb4f4cDpMeta?.name ?? "styles-slug___uk",
    path: _91slug_93nrjb4f4cDpMeta?.path ?? "/uk/styles/:slug()",
    meta: _91slug_93nrjb4f4cDpMeta || {},
    alias: _91slug_93nrjb4f4cDpMeta?.alias || [],
    redirect: _91slug_93nrjb4f4cDpMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/styles/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93nrjb4f4cDpMeta?.name ?? "styles-slug___de",
    path: _91slug_93nrjb4f4cDpMeta?.path ?? "/de/styles/:slug()",
    meta: _91slug_93nrjb4f4cDpMeta || {},
    alias: _91slug_93nrjb4f4cDpMeta?.alias || [],
    redirect: _91slug_93nrjb4f4cDpMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/styles/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93nrjb4f4cDpMeta?.name ?? "styles-slug___mx",
    path: _91slug_93nrjb4f4cDpMeta?.path ?? "/mx/styles/:slug()",
    meta: _91slug_93nrjb4f4cDpMeta || {},
    alias: _91slug_93nrjb4f4cDpMeta?.alias || [],
    redirect: _91slug_93nrjb4f4cDpMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/styles/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93nrjb4f4cDpMeta?.name ?? "styles-slug___fr",
    path: _91slug_93nrjb4f4cDpMeta?.path ?? "/fr/styles/:slug()",
    meta: _91slug_93nrjb4f4cDpMeta || {},
    alias: _91slug_93nrjb4f4cDpMeta?.alias || [],
    redirect: _91slug_93nrjb4f4cDpMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/styles/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93nrjb4f4cDpMeta?.name ?? "styles-slug___fr-ca",
    path: _91slug_93nrjb4f4cDpMeta?.path ?? "/fr-ca/styles/:slug()",
    meta: _91slug_93nrjb4f4cDpMeta || {},
    alias: _91slug_93nrjb4f4cDpMeta?.alias || [],
    redirect: _91slug_93nrjb4f4cDpMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/styles/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93O16HARYgGmMeta?.name ?? "support-prefix-slug___en-US",
    path: _91slug_93O16HARYgGmMeta?.path ?? "/support/:prefix()/:slug()",
    meta: _91slug_93O16HARYgGmMeta || {},
    alias: _91slug_93O16HARYgGmMeta?.alias || [],
    redirect: _91slug_93O16HARYgGmMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/support/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93O16HARYgGmMeta?.name ?? "support-prefix-slug___ca",
    path: _91slug_93O16HARYgGmMeta?.path ?? "/ca/support/:prefix()/:slug()",
    meta: _91slug_93O16HARYgGmMeta || {},
    alias: _91slug_93O16HARYgGmMeta?.alias || [],
    redirect: _91slug_93O16HARYgGmMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/support/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93O16HARYgGmMeta?.name ?? "support-prefix-slug___nl",
    path: _91slug_93O16HARYgGmMeta?.path ?? "/nl/support/:prefix()/:slug()",
    meta: _91slug_93O16HARYgGmMeta || {},
    alias: _91slug_93O16HARYgGmMeta?.alias || [],
    redirect: _91slug_93O16HARYgGmMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/support/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93O16HARYgGmMeta?.name ?? "support-prefix-slug___uk",
    path: _91slug_93O16HARYgGmMeta?.path ?? "/uk/support/:prefix()/:slug()",
    meta: _91slug_93O16HARYgGmMeta || {},
    alias: _91slug_93O16HARYgGmMeta?.alias || [],
    redirect: _91slug_93O16HARYgGmMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/support/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93O16HARYgGmMeta?.name ?? "support-prefix-slug___de",
    path: _91slug_93O16HARYgGmMeta?.path ?? "/de/support/:prefix()/:slug()",
    meta: _91slug_93O16HARYgGmMeta || {},
    alias: _91slug_93O16HARYgGmMeta?.alias || [],
    redirect: _91slug_93O16HARYgGmMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/support/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93O16HARYgGmMeta?.name ?? "support-prefix-slug___mx",
    path: _91slug_93O16HARYgGmMeta?.path ?? "/mx/support/:prefix()/:slug()",
    meta: _91slug_93O16HARYgGmMeta || {},
    alias: _91slug_93O16HARYgGmMeta?.alias || [],
    redirect: _91slug_93O16HARYgGmMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/support/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93O16HARYgGmMeta?.name ?? "support-prefix-slug___fr",
    path: _91slug_93O16HARYgGmMeta?.path ?? "/fr/support/:prefix()/:slug()",
    meta: _91slug_93O16HARYgGmMeta || {},
    alias: _91slug_93O16HARYgGmMeta?.alias || [],
    redirect: _91slug_93O16HARYgGmMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/support/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93O16HARYgGmMeta?.name ?? "support-prefix-slug___fr-ca",
    path: _91slug_93O16HARYgGmMeta?.path ?? "/fr-ca/support/:prefix()/:slug()",
    meta: _91slug_93O16HARYgGmMeta || {},
    alias: _91slug_93O16HARYgGmMeta?.alias || [],
    redirect: _91slug_93O16HARYgGmMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/support/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93MPARUJTBUCMeta?.name ?? "support-slug___en-US",
    path: _91slug_93MPARUJTBUCMeta?.path ?? "/support/:slug()",
    meta: _91slug_93MPARUJTBUCMeta || {},
    alias: _91slug_93MPARUJTBUCMeta?.alias || [],
    redirect: _91slug_93MPARUJTBUCMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/support/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93MPARUJTBUCMeta?.name ?? "support-slug___ca",
    path: _91slug_93MPARUJTBUCMeta?.path ?? "/ca/support/:slug()",
    meta: _91slug_93MPARUJTBUCMeta || {},
    alias: _91slug_93MPARUJTBUCMeta?.alias || [],
    redirect: _91slug_93MPARUJTBUCMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/support/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93MPARUJTBUCMeta?.name ?? "support-slug___nl",
    path: _91slug_93MPARUJTBUCMeta?.path ?? "/nl/support/:slug()",
    meta: _91slug_93MPARUJTBUCMeta || {},
    alias: _91slug_93MPARUJTBUCMeta?.alias || [],
    redirect: _91slug_93MPARUJTBUCMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/support/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93MPARUJTBUCMeta?.name ?? "support-slug___uk",
    path: _91slug_93MPARUJTBUCMeta?.path ?? "/uk/support/:slug()",
    meta: _91slug_93MPARUJTBUCMeta || {},
    alias: _91slug_93MPARUJTBUCMeta?.alias || [],
    redirect: _91slug_93MPARUJTBUCMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/support/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93MPARUJTBUCMeta?.name ?? "support-slug___de",
    path: _91slug_93MPARUJTBUCMeta?.path ?? "/de/support/:slug()",
    meta: _91slug_93MPARUJTBUCMeta || {},
    alias: _91slug_93MPARUJTBUCMeta?.alias || [],
    redirect: _91slug_93MPARUJTBUCMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/support/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93MPARUJTBUCMeta?.name ?? "support-slug___mx",
    path: _91slug_93MPARUJTBUCMeta?.path ?? "/mx/support/:slug()",
    meta: _91slug_93MPARUJTBUCMeta || {},
    alias: _91slug_93MPARUJTBUCMeta?.alias || [],
    redirect: _91slug_93MPARUJTBUCMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/support/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93MPARUJTBUCMeta?.name ?? "support-slug___fr",
    path: _91slug_93MPARUJTBUCMeta?.path ?? "/fr/support/:slug()",
    meta: _91slug_93MPARUJTBUCMeta || {},
    alias: _91slug_93MPARUJTBUCMeta?.alias || [],
    redirect: _91slug_93MPARUJTBUCMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/support/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93MPARUJTBUCMeta?.name ?? "support-slug___fr-ca",
    path: _91slug_93MPARUJTBUCMeta?.path ?? "/fr-ca/support/:slug()",
    meta: _91slug_93MPARUJTBUCMeta || {},
    alias: _91slug_93MPARUJTBUCMeta?.alias || [],
    redirect: _91slug_93MPARUJTBUCMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/support/[slug].vue").then(m => m.default || m)
  },
  {
    name: apps_45privacy_45policyYAif8v1gB7Meta?.name ?? "support-apps-privacy-policy___en-US",
    path: apps_45privacy_45policyYAif8v1gB7Meta?.path ?? "/support/apps-privacy-policy",
    meta: apps_45privacy_45policyYAif8v1gB7Meta || {},
    alias: apps_45privacy_45policyYAif8v1gB7Meta?.alias || [],
    redirect: apps_45privacy_45policyYAif8v1gB7Meta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/support/apps-privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: apps_45privacy_45policyYAif8v1gB7Meta?.name ?? "support-apps-privacy-policy___ca",
    path: apps_45privacy_45policyYAif8v1gB7Meta?.path ?? "/ca/support/apps-privacy-policy",
    meta: apps_45privacy_45policyYAif8v1gB7Meta || {},
    alias: apps_45privacy_45policyYAif8v1gB7Meta?.alias || [],
    redirect: apps_45privacy_45policyYAif8v1gB7Meta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/support/apps-privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: apps_45privacy_45policyYAif8v1gB7Meta?.name ?? "support-apps-privacy-policy___nl",
    path: apps_45privacy_45policyYAif8v1gB7Meta?.path ?? "/nl/support/apps-privacy-policy",
    meta: apps_45privacy_45policyYAif8v1gB7Meta || {},
    alias: apps_45privacy_45policyYAif8v1gB7Meta?.alias || [],
    redirect: apps_45privacy_45policyYAif8v1gB7Meta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/support/apps-privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: apps_45privacy_45policyYAif8v1gB7Meta?.name ?? "support-apps-privacy-policy___uk",
    path: apps_45privacy_45policyYAif8v1gB7Meta?.path ?? "/uk/support/apps-privacy-policy",
    meta: apps_45privacy_45policyYAif8v1gB7Meta || {},
    alias: apps_45privacy_45policyYAif8v1gB7Meta?.alias || [],
    redirect: apps_45privacy_45policyYAif8v1gB7Meta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/support/apps-privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: apps_45privacy_45policyYAif8v1gB7Meta?.name ?? "support-apps-privacy-policy___de",
    path: apps_45privacy_45policyYAif8v1gB7Meta?.path ?? "/de/support/apps-privacy-policy",
    meta: apps_45privacy_45policyYAif8v1gB7Meta || {},
    alias: apps_45privacy_45policyYAif8v1gB7Meta?.alias || [],
    redirect: apps_45privacy_45policyYAif8v1gB7Meta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/support/apps-privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: apps_45privacy_45policyYAif8v1gB7Meta?.name ?? "support-apps-privacy-policy___mx",
    path: apps_45privacy_45policyYAif8v1gB7Meta?.path ?? "/mx/support/apps-privacy-policy",
    meta: apps_45privacy_45policyYAif8v1gB7Meta || {},
    alias: apps_45privacy_45policyYAif8v1gB7Meta?.alias || [],
    redirect: apps_45privacy_45policyYAif8v1gB7Meta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/support/apps-privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: apps_45privacy_45policyYAif8v1gB7Meta?.name ?? "support-apps-privacy-policy___fr",
    path: apps_45privacy_45policyYAif8v1gB7Meta?.path ?? "/fr/support/apps-privacy-policy",
    meta: apps_45privacy_45policyYAif8v1gB7Meta || {},
    alias: apps_45privacy_45policyYAif8v1gB7Meta?.alias || [],
    redirect: apps_45privacy_45policyYAif8v1gB7Meta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/support/apps-privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: apps_45privacy_45policyYAif8v1gB7Meta?.name ?? "support-apps-privacy-policy___fr-ca",
    path: apps_45privacy_45policyYAif8v1gB7Meta?.path ?? "/fr-ca/support/apps-privacy-policy",
    meta: apps_45privacy_45policyYAif8v1gB7Meta || {},
    alias: apps_45privacy_45policyYAif8v1gB7Meta?.alias || [],
    redirect: apps_45privacy_45policyYAif8v1gB7Meta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/support/apps-privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: customer_45referrals_45faqnoebkzI9DOMeta?.name ?? "support-customer-referrals-faq___en-US",
    path: customer_45referrals_45faqnoebkzI9DOMeta?.path ?? "/support/customer-referrals-faq",
    meta: customer_45referrals_45faqnoebkzI9DOMeta || {},
    alias: customer_45referrals_45faqnoebkzI9DOMeta?.alias || [],
    redirect: customer_45referrals_45faqnoebkzI9DOMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/support/customer-referrals-faq.vue").then(m => m.default || m)
  },
  {
    name: customer_45referrals_45faqnoebkzI9DOMeta?.name ?? "support-customer-referrals-faq___ca",
    path: customer_45referrals_45faqnoebkzI9DOMeta?.path ?? "/ca/support/customer-referrals-faq",
    meta: customer_45referrals_45faqnoebkzI9DOMeta || {},
    alias: customer_45referrals_45faqnoebkzI9DOMeta?.alias || [],
    redirect: customer_45referrals_45faqnoebkzI9DOMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/support/customer-referrals-faq.vue").then(m => m.default || m)
  },
  {
    name: customer_45referrals_45faqnoebkzI9DOMeta?.name ?? "support-customer-referrals-faq___nl",
    path: customer_45referrals_45faqnoebkzI9DOMeta?.path ?? "/nl/support/customer-referrals-faq",
    meta: customer_45referrals_45faqnoebkzI9DOMeta || {},
    alias: customer_45referrals_45faqnoebkzI9DOMeta?.alias || [],
    redirect: customer_45referrals_45faqnoebkzI9DOMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/support/customer-referrals-faq.vue").then(m => m.default || m)
  },
  {
    name: customer_45referrals_45faqnoebkzI9DOMeta?.name ?? "support-customer-referrals-faq___uk",
    path: customer_45referrals_45faqnoebkzI9DOMeta?.path ?? "/uk/support/customer-referrals-faq",
    meta: customer_45referrals_45faqnoebkzI9DOMeta || {},
    alias: customer_45referrals_45faqnoebkzI9DOMeta?.alias || [],
    redirect: customer_45referrals_45faqnoebkzI9DOMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/support/customer-referrals-faq.vue").then(m => m.default || m)
  },
  {
    name: customer_45referrals_45faqnoebkzI9DOMeta?.name ?? "support-customer-referrals-faq___de",
    path: customer_45referrals_45faqnoebkzI9DOMeta?.path ?? "/de/support/customer-referrals-faq",
    meta: customer_45referrals_45faqnoebkzI9DOMeta || {},
    alias: customer_45referrals_45faqnoebkzI9DOMeta?.alias || [],
    redirect: customer_45referrals_45faqnoebkzI9DOMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/support/customer-referrals-faq.vue").then(m => m.default || m)
  },
  {
    name: customer_45referrals_45faqnoebkzI9DOMeta?.name ?? "support-customer-referrals-faq___mx",
    path: customer_45referrals_45faqnoebkzI9DOMeta?.path ?? "/mx/support/customer-referrals-faq",
    meta: customer_45referrals_45faqnoebkzI9DOMeta || {},
    alias: customer_45referrals_45faqnoebkzI9DOMeta?.alias || [],
    redirect: customer_45referrals_45faqnoebkzI9DOMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/support/customer-referrals-faq.vue").then(m => m.default || m)
  },
  {
    name: customer_45referrals_45faqnoebkzI9DOMeta?.name ?? "support-customer-referrals-faq___fr",
    path: customer_45referrals_45faqnoebkzI9DOMeta?.path ?? "/fr/support/customer-referrals-faq",
    meta: customer_45referrals_45faqnoebkzI9DOMeta || {},
    alias: customer_45referrals_45faqnoebkzI9DOMeta?.alias || [],
    redirect: customer_45referrals_45faqnoebkzI9DOMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/support/customer-referrals-faq.vue").then(m => m.default || m)
  },
  {
    name: customer_45referrals_45faqnoebkzI9DOMeta?.name ?? "support-customer-referrals-faq___fr-ca",
    path: customer_45referrals_45faqnoebkzI9DOMeta?.path ?? "/fr-ca/support/customer-referrals-faq",
    meta: customer_45referrals_45faqnoebkzI9DOMeta || {},
    alias: customer_45referrals_45faqnoebkzI9DOMeta?.alias || [],
    redirect: customer_45referrals_45faqnoebkzI9DOMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/support/customer-referrals-faq.vue").then(m => m.default || m)
  },
  {
    name: driver_45training_45spanishg5rJfmjxeEMeta?.name ?? "support-driver-training-spanish___en-US",
    path: driver_45training_45spanishg5rJfmjxeEMeta?.path ?? "/support/driver-training-spanish",
    meta: driver_45training_45spanishg5rJfmjxeEMeta || {},
    alias: driver_45training_45spanishg5rJfmjxeEMeta?.alias || [],
    redirect: driver_45training_45spanishg5rJfmjxeEMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/support/driver-training-spanish.vue").then(m => m.default || m)
  },
  {
    name: driver_45training_45spanishg5rJfmjxeEMeta?.name ?? "support-driver-training-spanish___ca",
    path: driver_45training_45spanishg5rJfmjxeEMeta?.path ?? "/ca/support/driver-training-spanish",
    meta: driver_45training_45spanishg5rJfmjxeEMeta || {},
    alias: driver_45training_45spanishg5rJfmjxeEMeta?.alias || [],
    redirect: driver_45training_45spanishg5rJfmjxeEMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/support/driver-training-spanish.vue").then(m => m.default || m)
  },
  {
    name: driver_45training_45spanishg5rJfmjxeEMeta?.name ?? "support-driver-training-spanish___nl",
    path: driver_45training_45spanishg5rJfmjxeEMeta?.path ?? "/nl/support/driver-training-spanish",
    meta: driver_45training_45spanishg5rJfmjxeEMeta || {},
    alias: driver_45training_45spanishg5rJfmjxeEMeta?.alias || [],
    redirect: driver_45training_45spanishg5rJfmjxeEMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/support/driver-training-spanish.vue").then(m => m.default || m)
  },
  {
    name: driver_45training_45spanishg5rJfmjxeEMeta?.name ?? "support-driver-training-spanish___uk",
    path: driver_45training_45spanishg5rJfmjxeEMeta?.path ?? "/uk/support/driver-training-spanish",
    meta: driver_45training_45spanishg5rJfmjxeEMeta || {},
    alias: driver_45training_45spanishg5rJfmjxeEMeta?.alias || [],
    redirect: driver_45training_45spanishg5rJfmjxeEMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/support/driver-training-spanish.vue").then(m => m.default || m)
  },
  {
    name: driver_45training_45spanishg5rJfmjxeEMeta?.name ?? "support-driver-training-spanish___de",
    path: driver_45training_45spanishg5rJfmjxeEMeta?.path ?? "/de/support/driver-training-spanish",
    meta: driver_45training_45spanishg5rJfmjxeEMeta || {},
    alias: driver_45training_45spanishg5rJfmjxeEMeta?.alias || [],
    redirect: driver_45training_45spanishg5rJfmjxeEMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/support/driver-training-spanish.vue").then(m => m.default || m)
  },
  {
    name: driver_45training_45spanishg5rJfmjxeEMeta?.name ?? "support-driver-training-spanish___mx",
    path: driver_45training_45spanishg5rJfmjxeEMeta?.path ?? "/mx/support/driver-training-spanish",
    meta: driver_45training_45spanishg5rJfmjxeEMeta || {},
    alias: driver_45training_45spanishg5rJfmjxeEMeta?.alias || [],
    redirect: driver_45training_45spanishg5rJfmjxeEMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/support/driver-training-spanish.vue").then(m => m.default || m)
  },
  {
    name: driver_45training_45spanishg5rJfmjxeEMeta?.name ?? "support-driver-training-spanish___fr",
    path: driver_45training_45spanishg5rJfmjxeEMeta?.path ?? "/fr/support/driver-training-spanish",
    meta: driver_45training_45spanishg5rJfmjxeEMeta || {},
    alias: driver_45training_45spanishg5rJfmjxeEMeta?.alias || [],
    redirect: driver_45training_45spanishg5rJfmjxeEMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/support/driver-training-spanish.vue").then(m => m.default || m)
  },
  {
    name: driver_45training_45spanishg5rJfmjxeEMeta?.name ?? "support-driver-training-spanish___fr-ca",
    path: driver_45training_45spanishg5rJfmjxeEMeta?.path ?? "/fr-ca/support/driver-training-spanish",
    meta: driver_45training_45spanishg5rJfmjxeEMeta || {},
    alias: driver_45training_45spanishg5rJfmjxeEMeta?.alias || [],
    redirect: driver_45training_45spanishg5rJfmjxeEMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/support/driver-training-spanish.vue").then(m => m.default || m)
  },
  {
    name: driver_45trainingGxB91EKJTNMeta?.name ?? "support-driver-training___en-US",
    path: driver_45trainingGxB91EKJTNMeta?.path ?? "/support/driver-training",
    meta: driver_45trainingGxB91EKJTNMeta || {},
    alias: driver_45trainingGxB91EKJTNMeta?.alias || [],
    redirect: driver_45trainingGxB91EKJTNMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/support/driver-training.vue").then(m => m.default || m)
  },
  {
    name: driver_45trainingGxB91EKJTNMeta?.name ?? "support-driver-training___ca",
    path: driver_45trainingGxB91EKJTNMeta?.path ?? "/ca/support/driver-training",
    meta: driver_45trainingGxB91EKJTNMeta || {},
    alias: driver_45trainingGxB91EKJTNMeta?.alias || [],
    redirect: driver_45trainingGxB91EKJTNMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/support/driver-training.vue").then(m => m.default || m)
  },
  {
    name: driver_45trainingGxB91EKJTNMeta?.name ?? "support-driver-training___nl",
    path: driver_45trainingGxB91EKJTNMeta?.path ?? "/nl/support/driver-training",
    meta: driver_45trainingGxB91EKJTNMeta || {},
    alias: driver_45trainingGxB91EKJTNMeta?.alias || [],
    redirect: driver_45trainingGxB91EKJTNMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/support/driver-training.vue").then(m => m.default || m)
  },
  {
    name: driver_45trainingGxB91EKJTNMeta?.name ?? "support-driver-training___uk",
    path: driver_45trainingGxB91EKJTNMeta?.path ?? "/uk/support/driver-training",
    meta: driver_45trainingGxB91EKJTNMeta || {},
    alias: driver_45trainingGxB91EKJTNMeta?.alias || [],
    redirect: driver_45trainingGxB91EKJTNMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/support/driver-training.vue").then(m => m.default || m)
  },
  {
    name: driver_45trainingGxB91EKJTNMeta?.name ?? "support-driver-training___de",
    path: driver_45trainingGxB91EKJTNMeta?.path ?? "/de/support/driver-training",
    meta: driver_45trainingGxB91EKJTNMeta || {},
    alias: driver_45trainingGxB91EKJTNMeta?.alias || [],
    redirect: driver_45trainingGxB91EKJTNMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/support/driver-training.vue").then(m => m.default || m)
  },
  {
    name: driver_45trainingGxB91EKJTNMeta?.name ?? "support-driver-training___mx",
    path: driver_45trainingGxB91EKJTNMeta?.path ?? "/mx/support/driver-training",
    meta: driver_45trainingGxB91EKJTNMeta || {},
    alias: driver_45trainingGxB91EKJTNMeta?.alias || [],
    redirect: driver_45trainingGxB91EKJTNMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/support/driver-training.vue").then(m => m.default || m)
  },
  {
    name: driver_45trainingGxB91EKJTNMeta?.name ?? "support-driver-training___fr",
    path: driver_45trainingGxB91EKJTNMeta?.path ?? "/fr/support/driver-training",
    meta: driver_45trainingGxB91EKJTNMeta || {},
    alias: driver_45trainingGxB91EKJTNMeta?.alias || [],
    redirect: driver_45trainingGxB91EKJTNMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/support/driver-training.vue").then(m => m.default || m)
  },
  {
    name: driver_45trainingGxB91EKJTNMeta?.name ?? "support-driver-training___fr-ca",
    path: driver_45trainingGxB91EKJTNMeta?.path ?? "/fr-ca/support/driver-training",
    meta: driver_45trainingGxB91EKJTNMeta || {},
    alias: driver_45trainingGxB91EKJTNMeta?.alias || [],
    redirect: driver_45trainingGxB91EKJTNMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/support/driver-training.vue").then(m => m.default || m)
  },
  {
    name: indexK9kOIR49UEMeta?.name ?? "support___en-US",
    path: indexK9kOIR49UEMeta?.path ?? "/support",
    meta: indexK9kOIR49UEMeta || {},
    alias: indexK9kOIR49UEMeta?.alias || [],
    redirect: indexK9kOIR49UEMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/support/index.vue").then(m => m.default || m)
  },
  {
    name: indexK9kOIR49UEMeta?.name ?? "support___ca",
    path: indexK9kOIR49UEMeta?.path ?? "/ca/support",
    meta: indexK9kOIR49UEMeta || {},
    alias: indexK9kOIR49UEMeta?.alias || [],
    redirect: indexK9kOIR49UEMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/support/index.vue").then(m => m.default || m)
  },
  {
    name: indexK9kOIR49UEMeta?.name ?? "support___nl",
    path: indexK9kOIR49UEMeta?.path ?? "/nl/support",
    meta: indexK9kOIR49UEMeta || {},
    alias: indexK9kOIR49UEMeta?.alias || [],
    redirect: indexK9kOIR49UEMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/support/index.vue").then(m => m.default || m)
  },
  {
    name: indexK9kOIR49UEMeta?.name ?? "support___uk",
    path: indexK9kOIR49UEMeta?.path ?? "/uk/support",
    meta: indexK9kOIR49UEMeta || {},
    alias: indexK9kOIR49UEMeta?.alias || [],
    redirect: indexK9kOIR49UEMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/support/index.vue").then(m => m.default || m)
  },
  {
    name: indexK9kOIR49UEMeta?.name ?? "support___de",
    path: indexK9kOIR49UEMeta?.path ?? "/de/support",
    meta: indexK9kOIR49UEMeta || {},
    alias: indexK9kOIR49UEMeta?.alias || [],
    redirect: indexK9kOIR49UEMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/support/index.vue").then(m => m.default || m)
  },
  {
    name: indexK9kOIR49UEMeta?.name ?? "support___mx",
    path: indexK9kOIR49UEMeta?.path ?? "/mx/support",
    meta: indexK9kOIR49UEMeta || {},
    alias: indexK9kOIR49UEMeta?.alias || [],
    redirect: indexK9kOIR49UEMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/support/index.vue").then(m => m.default || m)
  },
  {
    name: indexK9kOIR49UEMeta?.name ?? "support___fr",
    path: indexK9kOIR49UEMeta?.path ?? "/fr/support",
    meta: indexK9kOIR49UEMeta || {},
    alias: indexK9kOIR49UEMeta?.alias || [],
    redirect: indexK9kOIR49UEMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/support/index.vue").then(m => m.default || m)
  },
  {
    name: indexK9kOIR49UEMeta?.name ?? "support___fr-ca",
    path: indexK9kOIR49UEMeta?.path ?? "/fr-ca/support",
    meta: indexK9kOIR49UEMeta || {},
    alias: indexK9kOIR49UEMeta?.alias || [],
    redirect: indexK9kOIR49UEMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/support/index.vue").then(m => m.default || m)
  },
  {
    name: industrialPW3yEqRNgTMeta?.name ?? "support-industrial___en-US",
    path: industrialPW3yEqRNgTMeta?.path ?? "/support/industrial",
    meta: industrialPW3yEqRNgTMeta || {},
    alias: industrialPW3yEqRNgTMeta?.alias || [],
    redirect: industrialPW3yEqRNgTMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/support/industrial.vue").then(m => m.default || m)
  },
  {
    name: industrialPW3yEqRNgTMeta?.name ?? "support-industrial___ca",
    path: industrialPW3yEqRNgTMeta?.path ?? "/ca/support/industrial",
    meta: industrialPW3yEqRNgTMeta || {},
    alias: industrialPW3yEqRNgTMeta?.alias || [],
    redirect: industrialPW3yEqRNgTMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/support/industrial.vue").then(m => m.default || m)
  },
  {
    name: industrialPW3yEqRNgTMeta?.name ?? "support-industrial___nl",
    path: industrialPW3yEqRNgTMeta?.path ?? "/nl/support/industrial",
    meta: industrialPW3yEqRNgTMeta || {},
    alias: industrialPW3yEqRNgTMeta?.alias || [],
    redirect: industrialPW3yEqRNgTMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/support/industrial.vue").then(m => m.default || m)
  },
  {
    name: industrialPW3yEqRNgTMeta?.name ?? "support-industrial___uk",
    path: industrialPW3yEqRNgTMeta?.path ?? "/uk/support/industrial",
    meta: industrialPW3yEqRNgTMeta || {},
    alias: industrialPW3yEqRNgTMeta?.alias || [],
    redirect: industrialPW3yEqRNgTMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/support/industrial.vue").then(m => m.default || m)
  },
  {
    name: industrialPW3yEqRNgTMeta?.name ?? "support-industrial___de",
    path: industrialPW3yEqRNgTMeta?.path ?? "/de/support/industrial",
    meta: industrialPW3yEqRNgTMeta || {},
    alias: industrialPW3yEqRNgTMeta?.alias || [],
    redirect: industrialPW3yEqRNgTMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/support/industrial.vue").then(m => m.default || m)
  },
  {
    name: industrialPW3yEqRNgTMeta?.name ?? "support-industrial___mx",
    path: industrialPW3yEqRNgTMeta?.path ?? "/mx/support/industrial",
    meta: industrialPW3yEqRNgTMeta || {},
    alias: industrialPW3yEqRNgTMeta?.alias || [],
    redirect: industrialPW3yEqRNgTMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/support/industrial.vue").then(m => m.default || m)
  },
  {
    name: industrialPW3yEqRNgTMeta?.name ?? "support-industrial___fr",
    path: industrialPW3yEqRNgTMeta?.path ?? "/fr/support/industrial",
    meta: industrialPW3yEqRNgTMeta || {},
    alias: industrialPW3yEqRNgTMeta?.alias || [],
    redirect: industrialPW3yEqRNgTMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/support/industrial.vue").then(m => m.default || m)
  },
  {
    name: industrialPW3yEqRNgTMeta?.name ?? "support-industrial___fr-ca",
    path: industrialPW3yEqRNgTMeta?.path ?? "/fr-ca/support/industrial",
    meta: industrialPW3yEqRNgTMeta || {},
    alias: industrialPW3yEqRNgTMeta?.alias || [],
    redirect: industrialPW3yEqRNgTMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/support/industrial.vue").then(m => m.default || m)
  },
  {
    name: modern_45slavery_45statementppUEXI7OEVMeta?.name ?? "support-modern-slavery-statement___en-US",
    path: modern_45slavery_45statementppUEXI7OEVMeta?.path ?? "/support/modern-slavery-statement",
    meta: modern_45slavery_45statementppUEXI7OEVMeta || {},
    alias: modern_45slavery_45statementppUEXI7OEVMeta?.alias || [],
    redirect: modern_45slavery_45statementppUEXI7OEVMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/support/modern-slavery-statement.vue").then(m => m.default || m)
  },
  {
    name: modern_45slavery_45statementppUEXI7OEVMeta?.name ?? "support-modern-slavery-statement___ca",
    path: modern_45slavery_45statementppUEXI7OEVMeta?.path ?? "/ca/support/modern-slavery-statement",
    meta: modern_45slavery_45statementppUEXI7OEVMeta || {},
    alias: modern_45slavery_45statementppUEXI7OEVMeta?.alias || [],
    redirect: modern_45slavery_45statementppUEXI7OEVMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/support/modern-slavery-statement.vue").then(m => m.default || m)
  },
  {
    name: modern_45slavery_45statementppUEXI7OEVMeta?.name ?? "support-modern-slavery-statement___nl",
    path: modern_45slavery_45statementppUEXI7OEVMeta?.path ?? "/nl/support/modern-slavery-statement",
    meta: modern_45slavery_45statementppUEXI7OEVMeta || {},
    alias: modern_45slavery_45statementppUEXI7OEVMeta?.alias || [],
    redirect: modern_45slavery_45statementppUEXI7OEVMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/support/modern-slavery-statement.vue").then(m => m.default || m)
  },
  {
    name: modern_45slavery_45statementppUEXI7OEVMeta?.name ?? "support-modern-slavery-statement___uk",
    path: modern_45slavery_45statementppUEXI7OEVMeta?.path ?? "/uk/support/modern-slavery-statement",
    meta: modern_45slavery_45statementppUEXI7OEVMeta || {},
    alias: modern_45slavery_45statementppUEXI7OEVMeta?.alias || [],
    redirect: modern_45slavery_45statementppUEXI7OEVMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/support/modern-slavery-statement.vue").then(m => m.default || m)
  },
  {
    name: modern_45slavery_45statementppUEXI7OEVMeta?.name ?? "support-modern-slavery-statement___de",
    path: modern_45slavery_45statementppUEXI7OEVMeta?.path ?? "/de/support/modern-slavery-statement",
    meta: modern_45slavery_45statementppUEXI7OEVMeta || {},
    alias: modern_45slavery_45statementppUEXI7OEVMeta?.alias || [],
    redirect: modern_45slavery_45statementppUEXI7OEVMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/support/modern-slavery-statement.vue").then(m => m.default || m)
  },
  {
    name: modern_45slavery_45statementppUEXI7OEVMeta?.name ?? "support-modern-slavery-statement___mx",
    path: modern_45slavery_45statementppUEXI7OEVMeta?.path ?? "/mx/support/modern-slavery-statement",
    meta: modern_45slavery_45statementppUEXI7OEVMeta || {},
    alias: modern_45slavery_45statementppUEXI7OEVMeta?.alias || [],
    redirect: modern_45slavery_45statementppUEXI7OEVMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/support/modern-slavery-statement.vue").then(m => m.default || m)
  },
  {
    name: modern_45slavery_45statementppUEXI7OEVMeta?.name ?? "support-modern-slavery-statement___fr",
    path: modern_45slavery_45statementppUEXI7OEVMeta?.path ?? "/fr/support/modern-slavery-statement",
    meta: modern_45slavery_45statementppUEXI7OEVMeta || {},
    alias: modern_45slavery_45statementppUEXI7OEVMeta?.alias || [],
    redirect: modern_45slavery_45statementppUEXI7OEVMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/support/modern-slavery-statement.vue").then(m => m.default || m)
  },
  {
    name: modern_45slavery_45statementppUEXI7OEVMeta?.name ?? "support-modern-slavery-statement___fr-ca",
    path: modern_45slavery_45statementppUEXI7OEVMeta?.path ?? "/fr-ca/support/modern-slavery-statement",
    meta: modern_45slavery_45statementppUEXI7OEVMeta || {},
    alias: modern_45slavery_45statementppUEXI7OEVMeta?.alias || [],
    redirect: modern_45slavery_45statementppUEXI7OEVMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/support/modern-slavery-statement.vue").then(m => m.default || m)
  },
  {
    name: apps_45privacy_45policyYqPXt1fSdJMeta?.name ?? "support-privacy-apps-privacy-policy___en-US",
    path: apps_45privacy_45policyYqPXt1fSdJMeta?.path ?? "/support/privacy/apps-privacy-policy",
    meta: apps_45privacy_45policyYqPXt1fSdJMeta || {},
    alias: apps_45privacy_45policyYqPXt1fSdJMeta?.alias || [],
    redirect: apps_45privacy_45policyYqPXt1fSdJMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/support/privacy/apps-privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: apps_45privacy_45policyYqPXt1fSdJMeta?.name ?? "support-privacy-apps-privacy-policy___ca",
    path: apps_45privacy_45policyYqPXt1fSdJMeta?.path ?? "/ca/support/privacy/apps-privacy-policy",
    meta: apps_45privacy_45policyYqPXt1fSdJMeta || {},
    alias: apps_45privacy_45policyYqPXt1fSdJMeta?.alias || [],
    redirect: apps_45privacy_45policyYqPXt1fSdJMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/support/privacy/apps-privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: apps_45privacy_45policyYqPXt1fSdJMeta?.name ?? "support-privacy-apps-privacy-policy___nl",
    path: apps_45privacy_45policyYqPXt1fSdJMeta?.path ?? "/nl/support/privacy/apps-privacy-policy",
    meta: apps_45privacy_45policyYqPXt1fSdJMeta || {},
    alias: apps_45privacy_45policyYqPXt1fSdJMeta?.alias || [],
    redirect: apps_45privacy_45policyYqPXt1fSdJMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/support/privacy/apps-privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: apps_45privacy_45policyYqPXt1fSdJMeta?.name ?? "support-privacy-apps-privacy-policy___uk",
    path: apps_45privacy_45policyYqPXt1fSdJMeta?.path ?? "/uk/support/privacy/apps-privacy-policy",
    meta: apps_45privacy_45policyYqPXt1fSdJMeta || {},
    alias: apps_45privacy_45policyYqPXt1fSdJMeta?.alias || [],
    redirect: apps_45privacy_45policyYqPXt1fSdJMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/support/privacy/apps-privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: apps_45privacy_45policyYqPXt1fSdJMeta?.name ?? "support-privacy-apps-privacy-policy___de",
    path: apps_45privacy_45policyYqPXt1fSdJMeta?.path ?? "/de/support/privacy/apps-privacy-policy",
    meta: apps_45privacy_45policyYqPXt1fSdJMeta || {},
    alias: apps_45privacy_45policyYqPXt1fSdJMeta?.alias || [],
    redirect: apps_45privacy_45policyYqPXt1fSdJMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/support/privacy/apps-privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: apps_45privacy_45policyYqPXt1fSdJMeta?.name ?? "support-privacy-apps-privacy-policy___mx",
    path: apps_45privacy_45policyYqPXt1fSdJMeta?.path ?? "/mx/support/privacy/apps-privacy-policy",
    meta: apps_45privacy_45policyYqPXt1fSdJMeta || {},
    alias: apps_45privacy_45policyYqPXt1fSdJMeta?.alias || [],
    redirect: apps_45privacy_45policyYqPXt1fSdJMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/support/privacy/apps-privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: apps_45privacy_45policyYqPXt1fSdJMeta?.name ?? "support-privacy-apps-privacy-policy___fr",
    path: apps_45privacy_45policyYqPXt1fSdJMeta?.path ?? "/fr/support/privacy/apps-privacy-policy",
    meta: apps_45privacy_45policyYqPXt1fSdJMeta || {},
    alias: apps_45privacy_45policyYqPXt1fSdJMeta?.alias || [],
    redirect: apps_45privacy_45policyYqPXt1fSdJMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/support/privacy/apps-privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: apps_45privacy_45policyYqPXt1fSdJMeta?.name ?? "support-privacy-apps-privacy-policy___fr-ca",
    path: apps_45privacy_45policyYqPXt1fSdJMeta?.path ?? "/fr-ca/support/privacy/apps-privacy-policy",
    meta: apps_45privacy_45policyYqPXt1fSdJMeta || {},
    alias: apps_45privacy_45policyYqPXt1fSdJMeta?.alias || [],
    redirect: apps_45privacy_45policyYqPXt1fSdJMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/support/privacy/apps-privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: index5Sl0SQyz0DMeta?.name ?? "support-privacy___en-US",
    path: index5Sl0SQyz0DMeta?.path ?? "/support/privacy",
    meta: index5Sl0SQyz0DMeta || {},
    alias: index5Sl0SQyz0DMeta?.alias || [],
    redirect: index5Sl0SQyz0DMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/support/privacy/index.vue").then(m => m.default || m)
  },
  {
    name: index5Sl0SQyz0DMeta?.name ?? "support-privacy___ca",
    path: index5Sl0SQyz0DMeta?.path ?? "/ca/support/privacy",
    meta: index5Sl0SQyz0DMeta || {},
    alias: index5Sl0SQyz0DMeta?.alias || [],
    redirect: index5Sl0SQyz0DMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/support/privacy/index.vue").then(m => m.default || m)
  },
  {
    name: index5Sl0SQyz0DMeta?.name ?? "support-privacy___nl",
    path: index5Sl0SQyz0DMeta?.path ?? "/nl/support/privacy",
    meta: index5Sl0SQyz0DMeta || {},
    alias: index5Sl0SQyz0DMeta?.alias || [],
    redirect: index5Sl0SQyz0DMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/support/privacy/index.vue").then(m => m.default || m)
  },
  {
    name: index5Sl0SQyz0DMeta?.name ?? "support-privacy___uk",
    path: index5Sl0SQyz0DMeta?.path ?? "/uk/support/privacy",
    meta: index5Sl0SQyz0DMeta || {},
    alias: index5Sl0SQyz0DMeta?.alias || [],
    redirect: index5Sl0SQyz0DMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/support/privacy/index.vue").then(m => m.default || m)
  },
  {
    name: index5Sl0SQyz0DMeta?.name ?? "support-privacy___de",
    path: index5Sl0SQyz0DMeta?.path ?? "/de/support/privacy",
    meta: index5Sl0SQyz0DMeta || {},
    alias: index5Sl0SQyz0DMeta?.alias || [],
    redirect: index5Sl0SQyz0DMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/support/privacy/index.vue").then(m => m.default || m)
  },
  {
    name: index5Sl0SQyz0DMeta?.name ?? "support-privacy___mx",
    path: index5Sl0SQyz0DMeta?.path ?? "/mx/support/privacy",
    meta: index5Sl0SQyz0DMeta || {},
    alias: index5Sl0SQyz0DMeta?.alias || [],
    redirect: index5Sl0SQyz0DMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/support/privacy/index.vue").then(m => m.default || m)
  },
  {
    name: index5Sl0SQyz0DMeta?.name ?? "support-privacy___fr",
    path: index5Sl0SQyz0DMeta?.path ?? "/fr/support/privacy",
    meta: index5Sl0SQyz0DMeta || {},
    alias: index5Sl0SQyz0DMeta?.alias || [],
    redirect: index5Sl0SQyz0DMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/support/privacy/index.vue").then(m => m.default || m)
  },
  {
    name: index5Sl0SQyz0DMeta?.name ?? "support-privacy___fr-ca",
    path: index5Sl0SQyz0DMeta?.path ?? "/fr-ca/support/privacy",
    meta: index5Sl0SQyz0DMeta || {},
    alias: index5Sl0SQyz0DMeta?.alias || [],
    redirect: index5Sl0SQyz0DMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/support/privacy/index.vue").then(m => m.default || m)
  },
  {
    name: special_45featuresvN4iXq6O36Meta?.name ?? "support-privacy-special-features___en-US",
    path: special_45featuresvN4iXq6O36Meta?.path ?? "/support/privacy/special-features",
    meta: special_45featuresvN4iXq6O36Meta || {},
    alias: special_45featuresvN4iXq6O36Meta?.alias || [],
    redirect: special_45featuresvN4iXq6O36Meta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/support/privacy/special-features.vue").then(m => m.default || m)
  },
  {
    name: special_45featuresvN4iXq6O36Meta?.name ?? "support-privacy-special-features___ca",
    path: special_45featuresvN4iXq6O36Meta?.path ?? "/ca/support/privacy/special-features",
    meta: special_45featuresvN4iXq6O36Meta || {},
    alias: special_45featuresvN4iXq6O36Meta?.alias || [],
    redirect: special_45featuresvN4iXq6O36Meta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/support/privacy/special-features.vue").then(m => m.default || m)
  },
  {
    name: special_45featuresvN4iXq6O36Meta?.name ?? "support-privacy-special-features___nl",
    path: special_45featuresvN4iXq6O36Meta?.path ?? "/nl/support/privacy/special-features",
    meta: special_45featuresvN4iXq6O36Meta || {},
    alias: special_45featuresvN4iXq6O36Meta?.alias || [],
    redirect: special_45featuresvN4iXq6O36Meta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/support/privacy/special-features.vue").then(m => m.default || m)
  },
  {
    name: special_45featuresvN4iXq6O36Meta?.name ?? "support-privacy-special-features___uk",
    path: special_45featuresvN4iXq6O36Meta?.path ?? "/uk/support/privacy/special-features",
    meta: special_45featuresvN4iXq6O36Meta || {},
    alias: special_45featuresvN4iXq6O36Meta?.alias || [],
    redirect: special_45featuresvN4iXq6O36Meta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/support/privacy/special-features.vue").then(m => m.default || m)
  },
  {
    name: special_45featuresvN4iXq6O36Meta?.name ?? "support-privacy-special-features___de",
    path: special_45featuresvN4iXq6O36Meta?.path ?? "/de/support/privacy/special-features",
    meta: special_45featuresvN4iXq6O36Meta || {},
    alias: special_45featuresvN4iXq6O36Meta?.alias || [],
    redirect: special_45featuresvN4iXq6O36Meta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/support/privacy/special-features.vue").then(m => m.default || m)
  },
  {
    name: special_45featuresvN4iXq6O36Meta?.name ?? "support-privacy-special-features___mx",
    path: special_45featuresvN4iXq6O36Meta?.path ?? "/mx/support/privacy/special-features",
    meta: special_45featuresvN4iXq6O36Meta || {},
    alias: special_45featuresvN4iXq6O36Meta?.alias || [],
    redirect: special_45featuresvN4iXq6O36Meta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/support/privacy/special-features.vue").then(m => m.default || m)
  },
  {
    name: special_45featuresvN4iXq6O36Meta?.name ?? "support-privacy-special-features___fr",
    path: special_45featuresvN4iXq6O36Meta?.path ?? "/fr/support/privacy/special-features",
    meta: special_45featuresvN4iXq6O36Meta || {},
    alias: special_45featuresvN4iXq6O36Meta?.alias || [],
    redirect: special_45featuresvN4iXq6O36Meta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/support/privacy/special-features.vue").then(m => m.default || m)
  },
  {
    name: special_45featuresvN4iXq6O36Meta?.name ?? "support-privacy-special-features___fr-ca",
    path: special_45featuresvN4iXq6O36Meta?.path ?? "/fr-ca/support/privacy/special-features",
    meta: special_45featuresvN4iXq6O36Meta || {},
    alias: special_45featuresvN4iXq6O36Meta?.alias || [],
    redirect: special_45featuresvN4iXq6O36Meta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/support/privacy/special-features.vue").then(m => m.default || m)
  },
  {
    name: subprocessor_45listoHE1svX0plMeta?.name ?? "support-privacy-subprocessor-list___en-US",
    path: subprocessor_45listoHE1svX0plMeta?.path ?? "/support/privacy/subprocessor-list",
    meta: subprocessor_45listoHE1svX0plMeta || {},
    alias: subprocessor_45listoHE1svX0plMeta?.alias || [],
    redirect: subprocessor_45listoHE1svX0plMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/support/privacy/subprocessor-list.vue").then(m => m.default || m)
  },
  {
    name: subprocessor_45listoHE1svX0plMeta?.name ?? "support-privacy-subprocessor-list___ca",
    path: subprocessor_45listoHE1svX0plMeta?.path ?? "/ca/support/privacy/subprocessor-list",
    meta: subprocessor_45listoHE1svX0plMeta || {},
    alias: subprocessor_45listoHE1svX0plMeta?.alias || [],
    redirect: subprocessor_45listoHE1svX0plMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/support/privacy/subprocessor-list.vue").then(m => m.default || m)
  },
  {
    name: subprocessor_45listoHE1svX0plMeta?.name ?? "support-privacy-subprocessor-list___nl",
    path: subprocessor_45listoHE1svX0plMeta?.path ?? "/nl/support/privacy/subprocessor-list",
    meta: subprocessor_45listoHE1svX0plMeta || {},
    alias: subprocessor_45listoHE1svX0plMeta?.alias || [],
    redirect: subprocessor_45listoHE1svX0plMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/support/privacy/subprocessor-list.vue").then(m => m.default || m)
  },
  {
    name: subprocessor_45listoHE1svX0plMeta?.name ?? "support-privacy-subprocessor-list___uk",
    path: subprocessor_45listoHE1svX0plMeta?.path ?? "/uk/support/privacy/subprocessor-list",
    meta: subprocessor_45listoHE1svX0plMeta || {},
    alias: subprocessor_45listoHE1svX0plMeta?.alias || [],
    redirect: subprocessor_45listoHE1svX0plMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/support/privacy/subprocessor-list.vue").then(m => m.default || m)
  },
  {
    name: subprocessor_45listoHE1svX0plMeta?.name ?? "support-privacy-subprocessor-list___de",
    path: subprocessor_45listoHE1svX0plMeta?.path ?? "/de/support/privacy/subprocessor-list",
    meta: subprocessor_45listoHE1svX0plMeta || {},
    alias: subprocessor_45listoHE1svX0plMeta?.alias || [],
    redirect: subprocessor_45listoHE1svX0plMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/support/privacy/subprocessor-list.vue").then(m => m.default || m)
  },
  {
    name: subprocessor_45listoHE1svX0plMeta?.name ?? "support-privacy-subprocessor-list___mx",
    path: subprocessor_45listoHE1svX0plMeta?.path ?? "/mx/support/privacy/subprocessor-list",
    meta: subprocessor_45listoHE1svX0plMeta || {},
    alias: subprocessor_45listoHE1svX0plMeta?.alias || [],
    redirect: subprocessor_45listoHE1svX0plMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/support/privacy/subprocessor-list.vue").then(m => m.default || m)
  },
  {
    name: subprocessor_45listoHE1svX0plMeta?.name ?? "support-privacy-subprocessor-list___fr",
    path: subprocessor_45listoHE1svX0plMeta?.path ?? "/fr/support/privacy/subprocessor-list",
    meta: subprocessor_45listoHE1svX0plMeta || {},
    alias: subprocessor_45listoHE1svX0plMeta?.alias || [],
    redirect: subprocessor_45listoHE1svX0plMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/support/privacy/subprocessor-list.vue").then(m => m.default || m)
  },
  {
    name: subprocessor_45listoHE1svX0plMeta?.name ?? "support-privacy-subprocessor-list___fr-ca",
    path: subprocessor_45listoHE1svX0plMeta?.path ?? "/fr-ca/support/privacy/subprocessor-list",
    meta: subprocessor_45listoHE1svX0plMeta || {},
    alias: subprocessor_45listoHE1svX0plMeta?.alias || [],
    redirect: subprocessor_45listoHE1svX0plMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/support/privacy/subprocessor-list.vue").then(m => m.default || m)
  },
  {
    name: support_45kb_45articlesMSmViJcgLhMeta?.name ?? "support-support-kb-articles___en-US",
    path: support_45kb_45articlesMSmViJcgLhMeta?.path ?? "/support/support-kb-articles",
    meta: support_45kb_45articlesMSmViJcgLhMeta || {},
    alias: support_45kb_45articlesMSmViJcgLhMeta?.alias || [],
    redirect: support_45kb_45articlesMSmViJcgLhMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/support/support-kb-articles.vue").then(m => m.default || m)
  },
  {
    name: support_45kb_45articlesMSmViJcgLhMeta?.name ?? "support-support-kb-articles___ca",
    path: support_45kb_45articlesMSmViJcgLhMeta?.path ?? "/ca/support/support-kb-articles",
    meta: support_45kb_45articlesMSmViJcgLhMeta || {},
    alias: support_45kb_45articlesMSmViJcgLhMeta?.alias || [],
    redirect: support_45kb_45articlesMSmViJcgLhMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/support/support-kb-articles.vue").then(m => m.default || m)
  },
  {
    name: support_45kb_45articlesMSmViJcgLhMeta?.name ?? "support-support-kb-articles___nl",
    path: support_45kb_45articlesMSmViJcgLhMeta?.path ?? "/nl/support/support-kb-articles",
    meta: support_45kb_45articlesMSmViJcgLhMeta || {},
    alias: support_45kb_45articlesMSmViJcgLhMeta?.alias || [],
    redirect: support_45kb_45articlesMSmViJcgLhMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/support/support-kb-articles.vue").then(m => m.default || m)
  },
  {
    name: support_45kb_45articlesMSmViJcgLhMeta?.name ?? "support-support-kb-articles___uk",
    path: support_45kb_45articlesMSmViJcgLhMeta?.path ?? "/uk/support/support-kb-articles",
    meta: support_45kb_45articlesMSmViJcgLhMeta || {},
    alias: support_45kb_45articlesMSmViJcgLhMeta?.alias || [],
    redirect: support_45kb_45articlesMSmViJcgLhMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/support/support-kb-articles.vue").then(m => m.default || m)
  },
  {
    name: support_45kb_45articlesMSmViJcgLhMeta?.name ?? "support-support-kb-articles___de",
    path: support_45kb_45articlesMSmViJcgLhMeta?.path ?? "/de/support/support-kb-articles",
    meta: support_45kb_45articlesMSmViJcgLhMeta || {},
    alias: support_45kb_45articlesMSmViJcgLhMeta?.alias || [],
    redirect: support_45kb_45articlesMSmViJcgLhMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/support/support-kb-articles.vue").then(m => m.default || m)
  },
  {
    name: support_45kb_45articlesMSmViJcgLhMeta?.name ?? "support-support-kb-articles___mx",
    path: support_45kb_45articlesMSmViJcgLhMeta?.path ?? "/mx/support/support-kb-articles",
    meta: support_45kb_45articlesMSmViJcgLhMeta || {},
    alias: support_45kb_45articlesMSmViJcgLhMeta?.alias || [],
    redirect: support_45kb_45articlesMSmViJcgLhMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/support/support-kb-articles.vue").then(m => m.default || m)
  },
  {
    name: support_45kb_45articlesMSmViJcgLhMeta?.name ?? "support-support-kb-articles___fr",
    path: support_45kb_45articlesMSmViJcgLhMeta?.path ?? "/fr/support/support-kb-articles",
    meta: support_45kb_45articlesMSmViJcgLhMeta || {},
    alias: support_45kb_45articlesMSmViJcgLhMeta?.alias || [],
    redirect: support_45kb_45articlesMSmViJcgLhMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/support/support-kb-articles.vue").then(m => m.default || m)
  },
  {
    name: support_45kb_45articlesMSmViJcgLhMeta?.name ?? "support-support-kb-articles___fr-ca",
    path: support_45kb_45articlesMSmViJcgLhMeta?.path ?? "/fr-ca/support/support-kb-articles",
    meta: support_45kb_45articlesMSmViJcgLhMeta || {},
    alias: support_45kb_45articlesMSmViJcgLhMeta?.alias || [],
    redirect: support_45kb_45articlesMSmViJcgLhMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/support/support-kb-articles.vue").then(m => m.default || m)
  },
  {
    name: thank_45you_45pagejkJKLwryrWMeta?.name ?? "thank-you-page___en-US",
    path: thank_45you_45pagejkJKLwryrWMeta?.path ?? "/thank-you-page",
    meta: thank_45you_45pagejkJKLwryrWMeta || {},
    alias: thank_45you_45pagejkJKLwryrWMeta?.alias || [],
    redirect: thank_45you_45pagejkJKLwryrWMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/thank-you-page.vue").then(m => m.default || m)
  },
  {
    name: thank_45you_45pagejkJKLwryrWMeta?.name ?? "thank-you-page___ca",
    path: thank_45you_45pagejkJKLwryrWMeta?.path ?? "/ca/thank-you-page",
    meta: thank_45you_45pagejkJKLwryrWMeta || {},
    alias: thank_45you_45pagejkJKLwryrWMeta?.alias || [],
    redirect: thank_45you_45pagejkJKLwryrWMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/thank-you-page.vue").then(m => m.default || m)
  },
  {
    name: thank_45you_45pagejkJKLwryrWMeta?.name ?? "thank-you-page___nl",
    path: thank_45you_45pagejkJKLwryrWMeta?.path ?? "/nl/thank-you-page",
    meta: thank_45you_45pagejkJKLwryrWMeta || {},
    alias: thank_45you_45pagejkJKLwryrWMeta?.alias || [],
    redirect: thank_45you_45pagejkJKLwryrWMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/thank-you-page.vue").then(m => m.default || m)
  },
  {
    name: thank_45you_45pagejkJKLwryrWMeta?.name ?? "thank-you-page___uk",
    path: thank_45you_45pagejkJKLwryrWMeta?.path ?? "/uk/thank-you-page",
    meta: thank_45you_45pagejkJKLwryrWMeta || {},
    alias: thank_45you_45pagejkJKLwryrWMeta?.alias || [],
    redirect: thank_45you_45pagejkJKLwryrWMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/thank-you-page.vue").then(m => m.default || m)
  },
  {
    name: thank_45you_45pagejkJKLwryrWMeta?.name ?? "thank-you-page___de",
    path: thank_45you_45pagejkJKLwryrWMeta?.path ?? "/de/thank-you-page",
    meta: thank_45you_45pagejkJKLwryrWMeta || {},
    alias: thank_45you_45pagejkJKLwryrWMeta?.alias || [],
    redirect: thank_45you_45pagejkJKLwryrWMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/thank-you-page.vue").then(m => m.default || m)
  },
  {
    name: thank_45you_45pagejkJKLwryrWMeta?.name ?? "thank-you-page___mx",
    path: thank_45you_45pagejkJKLwryrWMeta?.path ?? "/mx/thank-you-page",
    meta: thank_45you_45pagejkJKLwryrWMeta || {},
    alias: thank_45you_45pagejkJKLwryrWMeta?.alias || [],
    redirect: thank_45you_45pagejkJKLwryrWMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/thank-you-page.vue").then(m => m.default || m)
  },
  {
    name: thank_45you_45pagejkJKLwryrWMeta?.name ?? "thank-you-page___fr",
    path: thank_45you_45pagejkJKLwryrWMeta?.path ?? "/fr/thank-you-page",
    meta: thank_45you_45pagejkJKLwryrWMeta || {},
    alias: thank_45you_45pagejkJKLwryrWMeta?.alias || [],
    redirect: thank_45you_45pagejkJKLwryrWMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/thank-you-page.vue").then(m => m.default || m)
  },
  {
    name: thank_45you_45pagejkJKLwryrWMeta?.name ?? "thank-you-page___fr-ca",
    path: thank_45you_45pagejkJKLwryrWMeta?.path ?? "/fr-ca/thank-you-page",
    meta: thank_45you_45pagejkJKLwryrWMeta || {},
    alias: thank_45you_45pagejkJKLwryrWMeta?.alias || [],
    redirect: thank_45you_45pagejkJKLwryrWMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/thank-you-page.vue").then(m => m.default || m)
  },
  {
    name: thankyou_45recruitingYjmsuYLZyjMeta?.name ?? "thankyou-recruiting___en-US",
    path: thankyou_45recruitingYjmsuYLZyjMeta?.path ?? "/thankyou-recruiting",
    meta: thankyou_45recruitingYjmsuYLZyjMeta || {},
    alias: thankyou_45recruitingYjmsuYLZyjMeta?.alias || [],
    redirect: thankyou_45recruitingYjmsuYLZyjMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/thankyou-recruiting.vue").then(m => m.default || m)
  },
  {
    name: thankyou_45recruitingYjmsuYLZyjMeta?.name ?? "thankyou-recruiting___ca",
    path: thankyou_45recruitingYjmsuYLZyjMeta?.path ?? "/ca/thankyou-recruiting",
    meta: thankyou_45recruitingYjmsuYLZyjMeta || {},
    alias: thankyou_45recruitingYjmsuYLZyjMeta?.alias || [],
    redirect: thankyou_45recruitingYjmsuYLZyjMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/thankyou-recruiting.vue").then(m => m.default || m)
  },
  {
    name: thankyou_45recruitingYjmsuYLZyjMeta?.name ?? "thankyou-recruiting___nl",
    path: thankyou_45recruitingYjmsuYLZyjMeta?.path ?? "/nl/thankyou-recruiting",
    meta: thankyou_45recruitingYjmsuYLZyjMeta || {},
    alias: thankyou_45recruitingYjmsuYLZyjMeta?.alias || [],
    redirect: thankyou_45recruitingYjmsuYLZyjMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/thankyou-recruiting.vue").then(m => m.default || m)
  },
  {
    name: thankyou_45recruitingYjmsuYLZyjMeta?.name ?? "thankyou-recruiting___uk",
    path: thankyou_45recruitingYjmsuYLZyjMeta?.path ?? "/uk/thankyou-recruiting",
    meta: thankyou_45recruitingYjmsuYLZyjMeta || {},
    alias: thankyou_45recruitingYjmsuYLZyjMeta?.alias || [],
    redirect: thankyou_45recruitingYjmsuYLZyjMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/thankyou-recruiting.vue").then(m => m.default || m)
  },
  {
    name: thankyou_45recruitingYjmsuYLZyjMeta?.name ?? "thankyou-recruiting___de",
    path: thankyou_45recruitingYjmsuYLZyjMeta?.path ?? "/de/thankyou-recruiting",
    meta: thankyou_45recruitingYjmsuYLZyjMeta || {},
    alias: thankyou_45recruitingYjmsuYLZyjMeta?.alias || [],
    redirect: thankyou_45recruitingYjmsuYLZyjMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/thankyou-recruiting.vue").then(m => m.default || m)
  },
  {
    name: thankyou_45recruitingYjmsuYLZyjMeta?.name ?? "thankyou-recruiting___mx",
    path: thankyou_45recruitingYjmsuYLZyjMeta?.path ?? "/mx/thankyou-recruiting",
    meta: thankyou_45recruitingYjmsuYLZyjMeta || {},
    alias: thankyou_45recruitingYjmsuYLZyjMeta?.alias || [],
    redirect: thankyou_45recruitingYjmsuYLZyjMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/thankyou-recruiting.vue").then(m => m.default || m)
  },
  {
    name: thankyou_45recruitingYjmsuYLZyjMeta?.name ?? "thankyou-recruiting___fr",
    path: thankyou_45recruitingYjmsuYLZyjMeta?.path ?? "/fr/thankyou-recruiting",
    meta: thankyou_45recruitingYjmsuYLZyjMeta || {},
    alias: thankyou_45recruitingYjmsuYLZyjMeta?.alias || [],
    redirect: thankyou_45recruitingYjmsuYLZyjMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/thankyou-recruiting.vue").then(m => m.default || m)
  },
  {
    name: thankyou_45recruitingYjmsuYLZyjMeta?.name ?? "thankyou-recruiting___fr-ca",
    path: thankyou_45recruitingYjmsuYLZyjMeta?.path ?? "/fr-ca/thankyou-recruiting",
    meta: thankyou_45recruitingYjmsuYLZyjMeta || {},
    alias: thankyou_45recruitingYjmsuYLZyjMeta?.alias || [],
    redirect: thankyou_45recruitingYjmsuYLZyjMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/thankyou-recruiting.vue").then(m => m.default || m)
  },
  {
    name: thankyou_45referralWCvNYbLJfZMeta?.name ?? "thankyou-referral___en-US",
    path: thankyou_45referralWCvNYbLJfZMeta?.path ?? "/thankyou-referral",
    meta: thankyou_45referralWCvNYbLJfZMeta || {},
    alias: thankyou_45referralWCvNYbLJfZMeta?.alias || [],
    redirect: thankyou_45referralWCvNYbLJfZMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/thankyou-referral.vue").then(m => m.default || m)
  },
  {
    name: thankyou_45referralWCvNYbLJfZMeta?.name ?? "thankyou-referral___ca",
    path: thankyou_45referralWCvNYbLJfZMeta?.path ?? "/ca/thankyou-referral",
    meta: thankyou_45referralWCvNYbLJfZMeta || {},
    alias: thankyou_45referralWCvNYbLJfZMeta?.alias || [],
    redirect: thankyou_45referralWCvNYbLJfZMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/thankyou-referral.vue").then(m => m.default || m)
  },
  {
    name: thankyou_45referralWCvNYbLJfZMeta?.name ?? "thankyou-referral___nl",
    path: thankyou_45referralWCvNYbLJfZMeta?.path ?? "/nl/thankyou-referral",
    meta: thankyou_45referralWCvNYbLJfZMeta || {},
    alias: thankyou_45referralWCvNYbLJfZMeta?.alias || [],
    redirect: thankyou_45referralWCvNYbLJfZMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/thankyou-referral.vue").then(m => m.default || m)
  },
  {
    name: thankyou_45referralWCvNYbLJfZMeta?.name ?? "thankyou-referral___uk",
    path: thankyou_45referralWCvNYbLJfZMeta?.path ?? "/uk/thankyou-referral",
    meta: thankyou_45referralWCvNYbLJfZMeta || {},
    alias: thankyou_45referralWCvNYbLJfZMeta?.alias || [],
    redirect: thankyou_45referralWCvNYbLJfZMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/thankyou-referral.vue").then(m => m.default || m)
  },
  {
    name: thankyou_45referralWCvNYbLJfZMeta?.name ?? "thankyou-referral___de",
    path: thankyou_45referralWCvNYbLJfZMeta?.path ?? "/de/thankyou-referral",
    meta: thankyou_45referralWCvNYbLJfZMeta || {},
    alias: thankyou_45referralWCvNYbLJfZMeta?.alias || [],
    redirect: thankyou_45referralWCvNYbLJfZMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/thankyou-referral.vue").then(m => m.default || m)
  },
  {
    name: thankyou_45referralWCvNYbLJfZMeta?.name ?? "thankyou-referral___mx",
    path: thankyou_45referralWCvNYbLJfZMeta?.path ?? "/mx/thankyou-referral",
    meta: thankyou_45referralWCvNYbLJfZMeta || {},
    alias: thankyou_45referralWCvNYbLJfZMeta?.alias || [],
    redirect: thankyou_45referralWCvNYbLJfZMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/thankyou-referral.vue").then(m => m.default || m)
  },
  {
    name: thankyou_45referralWCvNYbLJfZMeta?.name ?? "thankyou-referral___fr",
    path: thankyou_45referralWCvNYbLJfZMeta?.path ?? "/fr/thankyou-referral",
    meta: thankyou_45referralWCvNYbLJfZMeta || {},
    alias: thankyou_45referralWCvNYbLJfZMeta?.alias || [],
    redirect: thankyou_45referralWCvNYbLJfZMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/thankyou-referral.vue").then(m => m.default || m)
  },
  {
    name: thankyou_45referralWCvNYbLJfZMeta?.name ?? "thankyou-referral___fr-ca",
    path: thankyou_45referralWCvNYbLJfZMeta?.path ?? "/fr-ca/thankyou-referral",
    meta: thankyou_45referralWCvNYbLJfZMeta || {},
    alias: thankyou_45referralWCvNYbLJfZMeta?.alias || [],
    redirect: thankyou_45referralWCvNYbLJfZMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/thankyou-referral.vue").then(m => m.default || m)
  },
  {
    name: thankyouPZHhtu3f8vMeta?.name ?? "thankyou___en-US",
    path: thankyouPZHhtu3f8vMeta?.path ?? "/thankyou",
    meta: thankyouPZHhtu3f8vMeta || {},
    alias: thankyouPZHhtu3f8vMeta?.alias || [],
    redirect: thankyouPZHhtu3f8vMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/thankyou.vue").then(m => m.default || m)
  },
  {
    name: thankyouPZHhtu3f8vMeta?.name ?? "thankyou___ca",
    path: thankyouPZHhtu3f8vMeta?.path ?? "/ca/thankyou",
    meta: thankyouPZHhtu3f8vMeta || {},
    alias: thankyouPZHhtu3f8vMeta?.alias || [],
    redirect: thankyouPZHhtu3f8vMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/thankyou.vue").then(m => m.default || m)
  },
  {
    name: thankyouPZHhtu3f8vMeta?.name ?? "thankyou___nl",
    path: thankyouPZHhtu3f8vMeta?.path ?? "/nl/thankyou",
    meta: thankyouPZHhtu3f8vMeta || {},
    alias: thankyouPZHhtu3f8vMeta?.alias || [],
    redirect: thankyouPZHhtu3f8vMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/thankyou.vue").then(m => m.default || m)
  },
  {
    name: thankyouPZHhtu3f8vMeta?.name ?? "thankyou___uk",
    path: thankyouPZHhtu3f8vMeta?.path ?? "/uk/thankyou",
    meta: thankyouPZHhtu3f8vMeta || {},
    alias: thankyouPZHhtu3f8vMeta?.alias || [],
    redirect: thankyouPZHhtu3f8vMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/thankyou.vue").then(m => m.default || m)
  },
  {
    name: thankyouPZHhtu3f8vMeta?.name ?? "thankyou___de",
    path: thankyouPZHhtu3f8vMeta?.path ?? "/de/thankyou",
    meta: thankyouPZHhtu3f8vMeta || {},
    alias: thankyouPZHhtu3f8vMeta?.alias || [],
    redirect: thankyouPZHhtu3f8vMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/thankyou.vue").then(m => m.default || m)
  },
  {
    name: thankyouPZHhtu3f8vMeta?.name ?? "thankyou___mx",
    path: thankyouPZHhtu3f8vMeta?.path ?? "/mx/thankyou",
    meta: thankyouPZHhtu3f8vMeta || {},
    alias: thankyouPZHhtu3f8vMeta?.alias || [],
    redirect: thankyouPZHhtu3f8vMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/thankyou.vue").then(m => m.default || m)
  },
  {
    name: thankyouPZHhtu3f8vMeta?.name ?? "thankyou___fr",
    path: thankyouPZHhtu3f8vMeta?.path ?? "/fr/thankyou",
    meta: thankyouPZHhtu3f8vMeta || {},
    alias: thankyouPZHhtu3f8vMeta?.alias || [],
    redirect: thankyouPZHhtu3f8vMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/thankyou.vue").then(m => m.default || m)
  },
  {
    name: thankyouPZHhtu3f8vMeta?.name ?? "thankyou___fr-ca",
    path: thankyouPZHhtu3f8vMeta?.path ?? "/fr-ca/thankyou",
    meta: thankyouPZHhtu3f8vMeta || {},
    alias: thankyouPZHhtu3f8vMeta?.alias || [],
    redirect: thankyouPZHhtu3f8vMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/thankyou.vue").then(m => m.default || m)
  },
  {
    name: eliteZ9FLwkC7z0Meta?.name ?? "tiers-elite___uk",
    path: eliteZ9FLwkC7z0Meta?.path ?? "/uk/tiers/elite",
    meta: eliteZ9FLwkC7z0Meta || {},
    alias: eliteZ9FLwkC7z0Meta?.alias || [],
    redirect: eliteZ9FLwkC7z0Meta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/tiers/elite.vue").then(m => m.default || m)
  },
  {
    name: eliteZ9FLwkC7z0Meta?.name ?? "tiers-elite___en-US",
    path: eliteZ9FLwkC7z0Meta?.path ?? "/tiers/elite",
    meta: eliteZ9FLwkC7z0Meta || {},
    alias: eliteZ9FLwkC7z0Meta?.alias || [],
    redirect: eliteZ9FLwkC7z0Meta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/tiers/elite.vue").then(m => m.default || m)
  },
  {
    name: eliteZ9FLwkC7z0Meta?.name ?? "tiers-elite___ca",
    path: eliteZ9FLwkC7z0Meta?.path ?? "/ca/tiers/elite",
    meta: eliteZ9FLwkC7z0Meta || {},
    alias: eliteZ9FLwkC7z0Meta?.alias || [],
    redirect: eliteZ9FLwkC7z0Meta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/tiers/elite.vue").then(m => m.default || m)
  },
  {
    name: plusAt9faH3Yv0Meta?.name ?? "tiers-plus___uk",
    path: plusAt9faH3Yv0Meta?.path ?? "/uk/tiers/plus",
    meta: plusAt9faH3Yv0Meta || {},
    alias: plusAt9faH3Yv0Meta?.alias || [],
    redirect: plusAt9faH3Yv0Meta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/tiers/plus.vue").then(m => m.default || m)
  },
  {
    name: plusAt9faH3Yv0Meta?.name ?? "tiers-plus___en-US",
    path: plusAt9faH3Yv0Meta?.path ?? "/tiers/plus",
    meta: plusAt9faH3Yv0Meta || {},
    alias: plusAt9faH3Yv0Meta?.alias || [],
    redirect: plusAt9faH3Yv0Meta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/tiers/plus.vue").then(m => m.default || m)
  },
  {
    name: plusAt9faH3Yv0Meta?.name ?? "tiers-plus___ca",
    path: plusAt9faH3Yv0Meta?.path ?? "/ca/tiers/plus",
    meta: plusAt9faH3Yv0Meta || {},
    alias: plusAt9faH3Yv0Meta?.alias || [],
    redirect: plusAt9faH3Yv0Meta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/tiers/plus.vue").then(m => m.default || m)
  },
  {
    name: premierdgidJHP9HCMeta?.name ?? "tiers-premier___uk",
    path: premierdgidJHP9HCMeta?.path ?? "/uk/tiers/premier",
    meta: premierdgidJHP9HCMeta || {},
    alias: premierdgidJHP9HCMeta?.alias || [],
    redirect: premierdgidJHP9HCMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/tiers/premier.vue").then(m => m.default || m)
  },
  {
    name: premierdgidJHP9HCMeta?.name ?? "tiers-premier___en-US",
    path: premierdgidJHP9HCMeta?.path ?? "/tiers/premier",
    meta: premierdgidJHP9HCMeta || {},
    alias: premierdgidJHP9HCMeta?.alias || [],
    redirect: premierdgidJHP9HCMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/tiers/premier.vue").then(m => m.default || m)
  },
  {
    name: premierdgidJHP9HCMeta?.name ?? "tiers-premier___ca",
    path: premierdgidJHP9HCMeta?.path ?? "/ca/tiers/premier",
    meta: premierdgidJHP9HCMeta || {},
    alias: premierdgidJHP9HCMeta?.alias || [],
    redirect: premierdgidJHP9HCMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/tiers/premier.vue").then(m => m.default || m)
  },
  {
    name: starterNhW4x0rBfRMeta?.name ?? "tiers-starter___uk",
    path: starterNhW4x0rBfRMeta?.path ?? "/uk/tiers/starter",
    meta: starterNhW4x0rBfRMeta || {},
    alias: starterNhW4x0rBfRMeta?.alias || [],
    redirect: starterNhW4x0rBfRMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/tiers/starter.vue").then(m => m.default || m)
  },
  {
    name: starterNhW4x0rBfRMeta?.name ?? "tiers-starter___en-US",
    path: starterNhW4x0rBfRMeta?.path ?? "/tiers/starter",
    meta: starterNhW4x0rBfRMeta || {},
    alias: starterNhW4x0rBfRMeta?.alias || [],
    redirect: starterNhW4x0rBfRMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/tiers/starter.vue").then(m => m.default || m)
  },
  {
    name: starterNhW4x0rBfRMeta?.name ?? "tiers-starter___ca",
    path: starterNhW4x0rBfRMeta?.path ?? "/ca/tiers/starter",
    meta: starterNhW4x0rBfRMeta || {},
    alias: starterNhW4x0rBfRMeta?.alias || [],
    redirect: starterNhW4x0rBfRMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/tiers/starter.vue").then(m => m.default || m)
  },
  {
    name: processZJO7ndr1jVMeta?.name ?? "trial-process___en-US",
    path: processZJO7ndr1jVMeta?.path ?? "/trial/process",
    meta: processZJO7ndr1jVMeta || {},
    alias: processZJO7ndr1jVMeta?.alias || [],
    redirect: processZJO7ndr1jVMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/trial/process.vue").then(m => m.default || m)
  },
  {
    name: processZJO7ndr1jVMeta?.name ?? "trial-process___ca",
    path: processZJO7ndr1jVMeta?.path ?? "/ca/trial/process",
    meta: processZJO7ndr1jVMeta || {},
    alias: processZJO7ndr1jVMeta?.alias || [],
    redirect: processZJO7ndr1jVMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/trial/process.vue").then(m => m.default || m)
  },
  {
    name: processZJO7ndr1jVMeta?.name ?? "trial-process___nl",
    path: processZJO7ndr1jVMeta?.path ?? "/nl/trial/process",
    meta: processZJO7ndr1jVMeta || {},
    alias: processZJO7ndr1jVMeta?.alias || [],
    redirect: processZJO7ndr1jVMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/trial/process.vue").then(m => m.default || m)
  },
  {
    name: processZJO7ndr1jVMeta?.name ?? "trial-process___uk",
    path: processZJO7ndr1jVMeta?.path ?? "/uk/trial/process",
    meta: processZJO7ndr1jVMeta || {},
    alias: processZJO7ndr1jVMeta?.alias || [],
    redirect: processZJO7ndr1jVMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/trial/process.vue").then(m => m.default || m)
  },
  {
    name: processZJO7ndr1jVMeta?.name ?? "trial-process___de",
    path: processZJO7ndr1jVMeta?.path ?? "/de/trial/process",
    meta: processZJO7ndr1jVMeta || {},
    alias: processZJO7ndr1jVMeta?.alias || [],
    redirect: processZJO7ndr1jVMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/trial/process.vue").then(m => m.default || m)
  },
  {
    name: processZJO7ndr1jVMeta?.name ?? "trial-process___mx",
    path: processZJO7ndr1jVMeta?.path ?? "/mx/trial/process",
    meta: processZJO7ndr1jVMeta || {},
    alias: processZJO7ndr1jVMeta?.alias || [],
    redirect: processZJO7ndr1jVMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/trial/process.vue").then(m => m.default || m)
  },
  {
    name: processZJO7ndr1jVMeta?.name ?? "trial-process___fr",
    path: processZJO7ndr1jVMeta?.path ?? "/fr/trial/process",
    meta: processZJO7ndr1jVMeta || {},
    alias: processZJO7ndr1jVMeta?.alias || [],
    redirect: processZJO7ndr1jVMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/trial/process.vue").then(m => m.default || m)
  },
  {
    name: processZJO7ndr1jVMeta?.name ?? "trial-process___fr-ca",
    path: processZJO7ndr1jVMeta?.path ?? "/fr-ca/trial/process",
    meta: processZJO7ndr1jVMeta || {},
    alias: processZJO7ndr1jVMeta?.alias || [],
    redirect: processZJO7ndr1jVMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/trial/process.vue").then(m => m.default || m)
  },
  {
    name: supportkanVjPWHOzMeta?.name ?? "warranty-support___uk",
    path: supportkanVjPWHOzMeta?.path ?? "/uk/warranty/support",
    meta: supportkanVjPWHOzMeta || {},
    alias: supportkanVjPWHOzMeta?.alias || [],
    redirect: supportkanVjPWHOzMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/warranty/support.vue").then(m => m.default || m)
  },
  {
    name: supportkanVjPWHOzMeta?.name ?? "warranty-support___en-US",
    path: supportkanVjPWHOzMeta?.path ?? "/warranty/support",
    meta: supportkanVjPWHOzMeta || {},
    alias: supportkanVjPWHOzMeta?.alias || [],
    redirect: supportkanVjPWHOzMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/warranty/support.vue").then(m => m.default || m)
  },
  {
    name: supportkanVjPWHOzMeta?.name ?? "warranty-support___ca",
    path: supportkanVjPWHOzMeta?.path ?? "/ca/warranty/support",
    meta: supportkanVjPWHOzMeta || {},
    alias: supportkanVjPWHOzMeta?.alias || [],
    redirect: supportkanVjPWHOzMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/warranty/support.vue").then(m => m.default || m)
  },
  {
    name: supportkanVjPWHOzMeta?.name ?? "warranty-support___ie",
    path: supportkanVjPWHOzMeta?.path ?? "/ie/warranty/support",
    meta: supportkanVjPWHOzMeta || {},
    alias: supportkanVjPWHOzMeta?.alias || [],
    redirect: supportkanVjPWHOzMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/warranty/support.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93vnmUVMG7c1Meta?.name ?? "webinars-slug___en-US",
    path: _91slug_93vnmUVMG7c1Meta?.path ?? "/webinars/:slug()",
    meta: _91slug_93vnmUVMG7c1Meta || {},
    alias: _91slug_93vnmUVMG7c1Meta?.alias || [],
    redirect: _91slug_93vnmUVMG7c1Meta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/webinars/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93vnmUVMG7c1Meta?.name ?? "webinars-slug___ca",
    path: _91slug_93vnmUVMG7c1Meta?.path ?? "/ca/webinars/:slug()",
    meta: _91slug_93vnmUVMG7c1Meta || {},
    alias: _91slug_93vnmUVMG7c1Meta?.alias || [],
    redirect: _91slug_93vnmUVMG7c1Meta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/webinars/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93vnmUVMG7c1Meta?.name ?? "webinars-slug___nl",
    path: _91slug_93vnmUVMG7c1Meta?.path ?? "/nl/webinars/:slug()",
    meta: _91slug_93vnmUVMG7c1Meta || {},
    alias: _91slug_93vnmUVMG7c1Meta?.alias || [],
    redirect: _91slug_93vnmUVMG7c1Meta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/webinars/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93vnmUVMG7c1Meta?.name ?? "webinars-slug___uk",
    path: _91slug_93vnmUVMG7c1Meta?.path ?? "/uk/webinars/:slug()",
    meta: _91slug_93vnmUVMG7c1Meta || {},
    alias: _91slug_93vnmUVMG7c1Meta?.alias || [],
    redirect: _91slug_93vnmUVMG7c1Meta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/webinars/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93vnmUVMG7c1Meta?.name ?? "webinars-slug___de",
    path: _91slug_93vnmUVMG7c1Meta?.path ?? "/de/webinars/:slug()",
    meta: _91slug_93vnmUVMG7c1Meta || {},
    alias: _91slug_93vnmUVMG7c1Meta?.alias || [],
    redirect: _91slug_93vnmUVMG7c1Meta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/webinars/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93vnmUVMG7c1Meta?.name ?? "webinars-slug___mx",
    path: _91slug_93vnmUVMG7c1Meta?.path ?? "/mx/webinars/:slug()",
    meta: _91slug_93vnmUVMG7c1Meta || {},
    alias: _91slug_93vnmUVMG7c1Meta?.alias || [],
    redirect: _91slug_93vnmUVMG7c1Meta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/webinars/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93vnmUVMG7c1Meta?.name ?? "webinars-slug___fr",
    path: _91slug_93vnmUVMG7c1Meta?.path ?? "/fr/webinars/:slug()",
    meta: _91slug_93vnmUVMG7c1Meta || {},
    alias: _91slug_93vnmUVMG7c1Meta?.alias || [],
    redirect: _91slug_93vnmUVMG7c1Meta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/webinars/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93vnmUVMG7c1Meta?.name ?? "webinars-slug___fr-ca",
    path: _91slug_93vnmUVMG7c1Meta?.path ?? "/fr-ca/webinars/:slug()",
    meta: _91slug_93vnmUVMG7c1Meta || {},
    alias: _91slug_93vnmUVMG7c1Meta?.alias || [],
    redirect: _91slug_93vnmUVMG7c1Meta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/webinars/[slug].vue").then(m => m.default || m)
  },
  {
    name: indextjKbUF5IdyMeta?.name ?? "webinars___en-US",
    path: indextjKbUF5IdyMeta?.path ?? "/webinars",
    meta: indextjKbUF5IdyMeta || {},
    alias: indextjKbUF5IdyMeta?.alias || [],
    redirect: indextjKbUF5IdyMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/webinars/index.vue").then(m => m.default || m)
  },
  {
    name: indextjKbUF5IdyMeta?.name ?? "webinars___ca",
    path: indextjKbUF5IdyMeta?.path ?? "/ca/webinars",
    meta: indextjKbUF5IdyMeta || {},
    alias: indextjKbUF5IdyMeta?.alias || [],
    redirect: indextjKbUF5IdyMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/webinars/index.vue").then(m => m.default || m)
  },
  {
    name: indextjKbUF5IdyMeta?.name ?? "webinars___nl",
    path: indextjKbUF5IdyMeta?.path ?? "/nl/webinars",
    meta: indextjKbUF5IdyMeta || {},
    alias: indextjKbUF5IdyMeta?.alias || [],
    redirect: indextjKbUF5IdyMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/webinars/index.vue").then(m => m.default || m)
  },
  {
    name: indextjKbUF5IdyMeta?.name ?? "webinars___uk",
    path: indextjKbUF5IdyMeta?.path ?? "/uk/webinars",
    meta: indextjKbUF5IdyMeta || {},
    alias: indextjKbUF5IdyMeta?.alias || [],
    redirect: indextjKbUF5IdyMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/webinars/index.vue").then(m => m.default || m)
  },
  {
    name: indextjKbUF5IdyMeta?.name ?? "webinars___de",
    path: indextjKbUF5IdyMeta?.path ?? "/de/webinars",
    meta: indextjKbUF5IdyMeta || {},
    alias: indextjKbUF5IdyMeta?.alias || [],
    redirect: indextjKbUF5IdyMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/webinars/index.vue").then(m => m.default || m)
  },
  {
    name: indextjKbUF5IdyMeta?.name ?? "webinars___mx",
    path: indextjKbUF5IdyMeta?.path ?? "/mx/webinars",
    meta: indextjKbUF5IdyMeta || {},
    alias: indextjKbUF5IdyMeta?.alias || [],
    redirect: indextjKbUF5IdyMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/webinars/index.vue").then(m => m.default || m)
  },
  {
    name: indextjKbUF5IdyMeta?.name ?? "webinars___fr",
    path: indextjKbUF5IdyMeta?.path ?? "/fr/webinars",
    meta: indextjKbUF5IdyMeta || {},
    alias: indextjKbUF5IdyMeta?.alias || [],
    redirect: indextjKbUF5IdyMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/webinars/index.vue").then(m => m.default || m)
  },
  {
    name: indextjKbUF5IdyMeta?.name ?? "webinars___fr-ca",
    path: indextjKbUF5IdyMeta?.path ?? "/fr-ca/webinars",
    meta: indextjKbUF5IdyMeta || {},
    alias: indextjKbUF5IdyMeta?.alias || [],
    redirect: indextjKbUF5IdyMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/webinars/index.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93FMr0SHE25sMeta?.name ?? "webinars-thank-you-slug___en-US",
    path: _91slug_93FMr0SHE25sMeta?.path ?? "/webinars/thank-you/:slug()",
    meta: _91slug_93FMr0SHE25sMeta || {},
    alias: _91slug_93FMr0SHE25sMeta?.alias || [],
    redirect: _91slug_93FMr0SHE25sMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/webinars/thank-you/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93FMr0SHE25sMeta?.name ?? "webinars-thank-you-slug___ca",
    path: _91slug_93FMr0SHE25sMeta?.path ?? "/ca/webinars/thank-you/:slug()",
    meta: _91slug_93FMr0SHE25sMeta || {},
    alias: _91slug_93FMr0SHE25sMeta?.alias || [],
    redirect: _91slug_93FMr0SHE25sMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/webinars/thank-you/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93FMr0SHE25sMeta?.name ?? "webinars-thank-you-slug___nl",
    path: _91slug_93FMr0SHE25sMeta?.path ?? "/nl/webinars/thank-you/:slug()",
    meta: _91slug_93FMr0SHE25sMeta || {},
    alias: _91slug_93FMr0SHE25sMeta?.alias || [],
    redirect: _91slug_93FMr0SHE25sMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/webinars/thank-you/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93FMr0SHE25sMeta?.name ?? "webinars-thank-you-slug___uk",
    path: _91slug_93FMr0SHE25sMeta?.path ?? "/uk/webinars/thank-you/:slug()",
    meta: _91slug_93FMr0SHE25sMeta || {},
    alias: _91slug_93FMr0SHE25sMeta?.alias || [],
    redirect: _91slug_93FMr0SHE25sMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/webinars/thank-you/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93FMr0SHE25sMeta?.name ?? "webinars-thank-you-slug___de",
    path: _91slug_93FMr0SHE25sMeta?.path ?? "/de/webinars/thank-you/:slug()",
    meta: _91slug_93FMr0SHE25sMeta || {},
    alias: _91slug_93FMr0SHE25sMeta?.alias || [],
    redirect: _91slug_93FMr0SHE25sMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/webinars/thank-you/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93FMr0SHE25sMeta?.name ?? "webinars-thank-you-slug___mx",
    path: _91slug_93FMr0SHE25sMeta?.path ?? "/mx/webinars/thank-you/:slug()",
    meta: _91slug_93FMr0SHE25sMeta || {},
    alias: _91slug_93FMr0SHE25sMeta?.alias || [],
    redirect: _91slug_93FMr0SHE25sMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/webinars/thank-you/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93FMr0SHE25sMeta?.name ?? "webinars-thank-you-slug___fr",
    path: _91slug_93FMr0SHE25sMeta?.path ?? "/fr/webinars/thank-you/:slug()",
    meta: _91slug_93FMr0SHE25sMeta || {},
    alias: _91slug_93FMr0SHE25sMeta?.alias || [],
    redirect: _91slug_93FMr0SHE25sMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/webinars/thank-you/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93FMr0SHE25sMeta?.name ?? "webinars-thank-you-slug___fr-ca",
    path: _91slug_93FMr0SHE25sMeta?.path ?? "/fr-ca/webinars/thank-you/:slug()",
    meta: _91slug_93FMr0SHE25sMeta || {},
    alias: _91slug_93FMr0SHE25sMeta?.alias || [],
    redirect: _91slug_93FMr0SHE25sMeta?.redirect,
    component: () => import("/codebuild/output/src298072591/src/horizon/pages/webinars/thank-you/[slug].vue").then(m => m.default || m)
  }
]