<template>
  <LinksLocaleLink
    v-if="
      arrowLink.type === 'Large Blue' ||
      arrowLink.type === 'Blue Small Animated' ||
      arrowLink.type === 'Small Navy Animated' ||
      arrowLink.type === 'White Small Animated'
    "
    :class="arrowLinkClasses"
    :link="arrowLink.linkUrl"
    :target="arrowLink.target"
    >{{ arrowLink.text }}
    <svg
      width="14"
      height="11"
      viewBox="0 0 14 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      class="inline"
    >
      <path
        d="M6 1L11.1648 5.45055L6 9.9011"
        :stroke="
          arrowLink.type === 'Large Blue' ||
          arrowLink.type === 'Blue Small Animated'
            ? '#0384FB'
            : arrowLink.type === 'White Small Animated'
              ? '#FFFFFF'
              : '#00263e'
        "
        stroke-width="2.5"
      />
      <rect
        y="4.25"
        width="11"
        height="2.5"
        :fill="
          arrowLink.type === 'Large Blue' ||
          arrowLink.type === 'Blue Small Animated'
            ? '#0384FB'
            : arrowLink.type === 'White Small Animated'
              ? '#FFFFFF'
              : '#00263e'
        "
      />
    </svg>
    <LinksContentfulLink v-if="id" :id="id" label="arrowlink" />
  </LinksLocaleLink>
  <p
    v-else-if="arrowLink.type === 'Blue Arrowless'"
    class="a--link-arrow-arrowless relative inline-block pb-1/4 text-blue !no-underline"
  >
    <LinksLocaleLink
      :class="arrowLinkClasses"
      :link="arrowLink.linkUrl"
      :target="arrowLink.target"
      >{{ arrowLink.text }}</LinksLocaleLink
    ><span
      class="absolute bottom-0 left-0 z-20 w-0 bg-blue duration-200 ease-in-out"
    ></span>
    <LinksContentfulLink v-if="id" :id="id" label="arrowlink" />
  </p>
  <LinksBlueInstallationArrowlink
    v-else-if="arrowLink.type === 'Blue Installation Modal'"
    class="arrowlink--modal"
    :arrowlink="arrowLink"
  />
  <LinksVideoArrowLink
    v-else-if="arrowLink.type === 'Video Arrow'"
    class="arrowlink--modal-video"
    :class="arrowLinkClasses"
    :arrowlink="arrowLink"
  />
  <LinksScrollArrowLink
    v-else-if="arrowLink.type === 'Scroll Arrow'"
    class="scroll--arrow mr-1 font-bold text-blue"
    :class="arrowLinkClasses"
    :href="arrowLink.linkUrl"
    :text="arrowLink.text"
  />
  <LinksBlueModalArrowlink
    v-else-if="arrowLink.type === 'Blue Modal'"
    class="arrowlink--modal-blue"
    :arrowlink="arrowLink"
    :inline="inline"
  />
  <LinksLocaleLink
    v-else
    :class="arrowLinkClasses"
    :link="arrowLink.linkUrl"
    :target="arrowLink.target"
    >{{ arrowLink.text }}
    <LinksContentfulLink v-if="id" :id="id" label="arrowlink" />
  </LinksLocaleLink>
</template>

<script>
  export default {
    props: {
      arrowLink: {
        type: Object,
        required: true,
      },
      id: {
        type: String,
        required: false,
        default: null,
      },
      inline: {
        type: Boolean,
        required: false,
        default: false,
      },
    },
    computed: {
      arrowLinkClasses() {
        const styles = {
          'Large Teal':
            'a--link-arrow text-lg !no-underline inline-block text-teal font-medium !border-none',
          'Small Navy':
            'uppercase p--xs tracking-wide font-medium transition-colors duration-100 ease-in-out text-blue-dark !border-none a--link-arrow border-b-1 border-teal inline !no-underline relative pb-1/4 hover:text-teal',
          'No Arrow Blue':
            'uppercase p--xs font-bold tracking-half text-blue transition-colors duration-100 ease-in-out !border-none hover:text-blue-darker',
          'Small Blue':
            'a--link-arrow text-base !no-underline inline-block text-blue font-medium !border-none',
          'Blue Small Animated':
            'a--link-arrow-animated text-base !no-underline inline-block text-blue font-medium !border-none',
          'Large Blue':
            'a--link-arrow-animated text-lg !no-underline inline-block text-blue font-medium !border-none',
          'Blue Arrowless':
            '!no-underline border-b-0 font-semibold !border-none',
          'Small Navy Animated':
            'a--link-arrow-animated text-xxs uppercase tracking-wide !no-underline text-blue-dark font-bold hover:text-blue block py-1/2 !border-none',
          'Scroll Arrow': 'font-bold text-blue mr-1',
          'VideosTheVideo Arrow': 'text-white font-bold',
          'White Small Animated':
            'a--link-arrow-animated a--link-arrow-animated--white text-base inline-block text-white font-bold !border-none',
          'Regular Navy':
            'font-normal text-blue-dark !border-none inline-block md:hover:text-blue md:hover:cursor-pointer md:hover:font-medium',
        };
        return `whitespace-normal ${styles[this.$props.arrowLink.type]}`;
      },
    },
  };
</script>

<style lang="scss" scoped>
  .a--link-arrow-arrowless {
    font-size: 20px;
    span {
      height: 3px;
    }

    &:hover {
      span {
        width: 100%;
      }
    }

    &::after {
      @apply absolute bottom-0 left-0 w-full;

      background: rgba(0, 0, 0, 0.23);
      height: 3px;
      content: '';
    }

    @media (max-width: theme('screens.md')) {
      font-size: 16px;
    }
  }
</style>
