<template>
  <div
    tabindex="0"
    class="card cms-card-media-swap overflow-hidden md:aspect-[3/4] h-0 aspect-[1/1] relative z-10 flex flex-1 origin-center flex-col transition-all ease-out md:hover:scale-110 md:hover:cursor-pointer md:focus:z-20 md:focus:scale-110"
  >
    <LinksLocaleLink
      v-if="card.link"
      :link="card.link ? card.link : ''"
      class="cms-card-media-swap-link absolute bottom-0 left-0 right-0 top-0"
    >
    </LinksLocaleLink>
    <ImagesNuxtImg
      v-if="card.image.fields.image.fields.file"
      :url="card.image.fields.image.fields.file.url"
      :lazy="false"
      class="pointer-events-none absolute left-0 right-0 top-0 h-full w-full object-cover"
      :alt="card.image.fields.alt ? card.image.fields.alt : ''"
    />
    <ImagesNuxtImg
      v-if="card.secondaryImage.fields.image.fields.file"
      :url="card.secondaryImage.fields.image.fields.file.url"
      :lazy="false"
      class="pointer-events-none absolute left-0 right-0 top-0 h-full w-full object-cover md:opacity-0"
      :alt="
        card.secondaryImage.fields.alt
          ? card.secondaryImage.fields.image.alt
          : ''
      "
    />
    <div
      v-if="card.content"
      class="cms-text-content--top z-10 pointer-events-none absolute top-0 flex flex-col p-1 w-full"
    >
      <MarkdownTheMarkdown :source="card.content" />
    </div>

    <div
      class="text-content absolute bottom-0 left-0 z-10 flex flex-col p-1 transition-all"
    >
      <div
        v-if="card.content"
        class="md:block hidden h-[1px] my-1 bg-white w-full relative opacity-50"
      ></div>
      <h4
        v-if="card.title"
        class="pointer-events-none mb-1/2 pr-1 capitalize text-white"
        :class="textAlignmentClasses"
      >
        {{ card.title }}
      </h4>
      <p
        v-if="card.bodyText"
        class="pointer-events-none mb-0 md:max-h-0 pr-1 text-white md:opacity-0 h-full opacity-100 transition-all"
        :class="textAlignmentClasses"
      >
        {{ card.bodyText }}
      </p>
      <template v-if="card.cta">
        <div v-for="(cta, index) in card.cta" :key="index">
          <ButtonsTheButton
            v-if="cta.sys.contentType.sys.id === 'button'"
            :button="cta.fields"
          />
          <LinksArrowLink
            v-else-if="cta.sys.contentType.sys.id === 'arrowlink'"
            :arrow-link="cta.fields"
          />
        </div>
      </template>
    </div>
    <div
      class="gradient pointer-events-none absolute bottom-0 block w-full"
    ></div>
    <LinksContentfulLink v-if="id" :id="id" label="card" />
  </div>
</template>
<script>
  export default {
    props: {
      card: {
        type: Object,
        required: true,
      },
      id: {
        type: String,
        required: false,
        default: null,
      },
    },
    computed: {
      textAlignmentClasses() {
        const dict = {
          Center: 'text-center',
          Left: 'text-left',
          Right: 'text-right',
        };

        return dict[this.card.textAlign];
      },
    },
  };
</script>
<style lang="scss" scoped>
  .card {
    .cms-card-media-swap {
      img {
        @apply transition-transform;
      }

      .cms-text-content--top {
        :deep(h1),
        :deep(p) {
          @apply text-white mb-0 font-bold;
        }
        :deep(p) {
          @apply md:text-[11px] text-[16px] leading-[1.5em] md:w-[70%];
        }
      }

      .gradient {
        @apply md:h-[66%] h-[70%] opacity-100;
        background: linear-gradient(0deg, #000 -20.25%, rgba(0, 0, 0, 0) 87%);
      }

      .gradient-top {
        @apply md:h-[33%] h-[50%] opacity-100;
        background: linear-gradient(
          180deg,
          rgba(0, 0, 0, 0.6) 0%,
          rgba(0, 0, 0, 0) 81.59%
        );

        @screen md {
          background: linear-gradient(
            180deg,
            rgba(0, 0, 0, 0.5) 0%,
            rgba(0, 0, 0, 0) 81.59%
          );
        }
      }

      img:first-child {
        @apply opacity-0;
      }

      img:last-child {
        @apply opacity-100;
      }

      &:focus,
      &:focus-within {
        .text-content {
          h4 {
            @apply mb-0;
          }
          p {
            @apply mb-1 max-h-fit opacity-100;
          }
        }

        .gradient {
          @apply h-[100%] opacity-50;
          background: #000;
        }
      }

      img:first-child {
        @apply opacity-0;
      }

      img:last-child {
        @apply opacity-100;
      }

      @screen md {
        img:first-child {
          @apply opacity-100;
        }

        img:last-child {
          @apply opacity-0;
        }

        .cms-card-media-swap-link {
          &:hover {
            + img {
              @apply opacity-0;
            }
            + img + img {
              @apply opacity-100;
            }
          }
        }

        &:hover {
          img:first-child {
            @apply opacity-0;
          }

          img:last-child {
            @apply opacity-100;
          }

          .text-content {
            h4 {
              @apply mb-0;
            }
            p {
              @apply mb-1 max-h-fit opacity-100;
            }
          }

          .gradient {
            @apply h-[100%] opacity-60;
            background: #000;
          }
        }
      }

      @screen lg {
        &:hover,
        &:focus,
        &:focus-within {
          .gradient {
            @apply h-[70%] opacity-100;
            background: linear-gradient(
              0deg,
              #000 -20.25%,
              rgba(0, 0, 0, 0) 87%
            );
          }
        }
      }
    }
  }
</style>
