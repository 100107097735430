<template>
  <div class="comparison-table relative overflow-x-scroll md:overflow-hidden">
    <div :class="`Rtable Rtable--${table.columns}cols mb-1 flex flex-wrap p-0`">
      <ModularTableCell
        v-for="(cell, index) in table.cells"
        :key="index"
        :cell="cell"
      />
    </div>
    <LinksContentfulLink :id="id" label="table" />
  </div>
</template>
<style lang="scss" scoped>
  .comparison-table {
    .Rtable {
      min-width: 560px;
    }

    /* Table column sizing
================================== */
    .Rtable--2cols > :deep(.Rtable-cell) {
      width: 50%;

      &:nth-child(-n + 2) {
        background: #00263e;
        border: 0;
        font-weight: 600;
        color: #fff;
        text-transform: uppercase;

        p {
          font-weight: 600;
          color: #fff;
          text-transform: uppercase;
        }
      }

      &:nth-child(2n + 1) {
        border-left: 1px solid #e1e1e1;
      }

      &:nth-last-child(-n + 2) {
        border-bottom: 1px solid #e1e1e1;
      }
    }

    .Rtable--3cols > :deep(.Rtable-cell) {
      width: 33.33%;

      &:nth-child(-n + 3) {
        background: #00263e;
        border: 0;
        font-weight: 600;
        color: #fff;
        text-transform: uppercase;

        p {
          font-weight: 600;
          color: #fff;
          text-transform: uppercase;
        }
      }

      &:nth-child(3n + 1) {
        border-left: 1px solid #e1e1e1;
      }

      &:nth-last-child(-n + 3) {
        border-bottom: 1px solid #e1e1e1;
      }
    }

    .Rtable--4cols > :deep(.Rtable-cell) {
      width: 25%;

      &:nth-child(-n + 4) {
        background: #00263e;
        border: 0;
        font-weight: 600;
        color: #fff;
        text-transform: uppercase;

        p {
          font-weight: 600;
          color: #fff;
          text-transform: uppercase;
        }
      }

      &:nth-child(4n + 1) {
        border-left: 1px solid #e1e1e1;
      }

      &:nth-last-child(-n + 4) {
        border-bottom: 1px solid #e1e1e1;
      }
    }

    .Rtable--5cols > :deep(.Rtable-cell) {
      width: 20%;

      &:nth-child(-n + 5) {
        background: #00263e;
        border: 0;
        font-weight: 600;
        color: #fff;
        text-transform: uppercase;

        p {
          font-weight: 600;
          color: #fff;
          text-transform: uppercase;
        }
      }

      &:nth-child(5n + 1) {
        border-left: 1px solid #e1e1e1;
      }

      &:nth-last-child(-n + 5) {
        border-bottom: 1px solid #e1e1e1;
      }
    }

    .Rtable--6cols > :deep(.Rtable-cell) {
      width: 16.6%;

      &:nth-child(-n + 6) {
        background: #00263e;
        border: 0;
        font-weight: 600;
        color: #fff;
        text-transform: uppercase;

        p {
          font-weight: 600;
          color: #fff;
          text-transform: uppercase;
        }
      }

      &:nth-child(6n + 1) {
        border-left: 1px solid #e1e1e1;
      }

      &:nth-last-child(-n + 6) {
        border-bottom: 1px solid #e1e1e1;
      }
    }
  }
</style>
<script>
  export default {
    props: {
      table: {
        type: Object,
        required: true,
      },
      id: {
        type: String,
        required: false,
        default: null,
      },
    },
  };
</script>
