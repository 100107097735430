<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="25"
    height="25"
    viewBox="0 0 25 25"
    fill="none"
  >
    <path
      d="M16.9009 12L12.4503 17.1648L7.99978 12"
      :stroke="strokeColor"
      stroke-width="2"
    />
    <path
      d="M12.4009 15L12.4009 8"
      :stroke="strokeColor"
      stroke-width="2"
      stroke-linecap="square"
      stroke-linejoin="round"
    />
    <circle
      cx="12.5"
      cy="12.5"
      r="11.5"
      :stroke="strokeColor"
      stroke-width="2"
    />
  </svg>
</template>
<script>
  export default {
    props: {
      strokeColor: {
        type: String,
        required: false,
        default: '#0384FB',
      },
    },
  };
</script>
