import { BLOCKS } from '@contentful/rich-text-types/dist/blocks.js';
import { INLINES } from '@contentful/rich-text-types/dist/inlines.js';
import MARKS from '@contentful/rich-text-types/dist/marks.js';
import { h } from 'vue';
import BaseButton from '~/components/base/Button.vue';
import Button from '~/components/buttons/TheButton.vue';
import TheCard from '~/components/cards/TheCard.vue';
import TheIcon from '~/components/icons/TheIcon.vue';
import TheInput from '~/components/inputs/TheInput.vue';
import ArrowLink from '~/components/links/ArrowLink.vue';
import LocaleLink from '~/components/links/LocaleLink.vue';
import Table from '~/components/modular/Table.vue';
import Sym from '~/components/symbols/Sym.vue';
import CustomText from '~/components/typography/CustomText.vue';
import TheVideo from '~/components/videos/TheVideo.vue';
import ListWrapper from '~/components/wrappers/ListWrapper.vue';
import PictureWrapper from '~/components/wrappers/PictureWrapper.vue';
import EmbeddedForm from '~/components/forms/EmbeddedForm.vue';
import Chart from '~/components/charts/TheChart.vue';

// flattens the fields property of contentful rest repsponse data
export function flatten(data) {
  const flattenFields = (obj) => {
    if (obj === null || typeof obj !== 'object') {
      // base case, return primitive value
      return obj;
    }

    // handle arrays like rows
    if (Array.isArray(obj)) {
      return obj.map((item) => flattenFields(item));
    }

    const result = {};

    for (let key in obj) {
      if (obj.hasOwnProperty(key)) {
        // leave property sys intact
        if (key === 'sys') {
          result[key] = obj[key];
          continue;
        }

        // if a 'fields' property is found, recursively flatten it
        if (key === 'fields') {
          Object.assign(result, flattenFields(obj[key]));
        } else {
          // recursively process other properties which eventually return primitives
          result[key] = flattenFields(obj[key]);
        }
      }
    }

    return result;
  };

  const flattened = flattenFields(data.items[0]);

  return flattened;
}

const customHyperLinks = (node, key, next) => {
  const url = node.data.uri;
  const value = node.content[0].value;

  return h(
    LocaleLink,
    {
      key,
      link: url,
      target: '_blank',
    },
    [h('span', null, value)]
  );
};

const customEmbeddedEntry = (node, key, next) => {
  const fields = node.data.target.fields;
  const sysid = node.data.target.sys.id;
  const type = node.data.target.sys.contentType.sys.id;

  switch (type) {
    case 'arrowlink':
      return h(ArrowLink, {
        key,
        arrowLink: fields,
        id: sysid,
      });

    case 'baseButton':
      return h(BaseButton, {
        key,
        button: fields,
        id: sysid,
      });

    case 'button':
      return h(Button, {
        key,
        button: fields,
        id: sysid,
      });

    case 'card':
      return h(TheCard, {
        key,
        card: fields,
        id: sysid,
      });

    case 'chart':
      return h(Chart, {
        key,
        chart: fields,
        id: sysid,
      });

    case 'customText':
      return h(CustomText, {
        key,
        customText: fields,
        id: sysid,
      });

    case 'embeddedForm':
      return h(EmbeddedForm, {
        key,
        formId: fields.formId,
        successResponse: fields.successResponse,
        id: sysid,
      });

    case 'icon':
      return h(TheIcon, {
        key,
        icon: fields,
        id: sysid,
      });

    case 'image':
      return h(PictureWrapper, {
        key,
        image: fields,
        inline: false,
        id: sysid,
      });

    case 'input':
      return h(TheInput, {
        key,
        type: fields.type,
        tooltip: fields.tooltip,
        tooltipText: fields.tooltipText,
        placeholder: fields.placeholder,
        placeholderTextColor: fields.placeholderTextColor,
        endpoint: fields.endpoint,
        iconColor: fields.iconColor,
        id: sysid,
      });

    case 'list':
      return h(ListWrapper, {
        key,
        entryObj: fields,
        id: sysid,
      });

    case 'symbol':
      return h(Sym, {
        key,
        symbol: fields,
        id: sysid,
      });

    case 'table':
      return h(Table, {
        key,
        table: fields,
        id: sysid,
      });

    case 'video':
      return h(TheVideo, {
        key,
        video: fields,
        id: sysid,
      });

    default:
      return '';
  }
};

const customEmbeddedInlineEntry = (node, key, next) => {
  const fields = node.data.target.fields;
  const sysid = node.data.target.sys.id;
  const type = node.data.target ? node.data.target.sys.contentType.sys.id : '';

  switch (type) {
    case 'arrowlink':
      return h(ArrowLink, {
        key,
        arrowLink: fields,
        id: sysid,
        inline: true,
      });

    case 'button':
      return h(Button, {
        key,
        button: fields,
        id: sysid,
      });

    case 'card':
      return h(TheCard, {
        key,
        card: fields,
        id: sysid,
      });

    case 'customText':
      return h(CustomText, {
        key,
        customText: fields,
        id: sysid,
      });

    case 'icon':
      return h(TheIcon, {
        key,
        icon: fields,
        id: sysid,
      });

    case 'image':
      return h(PictureWrapper, {
        key,
        image: fields,
        inline: true,
        id: sysid,
      });

    case 'input':
      return h(TheInput, {
        key,
        type: fields.type,
        tooltip: fields.tooltip,
        tooltipText: fields.tooltipText,
        placeholder: fields.placeholder,
        endpoint: fields.endpoint,
        id: sysid,
      });

    case 'symbol':
      return h(Sym, {
        key,
        symbol: fields,
        id: sysid,
      });

    case 'video':
      return h(TheVideo, {
        key,
        video: fields,
        id: sysid,
      });

    default:
      return '';
  }
};

const customCode = (text) => {
  return h('span', { innerHTML: text[0] });
};

export const nodeRenderers = {
  [INLINES.HYPERLINK]: customHyperLinks,
  [BLOCKS.EMBEDDED_ENTRY]: customEmbeddedEntry,
  [INLINES.EMBEDDED_ENTRY]: customEmbeddedInlineEntry,
};

export const markRenderers = {
  [MARKS.CODE]: customCode,
};
