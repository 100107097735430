<template>
  <div
    ref="imageWrapper"
    class="wrapper__images relative -mx-gap2 mb-5 md:mx-auto md:mb-auto"
  >
    <ImagesPicture
      v-if="props.card.image"
      :image="props.card.image.fields"
      classes="w-full mb-5"
    />
    <ImagesPicture
      v-if="props.card.secondaryImage"
      ref="animatedImage"
      class="image--animated absolute left-0 top-0 w-full"
      classes="w-full"
      :image="props.card.secondaryImage.fields"
    />
    <LinksContentfulLink v-if="props.id" :id="props.id" label="card" />
  </div>
</template>

<script setup>
  const props = defineProps({
    card: {
      type: Object,
      required: true,
    },
    id: {
      type: String,
      required: false,
      default: null,
    },
  });
</script>

<style lang="scss" scoped>
  .image--animated {
    transform: translateY(30px);
    opacity: 0;
    transition: 0.6s ease-in-out;
    will-change: transform opacity;

    &.active {
      transform: translateY(0);
      opacity: 1;
    }
  }
</style>
