export enum ButtonAction {
  PLAY_AUDIO = 'Play Audio',
  TOGGLE_MODAL = 'Toggle Modal',
  JUMP_LINK = 'Jump Link',
}

export enum ButtonVariant {
  PRIMARY_BLUE = 'Primary Blue',
}

export enum Icon {
  AUDIO = 'Audio',
}

export enum IconPosition {
  BEFORE_TEXT = 'Before Text',
  AFTER_TEXT = 'After Text',
}
