import { generateAvailableLocales } from '~/utils/meta-generators';

const getContentfulPageLocales = async ({ options }) => {
  const { $contentfulClient } = useNuxtApp();
  const { 'fields.availableInLocale': _, ...opts } = options;

  const defaultOptions = {
    locale: '*',
    include: 1,
  };

  const parameters = {
    ...defaultOptions,
    ...opts,
  };

  try {
    const entries = await $contentfulClient.getEntries(parameters);
    return generateAvailableLocales(entries.items[0].fields.availableInLocale);
  } catch (error) {
    throw createError({
      statusCode: 500,
      statusMessage: error instanceof Error ? error.message : '',
    });
  }
};

export default defineNuxtPlugin(() => {
  return {
    provide: {
      getContentfulPageLocales,
    },
  };
});
