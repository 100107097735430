import { redirectList } from '~/constants/redirects-base.js';

export default defineNuxtRouteMiddleware((to) => {
  const localTest = '(fr|uk|de|mx|be|ca|fr-ca|nl)';
  const slugTest = '(.*$)';
  const rmTrailing = to.path.replace(/\/+$/, '');
  const queryParams = to.query;
  const queryString = () => {
    const searchParams = new URLSearchParams(queryParams);

    const queryString = searchParams.toString();

    return queryString !== '' ? `?${queryString}` : '';
  };

  to.url = rmTrailing;

  // in case we need to match trailing slash (.*[^\\\/]{1,})([\\\/]{1,}$)
  const redirect = redirectList.find((r) => {
    if (r.source.includes('<lang_code>')) {
      if (r.source.includes('<slug>')) {
        const testArray = r.source.split('<lang_code>');
        const testArraySlug = testArray[1].replace('<slug>', slugTest);
        const regSlug = new RegExp(
          `^${testArray[0]}${localTest}${testArraySlug}$`
        );
        const matches = to.url.match(regSlug);

        if (matches) {
          const newTo = r.target
            .replace('<lang_code>', matches[1])
            .replace('<slug>', matches[2]);
          r.target = newTo;
          return regSlug.test(to.url);
        } else return false;
      } else {
        const testArray = r.source.split('<lang_code>');
        const reg = new RegExp(`^${testArray[0]}${localTest}${testArray[1]}$`);
        const matches = to.url.match(reg);

        if (matches) {
          const newTo = r.target.replace('<lang_code>', matches[1]);
          r.target = newTo;
          return reg.test(to.url);
        } else return false;
      }
    } else if (
      r.source.includes('<slug>') &&
      !r.source.includes('<lang_code>')
    ) {
      const testString = r.source;
      const testArraySlug = testString.replace('<slug>', slugTest);
      const regSlug = new RegExp('^' + testArraySlug + '$');
      const matches = to.url.match(regSlug);

      if (matches) {
        const newTo = r.target.replace('<slug>', matches[1]);
        r.target = newTo;
        return regSlug.test(to.url);
      } else return false;
    } else {
      return r.source === to.url;
    }
  });

  if (redirect) {
    return navigateTo(`${redirect.target}${queryString()}`, {
      redirectCode: redirect.status ? redirect.status : 301,
      external: true,
    });
  }
});
