import { report } from '~/utils/telemetry';
import robots from '~/constants/bots-list';

interface TrackFunnelData {
  formType: string;
  pageName: string;
  formUrl: string;
  isMobile: boolean;
  localeSite: string;
  stepNumber: number | 0;
  stepName: string | null;
  stepText: string;
  flow: string;
  maxSteps: number;
  assetType: string | null;
  size: string | null;
  industry: string | null;
  feature: string | null;
}

export function useAnalytics() {
  const route = useRoute();
  const { isProduction } = useRuntimeConfig().public;
  const { isMobile } = useDevice();
  const optCookie = useCookie('opt_id');
  const optVariation = useCookie('opt_variation');

  function analyticsAlias(identifier, properties = {}, silent = false) {
    const analytics = (window.analytics = window.analytics || []);
    try {
      analytics.alias(identifier, properties);
    } catch (error) {
      if (!silent) {
        report(error);
      }
    }
  }

  function analyticsIdentify(identifier, properties = {}, silent = false) {
    const analytics = (window.analytics = window.analytics || []);

    try {
      const userAgent = window.navigator.userAgent || "";
      const isRobot = robots.some((robot) =>
        userAgent.toLowerCase().includes(robot)
      );

      if (!isRobot) {
        analytics.identify(identifier, properties);
      }
    } catch (error) {
      if (!silent) {
        report(error);
      }
    }
  }

  function analyticsTrack(eventName, customProperties = {}, silent = false) {
    if (!window.analytics) {
      return;
    }

    const analytics = (window.analytics = window.analytics || []);
    const userAgent = window.navigator.userAgent || "";
    try {
      const baseProperties = {
        is_mobile: isMobile,
        user_agent_mp: userAgent,
        app: 'horizon',
      };
      const properties = { ...baseProperties, ...customProperties };

      analytics.track(eventName, properties);
    } catch (error) {
      if (isProduction && !silent) {
        if (process.client) {
          report(error);
        }
      }
    }
  }

  function analyticsTrackLink(
    element,
    eventName,
    customProperties = {},
    silent = false
  ) {
    if (!window.analytics) {
      return;
    }

    const analytics = (window.analytics = window.analytics || []);
    try {
      const baseProperties = {
        is_mobile: isMobile,
      };
      const properties = { ...baseProperties, ...customProperties };

      analytics.trackLink(element, eventName, properties);
    } catch (error) {
      if (!silent) {
        report(error);
      }
    }
  }

  function analyticsTrackRetry(
    eventName,
    customProperties = {},
    maxAttempts = 10,
    silent = false
  ) {
    try {
      // Wait for analytics to be defined before tracking first step to avoid race
      // condition. This code does not block the main thread. The attempt logic is
      // to prevent this code from running perpetually if analytics will never be
      // defined like when someone has an adblocker.
      (async () => {
        let attempts = 0;
        while (
          typeof window.analytics === 'undefined' &&
          attempts < maxAttempts
        ) {
          attempts += 1;
          await new Promise((resolve) => setTimeout(resolve, 500));
        }
        if (attempts <= maxAttempts) {
          analyticsTrack(eventName, customProperties);
        }
      })();
    } catch (error) {
      if (!silent) {
        report(error);
      }
    }
  }

  function trackFleetAssessmentPricingClick() {
    const properties = {
      page_name: document.title,
      url: route.path,
    };
    analyticsTrack('FA Pricing Button Clicked', properties);
  }

  function trackFleetAssessmentStep(step, max) {
    const properties = {
      page_name: document.title,
      url: route.path,
      current_step: step,
      max_step: max,
    };
    analyticsTrack('FA Step Viewed', properties);
  }

  function trackFunnelStep({
    formType,
    formUrl,
    localeSite,
    isMobile,
    stepNumber,
    stepName,
    stepText,
    maxSteps,
    flow,
    pageName,
    size,
    assetType,
    industry,
    feature,
  }: TrackFunnelData) {
    const properties = {
      form_type: formType,
      'page name': pageName,
      form_url: formUrl,
      is_mobile: isMobile,
      locale_site: localeSite,
      step_number: stepNumber,
      step_name: stepName,
      step_text: stepText,
      flow,
      'max steps': maxSteps,
      field_data: {
        asset_type: assetType,
        size,
        industry,
        feature,
      },
    };
    if (stepNumber === 0) {
      analyticsTrack('Form Started', {
        form_type: formType,
        form_url: formUrl,
        is_mobile: isMobile,
        locale_site: localeSite,
      });
    } else {
      analyticsTrack('Form Step Completed', properties);
    }
  }

  function trackFleetAssessmentSubmit() {
    const properties = {
      page_name: document.title,
      url: route.path,
    };
    analyticsTrack('FA Submitted', properties);
  }

  function trackExperiment({ path, expRows, expKey, variation }) {
    if (optCookie && optVariation && expRows) {
      /* TODO: Add experiment mixpanel tracking: commenting this out for now until we get the ok 
      from David Benac on triggering the experiment in Mixpanel. More information on the event 
      here: https://docs.mixpanel.com/docs/reports/apps/experiments#add-experiments-to-an-implementation 
      ----
      analyticsTrack('$experiment_started', {
        'Experiment name': expKey,
        'Variant name': variation,
      });
      */
      analyticsTrack('experiment page viewed', {
        optimizely_experiment: expKey,
        optimizely_user_variation: variation,
        'page name': '',
        url: path,
      });
      analyticsIdentify({
        optimizely_feature_experiment: {
          user_experiment: expKey,
          user_variation: variation,
        },
      });
      // post to hotjar
      try {
        (async () => {
          const maxAttempts = 10;
          let attempts = 0;
          // wait for script to load
          while (typeof window.hj === undefined && attempts < maxAttempts) {
            attempts += 1;
            await new Promise((resolve) => setTimeout(resolve, 500));
          }
          if (attempts <= maxAttempts) {
            const hj =
              window.hj ||
              function () {
                (hj.q = hj.q || []).push(arguments);
              };
            const userId = optCookie || null;
            hj('identify', userId, {
              'Optimizely User Experiment': expKey,
              'Optimizely User Variation': variation,
            });
          }
        })();
      } catch (error) {
        report(error);
      }
    }
  }

  return {
    analyticsAlias,
    analyticsIdentify,
    analyticsTrack,
    analyticsTrackLink,
    analyticsTrackRetry,
    trackFleetAssessmentPricingClick,
    trackFleetAssessmentStep,
    trackFleetAssessmentSubmit,
    trackFunnelStep,
    trackExperiment,
  };
}
