<template>
  <div>
    <div v-if="stickyFooter && !hidePricing">
      <div
        v-if="stickyFooter.type === 'Footer Button'"
        class="footer--sticky btn--big fixed bottom-0 z-40 flex w-full items-center justify-center bg-white md:hidden"
      >
        <ButtonsTheButton
          v-if="stickyFooter.button.fields"
          :button="stickyFooter.button.fields"
        />
      </div>

      <div
        v-else-if="stickyFooter.type === 'Footer Button Text'"
        class="footer--sticky fixed bottom-0 z-40 flex w-full items-center justify-center bg-white sm:hidden"
      >
        <div class="inline-block w-6/12 text-center">
          <RichTextRenderer
            v-if="stickyFooter.content !== undefined"
            :document="stickyFooter.content"
            :node-renderers="nodeRenderers"
          />
        </div>
        <div class="btn--small flex w-6/12 items-center justify-center">
          <ButtonsTheButton
            v-if="stickyFooter.button.fields !== undefined"
            :button="stickyFooter.button.fields"
          />
        </div>
      </div>
    </div>

    <div v-else-if="!loading && !hidePricing">
      <div
        class="footer--sticky btn--big fixed bottom-0 z-40 flex w-full items-center justify-center bg-white md:hidden"
      >
        <span class="btn--big w-full text-center">
          <LinksLocaleLink
            v-if="header.tryNavigation?.href && header.tryNavigation?.text"
            :link="header.tryNavigation.href"
            class="btn btn--blue btn--blue:hover m-auto"
            >{{ header.tryNavigation.text }}</LinksLocaleLink
          >
        </span>
      </div>
    </div>
  </div>
</template>

<script>
  import RichTextRenderer from 'contentful-rich-text-vue-renderer';
  import { nodeRenderers } from '~/utils/contentful-helpers';
  import { localeMappings } from '~/constants/locales';

  export default {
    components: {
      RichTextRenderer,
    },
    props: {
      stickyFooter: {
        type: Object,
        required: false,
        default: () => ({}),
      },
      hidePricing: {
        type: Boolean,
        required: false,
        default: false,
      },
    },
    async setup() {
      const { locale } = useI18n();

      const { data, error, pending } = await useAsyncGql({
        operation: 'StickyFooter',
        variables: {
          locale: localeMappings[locale.value],
        },
      });

      if (error.value) {
        report(error.value);
      }

      return {
        header: data.value?.header ?? '',
        loading: pending.value,
      };
    },
  };
</script>

<style lang="scss" scoped>
  // Sticky footers
  .footer--sticky {
    height: 60px;
    .btn--big {
      a {
        width: 80%;
      }
    }
    .btn--small {
      a {
        padding: 1rem;
        min-width: 10px;
        font-size: 11px;
      }
    }
    p {
      @apply m-0 text-xs font-normal text-blue-dark;
    }
    p:first-of-type {
      @apply text-sm font-medium;
      margin-top: 4px;
    }
  }
</style>
