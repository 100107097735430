<template>
  <div class="relative">
    <div class="flex flex-row items-center">
      <IconsSvgTheWorld
        :stroke="transparent ? 'white' : '#00263E'"
        class="relative w-[20px]"
      />
      <select
        v-model="selectedLocale"
        name="select-region"
        tabindex="0"
        :class="{ 'text-white': transparent, 'text-blue-dark': !transparent }"
        class="tls--select ml-1/4 h-full w-[50px] border-none bg-transparent text-[10px] font-bold hover:cursor-pointer focus:outline-none focus-visible:outline-1 active:outline-none"
        @change.prevent.stop="changeLocale"
      >
        <option selected disabled class="text-xs">
          {{ selectedLocale.toUpperCase() }}
        </option>
        <option
          v-for="(country, index) in countries"
          :key="index"
          :value="country.value.toLowerCase()"
          class="text-xs"
          :class="{ hidden: selectedLocale === country.value }"
        >
          {{ country.value }}
        </option>
      </select>
    </div>
  </div>
</template>
<script setup>
  import { localeMappings } from '~/constants/locales';

  const { locale } = useI18n();
  const { fullPath } = useRoute();
  const { analyticsTrack } = useAnalytics();

  const props = defineProps({
    countries: {
      type: Array,
      required: true,
    },
    transparent: {
      type: Boolean,
      required: false,
      default: false,
    },
  });

  // METHODS

  const trackClick = (buttonName, locale) => {
    analyticsTrack('Button Clicked', {
      locale,
      buttonName,
    });
  };

  const formatLocale = (locale) => {
    return locale.split('-').join('-').toUpperCase();
  };

  const selectedLocale = ref(
    formatLocale(locale.value === 'en-US' ? 'us' : locale.value)
  );

  const changeLocale = async () => {
    const basePath =
      selectedLocale.value === 'us' ? '' : `/${selectedLocale.value}`;
    const host = window.location.origin;
    let newPath = basePath + fullPath;
    trackClick('Locale Selector', selectedLocale.value);
    if (locale !== 'en-US') {
      for (const key in localeMappings) {
        if (fullPath.split('/').includes(`${key}`)) {
          const pathSegments = fullPath.split('/');
          newPath = basePath + '/' + pathSegments.slice(2).join('/');
          break;
        }
      }
    }
    try {
      await $fetch.raw(host + newPath);
      await navigateTo({ path: newPath });
    } catch (error) {
      await navigateTo({ path: '/' });
    }
  };
</script>
