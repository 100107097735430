<template>
  <div
    class="li-list-item relative flex h-auto flex-col px-0 md:px-1/2"
    :class="{
      'mb-3 w-full': contentType === 'blogPost',
      'card mb-0 md:mb-1': contentType === 'card',
      'card--reveal': itemFields?.type === 'Reveal',
      ' border-b-1 border-gray-light first:border-t-1 md:border-transparent':
        itemFields?.type === 'Reveal' && totalItems > 3,
      'w-full': contentType === 'video',
      'md:px-[15px]':
        contentType === 'card' && itemFields?.type === 'Simple Image Left',
      'md:px-1/2': contentType !== 'card',
    }"
  >
    <template v-if="contentType === 'card'">
      <CardsTheCard
        :card="itemFields"
        :aspect-ratio="transformAspectRatio"
        :total-items="totalItems"
      />
    </template>
    <template v-if="contentType === 'blogPost'">
      <LinksLocaleLink
        class="relative mb-1 h-0 overflow-hidden bg-gray-lightest"
        :class="transformAspectRatio"
        :link="`${type(itemFields.type[0])}${itemFields.blogPageSlug}`"
        :target="target"
      >
        <ImagesNuxtImg
          :url="
            itemFields.backgroundImage.fields.file.url
              ? itemFields.backgroundImage.fields.file.url
              : ''
          "
          :is-inline="true"
          :alt="
            itemFields.backgroundImage.fields.description
              ? itemFields.backgroundImage.fields.description
              : ''
          "
          :class="applyMediaPosition"
          class="absolute bottom-0 left-0 right-0 top-0 mb-1 h-full w-full object-cover"
          :lazy="false"
        />
      </LinksLocaleLink>
      <LinksLocaleLink
        :link="`${type(itemFields.type[0])}${itemFields.blogPageSlug}`"
        :target="target"
      >
        <h4 class="mb-[17px] text-xl leading-8 hover:underline">
          {{ itemFields.title }}
        </h4>
      </LinksLocaleLink>
      <span class="mt-auto">
        <span
          v-if="itemFields.author && showAuthor === true"
          class="relative mb-1 flex flex-row"
        >
          <span
            class="relative mr-1 inline-block h-[44px] w-[44px] overflow-hidden rounded-full"
          >
            <ImagesNuxtImg
              :url="itemFields.author.fields?.image?.fields?.file?.url || ''"
              :is-inline="true"
              :alt="itemFields.author.fields?.name || ''"
              class="absolute bottom-0 left-0 right-0 top-0 h-full w-full object-cover"
              :lazy="false"
              :image="{
                width:
                  itemFields.author.fields?.image?.fields?.file?.details?.image
                    ?.width || 418,
                height:
                  itemFields.author.fields?.image?.fields?.file?.details?.image
                    ?.height || 418,
              }"
            />
          </span>
          <span class="justify-left flex flex-col self-center">
            <a
              v-if="
                itemFields.author.fields.name &&
                itemFields.author.fields.linkedinUrl
              "
              class="block text-xxs font-bold uppercase tracking-wide hover:underline"
              :href="itemFields.author.fields.linkedinUrl"
              target="_blank"
            >
              {{ itemFields.author.fields.name }}
            </a>
            <h5
              v-else-if="
                itemFields.author.fields.name &&
                !itemFields.author.fields.linkedinUrl
              "
              class="mb-0 font-bold uppercase tracking-wide hover:underline"
            >
              {{ itemFields.author.fields.name }}
            </h5>
            <p
              v-if="itemFields.author.fields.title"
              class="p--fine-print mb-0 italic leading-5"
            >
              {{ itemFields.author.fields.title }}
            </p></span
          >
        </span>
        <LinksLocaleLink
          :link="`${type(itemFields.type[0])}${itemFields.blogPageSlug}`"
          class="a--link-arrow-animated relative mb-0 mt-1 text-base font-medium capitalize text-blue no-underline"
          :target="target"
        >
          {{ itemLinkText }}

          <svg
            width="14"
            height="11"
            viewBox="0 0 14 11"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            class="inline"
          >
            <path
              d="M6 1L11.1648 5.45055L6 9.9011"
              stroke="#0384FB"
              stroke-width="2.5"
            />
            <rect y="4.25" width="11" height="2.5" fill="#0384FB" />
          </svg> </LinksLocaleLink
      ></span>
    </template>
    <template v-if="contentType === 'image'">
      <ImagesNuxtImg
        :url="itemFields.image.fields.file.url"
        :alt="
          itemFields.image.fields.alt
            ? itemFields.image.fields.alt
            : 'background img'
        "
        :lazy="false"
        :classes="classes"
        :image="{
          width: itemFields.image.fields.file.details.image.width
            ? itemFields.image.fields.file.details.image.width
            : null,
          height: itemFields.image.fields.file.details.image.height
            ? itemFields.image.fields.file.details.image.height
            : null,
        }"
      />
    </template>
    <template v-if="contentType === 'video'">
      <VideosTheVideo :state="'play'" :video="itemFields" :lazy="true" />
      <h4 v-if="itemFields.footnote" class="mb-0 mt-[17px] text-xl leading-8">
        {{ itemFields.footnote }}
      </h4>
    </template>
  </div>
</template>
<script>
  export default {
    props: {
      itemFields: {
        type: Object,
        required: true,
      },
      contentType: {
        type: String,
        required: true,
      },
      itemLinkText: {
        type: String,
        required: false,
        default: '',
      },
      visibleLimit: {
        type: Number,
        required: false,
        default: 3,
      },
      mediaPosition: {
        type: String,
        required: false,
        default: 'object-center',
      },
      showAuthor: {
        type: Boolean,
        required: false,
        default: false,
      },
      aspectRatio: {
        type: String,
        required: false,
        default: '3:2',
      },
      target: {
        type: String,
        required: false,
        default: '_self',
      },
      totalItems: {
        type: Number,
        required: false,
        default: 0,
      },
    },
    computed: {
      classes() {
        return this.itemFields.classes ? this.itemFields.classes.join(' ') : '';
      },
      applyMediaPosition() {
        const dictionary = {
          Center: 'object-center',
          Top: 'object-top',
          Bottom: 'object-bottom',
        };
        return dictionary[this.mediaPosition];
      },
      transformAspectRatio() {
        const dictionary = {
          '16:9': 'aspect--16x9',
          '3:2': 'aspect--3x2',
          '1:1': 'aspect--1x1',
          '4:3': 'aspect--4x3',
          '3:4': 'aspect-[3/4]',
        };
        return dictionary[this.aspectRatio];
      },
    },
    methods: {
      type(type) {
        const types = {
          Webinar: '/webinars/',
          'Blog Post': '/blog/',
          'Case Study': '/customers/',
          'Guide Page': '/guides/',
        };
        return types[type];
      },
    },
  };
</script>
