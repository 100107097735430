<template>
  <div>
    <FormsMarketoForm
      v-if="formId"
      :form-num="formId"
      :form-date="formDate"
      :form-type="formType"
      :custom-confirm="successResponse || null"
      :prevent-redirect="true"
      :gated="gated"
      @webinar:submitted="$emit('webinar:submitted')"
    />
  </div>
</template>
<script>
  export default {
    props: {
      formId: {
        type: String,
        required: true,
      },
      formType: {
        type: String,
        required: false,
        default: '',
      },
      formDate: {
        type: String,
        required: false,
        default: '',
      },
      successResponse: {
        type: String,
        required: false,
        default: null,
      },
      id: {
        type: String,
        required: false,
        default: null,
      },
      gated: {
        type: Boolean,
        required: false,
        default: false,
      },
    },
    emits: ['webinar:submitted'],
    setup() {
      useCreateMarketo();
    },
  };
</script>
