<template>
  <span v-if="link === undefined" :class="$attrs.class"></span>
  <a
    v-else-if="link.includes('http')"
    :class="$attrs.class"
    :href="link"
    target="_blank"
    ><slot></slot
  ></a>
  <a v-else-if="link.includes('mailto:')" :class="$attrs.class" :href="link"
    ><slot></slot
  ></a>
  <a
    v-else-if="!link.charAt(0).includes('/') && !link.includes('mailto:')"
    :class="$attrs.class"
    :href="link"
    :target="target"
    ><slot></slot
  ></a>
  <a v-else :class="$attrs.class" :href="localePath(link)" :target="target"
    ><slot></slot
  ></a>
</template>

<script>
  export default {
    props: {
      link: {
        type: String,
        required: true,
      },
      target: {
        type: String,
        required: false,
        default: '_self',
      },
    },
    setup() {
      const localePath = useLocalePath();

      return {
        localePath,
      };
    },
  };
</script>
