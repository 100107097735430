import { LDPlugin } from 'launchdarkly-vue-client-sdk';
import { generateUUID } from '~/utils/html-helpers';
import { datadogRum } from '@datadog/browser-rum';

export default defineNuxtPlugin((nuxtApp) => {
  const clientSideID = useRuntimeConfig().public.ldClient;
  const locale = nuxtApp.$i18n.locale.value;
  const route = nuxtApp._route.fullPath;
  const key = generateUUID();
  const { isMobile } = useDevice();

  const userContext = {
    kind: 'user',
    name: 'website_user',
    key,
    locale,
    route,
    isMobile,
  };

  // @ts-ignore
  nuxtApp.vueApp.use(LDPlugin, {
    clientSideID,
    context: userContext,
    options: {
      inspectors: [
        {
          type: 'flag-used',
          name: 'dd-inspector',
          method: (key, detail) => {
            datadogRum.addFeatureFlagEvaluation(key, detail.value);
          },
        },
      ],
    },
  });
});
