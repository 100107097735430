import { datadogRum } from '@datadog/browser-rum';

const initDatadog = ({
  ddApplicationId,
  ddApplicationToken,
  ddVersion,
  ddEnv,
}) => {
  datadogRum.init({
    applicationId: ddApplicationId,
    clientToken: ddApplicationToken,
    defaultPrivacyLevel: 'mask-user-input',
    env: ddEnv,
    enableExperimentalFeatures: ['feature_flags'],
    service: 'samsara.com',
    sessionReplaySampleRate: 0,
    sessionSampleRate: 1,
    site: 'datadoghq.com',
    trackLongTasks: true,
    trackResources: true,
    trackUserInteractions: true,
    version: ddVersion,
  });
  datadogRum.startSessionReplayRecording();
};

export default defineNuxtPlugin((nuxtApp) => {
  const { ddApplicationId, ddApplicationToken, ddVersion, ddEnv } =
    useRuntimeConfig().public;

  nuxtApp.hook('app:mounted', () => {
    initDatadog({
      ddApplicationId,
      ddApplicationToken,
      ddVersion,
      ddEnv,
    });
  });
});
