<template>
  <nav
    v-if="!linksPending && !entriesPending"
    :class="desktopClasses"
    class="tn--nav fixed top-0 flex h-[52px] w-full flex-row justify-between px-gap2 transition-all delay-100 md:h-[75px]"
  >
    <ul class="flex h-full flex-row items-center justify-between">
      <li class="h-[31px] w-[100px] md:w-[126px]">
        <NuxtLink :to="localePath('/')">
          <LogosSvgLogoMain
            class="tn-logo h-full w-full transition-all"
            :fill="
              !isTransparent ||
              scrollDirection === NavigationDirection.UP ||
              navigationActive
                ? '#00263e'
                : 'white'
            "
          />
        </NuxtLink>
      </li>
    </ul>
    <ul class="tn--nav-menu h-full flex-row items-center justify-between">
      <li
        v-for="(link, index) in links"
        :key="index"
        ref="parentLinks"
        role="button"
        tabindex="0"
        :data-parent="link.text"
        :aria-current="linkStates[index].active ? true : false"
        class="tn--nav-item flex h-[32px] flex-row items-center justify-center p-1 hover:cursor-pointer focus-visible:outline-1"
        :class="{
          active: linkStates[index].active,
          selected: activeParentIndex === index,
        }"
        @click="setActiveMenuItem(index)"
        @keydown.enter="setActiveMenuItem(index)"
        @keydown.escape="setActiveMenuItem(null)"
      >
        <p
          v-if="link.text"
          :class="{
            'text-white':
              (scrollDirection === NavigationDirection.TOP ||
                scrollDirection === NavigationDirection.UNSET) &&
              isTransparent &&
              !navigationActive,
            'text-blue-dark':
              (navigationActive ||
                scrollDirection === NavigationDirection.UP) &&
              !linkStates[index].active,
            'border-solid border-blue text-blue':
              linkStates[index].active === true,
            'border-transparent': !linkStates[index].active,
          }"
          class="mb-0 box-border flex flex-row items-center justify-center border-b-1 border-l-0 border-r-0 border-t-0 pb-0 font-medium"
        >
          {{ link.text }}
        </p>
        <IconsSvgChevron
          class="tn--chevron ml-1/2 transition-all delay-200"
          :stroke="
            isTransparent &&
            !navigationActive &&
            (scrollDirection === NavigationDirection.TOP ||
              scrollDirection === NavigationDirection.UNSET)
              ? 'white'
              : '#00263e'
          "
          :class="{
            '-rotate-90': linkStates[index].active,
            'rotate-90': !linkStates[index].active,
          }"
        />
      </li>
    </ul>
    <ul class="tn--nav-buttons h-full flex-row items-center justify-between">
      <li
        v-if="countries.length > 0"
        class="border-b-0 border-l-0 border-r-1 border-t-0 border-solid pr-1/2"
        :class="{
          'border-white':
            (scrollDirection === NavigationDirection.TOP ||
              scrollDirection === NavigationDirection.UNSET) &&
            isTransparent &&
            !navigationActive,
        }"
      >
        <ButtonsTheLocaleSelector
          :transparent="
            (scrollDirection === NavigationDirection.TOP ||
              scrollDirection === NavigationDirection.UNSET) &&
            isTransparent &&
            !navigationActive
              ? true
              : false
          "
          :countries="countries"
          tabindex="0"
          class="focus-visible:outline-1"
        />
      </li>
      <li
        v-for="(button, index) in navButtons"
        :key="button.sys.contentType.sys.id"
        :class="{ 'pl-1/2': index === 0 }"
      >
        <TypographyCustomText
          v-if="button.sys.contentType.sys.id === 'customText'"
          :custom-text="button.fields"
          class="mr-[30px]"
        />
        <ButtonsTheButton
          v-else-if="stickyHeader && stickyHeader.fields.button"
          :button="stickyHeader.fields.button.fields"
        />
        <ButtonsTheButton
          v-else-if="button.sys.contentType.sys.id === 'button'"
          :button="button.fields"
        />
      </li>
    </ul>
    <div
      class="absolute right-[16px] top-[50%] flex h-[22px] translate-y-[-50%] flex-row items-center justify-center"
    >
      <NuxtLink
        :to="
          !supportedEULocales.includes(locale)
            ? 'https://cloud.samsara.com/'
            : 'https://cloud.eu.samsara.com/'
        "
        target="_blank"
        :class="{
          'border-[#D6DBE1]':
            !isTransparent || scrollDirection === NavigationDirection.UP,
          'border-white':
            (scrollDirection === NavigationDirection.TOP ||
              scrollDirection === NavigationDirection.UNSET) &&
            isTransparent &&
            !navigationActive,
        }"
        class="tn--nav-profile h-full w-full border-1 border-b-0 border-l-0 border-r-1 border-t-0 border-solid pr-1/2"
        ><IconsSvgProfile
          :stroke="
            scrollDirection === NavigationDirection.UP || !isTransparent
              ? '#00263E'
              : 'white'
          "
        />
      </NuxtLink>
      <button
        class="tn--nav-toggle z-60 w-full pl-1/2"
        @click.prevent="toggleMobileMenu"
      >
        <IconsSvgMobileMenu
          v-show="!isMobileActive"
          :stroke="
            scrollDirection === NavigationDirection.UP || !isTransparent
              ? '#00263E'
              : 'white'
          "
          class="w-full"
        />
        <IconsSvgCloseIcon
          v-show="isMobileActive"
          class="z-20"
          :stroke="isMobileActive ? '#00263E' : 'white'"
        />
      </button>
    </div>
  </nav>

  <template v-if="!linksPending && !entriesPending && !isMobileActive">
    <nav
      v-show="navigationActive"
      ref="navMenuDesktop"
      class="tn--nav-desktop container fixed left-[50%] right-0 z-50 hidden h-auto translate-x-[-50%] bg-white !px-0 md:top-[75px] md:block"
    >
      <IconsSvgCloseIcon
        tabindex="0"
        class="absolute right-[16px] top-[16px] z-30 transition-all hover:scale-110 hover:cursor-pointer"
        stroke="#00263e"
        role="button"
        @click="setActiveMenuItem(null)"
      />
      <ul
        v-for="(parentLink, parentIndex) in links"
        :key="parentIndex"
        class="items-left flex h-full flex-col"
        @click="setActiveMenuItem(parentIndex)"
      >
        <li
          v-show="linkStates[parentIndex].active === true"
          class="ml-0 flex w-full flex-row transition-all"
        >
          <ul
            v-for="(groupItem, groupIndex) in parentLink.groupsCollection.items"
            :key="groupItem"
            tabindex="0"
            :class="{
              'w-6/12':
                (groupIndex === 0 && parentIndex === 0) ||
                (groupIndex === 0 && parentIndex === 1) ||
                parentIndex === links.length - 1,
              'w-3/12':
                (groupIndex > 0 && parentIndex === 0) ||
                (groupIndex > 0 && parentIndex === 1),
            }"
            class="cursor-default"
          >
            <li
              :class="{
                'pl-2 pr-1': groupIndex === 0,
                'px-1':
                  groupIndex > 0 &&
                  groupIndex < parentLink.groupsCollection.items.length,
                'pr-2':
                  groupIndex === parentLink.groupsCollection.items.length - 1,
              }"
              class="tn--group relative h-full w-full py-3 transition-all hover:bg-blue-lighter"
            >
              <ul v-if="parentIndex === 0" tabindex="0">
                <li
                  v-for="(childLink, childIndex) in groupItem.linksCollection
                    .items"
                  :key="childLink"
                  :class="linkClasses(childLink, childIndex, parentIndex)"
                >
                  <NuxtLink
                    v-if="childLink.text && childLink.link"
                    tabindex="0"
                    :target="childLink?.newWindow ? '_blank' : undefined"
                    :to="localePath(childLink.link)"
                    :class="{
                      'inline-block text-xs font-bold uppercase':
                        childLink?.type === 'Header',
                      'tn--link-active': isActiveLink(childLink.link),
                    }"
                    class="tn--link mb-0 block font-medium"
                  >
                    <div class="justify-left flex flex-row items-center">
                      <span
                        :class="{
                          'tn--link-header tracking-[1px]':
                            childLink?.type === 'Header',
                          'tn--link-text tracking-normal ':
                            childLink?.type !== 'Header' ||
                            childLink?.type === null,
                        }"
                        class="w-fit text-blue-dark"
                      >
                        {{ childLink.text }}
                      </span>
                      <IconsExternalLink
                        v-if="
                          childLink?.newWindow && childLink?.type !== 'Header'
                        "
                        class="relative ml-1/4 transition-all delay-200"
                      />
                      <IconsArrowIcon
                        v-else
                        :class="{
                          'tn--header-icon': childLink?.type === 'Header',
                          'tn--link-icon translate-x-[-3px] opacity-0':
                            childLink?.type !== 'Header' ||
                            childLink?.type === null,
                        }"
                        class="ml-[8px] transition-all delay-200"
                      />
                    </div>
                    <span
                      v-if="childLink.previewText"
                      class="tn--link-preview mb-0 block pb-0 pr-1 pt-[3px] text-xs font-normal tracking-normal text-gray-dark"
                    >
                      {{ childLink.previewText }}
                    </span></NuxtLink
                  >
                  <p
                    v-else-if="
                      childLink.text &&
                      childLink?.type === 'Header' &&
                      childLink.link === null
                    "
                    class="tn--link-text mb-0 block w-max text-xs font-bold uppercase tracking-normal text-blue-dark"
                  >
                    {{ childLink.text }}
                  </p>
                </li>
              </ul>
              <template v-else-if="parentIndex > 0">
                <template v-if="groupIndex === 0">
                  <div
                    v-for="(
                      childLink, childIndex
                    ) in groupItem.linksCollection.items.slice(0, 1)"
                    :key="childLink"
                    :class="{
                      'tn--nav-header border-b-1 border-l-0 border-r-0 border-t-0 border-solid pb-1 pt-0':
                        childLink?.type === 'Header',
                      'w-full': childIndex === 0,
                    }"
                    class="tn--nav-animateY cursor-default opacity-0"
                  >
                    <NuxtLink
                      v-if="childLink.text && childLink.link"
                      :target="childLink?.newWindow ? '_blank' : undefined"
                      :to="localePath(childLink.link)"
                      :class="{
                        'inline-block text-xs font-bold uppercase':
                          childLink?.type === 'Header',
                        'tn--link-active': isActiveLink(childLink.link),
                      }"
                      class="tn--link mb-0 block font-medium"
                    >
                      <div class="justify-left flex flex-row items-center">
                        <span
                          :class="{
                            'tn--link-header tracking-[1px]':
                              childLink?.type === 'Header',
                            'tn--link-text w-fit tracking-normal':
                              childLink?.type !== 'Header' ||
                              childLink?.type === null,
                          }"
                          class="text-blue-dark"
                        >
                          {{ childLink.text }}
                        </span>
                        <IconsExternalLink
                          v-if="
                            childLink?.newWindow && childLink?.type !== 'Header'
                          "
                          class="relative ml-1/4 transition-all delay-200"
                        />
                        <IconsArrowIcon
                          v-else
                          :class="{
                            'tn--header-icon': childLink?.type === 'Header',
                            'tn--link-icon translate-x-[-3px] opacity-0':
                              childLink?.type !== 'Header' ||
                              childLink?.type === null,
                          }"
                          class="ml-[8px] transition-all delay-200"
                        />
                      </div>
                      <span
                        v-if="childLink.previewText"
                        class="tn--link-preview mb-0 block pb-0 pr-1 pt-[3px] text-xs font-normal tracking-normal text-gray-dark"
                      >
                        {{ childLink.previewText }}
                      </span></NuxtLink
                    >
                    <p
                      v-else-if="
                        childLink.text &&
                        childLink?.type === 'Header' &&
                        childLink.link === null
                      "
                      class="tn--link mb-0 block w-max text-xs font-bold uppercase tracking-normal"
                    >
                      {{ childLink.text }}
                    </p>
                  </div>
                  <div
                    class="tn--nav-animateY flex flex-row flex-wrap pt-1 opacity-0"
                  >
                    <div
                      v-for="childLink in groupItem.linksCollection.items.slice(
                        1,
                        groupItem.linksCollection.items.length
                      )"
                      :key="childLink"
                      :class="{
                        'tn--nav-header border-b-1 border-l-0 border-r-0 border-t-0 border-solid pb-1 pt-0':
                          childLink?.type === 'Header',
                        'pb-1':
                          childLink?.type === 'Default' ||
                          childLink?.type === null,
                      }"
                      class="w-6/12"
                    >
                      <NuxtLink
                        v-if="childLink.text && childLink.link"
                        :target="childLink?.newWindow ? '_blank' : undefined"
                        :to="localePath(childLink.link)"
                        :class="{
                          'inline-block text-xs font-bold uppercase':
                            childLink?.type === 'Header',
                          'tn--link-active': isActiveLink(childLink.link),
                        }"
                        class="tn--link mb-0 block font-medium"
                      >
                        <div class="justify-left flex flex-row items-center">
                          <span
                            :class="{
                              'tn--link-header tracking-[1px]':
                                childLink?.type === 'Header',
                              'tn--link-text w-fit tracking-normal':
                                childLink?.type !== 'Header' ||
                                childLink?.type === null,
                            }"
                            class="text-blue-dark"
                          >
                            {{ childLink.text }}
                          </span>
                          <IconsExternalLink
                            v-if="
                              childLink?.newWindow &&
                              childLink?.type !== 'Header'
                            "
                            class="relative ml-1/4 transition-all delay-200"
                          />
                          <IconsArrowIcon
                            v-else
                            :class="{
                              'tn--header-icon': childLink?.type === 'Header',
                              'tn--link-icon translate-x-[-3px] opacity-0':
                                childLink?.type !== 'Header' ||
                                childLink?.type === null,
                            }"
                            class="ml-[8px] transition-all delay-200"
                          />
                        </div>
                        <span
                          v-if="childLink.previewText"
                          class="tn--link-preview mb-0 block pb-0 pr-1 pt-[3px] text-xs font-normal tracking-normal text-gray-dark"
                        >
                          {{ childLink.previewText }}
                        </span></NuxtLink
                      >
                      <p
                        v-else-if="
                          childLink.text &&
                          childLink?.type === 'Header' &&
                          childLink.link === null
                        "
                        class="tn--link mb-0 block w-max text-xs font-bold uppercase tracking-normal"
                      >
                        {{ childLink.text }}
                      </p>
                    </div>
                  </div>
                </template>
                <div
                  v-for="(childLink, childIndex) in groupItem.linksCollection
                    .items"
                  v-else
                  :key="childLink"
                  :class="{
                    'tn--nav-header border-b-1 border-l-0 border-r-0 border-t-0 border-solid pb-1 pt-0':
                      childLink?.type === 'Header',
                    'pb-1':
                      childLink?.type === 'Default' || childLink?.type === null,
                    'pt-1': childIndex === 1,
                  }"
                  class="tn--nav-animateY cursor-default opacity-0"
                >
                  <NuxtLink
                    v-if="childLink.text && childLink.link"
                    :target="childLink?.newWindow ? '_blank' : undefined"
                    :to="localePath(childLink.link)"
                    :class="{
                      'inline-block text-xs font-bold uppercase':
                        childLink?.type === 'Header',
                      'tn--link-active': isActiveLink(childLink.link),
                    }"
                    :data-testid="
                      childLink.link.includes(ResourceTypes.WEBINARS)
                        ? ResourceTypes.WEBINARS + '-link'
                        : ''
                    "
                    class="tn--link mb-0 block font-medium"
                  >
                    <div class="justify-left flex flex-row items-center">
                      <span
                        :class="{
                          'tn--link-header tracking-[1px]':
                            childLink?.type === 'Header',
                          'tn--link-text w-fit tracking-normal':
                            childLink?.type !== 'Header' ||
                            childLink?.type === null,
                        }"
                        class="text-blue-dark"
                      >
                        {{ childLink.text }}
                      </span>
                      <IconsExternalLink
                        v-if="
                          childLink?.newWindow && childLink?.type !== 'Header'
                        "
                        class="relative ml-1/4 transition-all delay-200"
                      />
                      <IconsArrowIcon
                        v-else
                        :class="{
                          'tn--header-icon': childLink?.type === 'Header',
                          'tn--link-icon translate-x-[-3px] opacity-0':
                            childLink?.type !== 'Header' ||
                            childLink?.type === null,
                        }"
                        class="ml-[8px] transition-all delay-200"
                      />
                    </div>
                    <span
                      v-if="childLink.previewText"
                      class="tn--link-preview mb-0 block pb-0 pr-1 pt-[3px] text-xs font-normal tracking-normal text-gray-dark"
                    >
                      {{ childLink.previewText }}
                    </span></NuxtLink
                  >
                  <p
                    v-else-if="
                      childLink.text &&
                      childLink?.type === 'Header' &&
                      childLink.link === null
                    "
                    class="tn--link mb-0 block w-max text-xs font-bold uppercase tracking-normal"
                  >
                    {{ childLink.text }}
                  </p>
                </div>
              </template>
            </li>
          </ul>
        </li>
      </ul>
    </nav>
  </template>
  <nav
    v-if="!linksPending && !entriesPending && isMobileActive"
    :class="{ active: isMobileActive }"
    :style="{ height: `${mobileHeight}px` }"
    class="tn--nav-mobile fixed right-0 top-0 z-50 w-[calc(100%-20px)] bg-white md:w-[calc(100%-83px)]"
  >
    <div class="relative h-[52px] md:h-[75px]">
      <button
        tabindex="0"
        class="tn--nav-toggle z-60 absolute right-[16px] top-[50%] h-[22px] w-[20px] translate-y-[-50%]"
        @click.prevent="toggleMobileMenu"
      >
        <IconsSvgCloseIcon
          v-show="isMobileActive"
          class="h-full w-full"
          :stroke="isMobileActive ? '#00263E' : 'white'"
        />
      </button>
    </div>

    <div
      class="relative z-10 h-[calc(100%-127px)] w-full md:h-[calc(100%-150px)]"
    >
      <ul class="items-left flex h-full flex-col overflow-y-auto">
        <li
          v-for="(parentLink, parentIndex) in links"
          :key="parentIndex"
          role="button"
          :aria-current="linkStates[parentIndex].active ? true : false"
          :class="{
            'border-none ': linkStates[parentIndex].active,
          }"
          class="pb-[24px]"
        >
          <div
            :class="{
              'pb-[24px] text-blue': linkStates[parentIndex].active === true,
            }"
            class="sticky top-0 z-10 flex flex-row items-center justify-between bg-white px-1 text-[20px] font-medium leading-[30px] transition-all"
            @click="setActiveMenuItem(parentIndex)"
          >
            {{ parentLink.text }}
            <IconsSvgChevron
              class="transition-all"
              :stroke="
                linkStates[parentIndex].active === true ? '#0384FB' : '#00263E'
              "
              :class="{
                '-rotate-90': linkStates[parentIndex].active === true,
                'rotate-90': linkStates[parentIndex].active !== true,
              }"
            />
            <div
              :class="{ hidden: linkStates[parentIndex].active !== true }"
              class="absolute bottom-0 left-[50%] h-[1px] w-[90%] translate-x-[-50%] bg-[#00263E]"
            ></div>
          </div>
          <ul
            :class="{
              'max-h-full': linkStates[parentIndex].active === true,
              'max-h-0 overflow-hidden':
                linkStates[parentIndex].active === false,
            }"
            class="transition-all"
          >
            <li
              v-for="groupItem in parentLink.groupsCollection.items"
              :key="groupItem"
              class="relative pt-[24px] last:pb-[24px]"
            >
              <ul>
                <li
                  v-for="(childLink, childIndex) in groupItem.linksCollection
                    .items"
                  :key="childLink"
                  class="tn--nav-animateY opacity-0"
                >
                  <NuxtLink
                    v-if="childLink.text && childLink.link"
                    :target="childLink?.newWindow ? '_blank' : undefined"
                    :to="localePath(childLink.link)"
                    :class="{
                      'text-xs font-bold uppercase ':
                        childLink?.type === 'Header',
                      'pb-0':
                        childIndex ===
                        groupItem.linksCollection.items.length - 1,
                      'py-1/2':
                        childIndex < groupItem.linksCollection.items.length,
                      'tn--link-active': isActiveLink(childLink.link),
                    }"
                    class="tn--link justify-left mb-0 flex h-full flex-col items-center px-2 focus:bg-blue-lighter active:bg-blue-lighter"
                  >
                    <div
                      class="relative h-full w-full"
                      :class="{
                        'border-b-1 border-l-0 border-r-0 border-t-0 border-solid border-[#D6DBE1] pb-[24px]':
                          childIndex ===
                          groupItem.linksCollection.items.length - 1,
                      }"
                    >
                      <div class="justify-left flex flex-row items-center">
                        <span
                          :class="{
                            'tn--link-header font-bold tracking-[1px]':
                              childLink?.type === 'Header',
                            'tn--link font-medium':
                              childLink?.type !== 'Header' ||
                              childLink?.type === null,
                          }"
                          class="text-blue-dark focus:text-blue"
                        >
                          {{ childLink.text }}
                        </span>
                      </div>
                      <span
                        v-if="childLink.previewText"
                        class="tn--link-preview mb-0 block pb-0 pr-1 pt-[3px] text-xs font-normal tracking-normal text-gray-dark"
                      >
                        {{ childLink.previewText }}
                      </span>
                    </div>
                  </NuxtLink>
                  <p
                    v-else-if="
                      childLink.text &&
                      childLink?.type === 'Header' &&
                      childLink.link === null
                    "
                    class="tn--link mb-0 block w-full px-2 pb-1/2 text-xs font-bold uppercase tracking-[1px] focus:text-blue-dark active:text-blue-dark"
                  >
                    {{ childLink.text }}
                  </p>
                </li>
              </ul>
            </li>
          </ul>
        </li>
        <li>
          <ButtonsTheLocaleSelector
            class="justify-left flex flex-row border border-b-0 border-l-0 border-r-0 border-t-1 px-1 py-1"
            :transparent="false"
            :countries="countries"
          />
        </li>
      </ul>
    </div>

    <ul
      class="tn--buttons-mobile absolute bottom-0 z-20 flex h-[75px] w-full items-center justify-evenly border border-b-0 border-l-0 border-r-0 border-t-1 border-solid bg-white px-1"
    >
      <li
        v-for="button in navButtons"
        :key="button.sys.contentType.sys.id"
        class="flex w-6/12 flex-row items-center justify-center first:pr-[12px]"
      >
        <div class="block w-full">
          <ButtonsTheButton
            v-if="button.sys.contentType.sys.id === 'button'"
            :button="button.fields"
          />
          <TypographyCustomText
            v-else-if="button.sys.contentType.sys.id === 'customText'"
            :custom-text="button.fields"
          />
          <ButtonsTheButton
            v-else-if="stickyHeader && stickyHeader.fields.button"
            :button="stickyHeader.fields.button.fields"
          />
        </div>
      </li>
    </ul>
  </nav>
  <nav
    v-if="stickyHeader?.fields?.links !== undefined"
    class="secondary-navigation fixed z-40 w-full transition-all"
    :class="secondaryNavigationClasses"
  >
    <p
      role="button"
      class="mb-0 block bg-blue-dark px-1 py-1 text-[20px] font-medium capitalize text-white md:hidden md:px-0 md:py-0"
      :class="{ 'mobile-navigation-active': secondaryNavExpanded }"
      @click="expandSecondaryNavigation"
    >
      {{ secondaryNavigationTitle
      }}<IconsSvgChevron
        :stroke="'white'"
        class="ml-1 inline scale-110 transition-all"
      ></IconsSvgChevron>
    </p>
    <nav
      class="origin-top-center absolute w-full bg-blue-lighter transition-all md:relative"
      :class="{
        'max-h-0 overflow-hidden md:max-h-fit': !secondaryNavExpanded,
        'max-h-[500px] overflow-visible': secondaryNavExpanded,
      }"
    >
      <ul
        v-if="stickyHeader?.fields?.links"
        class="justify-left flex h-full flex-col pb-1 md:flex-row md:pb-0"
      >
        <li
          v-for="(item, index) in stickyHeader.fields.links"
          :key="index"
          class="first:text-dark-blue flex h-full items-center px-1 pt-1/2 text-blue-dark first:font-bold md:py-1 md:first:bg-blue-dark md:first:text-white [&:not(:first-child)]:font-medium"
          :class="{
            'link-active': isActiveLink(item?.fields?.linkUrl || '/'),
            'py-1/2 [&:not(:first-child)]:hover:text-blue': !isActiveLink(
              item?.fields?.linkUrl || '/'
            ),
          }"
        >
          <template v-if="item.sys.contentType.sys.id === 'arrowlink'">
            <span
              v-if="!isActiveLink(item?.fields?.linkUrl || '/')"
              class="relative h-full w-fit"
            >
              <LinksArrowLink :arrow-link="item.fields" class="text-[14px]" />
            </span>
            <span v-else class="relative h-full w-fit pb-1/2 md:w-full md:py-0">
              <p
                class="mb-0 px-0 text-[14px] font-medium md:py-0"
                aria-current="page"
              >
                {{ item?.fields?.text || 'nav item' }}
              </p>
            </span>
          </template>
        </li>
      </ul>
    </nav>
  </nav>
</template>

<script setup>
  import { useWindowSize } from '@vueuse/core';
  import { localeMappings, supportedEULocales } from '~/constants/locales';
  import { NavigationDirection, ResourceTypes } from '~/types/enums';

  const props = defineProps({
    isTransparent: {
      type: Boolean,
      required: false,
      default: false,
    },
    hidePricing: {
      type: Boolean,
      required: false,
      default: false,
    },
    bannerClosed: {
      type: Boolean,
      required: false,
      default: false,
    },
    customPricing: {
      type: String,
      required: false,
      default: null,
    },
    secondaryNavigationTitle: {
      type: String,
      required: false,
      default: () => {
        return 'Menu';
      },
    },
    stickyHeader: {
      type: Object,
      required: false,
      default: () => {
        return null;
      },
    },
  });

  const { fullPath } = useRoute();
  const { isSandbox, isStaging } = useRuntimeConfig().public;
  const { $contentfulClient } = useNuxtApp();
  const { locale } = useI18n();
  const { width, height } = useWindowSize();
  const scrollDirection = useScrollDirection();
  const { isMobile } = useDevice();
  const localePath = useLocalePath();

  const navMenuDesktop = ref(null);
  const mobileHeight = ref(null);
  const isMobileActive = ref(false);
  const navigationActive = ref(false);
  const secondaryNavExpanded = ref(false);
  const activeParentIndex = ref(null);

  const {
    data: entries,
    pending: entriesPending,
    error: entriesError,
  } = await useAsyncData(() => {
    return $contentfulClient.getEntries({
      content_type: 'navHeader',
      'sys.id': '3JVZ0fPj8bAWsUzKOfCoM',
      locale: localeMappings[locale.value],
    });
  });

  const {
    data,
    error: linksError,
    pending: linksPending,
  } = await useAsyncGql({
    operation: 'Navigation',
    variables: {
      id: '3JVZ0fPj8bAWsUzKOfCoM',
      locale: localeMappings[locale.value],
      preview: isSandbox || isStaging,
    },
  });

  if (entriesError.value || linksError.value) {
    throw createError({ statusCode: 404 });
  }

  const links = data.value.navHeader.linksCollection.items;
  const linkStates = reactive(
    Array(links.length)
      .fill()
      .map(() => ({ active: false }))
  );

  const navButtons = entries.value.items[0].fields.buttons;
  const countries = entries.value.items[0].fields.countryDropdowns.countries;

  const desktopClasses = computed(() => {
    return {
      'translate-y-[-52px] md:translate-y-[-75px] bg-white border-color-[#A7B2BE] border-b-1 border-l-0 border-r-0 border-t-0 border-solid':
        scrollDirection.value === NavigationDirection.DOWN,
      'border-color-[#A7B2BE] translate-y-[0px] border-b-1 border-l-0 border-r-0 border-t-0 border-solid md:translate-y-[0px]':
        scrollDirection.value === NavigationDirection.UP ||
        scrollDirection.value === NavigationDirection.UNSET,
      'bg-white':
        (navigationActive.value ||
          scrollDirection.value === NavigationDirection.UP ||
          !props.isTransparent) &&
        !isMobileActive.value,
      'tn--transparent border-none bg-transparent border-b-1 border-l-0 border-r-0 border-t-0 border-transparent':
        (scrollDirection.value === NavigationDirection.UNSET ||
          scrollDirection.value === NavigationDirection.TOP) &&
        !navigationActive.value &&
        props.isTransparent &&
        scrollDirection.value !== NavigationDirection.UP,
      'z-50 ': !isMobileActive.value,
    };
  });

  const secondaryNavigationClasses = computed(() => {
    return {
      'top-[52px] md:top-[75px]':
        scrollDirection.value === NavigationDirection.UP ||
        scrollDirection.value === NavigationDirection.TOP ||
        scrollDirection.value === NavigationDirection.UNSET,
      'top-0': scrollDirection.value === NavigationDirection.DOWN,
    };
  });

  const removeAnimationClass = () => {
    if (navMenuDesktop.value) {
      navMenuDesktop.value.classList.remove('animated');
    }
  };

  watch(width, (newWidth) => {
    if (newWidth > 1078) {
      isMobileActive.value = false;
      navigationActive.value = false;
      setActiveMenuItem(null);
      removeOverlay();
    }
  });

  watch(height, (newHeight) => {
    mobileHeight.value = newHeight;
  });

  watch(navigationActive, (newValue) => {
    if ((!newValue || newValue === undefined) && !isMobile.value) {
      removeAnimationClass();
    }
  });

  const isActiveLink = computed(() => (link) => link === fullPath);

  const setActiveParentIndex = () => {
    links.forEach((link, index) => {
      if (link.groupsCollection?.items) {
        link.groupsCollection.items.forEach((group) => {
          if (group.linksCollection?.items) {
            group.linksCollection.items.forEach((childLink) => {
              if (childLink.link && isActiveLink.value(childLink.link)) {
                activeParentIndex.value = index;
              }
            });
          }
        });
      }
    });
  };

  const expandSecondaryNavigation = () => {
    secondaryNavExpanded.value = !secondaryNavExpanded.value;
  };

  const toggleMobileMenu = () => {
    isMobileActive.value = !isMobileActive.value;
    if (isMobileActive.value) {
      addOverlay();
    } else {
      removeOverlay();
    }
  };

  const linkClasses = (link, index) => {
    const headerClasses =
      link?.type === 'Header'
        ? 'border-solid border-b-1 border-r-0 border-l-0 border-t-0 tn--nav-header pt-0 pb-1'
        : '';
    const defaultClasses =
      link?.type === 'Default' || link?.type === null ? 'pb-1' : '';
    const pt1 = index === 1 ? 'pt-1' : '';

    return `${headerClasses} ${defaultClasses} ${pt1} tn--nav-animateY opacity-0`;
  };

  const addAnimationClass = () => {
    if (navMenuDesktop.value) {
      if (!navMenuDesktop.value.classList.contains('animated')) {
        setTimeout(() => {
          navMenuDesktop.value.classList.add('animated');
        }, 200);
      }
    }
  };

  const checkNavigationStateDesktop = () => {
    for (let i = 0; i < linkStates.length; i++) {
      if (linkStates[i].active) {
        navigationActive.value = true;
        addOverlay();
        addAnimationClass();
        break;
      } else {
        navigationActive.value = false;
        removeOverlay();
      }
    }
  };

  const addOverlay = () => {
    if (!document.querySelector('.overlay')) {
      const overlay = document.createElement('div');
      overlay.classList.add('overlay');
      document.body.appendChild(overlay);
      document.querySelector('html').classList.add('overflow-y-hidden');
    }
  };

  const removeOverlay = () => {
    if (document.querySelector('.overlay')) {
      document.querySelector('.overlay').remove();
      document.querySelector('html').classList.remove('overflow-y-hidden');
    }
  };

  const setActiveMenuItem = (index) => {
    linkStates.forEach((linkState, i) => {
      if (i === index) {
        linkState.active = !linkState.active;
      } else {
        linkState.active = false;
      }
    });

    if (!isMobile.value) {
      checkNavigationStateDesktop();
    }
  };

  const handleBodyClick = (event) => {
    if (event.target && event.target.className === 'overlay') {
      setActiveMenuItem(null);
    }
  };

  onBeforeMount(() => {
    setActiveParentIndex();
  });

  onBeforeUnmount(() => {
    removeOverlay();
  });

  onMounted(() => {
    mobileHeight.value = window.innerHeight;
    document.body.addEventListener('click', handleBodyClick, true);
  });

  onUnmounted(() => {
    document.body.removeEventListener('click', handleBodyClick);
  });
</script>

<style lang="scss" scoped>
  .tn {
    &--transparent {
      :deep(.tn--nav-login) {
        @apply text-white;
      }
      .tn--nav-item {
        &.selected {
          :deep(.tn--chevron) {
            path {
              @apply stroke-white;
            }
          }
          p {
            @apply border-white text-white;
          }
        }
      }
    }
    &--nav {
      &-animateY {
        @apply ease-in-out;
        animation-name: slide-in;
        animation-duration: 325ms;
        animation-fill-mode: forwards;
      }
      &-desktop {
        &.animated {
          .tn--nav-animateY {
            animation: none !important;
            opacity: 1 !important;
          }
        }
      }
      &-desktop,
      &-mobile {
        .tn--buttons-mobile {
          li {
            span {
              @apply flex w-full items-center justify-center;
            }
            .btn {
              @apply min-w-max;
            }
            :deep(.tn--nav-login),
            .btn {
              @apply flex h-2 w-full items-center justify-center p-0;
            }
            :deep(.tn--nav-login) {
              @apply border-1 border-transparent  bg-white text-center;
            }
          }
          @media (min-width: 768px) {
            li {
              :deep(.tn--nav-login) {
                @apply border-blue;
              }
            }
          }
        }
      }
      &-buttons,
      &-menu,
      &-toggle,
      &-profile {
        @apply hidden;
      }
      &-buttons,
      &-menu {
        @screen lg-min {
          @apply flex;
        }
      }
      &-buttons {
        :deep(.btn) {
          @apply px-[20px] py-1/2 text-[11px];
        }
      }
      &-toggle,
      &-profile {
        @screen lg-max {
          @apply block;
        }
      }
      @screen lg-min {
        &:hover {
          @apply bg-white;
          .tn--nav-item {
            &.selected {
              :deep(.tn--chevron) {
                path {
                  @apply stroke-blue;
                }
              }
              p {
                @apply border-blue text-blue;
              }
            }
          }
          .tn--nav-buttons {
            li:first-child {
              @apply border-[#D6DBE1];
            }
          }
          .tn-logo {
            :deep(g) {
              @apply fill-blue-dark;
            }
          }
          :deep(.tn--nav-login) {
            @apply text-blue-dark;
            &:hover {
              @apply text-blue;
            }
          }
          p {
            @apply text-blue-dark;
          }
          .tn--chevron {
            :deep(path) {
              @apply stroke-blue-dark;
            }
          }
          .tn--nav-toggle {
            span {
              @apply bg-blue-dark;
            }
          }
          :deep(.stw--the-world-icon) {
            circle,
            ellipse,
            line {
              @apply stroke-blue-dark;
            }
          }
          :deep(.tls--select) {
            @apply text-blue-dark;
          }
        }
      }

      &-item {
        @screen md {
          &:hover {
            p {
              @apply border-b-1 border-l-0 border-r-0 border-t-0 border-solid border-blue text-blue;
            }
            .tn--chevron {
              :deep(path) {
                @apply stroke-blue;
              }
            }
          }
        }
      }
    }
    &--group {
      &:hover {
        .tn--nav-header {
          @apply border-blue-dark;
        }
      }
    }
    &--header-icon {
      :deep(path) {
        @apply stroke-blue-dark;
      }
      :deep(path.ai--path-arrow) {
        @apply -translate-x-1/4;
      }
      :deep(path.ai--path-body) {
        @apply opacity-0;
      }
    }
    &--link {
      &:active,
      :focus {
        @apply text-blue;
        .tn--link-preview {
          @apply text-gray-dark;
        }
      }
      &:hover {
        .tn--link-text,
        .tn--link-header {
          @apply text-blue;
        }
        .tn--link-icon {
          @apply translate-x-0 opacity-100 ease-in-out;
        }
        .tn--header-icon {
          :deep(path.ai--path-arrow) {
            @apply translate-x-0 stroke-blue;
          }
          :deep(path.ai--path-body) {
            @apply stroke-blue opacity-100;
          }
        }
        :deep(.el--external-link-icon) {
          div {
            @apply bg-blue;
          }
          .el--border-left {
            @apply scale-y-0;
          }
          .el--border-bottom {
            @apply scale-x-0;
          }
          .el--arrow,
          .el--arrowbody {
            @apply translate-x-[1px] translate-y-[-1px];
          }
          .el--arrowbody {
            @apply h-full;
          }
        }
      }
      &-active {
        @apply pointer-events-none border-transparent;
        .tn--link-text,
        .tn--link {
          @apply border-b-1 border-l-0 border-r-0 border-t-0 border-solid border-blue text-blue;
        }
      }
    }
  }

  .tn--nav-item {
    &.active,
    &.selected {
      :deep(.tn--chevron) {
        path {
          @apply stroke-blue;
        }
      }
    }

    &.selected {
      p {
        @apply border-b-1 border-l-0 border-r-0 border-t-0 border-solid border-blue text-blue;
      }
    }
  }

  .secondary-navigation {
    .mobile-secondary-navigation-active {
      svg {
        transform: rotateX(180deg);
      }
    }
    nav {
      li:not(:first-child) {
        &.link-active {
          span {
            &:after {
              content: '';
              background-color: theme('colors.blue.DEFAULT');
              @apply absolute h-[2px] w-full;
            }
          }
          p {
            color: theme('colors.blue.DEFAULT');
          }
        }
      }
      li:first-child {
        p,
        a {
          @apply font-bold text-blue-dark md:text-white;
        }
      }
    }
  }

  @keyframes slide-in {
    from {
      transform: translateY(-10px);
      opacity: 0;
    }
    to {
      transform: translateY(0px);
      opacity: 1;
    }
  }
</style>
