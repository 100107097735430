import * as Sentry from '@sentry/vue';

export default defineNuxtPlugin((nuxtApp) => {
  const router = useRouter();
  const {
    public: { sentry },
  } = useRuntimeConfig();

  if (sentry.dsn && !sentry.disabled) {
    Sentry.init({
      app: nuxtApp.vueApp,
      dsn: sentry.dsn,
      environment: sentry.environment,

      integrations: [
        new Sentry.BrowserTracing({
          routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        }),
        new Sentry.Replay(),
      ],

      // Configure this whole part as you need it!
      tracesSampleRate: 0.2,
      tracingOptions: {
        trackComponents: true,
        hooks: ['activate', 'create', 'unmount', 'mount', 'update'],
      },

      // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: [
        'https://samsara.com',
        'https://samsara-staging.com/',
      ],

      replaysSessionSampleRate: 0.1,
      replaysOnErrorSampleRate: 1.0,
    });
  }
});
