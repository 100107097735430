import robots from '~/constants/bots-list';

async function analyticsPage(customProperties = {}, silent = false) {
  const { isProduction } = useRuntimeConfig().public;
  const analytics = window.analytics || {};

  if (analytics.initialized) {
    try {
      const route = useRoute();
      const { isMobile } = useDevice();
      const queryParameters = route.query;
      const userAgent = window.navigator.userAgent || "";

      const baseProperties = {
        cid: queryParameters.cid || '',
        gclid: queryParameters.gclid || '',
        keyword: queryParameters.keyword || '',
        msclkid: queryParameters.msclkid || '',
        utm_ext_ad_id: queryParameters.utm_ext_ad_id || '',
        utm_ext_adset_id: queryParameters.utm_ext_adset_id || '',
        utm_ext_campaign_id: queryParameters.utm_ext_campaign_id || '',
        is_mobile: isMobile,
        user_agent_mp: userAgent,
        app: 'horizon',
      };

      const properties = { ...baseProperties, ...customProperties };

      const isRobot = robots.some((robot) =>
        userAgent.toLowerCase().includes(robot)
      );

      if (!isRobot) {
        await analytics.page(properties);

        await analytics.track('page viewed', {
          ...properties,
          ...{
            'page name': document.title,
            url: window.location.pathname,
          },
        });
      }
    } catch (error) {
      if (isProduction && !silent) {
        report(error);
      }
    }
  }
}

export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.hook('page:loading:end', () => {
    analyticsPage();
  });
});
